var authenticationManager = require('authenticationManager');
var Sentry = require('@sentry/browser');
var env = require('env');
import { RewriteFrames } from '@sentry/integrations';
var { getItem } = require('@managers/localStorageManager.jsx');

export var init = (store) => {
  authenticationManager.init(store, env[env.stage].url);
  let userDetails = authenticationManager.getUserDetails();
  if (['prod', 'stg'].includes(env.stage)) {
    Sentry.init({
      dsn: 'https://a706d7c7ac2f4dc28d7e98c6389c1930@sentry.io/1390702',
      release: env.stage + '_' + env.version,
      environment: env.stage,
      integrations: [
        new RewriteFrames({
          root: global.__rootdir__,
        }),
      ],
    });
    Sentry.setTag('userEmail', userDetails?.email);
    Sentry.setTag(
      'selectedField',
      store.getState().distribution_data.selected_entities.field?.id,
    );
  } else {
    mixpanel.disable();
  }

  if (userDetails != null) {
    mixpanel.identify(userDetails.email);
    mixpanel.people.set({
      $last_login: new Date(),
      Units: userDetails.preferred_units,
    });
    mixpanel.track('Login', { Type: 'automatic' });
  }
};
