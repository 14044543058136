var React = require('react');
const { connect } = require('react-redux');
const { withTranslation, useTranslation } = require('react-i18next');
const { useEffect, useMemo } = require('react');
const $ = require('jquery');
var { upper } = require('@managers/helpers');

const WaterTreatmentComponent = React.memo((props) => {
  const { t } = useTranslation();
  const titlesColor = props.shouldDisplayAsDisabled
    ? 'text-disabled64'
    : 'text-grey45';
  const subTitlesColor = props.shouldDisplayAsDisabled
    ? 'text-disabled64'
    : 'text-grey71';
  const selectorClass = props.shouldDisplayAsDisabled
    ? 'disable-selector'
    : 'white-selector';
  const waterAnalysisTooltipID = `waterAnalysisTooltipID_${props.source}`;
  const waterAnalysisTooltipContainerID = `waterAnalysisTooltipContainerID_${props.source}`;
  const waterPropertiesTooltipID = `waterPropertiesTooltipID_${props.source}`;
  const waterPropertiesTooltipContainerID = `waterPropertiesTooltipContainerID_${props.source}`;

  useEffect(() => {
    // initialize tooltips
    $(function () {
      $('[data-toggle="tooltip"]').tooltip({ offset: '0,6' });
    });
  }, []);
  const WT_DATA = useMemo(
    () => ({
      water_source: ['Select...', 'river', 'well', 'reservoir', 'canal'],
      water_conveyance: ['Select...', 'open', 'closed', 'mix'],
      water_analysis: {
        organic: ['BOD<8', '8<BOD<12', 'BOD>13'],
        calc: ['Alc<80', '80<Alc<150', 'Alc<150'],
        fe: ['<0.3 pmm', '<0.3 pmm', '>0.5 pmm'],
      },
    }),
    [],
  );

  const WaterAnalysisTableRow = React.memo(
    ({ name, title, inputLabels, inputValues, onChange, lastRow = false }) => {
      return (
        <tr
          style={{
            paddingLeft: '16px',
            paddingRight: '36px',
            display: 'flex',
            fontSize: '14px',
            fontWeight: 'bold',
            paddingTop: '8px',
            paddingBottom: '8px',
            alignItems: 'flex-start',
            borderBottom: lastRow ? 'none' : '1px solid #DCE0E8',
          }}
        >
          <td className={'font-weight-bold mr-auto ' + titlesColor}>
            {upper(title)}
          </td>
          {inputLabels.map((label, index) => {
            let selectedElement = `${name}_${index}`;
            return (
              <td
                key={selectedElement}
                className='d-flex'
                style={{ width: '160px', padding: '4px 2px' }}
              >
                <input
                  disabled={props.shouldDisplayAsDisabled}
                  style={{ width: '16px', height: '16px', marginRight: '4px' }}
                  checked={inputValues[index]}
                  onChange={(e) => {
                    onChange(selectedElement);
                  }}
                  id='none'
                  type='radio'
                  name={name}
                />
                <label
                  className={'text-style-titles-14-regular ' + titlesColor}
                >
                  {label}
                </label>
              </td>
            );
          })}
        </tr>
      );
    },
  );

  const waterAnalysisValueToDisplay = (waterAnalysisValue) => {
    switch (waterAnalysisValue) {
      case 'low':
        return [true, false, false];
      case 'medium':
        return [false, true, false];
      case 'high':
        return [false, false, true];
      default:
        return [false, false, true];
    }
  };

  const onChangeWaterAnalysisValue = (newWaterAnalysisValue) => {
    let newValue = newWaterAnalysisValue.split('_');
    let newMaterialValue = newValue[0];
    let newMaterialLevel = newValue[1];
    switch (newValue[1]) {
      case '0':
        newMaterialLevel = 'low';
        break;
      case '1':
        newMaterialLevel = 'medium';
        break;
      case '2':
        newMaterialLevel = 'high';
        break;
    }
    props.onChangeFarmingUnitProperties(
      `wt.water_analysis.${newMaterialValue}`,
      newMaterialLevel,
    );
  };

  const waterAnalysisTableRowsInfo = [
    {
      name: 'organic',
      label: 'WT_protocol_edit_field_water_analysis_table_organic',
    },
    { name: 'calc', label: 'WT_protocol_edit_field_water_analysis_table_calc' },
    {
      name: 'fe',
      label: 'WT_protocol_edit_field_water_analysis_table_fe',
      lastRow: true,
    },
  ];
  return (
    <div>
      <div
        className={'ml-2 mr-2 mt-4 text-style-titles-16-bold ' + titlesColor}
        id={waterPropertiesTooltipContainerID}
      >
        {t('WT_protocol_edit_field_water_properties_section_title')}
        {props.shouldDisplayAsDisabled && (
          <img
            data-toggle='tooltip'
            id={waterPropertiesTooltipID}
            data-container={`#${waterPropertiesTooltipContainerID}`}
            onMouseOver={() => {
              $(`#${waterPropertiesTooltipID}`).tooltip('show');
            }}
            data-placement='top'
            title={t('Farming_unit_entity_tooltip')}
            style={{ width: '14px', height: '14px', marginLeft: '4px' }}
            src={require('../../resources/images/icons/info_icon.svg')}
          />
        )}
      </div>
      <div
        className='d-flex flex-row form-row ml-2 mr-2 p-0'
        style={{ marginTop: '16px' }}
      >
        <div className='col-12 col-lg-4 p-0'>
          <div className='form-group' style={{ marginRight: '8px' }}>
            <label className={'text-style-titles-14-semi-bold ' + titlesColor}>
              {t('WT_protocol_edit_field_water_source')}
            </label>
            <select
              style={{
                color: props.currentFarmingUnit?.wt?.water_properties
                  ?.water_source
                  ? '#4D5674'
                  : '#999CA3',
              }}
              value={
                props.currentFarmingUnit?.wt?.water_properties?.water_source ||
                'Select...'
              }
              disabled={props.shouldDisplayAsDisabled}
              className={'form-control ' + selectorClass}
              id='wt_water_source'
              onChange={(e) => {
                props.onChangeFarmingUnitProperties(
                  'wt.water_properties.water_source',
                  e.target.value,
                );
              }}
            >
              {WT_DATA.water_source.map((option, index) => {
                return (
                  <option key={index} disabled={index === 0} value={option}>
                    {upper(option)}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className='col-12 col-lg-4 p-0'>
          <div className='form-group' style={{ marginRight: '8px' }}>
            <label className={'text-style-titles-14-semi-bold ' + titlesColor}>
              {t('WT_protocol_edit_field_water_conveyance')}
            </label>
            <select
              style={{
                color: props.currentFarmingUnit?.wt?.water_properties
                  ?.water_conveyance
                  ? '#4D5674'
                  : '#999CA3',
              }}
              value={
                props.currentFarmingUnit?.wt?.water_properties
                  ?.water_conveyance || 'Select...'
              }
              disabled={props.shouldDisplayAsDisabled}
              className={'form-control ' + selectorClass}
              id='wt_water_conveyance'
              onChange={(e) => {
                props.onChangeFarmingUnitProperties(
                  'wt.water_properties.water_conveyance',
                  e.target.value,
                );
              }}
            >
              {WT_DATA.water_conveyance.map((option, index) => {
                return (
                  <option key={index} disabled={index === 0} value={option}>
                    {upper(option)}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <div
        className={
          'ml-2 mr-2 mt-4 mb-2 text-style-titles-16-bold ' + titlesColor
        }
        id={waterAnalysisTooltipContainerID}
      >
        {t('WT_protocol_edit_field_water_analysis_section_title')}
        {props.shouldDisplayAsDisabled && (
          <img
            data-toggle='tooltip'
            id={waterAnalysisTooltipID}
            data-container={`#${waterAnalysisTooltipContainerID}`}
            onMouseOver={() => {
              $(`#${waterAnalysisTooltipID}`).tooltip('show');
            }}
            data-placement='top'
            title={t('Farming_unit_entity_tooltip')}
            style={{ width: '14px', height: '14px', marginLeft: '4px' }}
            src={require('../../resources/images/icons/info_icon.svg')}
          />
        )}
      </div>
      <div
        className={
          'ml-2 mr-2 mb-3 text-style-titles-14-regular ' + subTitlesColor
        }
      >
        {t('WT_protocol_edit_field_water_analysis_section_subtitle')}
      </div>
      <div
        className='ml-2 mr-3 mb-3'
        style={{
          backgroundColor: 'rgba(255, 255, 255, 1)',
          border: '1px solid rgba(220, 224, 232, 1)',
          borderRadius: '5px',
        }}
      >
        <table style={{ display: 'grid' }}>
          <thead>
            <tr
              style={{
                borderTopLeftRadius: '5px',
                borderTopRightRadius: '5px',
                paddingLeft: '16px',
                paddingRight: '36px',
                display: 'flex',
                fontSize: '12px',
                fontWeight: 'bold',
                paddingTop: '8px',
                paddingBottom: '8px',
                alignItems: 'flex-start',
                borderBottom: '1px solid #DCE0E8',
              }}
            >
              <th
                className={'mr-auto ' + subTitlesColor}
                style={{
                  borderTopLeftRadius: '5px',
                  letterSpacing: '0.25px',
                }}
              >
                {t(
                  'WT_protocol_edit_field_water_analysis_table_materials',
                ).toUpperCase() +
                  ' / ' +
                  t(
                    'WT_protocol_edit_field_water_analysis_table_score',
                  ).toUpperCase()}
              </th>
              <th
                className={'d-flex ' + subTitlesColor}
                style={{
                  width: '160px',
                  letterSpacing: '0.25px',
                }}
              >
                {t(
                  'WT_protocol_edit_field_water_analysis_table_low',
                ).toUpperCase()}
              </th>
              <th
                className={'d-flex ' + subTitlesColor}
                style={{
                  width: '160px',
                  letterSpacing: '0.25px',
                }}
              >
                {t(
                  'WT_protocol_edit_field_water_analysis_table_medium',
                ).toUpperCase()}
              </th>
              <th
                className={'d-flex ' + subTitlesColor}
                style={{
                  width: '160px',
                  letterSpacing: '0.25px',
                }}
              >
                {t(
                  'WT_protocol_edit_field_water_analysis_table_high',
                ).toUpperCase()}
              </th>
            </tr>
          </thead>
          <tbody>
            {waterAnalysisTableRowsInfo.map((row, index) => (
              <WaterAnalysisTableRow
                key={row.name}
                name={row.name}
                onChange={onChangeWaterAnalysisValue}
                title={t(row.label)}
                inputValues={waterAnalysisValueToDisplay(
                  props.currentFarmingUnit?.wt?.water_analysis?.[row.name],
                )}
                inputLabels={WT_DATA['water_analysis'][row.name]}
                lastRow={row.lastRow || false}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div className='d-flex flex-row form-row ml-2 mr-2 p-0'>
        <div className='col-12 col-lg-3 p-0 d-flex flex-column'>
          <label
            className={'ml-2 mt-4 text-style-titles-16-bold ' + titlesColor}
          >
            {t('WT_protocol_edit_field_HPO_concentrations')}
          </label>
          <div
            className='d-flex flex-column ml-2 mr-2 p-0'
            style={{ marginTop: '8px' }}
          >
            <div className='d-flex'>
              <div className='form-group' style={{ marginRight: '8px' }}>
                <label
                  className={'text-style-titles-14-semi-bold ' + titlesColor}
                  htmlFor='distributionName'
                >
                  {'(%)'}
                </label>
                <input
                  disabled={props.shouldDisplayAsDisabled}
                  placeholder={'50'}
                  type='number'
                  value={
                    props.currentFarmingUnit?.wt?.material_concentrations
                      ?.hpo || ''
                  }
                  min={0}
                  max={100}
                  className={'form-control placeholder ' + titlesColor}
                  id='wt_hpo_concentrations'
                  onChange={(e) => {
                    props.onChangeFarmingUnitProperties(
                      'wt.material_concentrations.hpo',
                      e.target.value,
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='col-12 col-lg-3 p-0 d-flex flex-column'>
          <label
            className={'ml-2 mt-4 text-style-titles-16-bold ' + titlesColor}
          >
            {t('WT_protocol_edit_field_acid_titration')}
          </label>
          <div
            className='d-flex flex-column ml-2 mr-2 p-0'
            style={{ marginTop: '8px' }}
          >
            <div className='d-flex'>
              <div className='form-group' style={{ marginRight: '8px' }}>
                <label
                  className={'text-style-titles-14-semi-bold ' + titlesColor}
                  htmlFor='distributionName'
                >
                  {'(ml/10 liter)'}
                </label>
                <input
                  disabled={props.shouldDisplayAsDisabled}
                  placeholder={'0'}
                  type='number'
                  value={
                    props.currentFarmingUnit?.wt?.material_concentrations
                      ?.acid_titration || ''
                  }
                  min={0}
                  max={100}
                  className={'form-control placeholder ' + titlesColor}
                  id='wt_acid_titration'
                  onChange={(e) => {
                    props.onChangeFarmingUnitProperties(
                      'wt.material_concentrations.acid_titration',
                      e.target.value,
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

module.exports = connect()(withTranslation()(WaterTreatmentComponent));
