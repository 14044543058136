var axios = require("axios");
var GeneralClasses = require("@model/GeneralClasses");
var { error_message } = require("@managers/helpers");

const SCIROOT_DISTRIBUTION_ROLE_PATH = "distribution_role";
const SCIROOT_FARMING_UNIT_ROLE_PATH = "farming_unit_role";
const CROP_EXPERTS = ["danielaj@ndrip.com"];

export var distribution_roles = (distribution_id) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(SCIROOT_DISTRIBUTION_ROLE_PATH + "/" + distribution_id, {})
      .then(function (res) {
        var role_list = new GeneralClasses.RoleList(res.data);
        resolve(role_list);
      })
      .catch(function (e) {
        console.log(error_message(e));
        reject(e);
      });
  });
};

export var farming_unit_roles = (distribution_id, farming_unit_id) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        SCIROOT_FARMING_UNIT_ROLE_PATH +
          "/" +
          distribution_id +
          "/" +
          farming_unit_id,
        {},
      )
      .then(function (res) {
        var role_list = new GeneralClasses.RoleList(res.data);
        resolve(role_list);
      })
      .catch(function (e) {
        console.log(error_message(e));
        reject(e);
      });
  });
};

export var add_distribution_role = (distribution_id, user_id, role) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(SCIROOT_DISTRIBUTION_ROLE_PATH, {
        distribution_id: distribution_id,
        user_id: user_id,
        role: role,
      })
      .then(function (res) {
        resolve("");
      })
      .catch(function (e) {
        console.log(error_message(e));
        reject(e);
      });
  });
};

export var delete_distribution_role = (distribution_id, user_id) => {
  return new Promise(function (resolve, reject) {
    axios
      .delete(
        SCIROOT_DISTRIBUTION_ROLE_PATH + "/" + distribution_id + "/" + user_id,
        {},
      )
      .then(function (res) {
        resolve("");
      })
      .catch(function (e) {
        console.log(error_message(e));
        reject(e);
      });
  });
};

export var add_farming_unit_role = (
  distribution_id,
  farming_unit_id,
  user_id,
  role,
) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(SCIROOT_FARMING_UNIT_ROLE_PATH, {
        distribution_id: distribution_id,
        farming_unit_id: farming_unit_id,
        user_id: user_id,
        role: role,
      })
      .then(function (res) {
        resolve("");
      })
      .catch(function (e) {
        console.log(error_message(e));
        reject(e);
      });
  });
};

export var delete_farming_unit_role = (
  distribution_id,
  farming_unit_id,
  user_id,
) => {
  return new Promise(function (resolve, reject) {
    axios
      .delete(
        SCIROOT_FARMING_UNIT_ROLE_PATH +
          "/" +
          distribution_id +
          "/" +
          farming_unit_id +
          "/" +
          user_id,
        {},
      )
      .then(function (res) {
        resolve("");
      })
      .catch(function (e) {
        console.log(error_message(e));
        reject(e);
      });
  });
};

export const getIsAdmin = (all_distributions, isRoot) => {
  if (isRoot === "1") {
    return true;
  }
  if (!all_distributions || !all_distributions.distributions) return false;

  return !!Object.values(all_distributions.distributions).find(
    (farmingUnit) => farmingUnit.role === "admin",
  );
};
export const isAbleToSeeDistributionDropdown = (all_distributions, isRoot) => {
  if (isRoot === "1") {
    return true;
  }
  if (!all_distributions || !all_distributions.distributions) return false;

  return Object.keys(all_distributions.distributions).length > 1;
};
export const isAbleToSeeFarmingUnitDropdown = (all_distributions, isRoot) => {
  if (isRoot === "1") {
    return true;
  }
  if (!all_distributions || !all_distributions.distributions) {
    return false;
  } else {
    return (
      Object.values(all_distributions.distributions).flatMap((distribution) =>
        Object.values(distribution.farming_units),
      ).length > 1
    );
  }
};

export function isCropExpert(isRoot, userEmail) {
  return isRoot === "1" || CROP_EXPERTS.includes(userEmail);
}

export const getIsAdminForInsights = (email) => {
  return (
    email &&
    (email.endsWith("@ndrip.com") ||
      email.endsWith("@sciroot.ag") ||
      email === "asafovdat@gmail.com")
  );
};
