var React = require('react');
const { useTranslation } = require('react-i18next');
const { useState } = require('react');
const { getCropTypePrefix } = require('./CropManagement.util');
var cropDataManager = require('cropDataManager');
var CSS = require('../crop/CropManagement.scss');
var { upper } = require('@managers/helpers');

const AddNewCropSection = ({ crops, onUpdate }) => {
  const [newCropType, setNewCropType] = useState('');
  const [newCropVariety, setNewCropVariety] = useState('');
  const [newSubCropVariety, setNewSubCropVariety] = useState('');
  const [newCropFeature, setNewCropFeature] = useState('');
  const { t } = useTranslation();

  const handleNewCropTypeChange = (crop) => {
    setNewCropVariety('general');
    crop = crop.toLowerCase();
    setNewCropType(crop);
    setNewCropFeature('general');
  };

  const handleNewCropVarietyChange = (variety) => {
    setNewCropFeature('general');
    setNewCropVariety(variety.toLowerCase());
  };

  function handleNewSubCropVarietyChange(subVariety) {
    setNewCropFeature('general');
    setNewSubCropVariety(subVariety.toLowerCase());
  }

  const handleNewCropFeatureChange = (feature) => {
    setNewCropFeature(feature.toLowerCase());
  };

  const handleAddNew = () => {
    let crop_type = '';
    if (
      newCropType &&
      newCropVariety &&
      newCropVariety !== 'general' &&
      newCropFeature &&
      newCropFeature !== 'general'
    ) {
      if (newSubCropVariety && newSubCropVariety !== 'general') {
        crop_type = getCropTypePrefix(
          crops,
          newCropType,
          newCropVariety,
          newCropFeature,
        );
        if (crop_type !== '') {
          crop_type =
            crop_type + '#' + newSubCropVariety + '$' + newCropFeature;
        } else {
          crop_type =
            newCropType +
            '#' +
            newCropVariety +
            '#' +
            newSubCropVariety +
            '$' +
            newCropFeature;
        }
      } else {
        crop_type = getCropTypePrefix(
          crops,
          newCropType,
          newCropVariety,
          newCropFeature,
        );
        if (crop_type !== '') {
          crop_type = crop_type + '$' + newCropFeature;
        } else {
          crop_type = newCropType + '#' + newCropVariety + '$' + newCropFeature;
        }
      }
    } else if (
      newCropType &&
      newCropVariety &&
      newCropVariety !== 'general' &&
      (newCropFeature === '' || newCropFeature === 'general')
    ) {
      if (newSubCropVariety && newSubCropVariety !== 'general') {
        crop_type = getCropTypePrefix(crops, newCropType, newCropVariety);
        if (crop_type !== '') {
          crop_type = crop_type + '#' + newSubCropVariety;
        } else {
          crop_type =
            newCropType + '#' + newCropVariety + '#' + newSubCropVariety;
        }
      } else {
        crop_type = newCropType + '#' + newCropVariety;
      }
    } else if (
      newCropType &&
      (newCropVariety === 'general' || newCropVariety === '') &&
      newCropFeature &&
      newCropFeature !== 'general'
    ) {
      crop_type = newCropType + '$' + newCropFeature;
    } else if (
      newCropType &&
      (newCropVariety === 'general' || newCropVariety === '') &&
      (newCropFeature === 'general' || newCropFeature === '')
    ) {
      crop_type = newCropType;
    }

    if (
      newCropType in crops &&
      newCropVariety in crops[newCropType] &&
      newSubCropVariety in crops[newCropType] &&
      (newCropFeature in crops[newCropType][newCropVariety] ||
        newCropFeature in crops[newCropType][newSubCropVariety])
    ) {
      alert('Key already exist');
      return;
    }
    crop_type = crop_type.toLowerCase();

    try {
      cropDataManager
        .addNewCropItem({
          crop_type: crop_type,
          coup_parameters: {},
          gdd: {},
          phenological_phases: [],
          yield: {},
        })
        .then((res) => {
          if (res['success']) {
            onUpdate();
            alert('Crop item updated successfully!');
          }
        });
      mixpanel.track('Crop Added', {
        'Crop ID': crop_type,
        'Crop Name': newCropType,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <h2 className='pl-3'>Add New Section</h2>

      <div className='d-flex p-3 flex-column justify-content-start'>
        <div className='w-25'>
          <div>
            <label style={{ marginBottom: '4px' }}>{t('crop_type')}</label>
            <input
              className='form-control'
              list={'crop-type-list'}
              onChange={(e) => handleNewCropTypeChange(e.target.value)}
            />
            <datalist id={'crop-type-list'}>
              {crops &&
                Object.keys(crops)
                  .sort()
                  .map((item, index) => {
                    return <option key={item} value={item}></option>;
                  })}
            </datalist>
          </div>
          <label style={{ marginBottom: '4px' }}>{t('kc_variety')}</label>
          <input
            className='form-control'
            list={'crop-variety-list'}
            onChange={(event) => handleNewCropVarietyChange(event.target.value)}
          />
          <datalist id={'crop-variety-list'}>
            {crops &&
              newCropType in crops &&
              Object.keys(crops[newCropType])
                .sort()
                .map((item, index) => {
                  return <option key={item} value={item}></option>;
                })}
          </datalist>

          <label style={{ marginBottom: '4px' }}>
            {'Sub' + t('kc_variety')}
          </label>
          <input
            type='text'
            className='form-control'
            aria-describedby='emailHelp'
            onChange={(event) =>
              handleNewSubCropVarietyChange(event.target.value)
            }
            placeholder={'Sub' + t('kc_variety')}
          />

          <label style={{ marginBottom: '4px' }}>{t('kc_feature')}</label>
          <input
            className='form-control'
            list={'crop-feature-list'}
            onChange={(event) => handleNewCropFeatureChange(event.target.value)}
          />
          <br />
          <datalist id={'crop-feature-list'}>
            {crops &&
              newCropType in crops &&
              newCropVariety in crops[newCropType] &&
              Object.keys(crops[newCropType][newCropVariety])
                .sort()
                .map((item, index) => {
                  return (
                    <option key={item} value={item}>
                      {t(upper(item))}
                    </option>
                  );
                })}
          </datalist>
        </div>
        <div className='d-flex flex-column'>
          <button className='btn btn-grey45 w-25 mb-2' onClick={handleAddNew}>
            Add New
          </button>
          {/*<button className="btn btn-grey45 w-25 mt-2">Delete</button>*/}
        </div>
      </div>
    </div>
  );
};

module.exports = AddNewCropSection;
