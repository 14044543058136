var React = require('react');
var { connect } = require('react-redux');
var authenticateActions = require('authenticateActions');
var { withTranslation } = require('react-i18next');

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = { login_form_additional_classes: '' };
    this.onRegisterButtonClick = this.onRegisterButtonClick.bind(this);
    this.onFieldValueChange = this.onFieldValueChange.bind(this);

    this.state = {
      registered_user_name:
        this.props.authentication_data.register_flow.registered_user_name,
    };
  }

  onRegisterButtonClick(e) {
    e.preventDefault();
    e.stopPropagation();

    var form = document.getElementById('login-form');

    // Local check (given by bootstrap)
    if (form.checkValidity() == true) {
      // return to main login handler
      this.props.dispatch(
        authenticateActions.confirm(
          this.state.registered_user_name,
          this.refs.confirmation_code.value,
        ),
      );
    }

    // refresh login form according to local check
    this.setState({ login_form_additional_classes: 'was-validated' });
  }

  onFieldValueChange(event) {
    this.setState({ registered_user_name: event.target.value });
  }

  // value={this.props.authentication_data.register_flow.registered_user_name} readOnly

  render() {
    const { t } = this.props;

    var { confirm_fail_error } = this.props.authentication_data.register_flow;
    var login_form_additional_classes =
      this.state.login_form_additional_classes;

    return (
      <form
        className={`needs-validation ${this.state.login_form_additional_classes}`}
        noValidate
        onSubmit={this.onRegisterButtonClick}
        id='login-form'
      >
        <div className='form-group ml-2 mr-2 mt-3 text-light-blue'>
          <label htmlFor='emailInput'>{t('email')}</label>
          <input
            type='email'
            ref='email'
            className='form-control'
            value={this.state.registered_user_name}
            onChange={this.onFieldValueChange}
            placeholder={t('login_enter_your_email')}
          />
          <div className='invalid-feedback'>
            {t('login_enter_a_valid_email')}
          </div>
        </div>
        <div className='form-group ml-2 mr-2 mt-3 text-light-blue'>
          <label htmlFor='first_name'>{t('confirmation_code')}</label>
          <input
            type='number'
            ref='confirmation_code'
            className='form-control'
            placeholder={t('login_enter_confirmation_code')}
            required
          />
          <small className='form-text text-muted'>
            {t('login_enter_confirmation_code_message')}
          </small>
          <div className='invalid-feedback'>
            {t('login_enter_a_valid_confirmation_code')}
          </div>
        </div>

        <div className='text-center text-red'>{confirm_fail_error}</div>

        <div className='text-center'>
          <button
            type='submit'
            style={{ opacity: '0.9' }}
            className='btn btn-grey45 btn-lg general-button mt-3'
            disabled={
              this.props.authentication_data.register_flow.confirm_status ==
              'init'
            }
          >
            {t('confirm')}
            {this.props.authentication_data.register_flow.confirm_status ==
              'init' && (
              <span>
                &nbsp;&nbsp;
                <span
                  className='spinner-grow spinner-grow-sm mb-1'
                  role='status'
                  aria-hidden='true'
                ></span>
              </span>
            )}
          </button>
        </div>

        <div className='text-center mt-4 mb-5'>
          <br />
          <br />
          <br />
        </div>
      </form>
    );
  }
}

module.exports = connect((state) => {
  return {
    authentication_data: state.authentication_data,
  };
})(withTranslation()(RegisterForm));
