const React = require('react');
const { connect } = require('react-redux');
const { withTranslation } = require('react-i18next');

const NotificationsSelectorPerFieldSkeleton = ({ displayAlerts }) => {
  const radioButtonSkeleton = (
    <td
      className='d-flex justify-content-center'
      style={{ width: '120px', padding: '4px 2px' }}
    >
      <div
        className='color-transition'
        style={{ width: '16px', height: '16px', borderRadius: '8px' }}
      ></div>
    </td>
  );
  return ['', '', '', '', ''].map(() => {
    return (
      <tr
        style={{
          paddingLeft: '16px',
          paddingRight: '36px',
          display: 'flex',
          fontSize: '14px',
          fontWeight: 'bold',
          paddingTop: '8px',
          paddingBottom: '8px',
          alignItems: 'flex-start',
          borderBottom: '1px solid rgba(220, 224, 232, 1)',
        }}
      >
        <td
          className={'text-grey45 font-weight-bold mr-auto color-transition'}
          style={{
            width: '137px',
            height: '16px',
            borderRadius: '8px',
            marginTop: '4px',
          }}
        ></td>
        {radioButtonSkeleton}
        {displayAlerts && radioButtonSkeleton}
        {radioButtonSkeleton}
        {displayAlerts && radioButtonSkeleton}
      </tr>
    );
  });
};
module.exports = connect()(
  withTranslation()(NotificationsSelectorPerFieldSkeleton),
);
