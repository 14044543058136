const React = require('react');
const { useTranslation, withTranslation } = require('react-i18next');
const { useState, useEffect } = require('react');
const ContextMenu = require('ContextMenu');
const { connect } = require('react-redux');
const { Link } = require('react-router-dom');
const DayPickerInput = require('../DayPickerInput');
var { fertilizerList, fertilizerNameToDisplay } = require('nitrogenManager');

const FertigationConcentrationsInputLine = ({
  id,
  startDate,
  endDate,
  fertType,
  concentration,
  onStartDateChange,
  onFTypeChanged,
  onConcentrationChanged,
  onDelete,
}) => {
  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);

  const handleStartDateChange = (startDate) => {
    onStartDateChange(id, startDate);
  };

  const menuOptions = [
    {
      title: 'Delete',
      icon: require('images/icons/trash.svg'),
      func: () => onDelete(id),
      textColor: '#F66B7A',
    },
  ];

  return (
    <div className='form d-flex flex-row '>
      <div className='text-grey45 mr-2' style={{ flex: 1 }}>
        <DayPickerInput
          value={startDate}
          onChange={handleStartDateChange}
          inputProps={{
            id: 'fertigation_start_date',
            required: true,
          }}
        />
      </div>

      <div className='text-grey45 mr-2' style={{ flex: 1 }}>
        <input
          className='form-control float-left d-inline'
          type='text'
          value={endDate}
          style={{ lineHeight: 1 }}
          id={'fertigation_end_date'}
          readOnly
          required={false}
        />
      </div>

      <div className='text-grey45 mr-2' style={{ flex: 1 }}>
        <div
          className='dropdown text-grey71 font-weight-normal border border-grey91'
          style={{ borderRadius: 5, height: 40, maxWidth: 180 }}
        >
          <div
            className='dropdown-toggle d-flex align-items-center justify-content-between p-2 overflow-hidden d-inline-block text-truncate'
            id='dropdownMenuButton'
            data-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            <div className='overflow-hidden text-truncate d-inline-block'>
              <Link
                to=''
                className='h6 font-weight-normal text-grey71 overflow-hidden d-inline-block text-truncate'
              >
                {fertType
                  ? fertilizerNameToDisplay(fertType)
                  : t('please select')}
              </Link>
            </div>
          </div>
          <div className='dropdown-menu' style={{ cursor: 'pointer' }}>
            {fertilizerList()
              .sort()
              .map((fertType) => {
                return (
                  <a
                    className='dropdown-item text-grey71'
                    href='#'
                    key={fertType}
                    onClick={() => onFTypeChanged(id, fertType)}
                  >
                    {fertilizerNameToDisplay(fertType)}
                  </a>
                );
              })}
          </div>
        </div>
      </div>

      <div className='text-grey45' style={{ flex: 1 }}>
        <input
          className='form-control float-left d-inline'
          type='number'
          value={concentration}
          id={'concentration'}
          onChange={(e) => onConcentrationChanged(id, e.target.value)}
          required={true}
        />
      </div>

      {onDelete ? (
        <div className='d-flex align-items-center justify-content-center position-relative'>
          {showMenu && (
            <ContextMenu
              options={menuOptions}
              onToggle={setShowMenu}
              isDeleteMenu={true}
            />
          )}
          <button
            onClick={(e) => {
              setShowMenu(!showMenu);
              e.stopPropagation();
            }}
            type='button'
            className={`d-flex btn justify-content-center align-items-center sensorMenuBtn ${showMenu ? 'active' : ''}`}
          >
            <img src={require('images/icons/three-dots-horizontal.svg')} />
          </button>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

const mapStateToProps = function (state) {
  return {};
};
const mapDispatchToProps = {};

module.exports = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(FertigationConcentrationsInputLine));
