var React = require('react');
var { connect } = require('react-redux');
var userActions = require('userActions');
var distributionActions = require('distributionActions');
var authenticateActions = require('authenticateActions');
var { withTranslation } = require('react-i18next');
const { SelectMenu } = require('SelectMenu');
const LanguageMenu = require('../general/LanguageMenu');
var { upper } = require('@managers/helpers');
const NotificationsConfiguration = require('NotificationsConfiguration');
var BaseComponent = require('BaseComponent');

class Account extends React.Component {
  constructor(props) {
    super(props);

    this.handleReceiveNotificationChange =
      this.handleReceiveNotificationChange.bind(this);
    this.onLogoutButtonClick = this.onLogoutButtonClick.bind(this);

    this.onUserDataChange = this.onUserDataChange.bind(this);
    this.state = {
      userData: {},
      units: '',
    };
  }

  componentDidMount() {
    this.props.getUserData();
    this.setState({
      userData: this.props.userData,
      units: this.props.userData.unit_system,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.userData !== this.props.userData &&
      Object.keys(this.props.userData).length > 1
    ) {
      this.setState({
        userData: this.props.userData,
        units: this.props.userData.unit_system,
      });
    }
  }

  onUserDataChange(key, value) {
    let copyOfUserData = {};
    Object.assign(copyOfUserData, this.props.userData);
    let storableKeys = ['unit_system', 'recommendations_unit'];
    Object.keys(copyOfUserData).forEach(
      (key) => storableKeys.includes(key) || delete copyOfUserData[key],
    );

    copyOfUserData[key] = value;
    this.props.clearFarmingUnitsHistoryLoadAction();
    this.props.setUserData({ units: copyOfUserData });
    this.setState({ userData: copyOfUserData, units: value });
  }

  handleReceiveNotificationChange(event) {
    this.props.setUserAttributes({ 'custom:notifications': event ? '1' : '0' });
  }

  onLogoutButtonClick() {
    this.props.logout();
  }

  render() {
    const { t } = this.props;

    let unit_system_options = [
      { title: t('metric_india'), value: 'metric_india' },
      { title: t('metric_israel'), value: 'metric_israel' },
      { title: t('metric_australia'), value: 'metric_australia' },
      { title: t('metric'), value: 'metric' },
      { title: t('imperial'), value: 'imperial' },
    ];

    return (
      <div
        className='container pt-5 pb-5 d-flex justify-content-center'
        style={{ width: 865 }}
      >
        <div
          className='col justify-content-center bg-grey98 pl-4 pr-4 pt-3 border-grey91'
          style={{
            borderWidth: 1,
            borderRadius: 10,
            borderStyle: 'solid',
            marginBottom: '32px',
          }}
        >
          <div className='row justify-content-center'>
            <div className='col-12 ml-3 mt-3 mr-3 d-flex justify-content-between align-items-center'>
              <span className='text-grey45 text-size-32 font-weight-bold'>
                {t('account')}
              </span>
              <div>
                <div
                  className='bg-blue-primary d-flex align-items-center'
                  style={{ borderRadius: '3px' }}
                >
                  <span
                    className='text-white font-weight-semi-bold text-size-12'
                    style={{
                      marginBottom: 4,
                      marginTop: 4,
                      marginRight: 16,
                      marginLeft: 16,
                    }}
                    href='#'
                    onClick={this.onLogoutButtonClick}
                  >
                    {t('logout').toLowerCase()}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <form
            className='needs-validation mt-4'
            noValidate
            id='distribution-form'
            style={{ flex: 1 }}
          >
            <br />
            <span className='font-weight-bold text-size-20 text-grey45'>
              {upper(t('basic_info'))}
            </span>
            <div className='form-row mt-1'>
              <div className='col'>
                <div className='form-group text-blue mt-1'>
                  <label
                    className='text-size-14 text-grey45 font-weight-semi-bold'
                    htmlFor='first_name'
                  >
                    {t('first_name')}{' '}
                  </label>
                  <input
                    type='text'
                    ref='first_name'
                    value={this.props.userDetails?.first_name}
                    readOnly
                    className='form-control bg-white border-grey91'
                    placeholder='Enter your first name'
                    required
                  />
                  <div className='invalid-feedback'>
                    {t('account_first_name_invalid_data_message')}
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='form-group text-blue mt-1'>
                  <label
                    className='text-size-14 text-grey45 font-weight-semi-bold'
                    htmlFor='last_name'
                  >
                    {t('last_name')}
                  </label>
                  <input
                    type='text'
                    ref='last_name'
                    value={this.props.userDetails?.last_name}
                    readOnly
                    className='form-control bg-white border-grey91'
                    placeholder='Enter your last name'
                    required
                  />
                  <div className='invalid-feedback'>
                    {t('account_last_name_invalid_data_message')}
                  </div>
                </div>
              </div>
            </div>

            <div className='form-row mt-1'>
              <div className='col'>
                <div className='form-group mt-1 text-blue'>
                  <label
                    className='text-size-14 text-grey45 font-weight-semi-bold'
                    htmlFor='phone_numebr'
                  >
                    {t('phone_number')}
                  </label>
                  <input
                    type='text'
                    ref='phone_numebr'
                    value={this.props.userDetails?.phone_number}
                    readOnly
                    className='form-control bg-white border-grey91'
                    placeholder='Enter your phone number'
                    required
                  />
                  <div className='invalid-feedback'>
                    {t('account_phone_number_invalid_data_message')}
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='form-group mt-1 text-blue'>
                  <label
                    className='text-size-14 text-grey45 font-weight-semi-bold'
                    htmlFor='email'
                  >
                    {t('email')}
                  </label>
                  <input
                    type='text'
                    ref='email'
                    value={this.props.userDetails?.email}
                    readOnly
                    className='form-control bg-white border-grey91'
                    placeholder='Enter your email'
                    required
                  />
                  <div className='invalid-feedback'>
                    {t('account_email_invalid_data_message')}
                  </div>
                </div>
              </div>
            </div>
            <div className='form-row' style={{ alignItems: 'end' }}>
              <div className='col'>
                <div className='mt-2'>
                  <br />
                  <label
                    className='font-weight-bold text-size-20 text-grey45'
                    htmlFor='defaultCheck1'
                  >
                    {t('units')}
                  </label>
                  <div className='form-group mt-1 text-blue'>
                    <SelectMenu
                      menuStyle={{
                        height: 40,
                        borderWidth: 1,
                        borderColor: 'grey91',
                        fontSize: 14,
                      }}
                      options={unit_system_options}
                      onChange={(units_system) =>
                        this.onUserDataChange('unit_system', units_system)
                      }
                      curValue={this.state.units}
                      sortBox={'sortBox'}
                    />
                  </div>
                </div>
              </div>
              <div className='col'></div>
            </div>

            <div className='form-row'>
              <div className='col'>
                <div className='form-group mt-2 '>
                  <br />
                  <label
                    className='font-weight-bold text-size-20 text-grey45'
                    htmlFor='defaultCheck1'
                  >
                    {t('language')}
                  </label>
                  <div className=''>
                    <LanguageMenu />
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='form-group mt-3 text-light-blue'></div>
              </div>
            </div>
            <br />
          </form>

          <div className='form-row'>
            <div className='col'>
              <label
                className='font-weight-bold text-size-20 text-grey45'
                style={{ marginBottom: '8px' }}
              >
                {t('notifications')}
              </label>
              <div style={{ color: 'rgba(145, 157, 180, 1)', fontSize: 14 }}>
                {t('notifications_phase')}
              </div>
              <NotificationsConfiguration />
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    userData: state.authentication_data.user_data,
    userDetails: state.authentication_data.user_details,
    setUserFlow: state.authentication_data.set_user_attributes_flow,
  };
};

const mapDispatchToProps = {
  getUserData: userActions.get_user_data,
  setUserData: userActions.set_user_data,
  setUserAttributes: userActions.set_user_attributes,
  logout: authenticateActions.logout,
  clearFarmingUnitsHistoryLoadAction:
    distributionActions.clear_farming_unit_history_load_action,
};

module.exports = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Account));
