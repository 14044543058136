var React = require('react');
var { connect } = require('react-redux');
var {
  Link,
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  withRouter,
} = require('react-router-dom');
// var i18n = require('i18n');
var { withTranslation } = require('react-i18next');

class LanguageBar extends React.Component {
  constructor(props) {
    super(props);

    this.getLangLine = this.getLangLine.bind(this);
    this.selectLanaguage = this.selectLanaguage.bind(this);
  }

  selectLanaguage(lang) {
    const { t, i18n } = this.props;
    i18n.changeLanguage(lang);
  }

  getLangLine() {
    const { t, i18n } = this.props;

    let langDic = {
      en: 'English (US)',
      pt: 'Português',
      zh: '中文',
      es: 'Espanõl',
    };

    let selectedLanaguge = langDic[i18n.language];
    delete langDic[i18n.language];
    let line = [];
    line.push(selectedLanaguge);
    for (let langKey in langDic) {
      line.push(<span key={langKey + '1'}>&nbsp;&nbsp;</span>);
      line.push(
        <a href='#' key={langKey} onClick={() => this.selectLanaguage(langKey)}>
          {langDic[langKey]}
        </a>,
      );
    }

    return line;
  }

  render() {
    return (
      <div>
        <div className=''>{this.getLangLine()} </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    // user_details: state.authentication_data.user_details,
  };
};

module.exports = connect(mapStateToProps)(withTranslation()(LanguageBar));
