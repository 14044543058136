var React = require('react');
var { connect, useDispatch, useSelector } = require('react-redux');
var { useState, useEffect } = require('react');
var userActions = require('userActions');
var distributionActions = require('distributionActions');
const { PlanningPageContainer } = require('./PlanningPageContainer');
const { withTranslation, useTranslation } = require('react-i18next');
const {
  getContent,
  getPageTitle,
  getFirstDateInWeek,
  shouldWeekStartWithMonday,
} = require('./PlannerUtils');
var { compare } = require('Utils');
var { upper } = require('helpers');
var fertilizerDataActions = require('fertilizerDataActions');
var farmingUnitManager = require('farmingUnitManager');
var { Redirect } = require('react-router-dom');
const { ExportPopup } = require('./PlannerCommonUI');
var { get_configuration_config } = require('remoteConfigurationsManager');

let HEADER_HEIGHT = 56;
let MAIN_HEADER_HEIGHT = 48;

function LeftButtonsBar({
  setIsWeekViewMode,
  isWeekViewMode,
  startDate,
  setStartDate,
  startWithMonday,
}) {
  const { t } = useTranslation();

  function onArrowClick(direction) {
    let numWeeks = isWeekViewMode ? 1 : 5;
    if (direction === 'left') {
      numWeeks = -numWeeks;
    }

    let diff = startDate.getDate() + numWeeks * 7;

    let newStartDate = new Date(startDate.setDate(diff));
    setStartDate(newStartDate);
  }

  let weekTextStyle = isWeekViewMode ? 'tab-text-active' : 'tab-text-inactive';
  let weekBarStyle = isWeekViewMode ? 'tab-bar-active' : 'tab-bar-inactive';
  let seasonTextStyle = !isWeekViewMode
    ? 'tab-text-active'
    : 'tab-text-inactive';
  let seasonBarStyle = !isWeekViewMode ? 'tab-bar-active' : 'tab-bar-inactive';

  return (
    <div className='d-flex justify-content-start' style={{ paddingLeft: 24 }}>
      <div
        onClick={() => setIsWeekViewMode(true)}
        className={isWeekViewMode ? 'tab-active' : 'tab-inactive'}
        style={{ paddingRight: 8, flexDirection: 'column' }}
      >
        <div style={{ height: 4 }} />
        <span
          className={weekTextStyle}
          style={{
            fontSize: 14,
            letterSpacing: '0.1em',
            paddingRight: 4,
            paddingLeft: 4,
          }}
        >
          {t('week')}
        </span>
        <div
          className={'text-left ' + weekBarStyle}
          style={{ width: '100%', height: 4 }}
        />
      </div>
      <div className='ml-2' />
      <div
        onClick={() => setIsWeekViewMode(false)}
        className={isWeekViewMode ? 'tab-inactive' : 'tab-active'}
        style={{ flexDirection: 'column' }}
      >
        <div style={{ height: 4 }} />
        <span
          className={seasonTextStyle}
          style={{
            fontSize: 14,
            letterSpacing: '0.1em',
            paddingRight: 4,
            paddingLeft: 4,
          }}
        >
          {t('season')}
        </span>
        <div
          className={'text-left ' + seasonBarStyle}
          style={{ width: '100%', height: 4 }}
        />
      </div>
      <div
        className='bg-grey91 align-self-center'
        style={{
          width: '1px',
          height: '24px',
          marginLeft: 20,
          marginRight: 20,
          marginBottom: '2px',
        }}
      />
      <div className='d-flex justify-content-start align-items-center'>
        <img
          onClick={() => onArrowClick('left')}
          src={require('images/icons/planner/left_arrow.svg')}
          className='img-fluid text-button'
          alt='edit field'
          style={{ padding: 8, cursor: 'pointer' }}
        />
        <div
          onClick={() =>
            setStartDate(getFirstDateInWeek(new Date(), startWithMonday))
          }
          className='d-flex text-button'
          style={{
            padding: '4px 16px',
            marginLeft: 2,
            marginRight: 2,
            cursor: 'pointer',
          }}
        >
          <span
            className='text-grey45'
            style={{ fontWeight: '600', fontSize: 14, letterSpacing: 0.6 }}
          >
            {upper(t('today'))}
          </span>
        </div>
        <img
          onClick={() => onArrowClick('right')}
          src={require('images/icons/planner/right_arrow.svg')}
          className='img-fluid text-button'
          alt='edit field'
          style={{ padding: 8, cursor: 'pointer' }}
        />
      </div>
    </div>
  );
}

function ExportButton({ startWithMonday, allFields }) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const selectedFarmingUnit = useSelector(
    (state) => state.distribution_data.selected_entities.farming_unit,
  );

  const togglePopup = () => {
    setIsOpen(!isOpen);
    mixpanel.track('Export Button Click', {
      'Farming Unit': selectedFarmingUnit?.name,
    });
  };

  return (
    <div>
      <button
        className='primary-button'
        style={{
          height: 24,
          borderRadius: 3,
          paddingLeft: 12,
          paddingRight: 8,
          gap: 2,
        }}
        onClick={togglePopup}
      >
        <span className='text-style-titles-14-semi-bold text-white'>
          {upper(t('export'))}
        </span>
        <img
          src={require('images/icons/arrow_down.svg')}
          style={{ width: 14 }}
        />
      </button>

      {isOpen && (
        <ExportPopup
          startWithMonday={startWithMonday}
          allFields={allFields}
          closeExportPopup={() => setIsOpen(false)}
        />
      )}
    </div>
  );
}

function Planning() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isWeekViewMode, setIsWeekViewMode] = useState(true);
  const [startDate, setStartDate] = useState(
    getFirstDateInWeek(new Date(), true),
  );
  const [startWithMonday, setStartWithMonday] = useState(true);
  const [allFields, setAllFields] = useState([]);

  const selectedFarmingUnit = useSelector(
    (state) => state.distribution_data.selected_entities.farming_unit,
  );
  const selectedDistribution = useSelector(
    (state) => state.distribution_data.selected_entities.distribution,
  );
  const configuration_data = useSelector((state) => state.configuration_data);

  const shouldProceed =
    farmingUnitManager.shouldDisplayPlanner(selectedFarmingUnit);

  let pageTitle = getPageTitle(startDate, isWeekViewMode, t, startWithMonday);
  let dates = getContent(startDate, isWeekViewMode, startWithMonday, 'dates');
  const shouldDisplayExport =
    !isWeekViewMode &&
    get_configuration_config(
      configuration_data,
      'planner_export',
      selectedFarmingUnit?.name,
      selectedDistribution?.name,
    );

  useEffect(() => {
    dispatch(distributionActions.load_distributions(true));
    dispatch(userActions.get_user_data());
    dispatch(fertilizerDataActions.load_fertilizer_data());
  }, [dispatch]);

  useEffect(() => {
    let startWithMonday = shouldWeekStartWithMonday(selectedDistribution?.name);
    setStartWithMonday(startWithMonday);
    setStartDate(getFirstDateInWeek(new Date(), startWithMonday));
  }, [selectedDistribution?.id, selectedDistribution?.name, dispatch]);

  useEffect(() => {
    if (selectedFarmingUnit) {
      const newAllFields = Object.values(selectedFarmingUnit.fields)
        .filter((field) => field.settings.field_state === 'active')
        .sort(compare);
      setAllFields(newAllFields);
    }
  }, [selectedFarmingUnit, dispatch]);

  if (!shouldProceed) {
    return <Redirect to={'/'} from={'/Planning'} />;
  }

  return (
    <div className='bg-white main-screen'>
      <div
        className='d-flex bg-white position-fixed w-100 border-bottom'
        style={{ zIndex: 700, height: HEADER_HEIGHT }}
      >
        <div
          className='d-flex justify-content-between align-self-center h-100'
          style={{ flex: 1 }}
        >
          <div
            className='d-flex justify-content-start'
            style={{ width: '30%' }}
          >
            <LeftButtonsBar
              setIsWeekViewMode={setIsWeekViewMode}
              setStartDate={setStartDate}
              startDate={startDate}
              startWithMonday={startWithMonday}
              isWeekViewMode={isWeekViewMode}
            />
          </div>

          <div
            className='text-bermuda-gray font-weight-light'
            style={{ alignSelf: 'center', fontSize: '32px' }}
          >
            {pageTitle}
          </div>

          <div className='d-flex justify-content-end' style={{ width: '30%' }}>
            {shouldDisplayExport && (
              <div
                className='d-flex align-items-center'
                style={{ marginRight: 24 }}
              >
                <ExportButton
                  allFields={allFields}
                  startWithMonday={startWithMonday}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <PlanningPageContainer
        displaySettings={{ startDate, isWeekViewMode, startWithMonday }}
        isResponsive={!isWeekViewMode}
        dates={dates}
        style={{ top: HEADER_HEIGHT + MAIN_HEADER_HEIGHT + 1 }}
        allFields={allFields}
      />
    </div>
  );
}

module.exports = withTranslation()(Planning);
