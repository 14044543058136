import './i18n';

// Redux
export var store = require('configureStore').configure();
var { Provider } = require('react-redux');

// React
var React = require('react');
var ReactDOM = require('react-dom');
import { createRoot } from 'react-dom/client';

// Application loader
var appLoader = require('./appLoader').init(store);

// UI
import '../scss/custom.scss';
var Bootstrap = require('bootstrap');
var Login = require('Login');
var Main = require('Main');
var { BrowserRouter } = require('react-router-dom');

//

const container = document.getElementById('app');
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Main />
    </Provider>
  </BrowserRouter>,
);
