var React = require('react');
var { connect } = require('react-redux');
var authenticateActions = require('authenticateActions');
var { withTranslation } = require('react-i18next');
var { LogInBanner } = require('Banners');
var loginCSS = require('loginCSS');

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      login_form_additional_classes: '',
      shouldDisplayPassword: false,
    };

    this.onLoginButtonClick = this.onLoginButtonClick.bind(this);
    this.go_to_signup = this.go_to_signup.bind(this);
    this.go_to_forgot_password = this.go_to_forgot_password.bind(this);
    this.handleVisiblePassword = this.handleVisiblePassword.bind(this);

    this.shouldDisplayBanner = true;

    // this.refs.email = this.props.authentication_data.register_flow.registered_user_name;
  }

  onLoginButtonClick(e) {
    e.preventDefault();
    e.stopPropagation();

    var form = document.getElementById('login-form');

    // Local check (given by bootstrap)
    if (form.checkValidity() == true) {
      // return to main login handler
      this.props.dispatch(
        authenticateActions.login(
          this.refs.email.value.toLowerCase(),
          this.refs.password.value,
        ),
      );
    }

    // refresh login form according to local check
    this.setState({ login_form_additional_classes: 'was-validated' });
  }

  go_to_signup(e) {
    this.props.history.push('register');
  }

  go_to_forgot_password(e) {
    this.props.history.push('forgot');
  }

  handleVisiblePassword() {
    if (!this.state.shouldDisplayPassword) {
      this.setState((prevState) => ({
        login_form_additional_classes: prevState.login_form_additional_classes,
        shouldDisplayPassword: true,
      }));
    } else {
      this.setState((prevState) => ({
        login_form_additional_classes: prevState.login_form_additional_classes,
        shouldDisplayPassword: false,
      }));
    }
  }

  render() {
    const { t } = this.props;

    // var spinner = this.props.authentication_data.during_login ? <span className="spinner-grow spinner-grow-sm mb-1" role="status" aria-hidden="true"></span> : "";
    var { login_fail_error } = this.props.authentication_data;
    var login_form_additional_classes =
      this.state.login_form_additional_classes;

    let footer = (
      <div className='text-center mt-5 mb-5'>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        {t('login_dont_have_account_message')}
        <u>
          <a href='#' onClick={this.go_to_signup} className='text-black'>
            {t('login_sign_up')}
          </a>
        </u>
      </div>
    );
    if (this.shouldDisplayBanner) {
      footer = (
        <div className='text-center mt-5 mb-5'>
          {t('login_dont_have_account_message')}{' '}
          <u>
            <a href='#' onClick={this.go_to_signup} className='text-black'>
              {t('login_sign_up')}
            </a>
          </u>
          <br />
          <br />
          <br />
          <br />
          <br />
          <LogInBanner />
          <br />
        </div>
      );
    }

    return (
      <form
        className={`needs-validation ${this.state.login_form_additional_classes}`}
        noValidate
        onSubmit={this.onLoginButtonClick}
        id='login-form'
      >
        <div className='form-group ml-2 mr-2 mt-3 text-grey45'>
          <label htmlFor='emailInput'>{t('email')}</label>
          <input
            type='email'
            ref='email'
            className='form-control '
            id='emailInput'
            aria-describedby='emailHelp'
            placeholder={t('login_enter_your_email')}
            required
          />
          <div className='invalid-feedback'>
            {t('login_enter_a_valid_email')}
          </div>
        </div>

        <div className='form-group ml-2 mr-2 mt-3 text-grey45'>
          <label htmlFor='passwordInput'>{t('password')}</label>
          <div className='form-group' style={{ position: 'relative' }}>
            <input
              type={this.state.shouldDisplayPassword ? 'text' : 'password'}
              ref='password'
              className='form-control'
              id='passwordInput'
              aria-describedby='emailHelp'
              placeholder={t('login_enter_your_password')}
              required
            />
            <div onClick={this.handleVisiblePassword}>
              <img
                style={{ position: 'absolute', right: '12px', top: '12px' }}
                src={
                  this.state.shouldDisplayPassword
                    ? require('images/login/eye - open.svg')
                    : require('images/login/eye - closed.svg')
                }
              />
            </div>
          </div>
          <div className='invalid-feedback'>
            {t('login_enter_your_password')}
          </div>
        </div>

        <div className='text-center text-red'>{login_fail_error}</div>

        <div className='text-right'>
          <u>
            <a
              href='#'
              onClick={this.go_to_forgot_password}
              className='text-black mr-2'
            >
              {t('login_forgot_password')}
            </a>
          </u>
        </div>
        <div className='text-center'>
          <button
            type='submit'
            style={{ opacity: '0.9' }}
            className='btn btn-grey45 btn-lg general-button mt-3'
            disabled={this.props.authentication_data.during_login}
          >
            {t('login')}
            {this.props.authentication_data.during_login && (
              <span>
                &nbsp;&nbsp;
                <span
                  className='spinner-grow spinner-grow-sm mb-1'
                  role='status'
                  aria-hidden='true'
                ></span>
              </span>
            )}
          </button>
        </div>
        {footer}
      </form>
    );
  }
}

module.exports = connect((state) => {
  return {
    authentication_data: state.authentication_data,
  };
})(withTranslation()(LoginForm));
