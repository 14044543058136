var React = require('react');
const { Shadow } = require('./PlannerCommonUI');
const { useTranslation } = require('react-i18next');
const { colorMap } = require('./PlannerUtils');

export function SummaryCol({
  usedLine,
  isFirstLine,
  isSecondLine,
  isResponsive,
  type,
  units,
  summaryData,
  rightColWidth,
  rowHeight,
}) {
  let topBorder = '';
  if (usedLine) {
    topBorder = 'border-top border-grey91';
  }
  let summaryColStyle = { backgroundColor: 'white' };

  if (isResponsive) {
    summaryColStyle.position = 'absolute';
  }
  const summaryTitle = isResponsive ? 'season_total' : 'week_total';

  return (
    <div
      className={topBorder}
      style={{
        width: rightColWidth,
        right: 28,
        height: 31,
        flexDirection: 'row',
        ...summaryColStyle,
      }}
    >
      <Shadow
        style={{ width: 8, position: 'relative', left: -9, height: 32 }}
        direction={'right'}
        shouldHide={isFirstLine}
        rowHeight={rowHeight}
      />
      <div
        style={{
          position: 'relative',
          bottom: rowHeight + 1,
          right: 1,
          width: 172,
          backgroundColor: 'white',
          borderRadius: 8,
          height: rowHeight - 1,
        }}
      >
        <div className='summary-col'>
          {isFirstLine ? (
            <EmptyCell />
          ) : isSecondLine ? (
            <SummaryTitle title={summaryTitle} />
          ) : (
            <SummaryContent
              usedLine={usedLine}
              summaryData={summaryData}
              type={type}
              units={units}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function SummaryTitle({ title }) {
  const { t } = useTranslation();
  return (
    <div className='d-flex justify-content-end align-items-center pr-3'>
      <span className='text-style-caps-12-bold text-grey45'>{t(title)}</span>
    </div>
  );
}

function SummaryContent({ units, type, summaryData, usedLine }) {
  let textColor = usedLine ? 'text-grey71' : 'text-' + colorMap[type];
  let textStyle = usedLine
    ? 'text-style-body-14-regular'
    : 'text-style-body-14-bold';

  return (
    <div className='align-self-center pr-3' style={{ justifySelf: 'end' }}>
      <span className={[textStyle, textColor].join(' ')} style={{ flex: 1 }}>
        {summaryData}
        {units}
      </span>
    </div>
  );
}

function EmptyCell() {
  return <div />;
}
