var React = require('react');
var farmingUnitManager = require('farmingUnitManager');
var { connect } = require('react-redux');
var BaseComponent = require('BaseComponent');
var CustomMap = require('CustomMap');

class MapPage extends BaseComponent {
  constructor(props) {
    super(props);

    // this.onSubmitClick = this.onSubmitClick.bind(this);
    // this.onAreaChanged = this.onAreaChanged.bind(this);
    this.refreshView = this.refreshView.bind(this);
  }

  componentDidUpdate(nextProps, nextState) {
    if (
      (nextProps.selected_field &&
        this.props.selected_field &&
        nextProps.selected_field.id != this.props.selected_field.id) ||
      (!nextProps.selected_field && this.props.selected_field)
    ) {
      this.refreshView();
    }
  }

  componentDidMount() {}

  refreshView() {
    if (this.props.selected_farming_unit) {
      var selected_field_index = this.props.selected_field
        ? this.props.selected_field.id
        : '';

      if (this.custom_map) {
        var all_areas = farmingUnitManager.all_farming_unit_areas(
          this.props.selected_farming_unit,
          selected_field_index,
        );
        var all_markers = farmingUnitManager.all_farming_unit_markers(
          this.props.selected_farming_unit,
          selected_field_index,
        );

        this.custom_map.invalidate(all_areas, all_markers);
      }
    }
  }

  render() {
    return (
      <div>
        <h1>Map Page</h1>
        <CustomMap
          mapId='dashboard'
          showEditToolBar={false}
          onRef={(ref) => (this.custom_map = ref)}
          message=''
          onPolygonClick={this.onPolygonClick}
          map_height='600px'
          onPlusButtonClick={undefined}
          shouldPresentStressMenu={true}
          shouldPresentPlusButton={false}
          shouldDisplayNDVI={true}
        />
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    selected_farming_unit:
      state.distribution_data.selected_entities.farming_unit,
    selected_distribution:
      state.distribution_data.selected_entities.distribution,
    selected_field: state.distribution_data.selected_entities.field,
  };
};

module.exports = connect(mapStateToProps)(MapPage);
