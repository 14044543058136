var axios = require("axios");
var distribution_actions = require("@actions/distributionActions");
var { error_message } = require("@managers/helpers");
var { getMixpanelInstance } = require("@managers/mixpanelManager");

const SCIROOT_LOGIN_PATH = "login";
const SCIROOT_REGISTER_PATH = "register";
const SCIROOT_CONFIRM_PATH = "confirm";
const SCIROOT_LOGOUT_PATH = "logout";
const SCIROOT_REFRESH_TOKEN_PATH = "refresh_token";
const SCIROOT_FORGOT_PASSWORD_PATH = "forgot_password";

// Middleware
export var login = (username, password) => {
  return (dispatch, getState) => {
    dispatch(initiate_login());

    axios
      .post(SCIROOT_LOGIN_PATH, {
        username: username,
        password: password,
      })
      .then(function (res) {
        var id_token = res.data.id_token;
        var access_token = res.data.access_token;
        var refresh_token = res.data.refresh_token;
        var user_details = res.data.user_details;

        var mixpanelInstance = getMixpanelInstance();

        mixpanelInstance.identify(user_details.email);
        mixpanelInstance.people.set({
          $email: user_details.email,
          $first_name: user_details.first_name,
          $last_name: user_details.last_name,
          $phone: user_details.phone_number,
          $last_login: new Date(),
          Units: user_details.preferred_units,
        });
        mixpanelInstance.track("Login", { Type: "manual" });

        dispatch(
          login_success(id_token, access_token, refresh_token, user_details),
        );
      })
      .catch(function (err) {
        console.log(error_message(err));
        dispatch(login_fail(error_message(err)));
      });
  };
};

// Middleware
export var register = (
  user_id,
  first_name,
  last_name,
  phone_number,
  password,
  country,
) => {
  return (dispatch, getState) => {
    dispatch(initiate_register());

    axios
      .post(SCIROOT_REGISTER_PATH, {
        user_id: user_id,
        first_name: first_name,
        last_name: last_name,
        phone_number: phone_number,
        password: password,
        country: country,
      })
      .then(function (res) {
        dispatch(register_success(user_id));
      })
      .catch(function (err) {
        console.log(error_message(err));
        dispatch(register_fail(error_message(err)));
      });
  };
};

// Middleware
export var confirm = (user_id, confirmation_code) => {
  return (dispatch, getState) => {
    dispatch(initiate_confirm());

    axios
      .post(SCIROOT_CONFIRM_PATH, {
        user_id: user_id,
        confirmation_code: confirmation_code,
      })
      .then(function (res) {
        dispatch(confirm_success());
      })
      .catch(function (err) {
        console.log(error_message(err));
        dispatch(confirm_fail(error_message(err)));
      });
  };
};

// Middleware
export var forgot_password = (user_id) => {
  return (dispatch, getState) => {
    dispatch(initiate_forgot_password());

    axios
      .post(SCIROOT_FORGOT_PASSWORD_PATH, {
        user_id: user_id,
      })
      .then(function (res) {
        dispatch(forgot_password_confirmation_sent());
      })
      .catch(function (err) {
        console.log(error_message(err));
        dispatch(forgot_password_fail(error_message(err)));
      });
  };
};

// Middleware
export var forgot_password_confirm = (
  user_id,
  confirmation_code,
  new_password,
) => {
  return (dispatch, getState) => {
    dispatch(initiate_forgot_password_confirm());

    axios
      .post(SCIROOT_FORGOT_PASSWORD_PATH + "/confirm", {
        user_id: user_id,
        confirmation_code: confirmation_code,
        new_password: new_password,
      })
      .then(function (res) {
        dispatch(forgot_password_success());
      })
      .catch(function (err) {
        console.log(error_message(err));
        dispatch(forgot_password_confirm_fail(error_message(err)));
      });
  };
};

// Middleware
export var logout = () => {
  return (dispatch, getState) => {
    dispatch(initiate_logout());

    axios
      .post(SCIROOT_LOGOUT_PATH, {})
      .then(function (res) {
        var mixpanelInstance = getMixpanelInstance();

        mixpanelInstance.track("Logout");
        mixpanelInstance.reset();

        dispatch(logout_success());
        dispatch(distribution_actions.clear_distribution_data());
      })
      .catch(function (e) {
        dispatch(logout_success());
      });
  };
};

// Middleware
export var refresh_token = (refresh_token) => {
  return (dispatch, getState) => {
    dispatch(initiate_refresh_token());

    axios
      .post(SCIROOT_REFRESH_TOKEN_PATH + "/" + refresh_token, {})
      .then(function (res) {
        dispatch(
          refresh_token_success(
            res.id_token,
            res.access_token,
            res.refresh_token,
          ),
        );
      })
      .catch(function (err) {
        dispatch(refresh_token_fail(error_message(err)));
      });
  };
};

// Middleware
export var authenticate = (id_token, access_token, refresh_token) => {
  return (dispatch) => {
    dispatch(authenticate_success(id_token, access_token, refresh_token));
  };
};

export var initiate_login = () => {
  return {
    type: "LOGIN_INIT",
  };
};

export var authenticate_success = (id_token, access_token, refresh_token) => {
  return {
    type: "AUTHENTICATION_SUCCESS",
    tokens: {
      id_token,
      access_token,
      refresh_token,
    },
  };
};

export var login_success = (
  id_token,
  access_token,
  refresh_token,
  user_details,
) => {
  return {
    type: "LOGIN_SUCCESS",
    tokens: {
      id_token,
      access_token,
      refresh_token,
    },
    user_details: user_details,
  };
};

export var login_fail = (login_fail_error) => {
  return {
    type: "LOGIN_FAIL",
    login_fail_error,
  };
};

export var initiate_logout = () => {
  return {
    type: "LOGOUT_INIT",
  };
};

export var logout_success = () => {
  return {
    type: "LOGOUT_SUCCESS",
  };
};

export var initiate_register = () => {
  return {
    type: "REGISTER_INIT",
  };
};

export var register_success = (registered_user_name) => {
  return {
    type: "REGISTER_SUCCESS",
    registered_user_name: registered_user_name,
  };
};

export var register_fail = (register_fail_error) => {
  return {
    type: "REGISTER_FAIL",
    register_fail_error,
  };
};

export var initiate_confirm = () => {
  return {
    type: "CONFIRM_INIT",
  };
};

export var confirm_success = () => {
  return {
    type: "CONFIRM_SUCCESS",
  };
};

export var confirm_fail = (confirm_fail_error) => {
  return {
    type: "CONFIRM_FAIL",
    confirm_fail_error,
  };
};

export var initiate_refresh_token = () => {
  return {
    type: "REFRESH_TOKEN_INIT",
  };
};

export var refresh_token_success = (id_token, access_token, refresh_token) => {
  return {
    type: "REFRESH_TOKEN_SUCCESS",
    tokens: {
      id_token,
      access_token,
      refresh_token,
    },
  };
};

export var refresh_token_fail = (refresh_token_fail_error) => {
  return {
    type: "REFRESH_TOKEN_FAIL",
    refresh_token_fail_error,
  };
};

export var initiate_forgot_password = () => {
  return {
    type: "FORGOT_PASSWORD_INIT",
  };
};

export var initiate_forgot_password_confirm = () => {
  return {
    type: "FORGOT_PASSWORD_CONFIRM_INIT",
  };
};

export var forgot_password_confirmation_sent = () => {
  return {
    type: "FORGOT_PASSWORD_CONFIRMATION_SENT",
  };
};

export var forgot_password_success = () => {
  return {
    type: "FORGOT_PASSWORD_SUCCESS",
  };
};

export var forgot_password_fail = (forgot_password_error) => {
  return {
    type: "FORGOT_PASSWORD_FAIL",
    forgot_password_error: forgot_password_error,
  };
};

export var forgot_password_confirm_fail = (forgot_password_error) => {
  return {
    type: "FORGOT_PASSWORD_CONFIRM_FAIL",
    forgot_password_error: forgot_password_error,
  };
};

export var clear_forgot_password_actions = () => {
  return {
    type: "CLEAR_FORGOT_PASSWORD_ACTIONS",
  };
};

export var manual_login = (manual_login) => {
  return {
    type: "MANUAL_LOGIN",
    manual_login: manual_login,
  };
};
