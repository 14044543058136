const { useTranslation, withTranslation } = require('react-i18next');
const moment = require('moment');
const DayPickerInput = require('./DayPickerInput');
const React = require('react');
const { connect } = require('react-redux');
const { ComplexSelectMenu } = require('SelectMenu');
const { useMemo } = require('react');

const EditExtraSensorContent = React.memo(
  ({
    fertilizerData,
    chosenFertType,
    chosenFertID,
    chosenStartDate,
    onChangeFertType,
    onFertilizerRowStartDayChange,
    onFertilizerRowRemove,
    onFertilizerIDChange,
    readOnly,
  }) => {
    const { t } = useTranslation();
    var start_date_object = chosenStartDate
      ? moment.unix(chosenStartDate).format('DD-MMM-YYYY')
      : undefined;
    const getFertilizerOptions = (fertilizerData) =>
      fertilizerData
        ? Object.keys(fertilizerData).map((fertType) => ({
            title: t(fertilizerData[fertType].fertilizer_type),
            value: { type: fertType },
          }))
        : [];
    const fertilizerOptions = useMemo(
      () => getFertilizerOptions(fertilizerData),
      [fertilizerData],
    );

    return (
      <div className='bg-grey96'>
        <div
          className='d-flex flex-grow-1 card-body flex-row bg-grey96'
          style={{ padding: '12px 16px', gap: '8px' }}
        >
          <div
            className='text-grey45'
            style={{ width: '311px', marginRight: '8px' }}
          >
            <label className='font-weight-semi-bold mb-0 text-size-14 lh-1 text-grey45 line-height-1'>
              {t('Fert_sensor_fertlization_type')}
            </label>
            <div className='line-height-1'>
              <ComplexSelectMenu
                onChange={(newType) => onChangeFertType(newType)}
                options={fertilizerOptions}
                curValue={{ type: chosenFertType }}
                existingFieldDisableType={readOnly}
              />
            </div>
            <div className='line-height-1'></div>
          </div>

          <div
            className='text-grey45'
            style={{ width: '136px', marginRight: '8px' }}
          >
            <label className='font-weight-semi-bold mb-0 text-size-14 lh-1 text-grey45 line-height-1'>
              {t('fertilizer_meter_id')}
            </label>
            <div className='line-height-1'>
              <input
                className='form-control w-100'
                type='text'
                value={chosenFertID}
                onInput={(e) => onFertilizerIDChange(e.target.value)}
                disabled={readOnly}
              />
            </div>
          </div>
          <div
            className='text-grey45'
            style={{ width: '120px', marginRight: '16px' }}
          >
            <label className='font-weight-semi-bold mb-0 text-size-14 lh-1 text-grey45 line-height-1'>
              {t('start_date')}
            </label>
            <div className='line-height-1'>
              <DayPickerInput
                value={start_date_object}
                onChange={onFertilizerRowStartDayChange}
                dateFormat='YYYY-MMM-DD'
                inputProps={{
                  id: 'start_date_input',
                  disabled: readOnly,
                }}
              />
            </div>
          </div>
          {readOnly ? null : (
            <div
              className='text-grey45 d-flex align-content-center p-0'
              style={{ width: '120px', marginRight: '16px' }}
            >
              <button
                style={{
                  cursor: 'pointer',
                  background: 'none',
                  border: 'none',
                  paddingTop: '24px',
                }}
                onClick={onFertilizerRowRemove}
              >
                <img
                  src={require('images/icons/black_trash.svg')}
                  alt='Delete'
                  style={{ width: '16px', height: '16px' }}
                />
              </button>
            </div>
          )}
        </div>
      </div>
    );
  },
);

const mapStateToProps = function (state) {
  return {
    fertilizerData: state.fertilizer_data.fertilizer_data,
  };
};

module.exports = connect(mapStateToProps)(
  withTranslation()(EditExtraSensorContent),
);
