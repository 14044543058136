var React = require('react');
var { connect } = require('react-redux');
var distributionActions = require('distributionActions');
var { withTranslation } = require('react-i18next');
var { excludeFilterObjectByKeys } = require('helpers');
var $ = require('jquery');

let countryRegionDictionary = {
  'Please select a country': ['please select a region'],
  'USA- Arizona': ['Yuma', 'Parker', 'Wellton Mohawk', 'Safford'],
  'USA- Nebraska': ['Dawson'],
  'USA- Florida': ['Hastings'],
  'USA- California': ['Blythe'],
  Italy: ['South', 'North', 'Center'],
  India: ['Uttar Pradesh', 'Punjab', 'Haryana', 'Maharashtra'],
  Israel: ['South', 'North', 'Center'],
  Australia: ['Queensland', 'New South Wales'],
  Mexico: ['Guanajuato'],
  Spain: ['Murcia'],
};

class UpdateFarmingUnitModal extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmitClick = this.onSubmitClick.bind(this);
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.onChangeRegion = this.onChangeRegion.bind(this);

    this.state = {
      distribution_id: '',
      farming_unit_id: '',
      title: '',
      action: '',
    };
  }

  componentDidMount() {
    var that = this;

    $('#' + this.props.modalId).on('show.bs.modal', function (event) {
      // Clear all previous state for famring units
      var form = document.getElementById('farming_unit_form');
      form.classList.remove('was-validated');
      // that.props.clearFarmingUnitActions();

      var button = $(event.relatedTarget); // Button that triggered the modal
      var farming_unit_name = button[0].dataset.name; // Extract info from data-* attributes

      var salesforceID = button[0].dataset.salesforceid; // Extract info from data-* attributes
      var farming_unit_id = button[0].dataset.id; // Extract info from data-* attributes
      var distribution_id = button[0].dataset.distributionid; // Extract info from data-* attributes
      var country = button[0].dataset.country; // Extract info from data-* attributes
      var region = button[0].dataset.region; // Extract info from data-* attributes

      that.refs.farming_unit_name.value = farming_unit_name;
      that.refs.farming_unit_salesforce_id.value = salesforceID
        ? salesforceID
        : '';

      var salesforceinput = document.getElementById('salesforceinput');
      salesforceinput.required = ![
        '07bb924f-7ad2-4038-b1f5-59d88c81b2b2',
      ].includes(distribution_id);

      that.setState({
        farming_unit_id: farming_unit_id,
        distribution_id: distribution_id,
        farming_unit_country: country,
        farming_unit_region: region,
      });

      if (farming_unit_id == '') {
        that.setState({ title: 'Create' });
        that.setState({ action: 'Create' });
      } else {
        that.setState({ title: 'Update' });
        that.setState({ action: 'Update' });
      }
    });

    $('#' + this.props.modalId).on('hidden.bs.modal', function (event) {
      that.props.clearFarmingUnitActions();
    });
  }

  onChangeCountry(newCountry) {
    this.setState({ ...this.state, farming_unit_country: newCountry });
  }

  onChangeRegion(newRegion) {
    this.setState({ ...this.state, farming_unit_region: newRegion });
  }

  onSubmitClick(e) {
    e.preventDefault();
    e.stopPropagation();
    var form = document.getElementById('farming_unit_form');

    // Local check (given by bootstrap)
    if (form.checkValidity() == true) {
      let farmingUnitObj = {
        name: this.refs.farming_unit_name.value,
        salesforce_id: this.refs.farming_unit_salesforce_id.value,
        country: this.state.farming_unit_country,
        region: this.state.farming_unit_region,
      };
      if (this.state.action == 'Create') {
        this.props.createFarmingUnit(
          this.state.distribution_id,
          farmingUnitObj,
        );
      } else if (this.state.action == 'Update') {
        farmingUnitObj.id = this.state.farming_unit_id;
        this.props.updateFarmingUnit(
          this.state.distribution_id,
          excludeFilterObjectByKeys(farmingUnitObj, [
            'fields',
            'historical_data',
          ]),
        );
      }
    }
    form.classList.add('was-validated');
  }

  render() {
    const { t } = this.props;

    if (
      this.props.modalId &&
      this.props.farming_unit_actions.status == 'success' &&
      ['UPDATE', 'CREATE'].includes(this.props.farming_unit_actions.type)
    ) {
      $('#' + this.props.modalId).modal('hide');
    }

    return (
      <div
        className='modal fade'
        id={this.props.modalId}
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <form
          className='needs-validation'
          noValidate
          onSubmit={this.onSubmitClick}
          id='farming_unit_form'
        >
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {this.state.title} {t('farming_unit')}
                </h5>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <div className='form-group ml-2 mr-2 mt-3 text-light-blue'>
                  <label htmlFor='distributionName'>
                    {t('management_farming_unit_name')}
                  </label>
                  <input
                    type='text'
                    ref='farming_unit_name'
                    className='form-control'
                    id='farming_unit_name_input'
                    aria-describedby='emailHelp'
                    placeholder={t('management_farming_unit_name')}
                    required
                  />
                  <div className='invalid-feedback'>
                    {t('management_enter_a_valid_farming_unit_name')}
                  </div>
                </div>
                <div className='form-group ml-2 mr-2 mt-3 text-light-blue'>
                  <label htmlFor='mondayID'>{t('monday_id')}</label>
                  <input
                    type='text'
                    ref='farming_unit_salesforce_id'
                    className='form-control'
                    id='salesforceinput'
                    aria-describedby='emailHelp'
                    placeholder={t('monday_id')}
                  />
                  <div className='invalid-feedback'>{t('monday_id')}</div>
                </div>
                <div className='form-group ml-2 mr-2 mt-3 text-light-blue'>
                  <label htmlFor='countryID'>{t('country')}</label>
                  <select
                    value={this.state.farming_unit_country}
                    className='form-control custom-select'
                    id='farmingUnitCountryInput'
                    aria-describedby='emailHelp'
                    onChange={(e) => {
                      this.onChangeCountry(e.target.value);
                    }}
                    required
                  >
                    {Object.keys(countryRegionDictionary).map((key, index) => {
                      return (
                        <option key={index} value={index === 0 ? '' : key}>
                          {key}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className='form-group ml-2 mr-2 mt-3 text-light-blue'>
                  <label htmlFor='regionID'>{t('region')}</label>
                  <select
                    value={this.state.farming_unit_region}
                    className='form-control custom-select'
                    id='farmingUnitRegionInput'
                    aria-describedby='emailHelp'
                    onChange={(e) => {
                      this.onChangeRegion(e.target.value);
                    }}
                    required
                  >
                    {this.state.farming_unit_country !== undefined ? (
                      countryRegionDictionary[
                        this.state.farming_unit_country
                      ]?.map((key, index) => {
                        return (
                          <option key={index} value={key}>
                            {key}
                          </option>
                        );
                      })
                    ) : (
                      <option key={'region'} value={''}>
                        {'Please select country first'}
                      </option>
                    )}
                  </select>
                </div>
                <div className='text-red text-center'>
                  {this.props.farming_unit_actions.status == 'fail' &&
                    this.props.farming_unit_actions.error}
                </div>
              </div>
              <div className='modal-footer'>
                <div className='container text-center'>
                  <button
                    type='submit'
                    className='btn btn-dark-green btn-lg general-button'
                  >
                    {this.state.title}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    farming_unit_actions: state.distribution_data.farming_unit_actions,
  };
};

const mapDispatchToProps = {
  createFarmingUnit: distributionActions.create_farming_unit,
  updateFarmingUnit: distributionActions.update_farming_unit,
  clearFarmingUnitActions: distributionActions.clear_farming_unit_action,
};

module.exports = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(UpdateFarmingUnitModal));
