var React = require('react');
var ForgotPasswordForm = require('ForgotPasswordForm');
var MainCSS = require('mainCSS');
var loginCSS = require('loginCSS');
var { connect } = require('react-redux');
var { Redirect, Link } = require('react-router-dom');
var authenticateActions = require('authenticateActions');
var { withTranslation } = require('react-i18next');

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillUnmount() {
    this.props.clear_forgot_password_actions();
  }

  render() {
    const { t } = this.props;

    // go to root page if login already happened
    if (this.props.id_token) {
      return <Redirect to='/' />;
      // go to login if confirmation happened
    } else if (this.props.forgot_password_flow.state == 'success') {
      return <Redirect to='/login' />;
    }

    return (
      <div className='container'>
        <div className='row justify-content-center mainLoginRow shadow-lg mb-sm-5 rounded  mt-sm-5'>
          <div className='d-none d-lg-block col-12 col-lg-6 m-0 p-0 '>
            <img
              src={require('images/login/login-bg.png')}
              className='img-fluid w-100 h-100 '
              alt=''
              style={{ opacity: '0.9' }}
            />
          </div>
          <div className='col-12 col-lg-6 bg-white'>
            <div className='row mt-5'>
              <div className='col text-center'>
                <Link to='/'>
                  <img
                    src={require('images/general/ndrip-logo.png')}
                    className='img-fluid'
                    alt='N-Drip Logo'
                  />
                </Link>
              </div>
            </div>
            <ForgotPasswordForm />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    id_token: state.authentication_data.tokens.id_token,
    forgot_password_flow: state.authentication_data.forgot_password_flow,
  };
};

const mapDispatchToProps = {
  clear_forgot_password_actions:
    authenticateActions.clear_forgot_password_actions,
};
module.exports = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ForgotPassword));
