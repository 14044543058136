import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './NDcheckBox.css';

export const Mode = {
  Unchecked: 0,
  Intermediate: -1,
  Checked: 1,
};

function VImg({ size }) {
  let dim = size === 'small' ? 10 : 16;

  return (
    <svg
      width={dim}
      height={dim}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d_4282_71550)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14.8322 1.75208C15.5215 2.21161 15.7078 3.14292 15.2482 3.83221L8.58157 13.8322C8.30337 14.2495 7.83502 14.5002 7.33349 14.5002C6.83196 14.5002 6.36362 14.2495 6.08542 13.8322L2.75208 8.83221C2.29256 8.14292 2.47882 7.21161 3.16811 6.75208C3.8574 6.29255 4.78871 6.47882 5.24823 7.16811L7.33349 10.296L12.7521 2.16811C13.2116 1.47882 14.1429 1.29256 14.8322 1.75208Z'
          fill='#EEF1F6'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_209_43'
          x='0.5'
          y='0.5'
          width='17.0003'
          height='17.0002'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_209_43'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_209_43'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
}

function DashImag({ size }) {
  let width = size === 'small' ? 10 : 16;

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      viewBox='0 0 18 8'
      fill='none'
    >
      <g filter='url(#filter0_d_18019_17991)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.5 3C2.5 2.17157 3.17157 1.5 4 1.5H13.9999C14.8283 1.5 15.4999 2.17157 15.4999 3C15.4999 3.82843 14.8283 4.5 13.9999 4.5H4C3.17157 4.5 2.5 3.82843 2.5 3Z'
          fill='#EEF1F6'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_18019_17991'
          x='0.5'
          y='0.5'
          width='17'
          height='7'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_18019_17991'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_18019_17991'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
}

export const NDCheckBox = ({
  size,
  onClick,
  disable,
  mode,
  className,
  ...props
}) => {
  const [state, setState] = useState('idle');

  useEffect(() => {
    setState(disable ? 'disable' : 'idle');
  }, [disable, mode]);

  let innerElement = null;
  let cssMode = null;

  switch (mode) {
    case Mode.Unchecked:
      cssMode = 'unchecked';
      innerElement = null;
      break;
    case Mode.Intermediate:
      cssMode = 'intermediate';
      innerElement = <DashImag size={size} />;
      break;
    case Mode.Checked:
      cssMode = 'checked';
      innerElement = <VImg size={size} />;
      break;
  }

  function handleCheckBoxClick() {
    if (!disable) {
      let newMode = mode === Mode.Checked ? 0 : 1;
      onClick && onClick(newMode);
    }
  }

  return (
    <div
      className={[
        className,
        'storybook-checkbox',
        `checkbox--${size}`,
        `checkbox--${cssMode}`,
        `checkbox--${state}`,
      ].join(' ')}
      onMouseLeave={() => !disable && setState('idle')}
      onMouseEnter={() => !disable && setState('hover')}
      onClick={handleCheckBoxClick}
      {...props}
    >
      {innerElement}
    </div>
  );
};

NDCheckBox.propTypes = {
  onClick: PropTypes.func.isRequired,
};

NDCheckBox.defaultProps = {
  mode: Mode.Checked,
  size: 'large',
  disable: false,
};
