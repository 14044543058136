var React = require('react');
const { NotificationItem } = require('./NotificationItem');
const { WelcomeNotification } = require('./NotificationItem');
const { connect } = require('react-redux');
const { withTranslation, useTranslation } = require('react-i18next');

const NotificationsList = (props) => {
  const { t } = useTranslation();

  const setWelcomeNotificationDisplay = () => {
    props.updateUserWelcomeNotification();
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {props.shouldDisplayWelcomeNotification ? (
        <WelcomeNotification
          updateUserWelcomeNotification={setWelcomeNotificationDisplay}
        />
      ) : null}
      {props?.notificationsData
        ?.sort((a, b) => {
          return b.date - a.date;
        })
        .map((notificationObj, index) => {
          return (
            <NotificationItem
              onCloseClicked={props.onCloseClicked}
              updateUserMetaData={props.updateUserMetaData}
              key={index}
              index={index}
              notificationData={notificationObj}
            />
          );
        })}
      {props?.notificationsData?.length === 0 &&
      !props.shouldDisplayWelcomeNotification ? (
        <div
          style={{
            marginTop: '160px',
            fontSize: '24px',
            color: '#919DB4',
            textAlign: 'center',
            marginLeft: '48px',
            marginRight: '48px',
            lineHeight: '100%',
          }}
        >
          {t('no_notifications')}
        </div>
      ) : (
        <div style={{ height: '96px' }} />
      )}
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    userData: state.authentication_data.user_data,
  };
};

module.exports = connect(mapStateToProps)(withTranslation()(NotificationsList));
