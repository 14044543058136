// select menu options for generic Select Menu
const FieldModalMenuOptions = [
  { title: 'Aquastride', value: { type: 'aquastride' } },
  { title: 'Galcon', value: { type: 'galcon' } },
  { title: 'Motorola', value: { type: 'motorola' } },
  { title: 'Tal Gil', value: { type: 'tal-gil' } },
  { title: 'N-Drip Piezometer', value: { type: 'piezometer' } },
  { title: 'N-Drip Pulse', value: { type: 'pulse' } },
  { title: 'N-Drip Tensiometer', value: { type: 'wp' } },
  {
    title: 'N-Drip Flow Meter T4 1',
    value: { type: 'ndrip wm', subType: 'T4 1' },
  },
  {
    title: 'N-Drip Flow Meter T4 2',
    value: { type: 'ndrip wm', subType: 'T4 2' },
  },
  {
    title: 'N-Drip Flow Meter T6 1',
    value: { type: 'ndrip wm', subType: 'T6 1' },
  },
  {
    title: 'N-Drip Flow Meter T6 2',
    value: { type: 'ndrip wm', subType: 'T6 2' },
  },
  { title: 'N-Drip Flow Meter T8', value: { type: 'ndrip wm', subType: 'T8' } },
];

const FarmingUnitModalMenuOptions = [
  // {title: 'N-Drip Pulse', value: {type: 'pulse'}},
  { title: 'Tal Gil', value: { type: 'tal-gil' } },
];

module.exports = {
  FieldModalMenuOptions,
  FarmingUnitModalMenuOptions,
};
