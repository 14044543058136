const React = require('react');
const { connect } = require('react-redux');

const PrivacyPolicy = () => {
  let width = window.innerWidth;
  let height = window.innerHeight;

  return (
    <div style={{ width: width, height: height }}>
      <img
        style={{ opacity: 0.6 }}
        src={require('../../resources/images/general/ndrip-logo.png')}
        className='img-fluid'
        alt='N-Drip Logo'
      />

      <h5
        style={{
          display: 'flex',
          justifyContent: 'center',
          fontWeight: 'bold',
          textDecoration: 'underline',
        }}
      >
        NDrip LTD. – GENERAL TERMS AND CONDITIONS OF PURCHASE
      </h5>
      <div style={{ height: '50px' }} />
      {/*left side text*/}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          lineHeight: 1.2,
        }}
      >
        <div style={{ width: 0.05 * width }}></div>
        <div
          style={{
            width: 0.45 * width,
            whiteSpace: 'pre-line',
            overflowWrap: 'break-word',
          }}
        >
          <p style={{ fontWeight: 'bold' }}>1. General</p>
          <p>
            1.1. NDrip Ltd. (the "<strong>Company</strong>") markets and sells a
            system for spatial sampling of root water potential through the
            deployment, installment and use of innovative sensors (“System””)
            and provides services in connection with theSystem (“Services”)
            either directly or through its Authorized Distributors (as defined
            below).
          </p>
          <p>
            1.2. These Terms and Conditions (“<strong>T&Cs</strong>”) set forth
            the terms pursuant to which the customer (“<strong>Customer</strong>
            ” or “<strong>You</strong>”) may use the System provided by the
            Company to Customer.
          </p>
          <p>
            1.3. These T&Cs, together with (a) the Purchase Order issued by the
            Customer,(b) Company’s subsequent invoice confirming Customer’s
            Purchase Order(hereafter collectively the “
            <strong>Commercial Documentation</strong>”), shall constitute the
            entire agreement between the parties.
          </p>
          <p style={{ fontWeight: 'bold' }}>2. Supply and Consideration</p>
          <p>
            2.1. Company shall provide the System in such quantities as agreed
            in accordance with the terms of the Commercial Documentation.
          </p>
          <p>
            2.2. Customer is responsible for all field preparation and
            installation of the System.
          </p>
          <p>
            <strong>3. Maintenance Services.</strong> If you have acquired a
            maintenance package, it's terms shall be agreed in a separate
            written agreement.
          </p>
          <p style={{ fontWeight: 'bold' }}>4. Ownership and IP</p>
          <p>
            Customer acknowledges that, as between the parties, Company is and
            shall at all times be the sole and exclusive owner of all right,
            title and interest in and to any intellectual property, including
            but not limited to any copyright, patents,designs, domain names,
            trade names, trademarks and goodwill associated with the Company
            and/or the System, whether existing now or created in the
            future,whether registered or unregistered, and including with
            respect to any further developments, modification, or improvement of
            the System. Customer shall not, directly or indirectly, reverse
            engineer, decompile, manufacture or disassemble the System or any
            part thereof.
          </p>
          <p style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
            5. Access and Use of Data collected from the System.
          </p>
          <p>
            5.1. The System transmits data including personal data, such as
            names, contact details, field location and other personal
            information to an individual cloud services account, which shall be
            managed and controlled by Company for the Term. We refer to the
            cloud account as the “<strong>User Account</strong>,” to the data
            transmitted from the System to the User Account as “
            <strong>Field Data</strong>,” and to the term of our agreement as “
            <strong>Term</strong>.” Unless otherwise agreed in writing, the Term
            shall be the Growing Season set forth in the Commercial
            Documentation.
          </p>
          <p>
            5.2. NDrip shall have access to the Field Data in the User Account
            during the Term.
          </p>
          <p>
            5.3. Customer will have access to the User Account, but only during
            the Term.
          </p>
          <p>
            <strong>5.4.</strong>{' '}
            <u>
              NDrip, and if applicable, an Authorized Distributor or Service
              Provider, shall also have access to the Field Data in the User
              Account for the Term and shall have the right to use, process and
              transmit such Field Data and other personal information solely in
              order to allow it to perform the Services during the Term.
            </u>{' '}
            An “<strong>Authorized Distributor</strong>” is a distributor from
            whom you purchased the System and/or Services and a “
            <strong>Service Provider</strong>” is a party whom NDrip has hired
            to assist it in providing the Services (such as a consultant). A
            list of Service Providers will be available upon request. Other than
            as set forth above, NDrip will not transfer your personal
            information to third parties.
          </p>
          <p>
            5.5.
            <u>
              NDrip and an Authorized Distributor, if applicable, shall have the
              right touse the Field Data in the User Account during the Term in
              order to alter,modify, adapt, or improve the System (and if
              applicable, the Authorized Distributor’s technologies). User
              consents to such use and shall not have any claims with respect to
              any such modifications or improvements.
            </u>
          </p>
          <p>
            5.6. In addition, NDrip will arrange, prior to expiration of the
            Term, for the extraction of the Field Data in the User Account in an
            anonymous form to a separate database, which will amalgamate
            anonymous data from your User Account as well as from user accounts
            of other customers, and which will not contain data that will be
            able to identify you (“<strong>Company Database</strong>”).Since the
            data, in the Company Database cannot be used to identify you,there
            are no restrictions on the way that Company can use or share this
            data.For purposes of clarity, the Company Database shall be owned
            solely by Company, and Company shall be entitled inter alia, to use,
            sell, transfer, and grant access to the Company Database and data
            therein, at its sole and absolute discretion. To the extent, consent
            is required; you consent to the extraction of the Field Data, and
            shall not have any claims with respect to theCompany Database and
            ownership by Company thereof.
          </p>
          <p>
            5.7. Customer consents to any transfer of the User Account and the
            personal data therein to any new owner of Company (whether by
            merger, acquisition or other such transaction), if such personal
            data contained therein shall only be used by the new owner in the
            same manner as set forth herein.
          </p>
          <p>
            5.8. Based on your jurisdiction, you might have certain additional
            rights in respect of the protection of your personal data that we
            process. We recommend that you consult with your local data
            protection laws and regulations for more information on your rights.
          </p>
          <p>
            <strong>6. Disclaimer Of Warranties</strong> Company provides the
            System and Services to the Customer “as is” and to the maximum
            extent allowable in accordance with applicable law, Company and the
            Authorized Distributor disclaim any and all warranties, express or
            implied, including any implied warranties of merchantability or
            fitness for particular purpose. Company and theAuthorized
            Distributor do not warrant that the System, its use, operation or
            your ability to use the System will be uninterrupted or error-free
            or that any or all System errors will be corrected. Company and the
            authorized distributor do not warrant or make any representation
            that Customer will achieve particular results and does not guarantee
            any improvements in any agricultural or commercial yield or other
            measurements. Inferences made by individuals using the System do not
            reflect those of Company. Company and the Authorized Distributor do
            not warrant that the System’s security will be flawless
          </p>
          <p>
            6.1. Customer hereby fully waives, releases, re mises to the fullest
            extent permissible by law the Company, and its representatives, from
            any and all liability regarding the System and any Services provided
            by Company in connection therewith (including without limitation,
            claims resulting from data obtained from the Systems or
            recommendations provided by theCompany regarding the manner and
            method of irrigation in Your Field) and any damages that it may
            suffer in reliance upon those Services or Field Data.Customer agrees
            not to bring any claim against any of the foregoing parties in
            connection with the Services or Field Data, and in the event any
            such claim is brought against any of the foregoing parties, Customer
            agrees to fully indemnify the indemnified party for any damages
            suffered as a result of such claim, including without limitation all
            attorney’s fees and court costs.Any Services provided to Customer
            are being provided on reliance on this waiver.
          </p>
        </div>
        <div style={{ width: 0.05 * width }}></div>
        {/*right side text*/}
        <div
          style={{
            width: 0.4 * width,
            whiteSpace: 'pre-line',
            overflowWrap: 'break-word',
          }}
        >
          <p style={{ fontWeight: 'bold' }}>
            7. Limitation of Liability; Indemnity
          </p>
          <p>
            7.1. Under no circumstance shall Company bear any liability for
            indirect,incidental, special or consequential damages resulting from
            the furnishing of the System or Services to you, and/or use of the
            System or Services by you, including but not limited to any loss or
            damage to business earnings, reliance, cover or loss of anticipated
            profit,goodwill, or other benefit, business interruption or failure,
            suffered by any person and/or entity, arising from and/or related
            and/or connected to any use of the System and/or any component the
            thereof and the provision of the Services, in each case even if
            Company or any representative thereof has been advised of the
            possibility of such damages.
          </p>
          <p>
            7.2. In any event in connection with this agreement or the System or
            theServices, NDrip shall only be liable or bear any responsibility
            with respect to Customer for any damages up to a maximum amount
            equal to the amount actually paid to Company for the Services in the
            6months immediately preceding the date on which the cause of action
            giving rise to the claim arose. Customer agrees not to bring any
            suit or action against Company, its subsidiaries, affiliates, and/or
            any of their respective directors, officers, employees, or agents
            for any reason whatsoever more than one year after the cause of
            action arises.
          </p>
          <p>
            7.3. Customer shall indemnify Company for any loss and/or damage,
            including claims and/or demands by third parties relating to
            Customer’s use of theSystem or Customer’s breach of these T&C's.
          </p>
          <p style={{ fontWeight: 'bold' }}>8. Confidentiality</p>
          <p>
            {' '}
            Customer agrees, at all times, including after termination hereof,
            to keep in confidence and trust all information and materials
            disclosed to it by Company which are marked as confidential or which
            would reasonably be held to be confidential (“
            <strong>confidential information</strong>”) and shall not disclose
            such ConfidentialInformation to any third party without Company’s
            prior written consent. For the avoidance of doubt, Confidential
            Information does not include information, which was already in the
            public domain not as a result of breach of any agreement with the
            Company, or information that is required to be disclosed by law.
          </p>
          <p style={{ fontWeight: 'bold' }}>
            9. Use Of Internet And Third Party Wireless Network
          </p>
          <p>
            Customer acknowledges that internet and communications over it and
            third party wireless network may not be absolutely secure.
          </p>
          <p style={{ fontWeight: 'bold' }}>10. Miscellaneous</p>
          <p>
            10.1 Customer alone shall be solely responsible for any taxes or
            other compulsory payments with respect to any moneys payable by it
            to theCompany. Customer represents that no withholding is required
            to be paid by it in respect of any amounts payable under or in
            respect of the CommercialDocumentation. If, notwithstanding the
            above, a withholding requirement applies, then all amounts payable
            shall be grossed up so that the net amount payable by Customer shall
            be the amount set out in the CommercialDocumentation.
          </p>
          <p>
            10.2 The Commercial Documentation shall be governed by and construed
            in accordance with the laws of the State of Israel, without giving
            effect to the principles of conflicts of law thereof. Sole
            jurisdiction with respect to any matter relating to the Commercial
            Documentation is hereby granted to the qualified courts of Tel
            Aviv-Jaffa or the Central District, to the absolute exclusion of any
            other court in any other jurisdiction.
          </p>
        </div>
        <div style={{ width: 0.05 * width }}></div>
      </div>
    </div>
  );
};
module.exports = connect()(PrivacyPolicy);
