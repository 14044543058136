import { unitDisplayName } from "./unitsManager";
import { loadFieldRecords } from "./fieldManager";
var moment = require("moment");
const DAY = 60 * 60 * 24;

const TYPE_UNITS = {
  plan_irr: "length",
  plan_fert: "weight/area",
  plan_hcl: "volume",
  plan_hpo: "volume",
  plan_note: "",
};

export const waterTreatmentDisplayName = {
  hpo: "HPO",
  cs: "Copper Sulphate",
  sanidate: "SaniDate",
  acid: "Acid",
};

export var sumEventsValues = (items, date, durationInDays) => {
  let total = {};
  items &&
    Object.keys(items).forEach((type) => {
      let sumAmount = 0;
      let sumEvents = 0;

      items[type] &&
        Object.keys(items[type]).forEach((item) => {
          if (
            moment.unix(date).isSameOrBefore(moment.unix(Number(item))) &&
            moment
              .unix(Number(item))
              .isBefore(moment.unix(date + durationInDays * DAY))
          ) {
            sumAmount +=
              type === "plan_note"
                ? 1
                : Number(items[type][item].value) || Number(items[type][item]);
            sumEvents += 1;
          }
        });
      total[type] = { sumAmount: sumAmount, sumEvents: sumEvents };
    });
  return total;
};

export var getPlannerItems = (fieldId, userData, setPlans) => {
  // Load all coming plans for current field
  loadFieldRecords(
    fieldId,
    0,
    ["plan_irr", "plan_fert", "plan_hpo", "plan_hcl", "plan_note", "plan_wtr"],
    ["endDate/type", "fertType/source/nitroAmount", "", "", "", "endDate/type"],
  ).then(function (res) {
    let loadedPlans = [];

    for (const [planType, planObjects] of Object.entries(res)) {
      for (const [startDate, values] of Object.entries(planObjects["values"])) {
          let tmpDict = {};
          tmpDict["planType"] = planType;
          tmpDict["startDate"] = startDate;
          tmpDict["sortDate"] = startDate;

          if (typeof values === "object") {
            if ("type" in values && values["type"] === "filler") {
              // filler value used for fill accumulated graph only , will not apper in "coming up" section and planning
              continue;
            }
            tmpDict = Object.assign({}, tmpDict, values);
            tmpDict["value"] =
              parseFloat(parseFloat(tmpDict["value"]).toFixed(4)) / 1;

            if ("endDate" in values) {
              tmpDict["sortDate"] = values["endDate"];
              tmpDict["duration"] = (
                (parseFloat(values["endDate"]) - parseFloat(startDate)) /
                (60 * 60)
              ).toFixed(1);
            }
            if ("fertType" in values) {
              tmpDict["fertType"] = values["fertType"];
            }
          } else {
            tmpDict["value"] = values;
          }
          if (userData) {
            if (planType === "plan_wtr") {
              let wtrUnits = getUnitsForWTRType(values.type);
              tmpDict["units"] = userData[wtrUnits];
              tmpDict["note"] =
                values.type === "cs"
                  ? "WT_timing_recommendation_after_irrigation_starts"
                  : "WT_timing_recommendation_last_hour";
            } else {
              let unit = TYPE_UNITS[planType];
              tmpDict["units"] =
                userData && unit in userData
                  ? unitDisplayName(userData[unit])
                  : unit;
            }
            loadedPlans.push(tmpDict);
          }
      }
    }

    // Sorting by sortDate which a parameter that holds the start date for value only params and the end
    // date for params with start and end dates
    loadedPlans.sort(function (a, b) {
      return a.sortDate - b.sortDate;
    });
    if (setPlans) {
      setPlans(loadedPlans);
    }
  });
};

export function getUnitsForWTRType(wtrType) {
  if (["cs"].includes(wtrType)) {
    return "weight";
  }
  return "small_volume";
}
