var React = require('react');
var { Link } = require('react-router-dom');
var { connect } = require('react-redux');
var distributionActions = require('distributionActions');

class DistributionsDropdown extends React.Component {
  constructor(props) {
    super(props);
  }

  onDropdownSelection(key) {
    if (key) {
      var selected_distribution = this.props.distributions.distributions[key];

      var first_farming_unit_key = Object.keys(
        selected_distribution.farming_units,
      )[0];
      var selected_farming_unit =
        selected_distribution.farming_units[first_farming_unit_key];

      this.props.dispatch(
        distributionActions.selected_entities(
          selected_distribution,
          selected_farming_unit,
          null,
          null,
        ),
      );
    }
  }

  render() {
    let sortedDistributions = undefined;
    if (this.props.distributions) {
      sortedDistributions = Object.values(
        this.props.distributions.distributions,
      );
      sortedDistributions.sort((a, b) => a.name.localeCompare(b.name));
    }

    return (
      <div>
        <div className='dropdown text-grey71 font-weight-normal'>
          <div
            className='dropdown-toggle d-flex align-items-center'
            id='dropdownMenuButton'
            data-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            <div className='overflow-hidden text-truncate'>
              <Link to='' className='text-style-titles-16-regular text-grey45'>
                {this.props.active_distribution &&
                  this.props.active_distribution.name}
              </Link>
            </div>
          </div>
          <div
            className='dropdown-menu scrollable-menu'
            aria-labelledby='dropdownMenuButton'
          >
            {sortedDistributions &&
              sortedDistributions.map((current_distribution) => {
                return (
                  <a
                    className='dropdown-item text-grey71'
                    href='#'
                    key={current_distribution.id}
                    onClick={() => {
                      this.onDropdownSelection(current_distribution.id);
                    }}
                  >
                    {current_distribution.name}
                  </a>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    distributions: state.distribution_data.distributions,
    active_distribution: state.distribution_data.selected_entities.distribution,
    active_farming_unit: state.distribution_data.selected_entities.farming_unit,
  };
};

module.exports = connect(mapStateToProps)(DistributionsDropdown);
