import moment from "moment";
var axios = require("axios");
var { subscribe } = require("redux-subscriber");
var authenticateActions = require("@actions/authenticateActions");
var store = require("@store/configureStore").configure();
var { error_message } = require("@managers/helpers");
var {
  getItem,
  removeItem,
  setItem,
} = require("@managers/localStorageManager.jsx");
var { getMixpanelInstance } = require("@managers/mixpanelManager");

const SCIROOT_REFRESH_TOKEN_PATH = "refresh_token";

function updateHeaders(authorization, access_token) {
  axios.defaults.headers.common["Authorization"] = authorization;
  axios.defaults.headers.common["AccessToken"] = access_token;
}

var clearLocalStorage = () => {
  removeItem("ID_TOKEN");
  removeItem("ACCESS_TOKEN");
  removeItem("REFRESH_TOKEN");
  removeItem("USER_DETAILS");
  removeItem("SELECTED_DISTRIBUTION_ID");
  removeItem("SELECTED_FARMING_UNIT_ID");
  removeItem("SELECTED_FIELD_ID");
  removeItem("SELECTED_SENSOR_ID");
  removeItem("DISTRIBUTIONS_DATA");
};

export var getUserDetails = () => {
  if (
    getItem("ID_TOKEN", "") &&
    getItem("ACCESS_TOKEN", "") &&
    getItem("USER_DETAILS", "")
  ) {
    let userDetails = JSON.parse(getItem("USER_DETAILS", ""));
    return userDetails;
  } else {
    return null;
  }
};

export var init = (store, baseUrl) => {
  axios.defaults.baseURL = baseUrl;

  updateHeaders(getItem("ID_TOKEN", ""), getItem("ACCESS_TOKEN", ""));

  // subscribe to authentication changes
  subscribe("authentication_data.tokens", (state) => {
    var id_token = state.authentication_data.tokens.id_token;
    var access_token = state.authentication_data.tokens.access_token;
    var refresh_token = state.authentication_data.tokens.refresh_token;

    updateHeaders(id_token, access_token);

    if (id_token && access_token && refresh_token) {
      // Save tokens to local storage
      setItem("ID_TOKEN", id_token);
      setItem("ACCESS_TOKEN", access_token);
      setItem("REFRESH_TOKEN", refresh_token);
    } else {
      clearLocalStorage();
    }
  });

  // subscribe to user details changes
  subscribe("authentication_data.user_details", (state) => {
    var user_details = state.authentication_data.user_details;

    if (user_details) {
      // Save user details to local storage
      setItem("USER_DETAILS", JSON.stringify(user_details));
    } else {
      removeItem("USER_DETAILS");
    }
  });

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Do something with response data
      return response;
    },
    function (error) {
      if (error.response) {
        var mixpanelInstance = getMixpanelInstance();
        mixpanelInstance.track("DEBUG_API Call Failed", {
          path: error.response.request.responseURL,
          err: error.response.status,
          err_msg: error.response.data.message,
        });
        switch (error.response.status) {
          case 400:
            console.log("400");
            break;
          case 401:
            console.log(error.response.data.message);
            if (
              error.response.data.message == "The incoming token has expired"
            ) {
              console.log("Refreshing token...");

              store.dispatch(authenticateActions.initiate_refresh_token());

              // refresh the token
              var refresh_token =
                store.getState().authentication_data.tokens.refresh_token;

              return axios
                .post(SCIROOT_REFRESH_TOKEN_PATH + "/" + refresh_token, {})
                .then(function (res) {
                  store.dispatch(
                    authenticateActions.refresh_token_success(
                      res.data.id_token,
                      res.data.access_token,
                      res.data.refresh_token,
                    ),
                  );

                  error.config.headers.Authorization = res.data.id_token;
                  error.config.headers.AccessToken = res.data.access_token;

                  mixpanelInstance.track("DEBUG_Refresh Token Succeeded");

                  return axios.request(error.config);
                })
                .catch(function (err) {
                  mixpanelInstance.track("DEBUG_Refresh Token Failed", {
                    err_msg: error_message(err),
                  });
                  store.dispatch(
                    authenticateActions.refresh_token_fail(error_message(err)),
                  );
                });
            } else {
              // Remove Local Storage values
              clearLocalStorage();
            }

            break;
          default:
        }
      }

      // save failed sensor requests in async storage - Only for Mobile ( axios.interceptors uses for axios calls that fails)
      if (
        error.config.method.includes("post") &&
        error.config.url.includes("sensor_records")
      ) {
        getItem(`failed_sensor_request_${moment().unix()}`)
          .then((value) => {
            if (value === null || value === undefined) {
              setItem(
                `failed_sensor_request_${moment().unix()}`,
                JSON.stringify(error.config),
              )
                .then(() => {
                  console.log("failed_sensor_request saved in local storage");
                })
                .catch((err) => {
                  console.log(
                    "failed_sensor_request not saved in local storage",
                  );
                });
            }
          })
          .catch((err) => {
            console.log("failed_sensor_request not saved in local storage");
          });
      }
      return Promise.reject(error);
    },
  );
};

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
