var React = require('react');
var ConfirmForm = require('ConfirmForm');
var MainCSS = require('mainCSS');
var loginCSS = require('loginCSS');
var { connect } = require('react-redux');
var { Redirect } = require('react-router-dom');
var { Link } = require('react-router-dom');
var { withTranslation } = require('react-i18next');

class Confirm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t } = this.props;

    // go to root page if login already happened
    if (this.props.id_token) {
      return <Redirect to='/' />;
      // go to login if confirmation happened
    } else if (this.props.register_flow.confirm_status == 'success') {
      return <Redirect to='/login' />;
    }

    return (
      <div className='container'>
        <div className='row justify-content-center mainLoginRow shadow-lg mb-sm-5 rounded mt-sm-5'>
          <div className='d-none d-lg-block col-12 col-lg-6 m-0 p-0 '>
            <img
              src={require('images/login/login-bg.png')}
              className='img-fluid w-100 h-100 '
              alt=''
              style={{ opacity: '0.9' }}
            />
          </div>
          <div className='col-12 col-lg-6 bg-white'>
            <div className='row mt-5'>
              <div className='col text-center'>
                <img
                  src={require('images/general/ndrip-logo.png')}
                  className='img-fluid'
                  alt='N-Drip Logo'
                />
              </div>
            </div>
            <div className='ml-2 mr-2 mt-3'>
              <p>{t('login_registration_confirmation_message')}</p>
              <p>
                {t('login_registration_after_confirmation_message')}{' '}
                <Link to='/'>{t('login_page')}</Link>{' '}
                {t('login_registration_after_confirmation_message_2')}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    id_token: state.authentication_data.tokens.id_token,
    register_flow: state.authentication_data.register_flow,
  };
};

module.exports = connect(mapStateToProps)(withTranslation()(Confirm));
