var axios = require("axios");
var { error_message } = require("@managers/helpers");
var GeneralClasses = require("../model/GeneralClasses");

const SCIROOT_NOTIFICATION_PATH = "notification";
const NOTIFICATIONS_CONFIGURATIONS = "notification_configurations";

export var notifyUpdatedPlan = (distribution_id, farming_unit_id) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        SCIROOT_NOTIFICATION_PATH +
          "/" +
          distribution_id +
          "/" +
          farming_unit_id,
        {},
      )
      .then(function (res) {
        let resJson = JSON.parse(res.data);
        if (resJson.statusCode != 200) {
          throw resJson.body;
        }

        resolve(resJson);
      })
      .catch(function (err) {
        console.log(error_message(err));
        reject(err);
      });
  });
};

export var replaceKeywords = (notificationObj) => {
  if (
    !notificationObj.hasOwnProperty("field_name") ||
    !notificationObj.hasOwnProperty("sensor_name")
  ) {
    console.error(
      "field name or sensor name is missing in notification object",
    );
    return notificationObj;
  } else {
    const keywordMappings = [
      { keyword: "fieldX", replacement: notificationObj.field_name },
      { keyword: "sensorX", replacement: notificationObj.sensor_name },
    ];

    keywordMappings.forEach(({ keyword, replacement }) => {
      if (notificationObj.notification_text.title.includes(keyword)) {
        notificationObj.notification_text.title =
          notificationObj.notification_text.title.replace(
            new RegExp(keyword, "g"),
            replacement,
          );
      }

      if (notificationObj.notification_text.body.includes(keyword)) {
        notificationObj.notification_text.body =
          notificationObj.notification_text.body.replace(
            new RegExp(keyword, "g"),
            replacement,
          );
      }
    });
    return notificationObj;
  }
};

export var getNotificationsForUser = (currentDate, selectedEntities) => {
  return new Promise(async function (resolve, reject) {
    axios
      .get(SCIROOT_NOTIFICATION_PATH + "/" + currentDate, {})
      .then(function (res) {
        resolve(res.data);
      })
      .catch(function (err) {
        console.log(error_message(err));
        reject(err);
      });
  });
};

export var getNotificationsConfigurations = () => {
  // This function returns the structure of the configuration that is displayed on the account page under the notifications section , sorted by the distributions , farming units and fields.
  return new Promise(async function (resolve, reject) {
    axios
      .get(NOTIFICATIONS_CONFIGURATIONS, {})
      .then(function (res) {
        let userDistributions = res.data;
        let sortedDistributionsIds = Object.keys(userDistributions);

        userDistributions = Object.values(userDistributions)
          .map((distribution, index) => {
            if (!distribution.hasOwnProperty("name")) {
              // Skip empty distributions
              return null;
            }
            return { ...distribution, id: sortedDistributionsIds[index] };
          })
          .filter((item) => item !== null)
          .sort((a, b) => a.name.localeCompare(b.name));

        let userFarmingUnits = [];
        let sortedFarmingUnitsIds = [];
        if (
          userDistributions.length > 0 &&
          userDistributions[0].hasOwnProperty("farming_units") &&
          Object.keys(userDistributions[0].farming_units).length > 0
        ) {
          userFarmingUnits = Object.values(userDistributions[0]?.farming_units);
          sortedFarmingUnitsIds = Object.keys(
            userDistributions[0]?.farming_units,
          );

          userFarmingUnits = Object.values(userFarmingUnits)
            .map((farmingUnit, index) => {
              if (!farmingUnit.hasOwnProperty("name")) {
                // Skip empty farming units
                return null;
              }
              return { ...farmingUnit, id: sortedFarmingUnitsIds[index] };
            })
            .filter((item) => item !== null)
            .sort((a, b) => a.name.localeCompare(b.name));
        }

        let userFields = [];
        if (
          userFarmingUnits.length > 0 &&
          userFarmingUnits[0].hasOwnProperty("fields") &&
          Object.keys(userFarmingUnits[0]).length > 0
        ) {
          let sortedFieldsIds = Object.keys(userFarmingUnits[0]?.fields);
          userFields = Object.values(userFarmingUnits[0]?.fields)
            .map((field, index) => {
              return { ...field, id: sortedFieldsIds[index] };
            })
            .sort((a, b) => a.name.localeCompare(b.name));
        }

        resolve({
          distributions: userDistributions,
          farmingUnits: userFarmingUnits,
          fields: userFields,
        });
      })
      .catch(function (err) {
        console.log(error_message(err));
        reject(err);
      });
  });
};

export var notificationsDataMap = (notificationsData, selectedFieldID = "") => {
  let allNotificationsMapped = [];
  let currentFieldNotificationsMapped = [];
  let notificationsObj = notificationsData.alerts;
  let sensorAlertsDictionary = notificationsData.sensor_alerts_dictionary;
  let fieldAlertsDictionary = notificationsData.field_alerts_dictionary;

  if (Object.keys(notificationsObj).length > 0) {
    for (const distribution in notificationsObj) {
      for (const farmingUnit in notificationsObj[distribution][
        "farming_units"
      ]) {
        let farmingUnitName =
          notificationsObj[distribution]["farming_units"][farmingUnit].name;

        if (
          notificationsObj[distribution]["farming_units"][
            farmingUnit
          ].hasOwnProperty("fields")
        ) {
          for (const field in notificationsObj[distribution]["farming_units"][
            farmingUnit
          ]["fields"]) {
            let fieldObj =
              notificationsObj[distribution]["farming_units"][farmingUnit][
                "fields"
              ][field];
            let fieldName = fieldObj.name;

            if (fieldObj.hasOwnProperty("alerts"))
              for (const alert in fieldObj["alerts"]) {
                let fieldNotification = fieldObj["alerts"][alert];
                let notificationObj = new GeneralClasses.Notification({
                  farming_unit_name: farmingUnitName,
                  field_name: fieldName,
                  date: fieldNotification.date,
                  notification_text:
                    fieldAlertsDictionary[fieldNotification.value],
                  field_id: field,
                  farming_unit_id: farmingUnit,
                  distribution_id: distribution,
                });

                if (selectedFieldID === field) {
                  currentFieldNotificationsMapped.push(notificationObj);
                }
                allNotificationsMapped.push(notificationObj);
              }

            if (fieldObj.hasOwnProperty("sensors")) {
              let fieldSensors = fieldObj["sensors"];
              for (const sensor in fieldSensors) {
                if (
                  fieldSensors[sensor].hasOwnProperty("alerts") &&
                  fieldSensors[sensor]["alerts"].length > 0
                ) {
                  let fieldSensorsNotifications =
                    fieldSensors[sensor]["alerts"];
                  for (const sensorAlert in fieldSensorsNotifications) {
                    let notificationObj = new GeneralClasses.Notification({
                      farming_unit_name: farmingUnitName,
                      field_name: fieldName,
                      sensor_name: fieldSensors[sensor].name,
                      date: fieldSensorsNotifications[sensorAlert].date,
                      notification_text:
                        sensorAlertsDictionary[
                          fieldSensorsNotifications[sensorAlert].value
                        ],
                      field_id: field,
                      farming_unit_id: farmingUnit,
                      distribution_id: distribution,
                    });

                    if (selectedFieldID === field) {
                      currentFieldNotificationsMapped.push(notificationObj);
                    }
                    allNotificationsMapped.push(notificationObj);
                  }
                }
              }
            }
          }
        }
      }
    }
    return {
      allNotifications: allNotificationsMapped,
      currentFieldNotifications: currentFieldNotificationsMapped,
    };
  }
};
