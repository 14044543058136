var React = require('react');
var LoginForm = require('LoginForm');
var MainCSS = require('mainCSS');
var loginCSS = require('loginCSS');
var { connect } = require('react-redux');
var { Redirect } = require('react-router-dom');
var { withTranslation } = require('react-i18next');
var $ = require('jquery');

class TermsAndConditions extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t } = this.props;

    return (
      <div
        className='modal fade mt-5 mb-5 pt-3 pb-5'
        id='termsAndConditionsId'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div
          className='modal-dialog modal-lg modal-dialog-centered'
          role='document'
        >
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Terms and Conditions
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <div className='overflow-auto' style={{ height: '600px' }}>
                <ul className='nav nav-tabs' id='myTab' role='tablist'>
                  <li className='nav-item'>
                    <a
                      className='nav-link active'
                      id='home-tab'
                      data-toggle='tab'
                      href='#home'
                      role='tab'
                      aria-controls='home'
                      aria-selected='true'
                    >
                      English
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      id='profile-tab'
                      data-toggle='tab'
                      href='#profile'
                      role='tab'
                      aria-controls='profile'
                      aria-selected='false'
                    >
                      עברית
                    </a>
                  </li>
                </ul>

                <div className='tab-content' id='myTabContent'>
                  <div
                    className='tab-pane fade show active'
                    id='home'
                    role='tabpanel'
                    aria-labelledby='home-tab'
                  >
                    <iframe
                      className='w-100 '
                      style={{
                        height: '550px',
                        border: '1px solid #dfe7f0',
                        'border-top': '0px solid #dfe7f0',
                      }}
                      src={require('updatedTNC')}
                    ></iframe>
                  </div>
                  <div
                    className='tab-pane fade'
                    id='profile'
                    role='tabpanel'
                    aria-labelledby='profile-tab'
                  >
                    <iframe
                      className='w-100 '
                      style={{
                        height: '550px',
                        border: '1px solid #dfe7f0',
                        'border-top': '0px solid #dfe7f0',
                      }}
                      src={require('HebrewTNC')}
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    id_token: state.authentication_data.tokens.id_token,
  };
};

module.exports = connect(mapStateToProps)(
  withTranslation()(TermsAndConditions),
);
