var React = require('react');
var { connect } = require('react-redux');
var BaseComponent = require('BaseComponent');
var { withTranslation } = require('react-i18next');
var sensorsManager = require('sensorsManager');
var { sensorsMap } = require('sensorsManager');
var moment = require('moment-timezone');
var { timeSinceNowString } = require('helpers');
var DischargeGraphContainer = require('DischargeGraphContainer');
const SearchContainer = require('./SearchContainer.jsx');
const adminCSS = require('adminCSS');

const componentState = {
  distribution_name: '',
  farming_unit_name: '',
  field_name: '',
  transmitter_id: '',
  onLoad: false,
  semiLoad: false,
  recentAnalogValues: [],
  recentC1Values: [],
  recentRSSIValues: [],
  recentBerValues: [],
  recentRSRQValues: [],
  recentRSRPValues: [],
  recentBatteryValues: [],
  recentWFRSamples: [],
  lastSeen: '',
  simStatus: '',
  modem_hardware: '',
  modem_software: undefined,
  desired_modem_software: undefined,
  iccid: '',
  sensor_batch: '',
  transmission_interval: '',
  sample_interval: '',
  micro_version: '',
  installation_date: '',
  recent_errors: [],
  time_zone: '',
  type: '',
  sub_type: '',
  should_present_graph: false,
  irrigation_hin: '',
  transmitter_name: '',
  pressure_samples: [],
  discharge_samples: [],
};

class AdminPage extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = { ...componentState };

    this.onStateChanged = this.onStateChanged.bind(this);
    this.onTestButtonClicked = this.onTestButtonClicked.bind(this);
    this.itemAtIndex = this.itemAtIndex.bind(this);
  }

  componentDidMount() {}

  onStateChanged(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onTestButtonClicked(transmitterID) {
    let that = this;
    that.setState({ onLoad: true });

    // first request with false to get fast and tiny response
    sensorsManager
      .getTransmitterStatus(transmitterID, false)
      .then(function (res) {
        let shouldPresentGraph = false;
        if (
          res.body.transmitter_type.includes('piezometer') ||
          res.body.transmitter_type.includes('ndrip') ||
          res.body.transmitter_type.includes('wfr')
        ) {
          shouldPresentGraph = true;
        }
        let sensor_name = res.body.sensor_name;
        let time_zone = res.body.time_zone;
        let recentAnalogValues = res.body.recent_analog_samples.map((x) => ({
          value: x.value,
          date: moment(parseInt(x.ts) * 1000).format('L LTS Z'),
        }));
        let recentWFRSamples = res.body.recent_wfr_samples.map((x) => ({
          volume: x.value,
          discharge: Number.parseFloat(x.discharge).toFixed(2),
          pressure: Number.parseFloat(x.pressure).toFixed(2),
          start_date: moment(parseInt(x.ts) * 1000).format('L LTS Z'),
          end_date: moment(parseInt(x.end_date) * 1000).format('L LTS Z'),
        }));
        let pressure_samples =
          res.body.pressure_samples.length !== 0
            ? Object.entries(res.body.pressure_samples).map((x) => ({
                date: moment(parseInt(x[0]) * 1000).format('L LTS Z'),
                value: x[1],
              }))
            : [];
        let discharge_samples =
          res.body.discharge_samples.length !== 0
            ? Object.entries(res.body.discharge_samples).map((x) => ({
                date: moment(parseInt(x[0]) * 1000).format('L LTS Z'),
                value: x[1],
              }))
            : [];
        let irrigation_hin =
          pressure_samples.length > 0 ? res.body.irrigation_hin : undefined;
        let type = sensorsMap.get(res.body.transmitter_type);
        let sub_type =
          res.body.transmitter_sub_type === ''
            ? undefined
            : res.body.transmitter_sub_type;
        let distribution_name = res.body.distribution.name;
        let farming_unit_name = res.body.farming_unit.name;
        let field_name = res.body.field.name;
        that.setState({
          ...componentState,
          transmitter_id: transmitterID,
          onLoad: false,
          semiLoad: true,
          recentAnalogValues: recentAnalogValues,
          should_present_graph: shouldPresentGraph,
          pressure_samples: pressure_samples,
          discharge_samples: discharge_samples,
          recentWFRSamples: recentWFRSamples,
          irrigation_hin: irrigation_hin,
          transmitter_name: sensor_name,
          time_zone: time_zone,
          distribution_name: distribution_name,
          farming_unit_name: farming_unit_name,
          field_name: field_name,
          type: type,
          sub_type: sub_type,
        });

        mixpanel.track('Transmitter Test Pressed', {
          'Transmitter ID': transmitterID,
          is_pressure_graph_visible: !!recentWFRSamples.length,
        });
      });

    sensorsManager.getTransmitterStatus(transmitterID, true).then(
      function (res) {
        let installationDate = '';
        if (res.body.installation_date) {
          let installationDateObject = moment(
            parseInt(res.body.installation_date) * 1000,
          );
          let timeAgo = timeSinceNowString(installationDateObject);
          installationDate =
            installationDateObject.format('L LTS Z') +
            ' (' +
            that.props.t(timeAgo[1]).format(timeAgo[0]) +
            ')';
        }
        let lastSeenTS = parseInt(res.body.last_seen);
        let lastSeenDate = moment(lastSeenTS * 1000).format('L LTS Z');
        let simStatus = parseInt(res.body.sim_status);

        let recentC1Values = res.body.recent_c1_samples.map((x) => ({
          value: x.value,
          date: moment(parseInt(x.ts) * 1000).format('L LTS Z'),
        }));
        let recentRSSIValues = res.body.recent_rssi_samples.map((x) => ({
          value: x.value,
          date: moment(parseInt(x.ts) * 1000).format('L LTS Z'),
          classification: x.classification,
        }));
        let recentBerValues = res.body.recent_ber_samples.map((x) => ({
          value: x.value,
          date: moment(parseInt(x.ts) * 1000).format('L LTS Z'),
          classification: x.classification,
        }));
        let recentRSRQValues = res.body.recent_rsrq_samples.map((x) => ({
          value: x.value,
          date: moment(parseInt(x.ts) * 1000).format('L LTS Z'),
          classification: x.classification,
        }));
        let recentRSRPValues = res.body.recent_rsrp_samples.map((x) => ({
          value: x.value,
          date: moment(parseInt(x.ts) * 1000).format('L LTS Z'),
          classification: x.classification,
        }));
        let recentBatteryValues = res.body.recent_battery_samples.map((x) => ({
          value: x.value,
          date: moment(parseInt(x.ts) * 1000).format('L LTS Z'),
          classification: x.classification,
        }));
        let modem_hardware = res.body.modem_hardware;
        let modem_software = res.body.modem_software;
        let desired_modem_software = res.body.desired_modem_software;
        let iccid = res.body.iccid;
        let sensor_batch = res.body.sensor_batch;
        let transmission_interval = res.body.transmission_interval;
        let sample_interval = res.body.sample_interval;
        let micro_version = res.body.micro_version;

        let sim_last_event = res.body.sim_last_event.substring(0, 61);
        let recent_errors = res.body.recent_errors.map((x) => ({
          value: x.value,
          date: moment(parseInt(x.ts) * 1000).format('L LTS Z'),
        }));

        that.setState({
          semiLoad: false,
          recentC1Values: recentC1Values,
          recentRSSIValues: recentRSSIValues,
          recentBerValues: recentBerValues,
          recentRSRQValues: recentRSRQValues,
          recentRSRPValues: recentRSRPValues,
          recentBatteryValues: recentBatteryValues,
          lastSeen: lastSeenDate,
          simStatus: simStatus,
          modem_hardware: modem_hardware,
          modem_software: modem_software,
          desired_modem_software: desired_modem_software,
          iccid: iccid,
          sensor_batch: sensor_batch,
          transmission_interval: transmission_interval,
          sample_interval: sample_interval,
          micro_version: micro_version,
          installation_date: installationDate,
          sim_last_event: sim_last_event,
          recent_errors: recent_errors,
        });
      },
      function (error) {
        that.setState({
          ...componentState,
        });
        console.log(error);
      },
    );
    // return to main login handler
    // this.props.dispatch(authenticateActions.login(this.refs.email.value.toLowerCase(), this.refs.password.value));
  }

  // translate classification to color
  colorByClassification(classification) {
    if (classification === 'GOOD') {
      return 'green';
    } else if (classification === 'FAIR') {
      return 'orange';
    } else if (classification === 'BAD') {
      return 'red';
    } else {
      return 'black';
    }
  }

  itemAtIndex(valuesArray, index) {
    if (valuesArray.length > index) {
      return valuesArray[index];
    } else {
      return { value: '', date: '' };
    }
  }

  render() {
    const { t } = this.props;
    var distributionModalId = 'distribution_edit_modal';

    var modem_title = '';
    if (
      this.state.modem_software &&
      this.state.modem_hardware &&
      this.state.desired_modem_software
    ) {
      modem_title =
        this.state.modem_hardware +
        ' \\ ' +
        this.state.modem_software +
        ' (Latest: ' +
        this.state.desired_modem_software +
        ')';
    }

    // style for rows that still loading data
    var semiLoadStyle = {
      backgroundColor: this.state.semiLoad ? '#FFFFFF' : 'transparent',
      opacity: this.state.semiLoad ? 0.5 : 1,
    };

    return (
      <div className='container pt-5'>
        <div className='row justify-content-center'>
          <div className='col-12'>
            <h1>{t('transmitter_admin_page')}</h1>
          </div>
        </div>

        <div className='row justify-content-center'>
          <div
            className='col-12 align-self-center mt-3 pb-3 shadow-lg rounded'
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <SearchContainer
              onTestClick={this.onTestButtonClicked}
              loading={this.state.onLoad}
            />
            <div
              style={{
                backgroundColor: 'white',
                padding: 16,
                paddingTop: 0,
                borderBottom: '1px solid #DCE0E8',
              }}
            ></div>
            <div style={{ padding: 16 }}>
              {this.state.should_present_graph ? (
                <div
                  className='align-items-center d-flex'
                  style={{
                    height: '332px',
                    backgroundColor: this.state.onLoad
                      ? '#FFFFFF'
                      : 'transparent',
                    opacity: this.state.onLoad ? 0.5 : 1,
                  }}
                >
                  <DischargeGraphContainer
                    irrigation_hin={this.state?.irrigation_hin}
                    samples={this.state.recentWFRSamples}
                    pressure_samples={this.state.pressure_samples}
                    discharge_samples={this.state.discharge_samples}
                    lastSeen={
                      this.itemAtIndex(this.state.recentAnalogValues, 0).date
                    }
                    time_zone={this.state.time_zone}
                  />
                </div>
              ) : null}
              <table
                className='table table-hover table-sm'
                style={{
                  backgroundColor: this.state.onLoad
                    ? '#FFFFFF'
                    : 'transparent',
                  opacity: this.state.onLoad ? 0.5 : 1,
                }}
              >
                <thead>
                  {this.state.simStatus == 1 && (
                    <tr>
                      <td
                        className='bg-green2 text-center text-white'
                        colspan='3'
                      >
                        {t('sim_activation')}
                      </td>
                    </tr>
                  )}
                  <tr className='table-grey45'>
                    <th className='table-header-cell' scope='row'></th>
                    <th className='table-header-cell'>{t('date')}</th>
                    <th className='table-header-cell'>{t('value')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='table-smoked'>
                    <th scope='row'>{t('last_seen')}</th>
                    <td>{this.state.lastSeen}</td>
                    <td></td>
                  </tr>
                  <tr className='table-grey71'>
                    <th scope='row'>{t('recent_analog_values')}</th>
                    <td>
                      {this.itemAtIndex(this.state.recentAnalogValues, 0).date}
                    </td>
                    <td>
                      {this.itemAtIndex(this.state.recentAnalogValues, 0).value}
                    </td>
                  </tr>
                  <tr className='table-grey71'>
                    <th scope='row'></th>
                    <td>
                      {this.itemAtIndex(this.state.recentAnalogValues, 1).date}
                    </td>
                    <td>
                      {this.itemAtIndex(this.state.recentAnalogValues, 1).value}
                    </td>
                  </tr>
                  <tr className='table-grey71'>
                    <th scope='row'></th>
                    <td>
                      {this.itemAtIndex(this.state.recentAnalogValues, 2).date}
                    </td>
                    <td>
                      {this.itemAtIndex(this.state.recentAnalogValues, 2).value}
                    </td>
                  </tr>

                  <tr className='table-smoked' style={semiLoadStyle}>
                    <th scope='row'>{t('recent_connectivity_values')}</th>
                    <td>
                      {this.itemAtIndex(this.state.recentRSSIValues, 0).date}
                    </td>
                    <td>
                      {this.state.recentRSSIValues.length > 0 ? (
                        <div style={{ display: 'inline' }}>
                          RSSI:{' '}
                          <span
                            style={{
                              color: this.colorByClassification(
                                this.itemAtIndex(this.state.recentRSSIValues, 0)
                                  .classification,
                                'RSSI',
                              ),
                            }}
                          >
                            {' '}
                            {
                              this.itemAtIndex(this.state.recentRSSIValues, 0)
                                .value
                            }
                            dB
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                      {this.state.recentRSRPValues.length > 0 ? (
                        <div style={{ display: 'inline' }}>
                          , RSRP:{' '}
                          <span
                            style={{
                              color: this.colorByClassification(
                                this.itemAtIndex(this.state.recentRSRPValues, 0)
                                  .classification,
                                'RSRP',
                              ),
                            }}
                          >
                            {' '}
                            {
                              this.itemAtIndex(this.state.recentRSRPValues, 0)
                                .value
                            }
                            dBm
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                      {this.state.recentRSRQValues.length > 0 ? (
                        <div style={{ display: 'inline' }}>
                          , RSRQ:{' '}
                          <span
                            style={{
                              color: this.colorByClassification(
                                this.itemAtIndex(this.state.recentRSRQValues, 0)
                                  .classification,
                                'RSRQ',
                              ),
                            }}
                          >
                            {' '}
                            {
                              this.itemAtIndex(this.state.recentRSRQValues, 0)
                                .value
                            }
                            dB
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                      {this.state.recentBerValues.length > 0 ? (
                        <div style={{ display: 'inline' }}>
                          , BER:{' '}
                          <span
                            style={{
                              color: this.colorByClassification(
                                this.itemAtIndex(this.state.recentBerValues, 0)
                                  .classification,
                                'BER',
                              ),
                            }}
                          >
                            {' '}
                            {this.itemAtIndex(this.state.recentBerValues, 0)
                              .value * 100}
                            %
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                  <tr className='table-smoked'>
                    <th scope='row'></th>
                    <td>
                      {this.itemAtIndex(this.state.recentRSSIValues, 1).date}
                    </td>
                    <td>
                      {this.state.recentRSSIValues.length > 1 ? (
                        <div style={{ display: 'inline' }}>
                          RSSI:{' '}
                          <span
                            style={{
                              color: this.colorByClassification(
                                this.itemAtIndex(this.state.recentRSSIValues, 1)
                                  .classification,
                                'RSSI',
                              ),
                            }}
                          >
                            {' '}
                            {
                              this.itemAtIndex(this.state.recentRSSIValues, 1)
                                .value
                            }
                            dB
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                      {this.state.recentRSRPValues.length > 1 ? (
                        <div style={{ display: 'inline' }}>
                          , RSRP:{' '}
                          <span
                            style={{
                              color: this.colorByClassification(
                                this.itemAtIndex(this.state.recentRSRPValues, 1)
                                  .classification,
                                'RSRP',
                              ),
                            }}
                          >
                            {' '}
                            {
                              this.itemAtIndex(this.state.recentRSRPValues, 1)
                                .value
                            }
                            dBm
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                      {this.state.recentRSRQValues.length > 1 ? (
                        <div style={{ display: 'inline' }}>
                          , RSRQ:{' '}
                          <span
                            style={{
                              color: this.colorByClassification(
                                this.itemAtIndex(this.state.recentRSRQValues, 1)
                                  .classification,
                                'RSRQ',
                              ),
                            }}
                          >
                            {' '}
                            {
                              this.itemAtIndex(this.state.recentRSRQValues, 1)
                                .value
                            }
                            dB
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                      {this.state.recentBerValues.length > 1 ? (
                        <div style={{ display: 'inline' }}>
                          , BER:{' '}
                          <span
                            style={{
                              color: this.colorByClassification(
                                this.itemAtIndex(this.state.recentBerValues, 1)
                                  .classification,
                                'BER',
                              ),
                            }}
                          >
                            {' '}
                            {this.itemAtIndex(this.state.recentBerValues, 1)
                              .value * 100}
                            %
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                  <tr className='table-smoked'>
                    <th scope='row'></th>
                    <td>
                      {this.itemAtIndex(this.state.recentRSSIValues, 2).date}
                    </td>
                    <td>
                      {this.state.recentRSSIValues.length > 2 ? (
                        <div style={{ display: 'inline' }}>
                          RSSI:{' '}
                          <span
                            style={{
                              color: this.colorByClassification(
                                this.itemAtIndex(this.state.recentRSSIValues, 2)
                                  .classification,
                                'RSSI',
                              ),
                            }}
                          >
                            {' '}
                            {
                              this.itemAtIndex(this.state.recentRSSIValues, 2)
                                .value
                            }
                            dB
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                      {this.state.recentRSRPValues.length > 2 ? (
                        <div style={{ display: 'inline' }}>
                          , RSRP:{' '}
                          <span
                            style={{
                              color: this.colorByClassification(
                                this.itemAtIndex(this.state.recentRSRPValues, 2)
                                  .classification,
                                'RSRP',
                              ),
                            }}
                          >
                            {' '}
                            {
                              this.itemAtIndex(this.state.recentRSRPValues, 2)
                                .value
                            }
                            dBm
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                      {this.state.recentRSRQValues.length > 2 ? (
                        <div style={{ display: 'inline' }}>
                          , RSRQ:{' '}
                          <span
                            style={{
                              color: this.colorByClassification(
                                this.itemAtIndex(this.state.recentRSRQValues, 2)
                                  .classification,
                                'RSRQ',
                              ),
                            }}
                          >
                            {' '}
                            {
                              this.itemAtIndex(this.state.recentRSRQValues, 2)
                                .value
                            }
                            dB
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                      {this.state.recentBerValues.length > 2 ? (
                        <div style={{ display: 'inline' }}>
                          , BER:{' '}
                          <span
                            style={{
                              color: this.colorByClassification(
                                this.itemAtIndex(this.state.recentBerValues, 2)
                                  .classification,
                                'BER',
                              ),
                            }}
                          >
                            {' '}
                            {this.itemAtIndex(this.state.recentBerValues, 2)
                              .value * 100}
                            %
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>

                  <tr className='table-grey71' style={semiLoadStyle}>
                    <th scope='row'>{t('recent_battery_values')}</th>
                    <td>
                      {this.itemAtIndex(this.state.recentBatteryValues, 0).date}
                    </td>
                    <td>
                      {
                        <span
                          style={{
                            color: this.colorByClassification(
                              this.itemAtIndex(
                                this.state.recentBatteryValues,
                                0,
                              ).classification,
                              'battery',
                            ),
                          }}
                        >
                          {
                            this.itemAtIndex(this.state.recentBatteryValues, 0)
                              .value
                          }
                        </span>
                      }
                    </td>
                  </tr>
                  <tr className='table-grey71'>
                    <th scope='row'></th>
                    <td>
                      {this.itemAtIndex(this.state.recentBatteryValues, 1).date}
                    </td>
                    <td>
                      {
                        <span
                          style={{
                            color: this.colorByClassification(
                              this.itemAtIndex(
                                this.state.recentBatteryValues,
                                1,
                              ).classification,
                              'battery',
                            ),
                          }}
                        >
                          {
                            this.itemAtIndex(this.state.recentBatteryValues, 1)
                              .value
                          }
                        </span>
                      }
                    </td>
                  </tr>
                  <tr className='table-grey71'>
                    <th scope='row'></th>
                    <td>
                      {this.itemAtIndex(this.state.recentBatteryValues, 2).date}
                    </td>
                    <td>
                      {
                        <span
                          style={{
                            color: this.colorByClassification(
                              this.itemAtIndex(
                                this.state.recentBatteryValues,
                                2,
                              ).classification,
                              'battery',
                            ),
                          }}
                        >
                          {
                            this.itemAtIndex(this.state.recentBatteryValues, 2)
                              .value
                          }
                        </span>
                      }
                    </td>
                  </tr>

                  <tr className='table-smoked'>
                    <th scope='row' style={semiLoadStyle}>
                      {t('recent_c1_values')}
                    </th>
                    <td>
                      {this.itemAtIndex(this.state.recentC1Values, 0).date}
                    </td>
                    <td>
                      {this.itemAtIndex(this.state.recentC1Values, 0).value}
                    </td>
                  </tr>
                  <tr className='table-smoked'>
                    <th scope='row'></th>
                    <td>
                      {this.itemAtIndex(this.state.recentC1Values, 1).date}
                    </td>
                    <td>
                      {this.itemAtIndex(this.state.recentC1Values, 1).value}
                    </td>
                  </tr>
                  <tr className='table-smoked'>
                    <th scope='row'></th>
                    <td>
                      {this.itemAtIndex(this.state.recentC1Values, 2).date}
                    </td>
                    <td>
                      {this.itemAtIndex(this.state.recentC1Values, 2).value}
                    </td>
                  </tr>

                  <tr className='table-grey71' style={semiLoadStyle}>
                    <th scope='row'>{t('recent_errors_values')}</th>
                    <td>
                      {this.itemAtIndex(this.state.recent_errors, 0).date}
                    </td>
                    <td>
                      {this.itemAtIndex(this.state.recent_errors, 0).value}
                    </td>
                  </tr>
                  <tr className='table-grey71'>
                    <th scope='row'></th>
                    <td>
                      {this.itemAtIndex(this.state.recent_errors, 1).date}
                    </td>
                    <td>
                      {this.itemAtIndex(this.state.recent_errors, 1).value}
                    </td>
                  </tr>
                  <tr className='table-grey71'>
                    <th scope='row'></th>
                    <td>
                      {this.itemAtIndex(this.state.recent_errors, 2).date}
                    </td>
                    <td>
                      {this.itemAtIndex(this.state.recent_errors, 2).value}
                    </td>
                  </tr>
                  <tr className='table-blue-third'>
                    <th scope='row'>{t('transmitter_id')}</th>
                    <td colSpan='2'>{this.state.transmitter_id}</td>
                  </tr>
                  {this.state.transmitter_name !== '' && (
                    <tr className='table-blue-third'>
                      <th scope='row'>{t('transmitter_name')}</th>
                      <td colSpan='2'>{this.state.transmitter_name}</td>
                    </tr>
                  )}
                  <tr className='table-blue-third'>
                    <th scope='row'>{t('distribution_unit')}</th>
                    <td colspan='2'>{this.state.distribution_name}</td>
                  </tr>
                  <tr className='table-blue-third'>
                    <th scope='row'>{t('farming_unit')}</th>
                    <td colspan='2'>{this.state.farming_unit_name}</td>
                  </tr>
                  <tr className='table-blue-third'>
                    <th scope='row'>{t('field')}</th>
                    <td colspan='2'>{this.state.field_name}</td>
                  </tr>
                  <tr className='table-blue-third'>
                    <th scope='row'>Sensor type</th>
                    <td colSpan='2'>{this.state.type}</td>
                  </tr>
                  {this.state.sub_type ? (
                    <tr className='table-blue-third'>
                      <th scope='row'>Sensor Sub Type</th>
                      <td colSpan='2'>{this.state.sub_type}</td>
                    </tr>
                  ) : null}
                  <tr className='table-blue-third' style={semiLoadStyle}>
                    <th scope='row'>{t('installation_date')}</th>
                    <td colspan='2'>{this.state.installation_date}</td>
                  </tr>
                  <tr className='table-blue-third' style={semiLoadStyle}>
                    <th scope='row'>{t('modem_version')}</th>
                    <td colspan='2'>{modem_title}</td>
                  </tr>
                  <tr className='table-blue-third' style={semiLoadStyle}>
                    <th scope='row'>{t('iccid')}</th>
                    <td colspan='2'>{this.state.iccid}</td>
                  </tr>
                  <tr className='table-blue-third' style={semiLoadStyle}>
                    <th scope='row'>{t('sensor_batch')}</th>
                    <td colspan='2'>{this.state.sensor_batch}</td>
                  </tr>
                  <tr className='table-blue-third' style={semiLoadStyle}>
                    <th scope='row'>{t('transmission_interval')}</th>
                    <td colspan='2'>{this.state.transmission_interval}</td>
                  </tr>
                  <tr className='table-blue-third' style={semiLoadStyle}>
                    <th scope='row'>{t('sample_interval')}</th>
                    <td colspan='2'>{this.state.sample_interval}</td>
                  </tr>
                  <tr className='table-blue-third' style={semiLoadStyle}>
                    <th scope='row'>{t('micro_version')}</th>
                    <td colspan='2'>{this.state.micro_version}</td>
                  </tr>
                  <tr className='table-blue-third' style={semiLoadStyle}>
                    <th scope='row'>{t('sim_last_event')}</th>

                    <td colspan='2'>{this.state.sim_last_event}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              style={{
                position: 'absolute',
                width: 115,
                height: 36,
                left: '44%',
                top: this.state.should_present_graph
                  ? this.state.semiLoad
                    ? '55%'
                    : '20%'
                  : this.state.semiLoad
                    ? '45%'
                    : '30%',
                backgroundColor: '#FFFFFF',
                border: '1px solid #EEF1F6',
                borderRadius: 10,
                boxShadow: '0px 4px 10px rgba(145, 157, 180, 0.19)',
                display:
                  this.state.onLoad || this.state.semiLoad ? 'flex' : 'none',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  fontWeight: 'bold',
                  color: '#919DB4',
                  display: 'flex',
                }}
              >
                <span>
                  <span
                    style={{ color: '#919DB4', marginTop: '4px' }}
                    className='spinner-border spinner-border-sm mb-1'
                    role='status'
                    aria-hidden='true'
                  ></span>
                </span>
                &nbsp;&nbsp;&nbsp;Loading&nbsp;
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    user_details: state.authentication_data.user_details,
    all_distributions: state.distribution_data.distributions,
  };
};

module.exports = connect(mapStateToProps)(withTranslation()(AdminPage));
