var moment = require("moment");

// threshold for the discharge indication trend tooltip (used in web, mobile)
export const DISCHARGE_ALERT_THRESHOLD = 0.85;
const DISCHARGE_SAMPLES_THRESHOLD = 3;

export const wtrMaterialConcentrationsDict = {
  hpo: 50,
  cs: null,
  acid: null,
};

if (!String.prototype.format) {
  String.prototype.format = function () {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != "undefined" ? args[number] : match;
    });
  };
}

export var upper = (current_string) => {
  if (!current_string) {
    return "";
  }

  return current_string.toLowerCase().replace(/\b\w/g, function (l) {
    return l.toUpperCase();
  });
};

export var lower = (current_string) => {
  if (!current_string) {
    return "";
  }
  return current_string.charAt(0).toLowerCase() + current_string.slice(1);
};

export var dailyUnix = (timeZone) => {
  let nowObject = moment().tz(timeZone);
  let totalMinutes =
    parseInt(nowObject.format("H")) * 60 + parseInt(nowObject.format("m"));

  if (totalMinutes < 60 * 5 + 30) {
    nowObject = nowObject.subtract(1, "days");
  }

  let dateOfMeasurement = nowObject.format("YYYY-MM-DD");
  let dailyUnixString = moment.tz(dateOfMeasurement, "UTC").unix();

  return dailyUnixString;
};

export var error_message = (error_object) => {
  var message = "";

  if (
    error_object.response &&
    error_object.response.data &&
    error_object.response.data.error
  ) {
    message = error_object.response.data.error.message;
  } else if (
    error_object.response &&
    error_object.response.data &&
    error_object.response.data.errorMessage
  ) {
    message = error_object.response.data.errorMessage;
  } else if (error_object.message) {
    message = error_object.message;
  }

  return message;
};

export var sortArrayByValue = (arr, property) => {
  arr.sort((a, b) =>
    a[property] > b[property] ? 1 : b[property] > a[property] ? -1 : 0,
  );
  return arr;
};

export var sort_dictioanry_by_value = (dict) => {
  // Create items array
  var items = Object.keys(dict).map(function (key) {
    return [key, dict[key]];
  });

  // Sort the array based on the second element
  items.sort(function (first, second) {
    var bol = first[1].localeCompare(second[1]);

    return bol;
  });

  return items.slice(0, items.length);
};

export var sort_dictioanry_by_value_and_property = (dict, property) => {
  // Create items array
  var items = Object.keys(dict).map(function (key) {
    return [key, dict[key]];
  });

  // Sort the array based on the second element
  items.sort(function (first, second) {
    var bol = first[1][property].localeCompare(second[1][property]);

    return bol;
  });

  return items.slice(0, items.length);
};

export var date_string = (date) => {
  var today = moment().format("YYYY-MM-DD");
  var yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
  var current_date = moment.unix(date).format("YYYY-MM-DD");

  if (current_date == today) {
    return "today";
  } else if (current_date == yesterday) {
    return "yesterday";
  } else {
    return current_date;
  }
};

export var arrayToDictionary = (arr, key, valueKey) => {
  var dict = {};
  dict = arr.reduce(
    (dict, el, index) => ((dict[el[key]] = el[valueKey]), dict),
    {},
  );

  return dict;
};

export const getMonthName = (monthName) => {
  var monthStrings = {
    Jan: "months_january",
    Feb: "months_february",
    Mar: "months_march",
    Apr: "months_april",
    May: "months_may",
    Jun: "months_june",
    Jul: "months_july",
    Aug: "months_august",
    Sep: "months_september",
    Oct: "months_october",
    Nov: "months_november",
    Dec: "months_december",
  };

  return monthStrings[monthName] || monthName;
};

export var timeSinceNowString = (currentDate) => {
  let nowDate = moment();
  var duration = moment.duration(nowDate.diff(currentDate));

  var minutes = duration.asMinutes().toFixed(0);
  var hours = duration.asHours().toFixed(0);
  var days = duration.asDays().toFixed(0);

  let returnObject = [];
  if (minutes < 60) {
    if (minutes == 1) {
      returnObject = [minutes.toString(), "minute_ago"];
    } else {
      returnObject = [minutes.toString(), "minutes_ago"];
    }
  } else if (hours < 24) {
    if (hours == 1) {
      returnObject = [hours.toString(), "hour_ago"];
    } else {
      returnObject = [hours.toString(), "hours_ago"];
    }
  } else {
    if (days == 1) {
      returnObject = [days.toString(), "day_ago"];
    } else {
      returnObject = [days.toString(), "days_ago"];
    }
  }

  return returnObject;
};

export var daysSinceNowFromString = (dateString) => {
  // Convert the date string to a Moment object.
  const date = moment(dateString);

  // Get the current date.
  const today = moment();

  // Calculate the difference between the two dates in days.
  const daysLeft = today.diff(date, "days");

  // Return the number of days left.
  return daysLeft;
};

export var latestKeyfromDict = (dict) => {
  if (dict) {
    let maxKey = Math.max.apply(null, Object.keys(dict));
    if (maxKey) {
      return maxKey;
    }
  }

  return undefined;
};

export var latestObjectFromDictWithKey = (dict) => {
  if (dict) {
    let maxKey = Math.max.apply(null, Object.keys(dict));
    if (maxKey) {
      return [maxKey, dict[maxKey]];
    }
  }

  return undefined;
};

export var latestObjectfromDict = (dict) => {
  if (dict) {
    let maxKey = Math.max.apply(null, Object.keys(dict));
    if (maxKey) {
      return dict[maxKey];
    }
  }

  return undefined;
};

export var sortByAlphabetic = (items, parameterName) => {
  items.sort((a, b) => {
    let nameA = a[parameterName].toUpperCase();
    let nameB = b[parameterName].toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};

export var sortByDate = (items, parameterName) => {
  items.sort((a, b) => {
    let dateA = moment(a[parameterName]);
    let dateB = moment(b[parameterName]);

    // DESC order
    return dateB.unix() - dateA.unix();
  });
};

export const reduceSampleDensity = (samples, isBigScaleReducer = true) => {
  if (isBigScaleReducer) {
    let shrinker =
      samples.length > 10000
        ? 700
        : samples.length > 1000
          ? 100
          : samples.length > 500
            ? 2
            : 1;
    let lastDate = samples[samples.length - 1];
    let data = samples
      .filter(function (value, index) {
        return index % shrinker === 0;
      })
      .map((sample, index) => {
        return { value: sample.value, date: sample.date, index: index };
      });
    if (shrinker > 1) {
      data.push({
        value: lastDate.value,
        date: lastDate.date,
        index: data.length,
      });
    }

    return data;
  } else {
    let shrinker =
      samples.length > 2000
        ? 6
        : samples.length > 1000
          ? 5
          : samples.length > 500
            ? 3
            : samples.length > 50
              ? 2
              : 1;
    var data = samples.filter((sample, index) => {
      return index % shrinker === 0 || sample.value == "0";
    });
    return data;
  }
};

export var getEarlyAdoptersAccess = (email) => {
  if (
    email &&
    (email.endsWith("@ndrip.com") || email.endsWith("@sciroot.ag"))
  ) {
    return true;
  }
  return false;
};

export var getNotificationsCenterAccess = (email) => {
  // disable notifications center access only for specific users and alerts section in configurations page.
  let hasAccessUsers = [];

  if (!hasAccessUsers.includes(email)) {
    return true;
  }
  return false;
};

export function parseShortFloat(num, fractionDigits = 2, defaultValue = 0) {
  if (num) return num.toFixed(fractionDigits).replace(/([^.])0+$/, "$1");
  return defaultValue;
}

// Function to generate dates between start and end dates in Moment format with a specified interval in seconds
export function generateDates(startDate, endDate, secondsInterval) {
  let dates = [];
  let currentDate = startDate.clone();

  while (currentDate <= endDate) {
    dates.push(currentDate.clone());
    currentDate.add(secondsInterval, "seconds");
  }

  return dates;
}

export const isEmptyObject = (objectName) => {
  return Object.keys(objectName).length === 0;
};

export function calculateDischargeRate(
  averageTime,
  location,
  selectedFieldTankType = "",
  testHeight = 0.15,
) {
  //default tank size is 800 liter
  let tankSize = 0.8824733764;
  if (selectedFieldTankType.includes("500") || location.includes("India")) {
    tankSize = 0.8666666667;
  } else if (
    selectedFieldTankType.includes("800") ||
    location.includes("USA")
  ) {
    tankSize = 0.8824733764;
  }
  return parseFloat(
    ((tankSize * testHeight * 3600) / averageTime / 0.8).toFixed(1),
  );
}

export function calculateDischargeDeviation(
  currentDischargeRate = {},
  lastDischargeTestsArr = [],
) {
  let x = [];
  let y = [];
  let testsData = [];
  let avgDischargeRate = 0;
  let testsCounter = 0;
  let index = 1;
  let copyOfLastDischargeTestsArr = [...lastDischargeTestsArr];
  if (Object.keys(currentDischargeRate).length > 0) {
    copyOfLastDischargeTestsArr.unshift(currentDischargeRate);
  }
  for (let i = copyOfLastDischargeTestsArr.length - 1; i >= 0; i--) {
    let test = copyOfLastDischargeTestsArr[i];
    testsData.push({ value: test.value, date: test.date });
    x.push(index);
    y.push(test.value);
    avgDischargeRate += test.value;
    testsCounter++;
    if (index > DISCHARGE_SAMPLES_THRESHOLD) {
      let lr = LinearRegression(y, x);
      testsData[index - 1]["intercept"] = lr.intercept;
      testsData[index - 1]["slope"] = lr.slope;
      testsData[index - 1]["estimatedQ"] = lr.intercept + lr.slope * index;
      testsData[index - 1]["longTermQ"] = avgDischargeRate / testsCounter;
      testsData[index - 1]["relationDiff"] =
        testsData[index - 1]["estimatedQ"] / testsData[index - 1]["longTermQ"];
      x.shift();
      y.shift();
    }
    index++;
  }
  return testsData;
}

export function formatNumberWithoutRounding(number, decimalPlaces) {
  // Convert the number to a string and split into integer and fractional parts
  let [integerPart, fractionalPart] = number.toString().split(".");

  // If the number has no fractional part, set it to an empty string
  fractionalPart = fractionalPart || "";

  // Limit the fractional part to the desired number of decimal places without rounding
  fractionalPart = fractionalPart.slice(0, decimalPlaces);

  // Format the integer part using toLocaleString
  let formattedInteger = parseInt(integerPart).toLocaleString("en-US");

  // Combine the formatted integer part with the truncated fractional part
  return fractionalPart
    ? `${formattedInteger}.${fractionalPart}`
    : formattedInteger;
}

export function dateToUnixTimestamp(dateString) {
  // Create a new Date object from the date string
  const date = new Date(dateString);

  // Get the Unix timestamp in seconds
  const unixTimestamp = Math.floor(date.getTime() / 1000);

  return unixTimestamp;
}

export function excludeFilterObjectByKeys(obj, keys) {
  const filtered = { ...obj };
  keys.forEach((attr) => delete filtered[attr]);
  return filtered;
}

export function getSubstringAfterKeyword(input, keyword) {
  const regex = new RegExp(`\\b${keyword}\\b(.*)`, "i");
  const match = input.match(regex);
  return match ? match[1].trim() : null;
}

function LinearRegression(y, x) {
  var lr = {};
  var n = y.length;
  var sum_x = 0;
  var sum_y = 0;
  var sum_xy = 0;
  var sum_xx = 0;
  var sum_yy = 0;

  for (var i = 0; i < y.length; i++) {
    sum_x += x[i];
    sum_y += y[i];
    sum_xy += x[i] * y[i];
    sum_xx += x[i] * x[i];
    sum_yy += y[i] * y[i];
  }

  lr["slope"] = (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x);
  lr["intercept"] = (sum_y - lr.slope * sum_x) / n;
  lr["r2"] = Math.pow(
    (n * sum_xy - sum_x * sum_y) /
      Math.sqrt((n * sum_xx - sum_x * sum_x) * (n * sum_yy - sum_y * sum_y)),
    2,
  );

  return lr;
}
