var React = require('react');
var { connect } = require('react-redux');
var authenticateActions = require('authenticateActions');
var countryDialPrefixManager = require('countryDialPrefixManager');
var { sortArrayByValue, upper } = require('helpers');
var { withTranslation } = require('react-i18next');
var $ = require('jquery');

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      login_form_additional_classes: '',
      user_name: this.props.user_name,
    };
    this.onRegisterButtonClick = this.onRegisterButtonClick.bind(this);
    this.handleUserNameChange = this.handleUserNameChange.bind(this);
    this.onTermsClick = this.onTermsClick.bind(this);
  }

  componentDidMount() {
    this.setState({
      dial_prefixes: countryDialPrefixManager.get_dial_code_prefixes(),
    });
  }

  onTermsClick(e) {
    $('#termsAndConditionsId').modal('show');
  }

  onRegisterButtonClick(e) {
    e.preventDefault();
    e.stopPropagation();

    var form = document.getElementById('login-form');

    // Local check (given by bootstrap)
    if (form.checkValidity() == true) {
      let currentDialPrefixObject =
        this.state.dial_prefixes[parseInt(this.refs.dial_prefix_field.value)];

      var phone_number = countryDialPrefixManager.generate_phone_number(
        currentDialPrefixObject.prefix,
        this.refs.phone_number.value,
      );
      let userCountry = currentDialPrefixObject.country;

      // return to main login handler
      this.props.dispatch(
        authenticateActions.register(
          this.state.user_name.toLowerCase(),
          upper(this.refs.first_name.value),
          upper(this.refs.last_name.value),
          phone_number,
          this.refs.password.value,
          userCountry,
        ),
      );
    }

    // refresh login form according to local check
    this.setState({ login_form_additional_classes: 'was-validated' });
  }

  handleUserNameChange(event) {
    this.setState({ user_name: event.target.value });
  }

  render() {
    const { t } = this.props;

    var { register_fail_error } = this.props.authentication_data.register_flow;
    var login_form_additional_classes =
      this.state.login_form_additional_classes;

    return (
      <form
        className={`needs-validation ${this.state.login_form_additional_classes}`}
        noValidate
        onSubmit={this.onRegisterButtonClick}
        id='login-form'
      >
        <div className='form-row'>
          <div className='col'>
            <div className='form-group ml-2 mr-2 mt-3 text-light-blue'>
              <label htmlFor='first_name'>{t('first_name')}</label>
              <input
                type='text'
                ref='first_name'
                className='form-control'
                placeholder={t('login_enter_first_name')}
                required
              />
              <div className='invalid-feedback'>
                {t('account_first_name_invalid_data_message')}
              </div>
            </div>
          </div>
          <div className='col'>
            <div className='form-group ml-2 mr-2 mt-3 text-light-blue'>
              <label htmlFor='last_name'>{t('last_name')}</label>
              <input
                type='text'
                ref='last_name'
                className='form-control '
                placeholder={t('login_enter_last_name')}
                required
              />
              <div className='invalid-feedback'>
                {t('account_last_name_invalid_data_message')}
              </div>
            </div>
          </div>
        </div>

        <div className='form-group ml-2 mr-2 mt-1 text-light-blue'>
          <label htmlFor='emailInput'>{t('email')}</label>
          <input
            type='email'
            ref='email'
            className='form-control '
            value={this.state.user_name}
            onChange={this.handleUserNameChange}
            placeholder={t('login_enter_your_email')}
            required
          />
          <div className='invalid-feedback'>
            {t('login_enter_a_valid_email')}
          </div>
        </div>

        <div className='form-group ml-2 mr-2 mt-1 text-light-blue'>
          <label htmlFor='passwordInput'>{t('password')}</label>
          <input
            type='password'
            ref='password'
            className='form-control'
            placeholder={t('login_enter_your_password')}
            autoComplete='new-password'
            required
          />
          <small className='form-text text-muted'>
            {t('login_password_limitation_message')}
          </small>
          <div className='invalid-feedback'>
            {t('login_enter_your_password')}
          </div>
        </div>

        <div className='form-row'>
          <div className='col-4 '>
            <div className='form-group ml-2 mr-2 mt-1 text-light-blue'>
              <label htmlFor='dial_prefix'>{t('prefix')}</label>
              <select
                className='form-control'
                ref='dial_prefix_field'
                id='exampleFormControlSelect1'
              >
                {this.state.dial_prefixes &&
                  sortArrayByValue(this.state.dial_prefixes, 'name').map(
                    (item, index) => {
                      return (
                        <option key={index} value={index}>
                          {item['name']}
                        </option>
                      );
                    },
                  )}
              </select>
            </div>
          </div>
          <div className='col-8'>
            <div className='form-group ml-2 mr-2 mt-1 text-light-blue'>
              <label htmlFor='phone_number'>{t('phone_number')}</label>
              <input
                type='text'
                ref='phone_number'
                className='form-control '
                placeholder={t('login_enter_phone_number')}
                required
              />
              <div className='invalid-feedback'>
                {t('account_phone_number_invalid_data_message')}
              </div>
            </div>
          </div>
        </div>

        <div className='form-check  ml-2 mr-2 mt-2'>
          <input
            className='form-check-input'
            type='checkbox'
            value=''
            id='termsAgreement'
            required
          />
          <label className='text-grey' htmlFor='termsAgreement'>
            {t('i_agree_to')}
            <u>
              {' '}
              <a
                href='#'
                onClick={(e) => {
                  this.onTermsClick(e);
                }}
              >
                {t('terms_and_conditions')}
              </a>
            </u>
          </label>
          <div className='invalid-feedback mt-0 pt-0'>
            You must agree before registering.
          </div>
        </div>

        <div className='text-center text-red'>{register_fail_error}</div>

        <div className='text-center'>
          <button
            type='submit'
            style={{ opacity: '0.9' }}
            className='btn btn-grey45 btn-lg general-button mt-3'
            disabled={
              this.props.authentication_data.register_flow.during_register
            }
          >
            {t('register')}
            {this.props.authentication_data.register_flow.during_register && (
              <span>
                &nbsp;&nbsp;
                <span
                  className='spinner-grow spinner-grow-sm mb-1'
                  role='status'
                  aria-hidden='true'
                ></span>
              </span>
            )}
          </button>
        </div>

        <div className='text-center mt-4 mb-5'>
          <br />
          <br />
          <br />
        </div>
      </form>
    );
  }
}

module.exports = connect((state) => {
  return {
    authentication_data: state.authentication_data,
  };
})(withTranslation()(RegisterForm));
