var React = require('react');
var Dashboard = require('Dashboard');
var DistributionUnitsManagement = require('DistributionUnitsManagement');
var Admin = require('Admin');
var FarmingUnitsManagement = require('FarmingUnitsManagement');
var Settings = require('Settings');
var Account = require('Account');
var Planning = require('Planning');
var Insights = require('../insights/InsightsContainer.jsx');
var Login = require('Login');
var Register = require('Register');
var Confirm = require('Confirm');
var ForgotPassword = require('ForgotPassword');
var {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  withRouter,
} = require('react-router-dom');
var { connect } = require('react-redux');
var Header = require('Header');
var LeftSideBar = require('LeftSideBar');
var MainCSS = require('mainCSS');
var reactDatetimeCSS = require('reactDatetimeCSS');
var MapPage = require('MapPage');
var { isMobile } = require('Utils');
var { getItem, setItem } = require('@managers/localStorageManager.jsx');
var CropManagement = require('CropManagement');
var PrivacyPolicy = require('PrivacyPolicy');
var {
  RemoteConfigurationsContainer,
} = require('../general/RemoteConfigurations/RemoteConfigurationsContainer.jsx');

const PrivateRoute = ({
  shouldDisplayLogin,
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (shouldDisplayLogin) {
          return <Redirect to='/login' />;
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayMobileBanner: isMobile() && !getItem('HIDE_MOBILE_BANNER'),
    };
  }

  userLoggedIn() {
    return this.props.id_token != null;
  }

  closeMobileBanner() {
    this.setState && this.setState({ displayMobileBanner: false });
    setItem('HIDE_MOBILE_BANNER', true);
  }

  render() {
    const pathname = this.props.history.location.pathname;
    const insights_active = pathname.startsWith('/Insights');
    let headerHeight = 48;
    let seperatorHeight = 1;
    let externalSpaceForMobileBanner = this.state.displayMobileBanner ? 40 : 0;

    // if (window.innerWidth < 768) {
    //   headerHeight = 10;
    // }

    let mainScreenStyle = this.userLoggedIn()
      ? insights_active
        ? {}
        : {
            marginTop:
              (
                headerHeight +
                seperatorHeight +
                externalSpaceForMobileBanner
              ).toString() + 'px',
            height: (100 - headerHeight + seperatorHeight).toString() + 'px',
          }
      : {};

    return (
      <div>
        <Header
          shouldDisplayNavigation={this.userLoggedIn()}
          history={this.props.history}
          headerHeight={headerHeight}
          seperatorHeight={seperatorHeight}
          closeBanner={() => this.closeMobileBanner()}
          displayMobileBanner={this.state.displayMobileBanner}
        />

        {/*<LeftSideBar shouldDisplayNavigation={this.userLoggedIn()} history={this.props.history} headerHeight={headerHeight} seperatorHeight={seperatorHeight}/>*/}

        <div style={mainScreenStyle}>
          <div>
            <Switch>
              <PrivateRoute
                exact
                path='/'
                component={Dashboard}
                shouldDisplayLogin={!this.userLoggedIn()}
              />
              <PrivateRoute
                exact
                path='/Planning'
                component={Planning}
                shouldDisplayLogin={!this.userLoggedIn()}
              />
              <PrivateRoute
                exact
                path='/Insights'
                component={Insights}
                shouldDisplayLogin={!this.userLoggedIn()}
              />
              <PrivateRoute
                exact
                path='/Settings'
                component={Settings}
                shouldDisplayLogin={!this.userLoggedIn()}
              />
              <PrivateRoute
                exact
                path='/Account'
                component={Account}
                shouldDisplayLogin={!this.userLoggedIn()}
              />
              <PrivateRoute
                exact
                path='/DistributionUnitsManagement'
                component={DistributionUnitsManagement}
                shouldDisplayLogin={!this.userLoggedIn()}
              />
              <PrivateRoute
                exact
                path='/Admin'
                component={Admin}
                shouldDisplayLogin={!this.userLoggedIn()}
              />
              <PrivateRoute
                exact
                path='/FarmingUnitsManagement'
                component={FarmingUnitsManagement}
                shouldDisplayLogin={!this.userLoggedIn()}
              />
              <PrivateRoute
                exact
                path='/CropManagement'
                component={CropManagement}
                shouldDisplayLogin={!this.userLoggedIn()}
              />
              <PrivateRoute
                exact
                path='/RemoteConfigurations'
                component={RemoteConfigurationsContainer}
                shouldDisplayLogin={!this.userLoggedIn()}
              />
              <Route exact path='/login' component={Login} />
              <Route exact path='/register' component={Register} />
              <Route exact path='/confirm' component={Confirm} />
              <Route exact path='/forgot' component={ForgotPassword} />
              <Route exact path='/map' component={MapPage} />
              <Route exact path='/privacy' component={PrivacyPolicy} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

// export default Main
const mapStateToProps = function (state) {
  return {
    id_token: state.authentication_data.tokens.id_token,
  };
};

module.exports = withRouter(connect(mapStateToProps)(Main));

/*

<Switch>
  <PrivateRoute exact path="/" component={Dashboard} shouldDisplayLogin={!this.userLoggedIn()}/>
  <PrivateRoute exact path='/Settings' component={Settings} shouldDisplayLogin={!this.userLoggedIn()}/>
  <PrivateRoute exact path='/Account' component={Account} shouldDisplayLogin={!this.userLoggedIn()}/>
  <PrivateRoute exact path='/DistributionUnitsManagement' component={DistributionUnitsManagement} shouldDisplayLogin={!this.userLoggedIn()}/>
  <PrivateRoute exact path='/FarmingUnitsManagement' component={FarmingUnitsManagement} shouldDisplayLogin={!this.userLoggedIn()}/>
  <Route exact path='/login' component={Login}/>
  <Route exact path='/register' component={Register}/>
  <Route exact path='/confirm' component={Confirm}/>
  <Route exact path='/forgot' component={ForgotPassword}/>
  <Route exact path='/map' component={MapPage}/>
</Switch>

*/
