var { getItem } = require("@managers/localStorageManager.jsx");

export const DEFAULT_SEARCH_TYPE = "FREE_SEARCH";
export const SEARCH_BY_FIELD_TYPE = "SEARCH_BY_FIELD_TYPE";

// Authentication reducers
var authenticationDataDefaults = {
  tokens: {
    id_token: getItem("ID_TOKEN", ""),
    access_token: getItem("ACCESS_TOKEN", ""),
    refresh_token: getItem("REFRESH_TOKEN", ""),
  },
  user_details: JSON.parse(getItem("USER_DETAILS", "{}")),
  user_data: {},
  user_notifications: {},
  user_notifications_flow: {
    state: "",
    user_notifications_error: "",
  },
  remote_configuration: {},
  user_discharge_tests: {},
  register_flow: {
    registered_user_name: "",
    during_register: false,
    register_status: "",
    register_fail_error: "",
    confirm_status: "",
    confirm_fail_error: "",
  },
  during_login: false,
  during_refresh_token: false,
  refresh_token_fail_error: "",
  login_fail_error: "",
  during_logout: false,
  forgot_password_flow: {
    phase: "forgot",
    state: "",
    forgot_password_error: "",
  },
  set_user_attributes_flow: {
    state: "",
    set_user_attributes_error: "",
  },
  manual_login: false,
};

export var authenticationReducer = (
  state = authenticationDataDefaults,
  action,
) => {
  switch (action.type) {
    case "LOGIN_INIT":
      return {
        ...state,
        during_login: true,
      };
      break;
    case "AUTHENTICATION_SUCCESS":
      return {
        ...state,
        tokens: {
          id_token: action.tokens.id_token,
          access_token: action.tokens.access_token,
          refresh_token: action.tokens.refresh_token,
        },
        login_fail_error: "",
        during_login: false,
      };
      break;
    case "LOGIN_SUCCESS":
      return {
        ...state,
        tokens: {
          id_token: action.tokens.id_token,
          access_token: action.tokens.access_token,
          refresh_token: action.tokens.refresh_token,
        },
        user_details: action.user_details,
        login_fail_error: "",
        during_login: false,
      };
      break;
    case "LOGIN_FAIL":
      return {
        ...state,
        login_fail_error: action.login_fail_error,
        during_login: false,
      };
      break;
    case "REFRESH_TOKEN_INIT":
      return {
        ...state,
        during_refresh_token: true,
      };
      break;
    case "REFRESH_TOKEN_SUCCESS":
      return {
        ...state,
        tokens: {
          id_token: action.tokens.id_token,
          access_token: action.tokens.access_token,
          refresh_token: action.tokens.refresh_token,
        },
        refresh_token_fail_error: "",
        during_refresh_token: false,
      };
      break;
    case "REFRESH_TOKEN_FAIL":
      return {
        ...state,
        refresh_token_fail_error: action.refresh_token_fail_error,
        during_refresh_token: false,
        tokens: {},
      };
      break;
    case "REGISTER_INIT":
      return {
        ...state,
        register_flow: {
          ...state,
          during_register: true,
          register_status: "init",
        },
      };
      break;
    case "REGISTER_SUCCESS":
      return {
        ...state,
        register_flow: {
          ...state,
          register_fail_error: "",
          during_register: false,
          register_status: "success",
          registered_user_name: action.registered_user_name,
        },
      };
      break;
    case "REGISTER_FAIL":
      return {
        ...state,
        register_flow: {
          ...state,
          register_fail_error: action.register_fail_error,
          during_register: false,
          register_status: "fail",
          registered_user_name: "",
        },
      };
      break;
    case "CONFIRM_INIT":
      return {
        ...state,
        register_flow: {
          ...state,
          confirm_status: "init",
        },
      };
      break;
    case "CONFIRM_SUCCESS":
      return {
        ...state,
        register_flow: {
          ...state,
          confirm_fail_error: "",
          confirm_status: "success",
        },
      };
      break;
    case "CONFIRM_FAIL":
      return {
        ...state,
        register_flow: {
          ...state,
          confirm_fail_error: action.confirm_fail_error,
          confirm_status: "fail",
        },
      };
      break;
    case "FORGOT_PASSWORD_INIT":
      return {
        ...state,
        forgot_password_flow: {
          phase: "forgot",
          state: "forgot_init",
          forgot_password_error: "",
        },
      };
      break;
    case "FORGOT_PASSWORD_CONFIRM_INIT":
      return {
        ...state,
        forgot_password_flow: {
          phase: "confirm",
          state: "confirm_init",
          forgot_password_error: "",
        },
      };
      break;
    case "FORGOT_PASSWORD_CONFIRMATION_SENT":
      return {
        ...state,
        forgot_password_flow: {
          phase: "confirm",
          state: "confirmation_sent",
          forgot_password_error: "",
        },
      };
      break;
    case "FORGOT_PASSWORD_SUCCESS":
      return {
        ...state,
        forgot_password_flow: {
          phase: "confirm",
          state: "success",
          forgot_password_error: "",
        },
      };
      break;
    case "FORGOT_PASSWORD_FAIL":
      return {
        ...state,
        forgot_password_flow: {
          phase: "forgot",
          state: "fail",
          forgot_password_error: action.forgot_password_error,
        },
      };
      break;
    case "FORGOT_PASSWORD_CONFIRM_FAIL":
      return {
        ...state,
        forgot_password_flow: {
          phase: "confirm",
          state: "fail",
          forgot_password_error: action.forgot_password_error,
        },
      };
      break;
    case "CLEAR_FORGOT_PASSWORD_ACTIONS":
      return {
        ...state,
        forgot_password_flow: {
          phase: "forgot",
          state: "",
          forgot_password_error: "",
        },
      };
      break;

    case "LOGOUT_INIT":
      return {
        ...state,
        during_logout: true,
      };
      break;
    case "LOGOUT_SUCCESS":
      return {
        ...state,
        tokens: {},
        during_logout: false,
      };
      break;
    case "GET_USER_DATA_SUCCESS":
      return {
        ...state,
        user_data: action.userData,
        set_user_attributes_flow: {
          state: "success",
          set_user_attributes_error: "",
        },
      };
      break;

    case "SET_USER_ATTRIBUTES_INIT":
      return {
        ...state,
        set_user_attributes_flow: {
          state: "init",
          set_user_attributes_error: "",
        },
      };
      break;
    case "SET_USER_ATTRIBUTES_SUCCESS":
      return {
        ...state,
        user_details: action.user_details,
        set_user_attributes_flow: {
          state: "success",
          set_user_attributes_error: "",
        },
      };
      break;
    case "GET_USER_NOTIFICATIONS_SUCCESS":
      return {
        ...state,
        user_notifications: action.user_notifications,
        user_notifications_flow: {
          state: "success",
          user_notifications_error: "",
        },
      };
      break;
    case "GET_USER_NOTIFICATIONS_INIT":
      return {
        ...state,
        user_notifications_flow: {
          state: "init",
          user_notifications_error: "",
        },
      };
      break;
    case "GET_USER_NOTIFICATIONS_FAIL":
      return {
        ...state,
        user_notifications_flow: {
          state: "fail",
          user_notifications_error: action.get_user_notifications_error,
        },
      };
      break;
    case "SET_USER_ATTRIBUTES_FAIL":
      return {
        ...state,
        set_user_attributes_flow: {
          state: "fail",
          set_user_attributes_error: action.set_user_attributes_error,
        },
      };
      break;
    case "MANUAL_LOGIN":
      return {
        ...state,
        manual_login: action.manual_login,
      };
      break;
    case "HIDDEN_ITEM_HAS_PREVIEWED":
      return {
        ...state,
        has_previewed: action.has_previewed,
      };
      break;
    case "SET_DISCHARGE_TEST":
      return {
        ...state,
        user_discharge_tests: {
          ...state.user_discharge_tests,
          [action.field_id]: action.discharge_test,
        },
      };
      break;
    default:
      return state;
  }
};

// Distribution actions
var soil_data = {
  soil_data: null,
  soil_data_actions: {
    type: "",
    status: "",
    error: "",
  },
};

export var soildDataReducer = (state = soil_data, action) => {
  switch (action.type) {
    case "GET_SOIL_DATA_INIT":
      return {
        ...state,
        soil_data_actions: {
          type: action.type,
          status: "init",
          error: "",
        },
      };
      break;
    case "GET_SOIL_DATA_SUCCESS":
      return {
        ...state,
        soil_data: action.soil_data,
        soil_data_actions: {
          type: action.type,
          status: "success",
          error: "",
        },
      };
      break;
    case "GET_SOIL_DATA_FAIL":
      return {
        ...state,
        soil_data_actions: {
          type: action.type,
          status: "fail",
          error: action.error_message,
        },
      };
      break;
    case "CLEAR_SOIL_DATA_ACTIONS":
      return {
        ...state,
        soil_data_actions: {
          type: "",
          status: "",
          error: "",
        },
      };
      break;
    default:
      return state;
  }
};

// Crop Data Actions
var crop_data = {
  crop_data: null,
  crop_data_actions: {
    type: "",
    status: "",
    error: "",
  },
};

export var cropdDataReducer = (state = crop_data, action) => {
  switch (action.type) {
    case "GET_CROP_DATA_INIT":
      return {
        ...state,
        crop_data_actions: {
          type: action.type,
          status: "init",
          error: "",
        },
      };
      break;
    case "GET_CROP_DATA_SUCCESS":
      return {
        ...state,
        crop_data: action.crop_data,
        crop_data_actions: {
          type: action.type,
          status: "success",
          error: "",
        },
      };
      break;
    case "GET_CROP_DATA_FAIL":
      return {
        ...state,
        crop_data_actions: {
          type: action.type,
          status: "fail",
          error: action.error_message,
        },
      };
      break;
    case "CLEAR_CROP_DATA_ACTIONS":
      return {
        ...state,
        crop_data_actions: {
          type: "",
          status: "",
          error: "",
        },
      };
      break;
    default:
      return state;
  }
};

var configuration_data = {
  configuration_data: null,
  configuration_data_actions: {
    type: "",
    status: "",
    error: "",
  },
};

export var configurationDataReducer = (state = configuration_data, action) => {
  switch (action.type) {
    case "GET_REMOTE_CONFIGURATION_INIT":
      return {
        ...state,
        configuration_data: action.data,
        configuration_data_actions: {
          type: action.type,
          status: "init",
          error: "",
        },
      };
      break;
    default:
      return state;
  }
};
var fertilizer_data = {
  fertilizer_data: null,
  fertilizer_data_actions: {
    type: "",
    status: "",
    error: "",
  },
};

export var fertilizerDataReducer = (state = fertilizer_data, action) => {
  switch (action.type) {
    case "GET_FERTILIZER_DATA_INIT":
      return {
        ...state,
        fertilizer_data_actions: {
          type: action.type,
          status: "init",
          error: "",
        },
      };
      break;
    case "GET_FERTILIZER_DATA_SUCCESS":
      return {
        ...state,
        fertilizer_data: action.fertilizer_data,
        fertilizer_data_actions: {
          type: action.type,
          status: "success",
          error: "",
        },
      };
      break;
    case "GET_FERTILIZER_DATA_FAIL":
      return {
        ...state,
        fertilizer_data_actions: {
          type: action.type,
          status: "fail",
          error: action.error_message,
        },
      };
      break;
    case "CLEAR_FERTILIZER_DATA_ACTIONS":
      return {
        ...state,
        fertilizer_data_actions: {
          type: "",
          status: "",
          error: "",
        },
      };
      break;
    default:
      return state;
  }
};

// Distribution actions
var distribution_data = {
  distributions: null,
  selected_entities: {
    distribution: null,
    farming_unit: null,
    field: null,
    sensors: null,
  },
  transmitter_coordinates: null,
  field_state: "active",
  distribution_actions: {
    type: "",
    status: "",
    error: "",
  },
  farming_unit_actions: {
    type: "",
    status: "",
    error: "",
  },
  field_actions: {
    type: "",
    status: "",
    error: "",
  },
  farming_unit_load_history: {
    status: "",
    farming_unit_ids: [],
  },
  field_load_history: {
    status: "",
    field_ids: [],
  },
  all_distribution_load_status: {
    status: "",
  },
};

export var distributionsReducer = (state = distribution_data, action) => {
  switch (action.type) {
    case "SET_SELECTED_ENTITIES":
      return {
        ...state,
        selected_entities: {
          distribution: action.selected_distribution,
          farming_unit: action.selected_farming_unit,
          field: action.selected_field,
          sensors: action.selected_sensors,
        },
      };
      break;

    case "SET_FIELD_STATE":
      return {
        ...state,
        field_state: action.field_state,
      };

      break;
    case "SET_TRANSMITTER_COORDINATES":
      return {
        ...state,
        transmitter_coordinates: action.transmitter_coordinates,
      };

      break;
    case "SET_SELECTED_FARMING_UNIT":
      return {
        ...state,
        selected_entities: {
          farming_unit: action.selected_farming_unit,
        },
      };
      break;
    case "SET_SELECTED_DISTRIBUTION":
      return {
        ...state,
        selected_entities: {
          distribution: action.selected_distribution,
        },
      };
      break;
    case "LOAD_FARMING_UNIT_HISTORY_ACTION_INIT":
      let ids = state.farming_unit_load_history.farming_unit_ids;
      if (!ids.includes(action.farming_unit_id)) {
        ids.push(action.farming_unit_id);
      }

      return {
        ...state,
        farming_unit_load_history: {
          status: "init",
          farming_unit_ids: ids,
        },
      };
      break;
    case "LOAD_FARMING_UNIT_HISTORY_ACTION_CLEAR":
      return {
        ...state,
        farming_unit_load_history: {
          status: "",
          farming_unit_ids: [],
        },
      };
      break;
    case "LOAD_FARMING_UNIT_HISTORY_ACTION_CLEAR_STATUS":
      return {
        ...state,
        farming_unit_load_history: {
          ...state.farming_unit_load_history,
          status: "",
        },
      };
      break;
    case "LOAD_FARMING_UNIT_HISTORY_ACTION_SUCCESS":
      return {
        ...state,
        farming_unit_load_history: {
          ...state.farming_unit_load_history,
          status: "success",
        },
      };
      break;

    case "LOAD_FIELD_HISTORY_ACTION_INIT":
      let field_ids = state.field_load_history.field_ids;
      if (!field_ids.includes(action.field_id)) {
        field_ids.push(action.field_id);
      }

      return {
        ...state,
        field_load_history: {
          status: "init",
          field_ids: field_ids,
        },
      };
    case "LOAD_FIELD_HISTORY_ACTION_CLEAR_STATUS":
      return {
        ...state,
        field_load_history: {
          ...state.field_load_history,
          status: "",
        },
      };
      break;
    case "LOAD_FIELD_HISTORY_ACTION_SUCCESS":
      return {
        ...state,
        field_load_history: {
          ...state.field_load_history,
          status: "success",
        },
      };
      break;
    case "SET_ALL_DISTRIBUTIONS":
      return {
        ...state,
        distributions: action.distributions,
      };
      break;
    case "SET_FIRST_ALL_DISTRIBUTIONS_LOAD_STATUS":
      return {
        ...state,
        all_distribution_load_status: { status: action.status },
      };
      break;
    case "DISTRIBUTION_ACTION_INIT":
      return {
        ...state,
        distribution_actions: {
          type: action.distribution_action_type,
          status: "init",
          error: "",
        },
      };
      break;
    case "DISTRIBUTION_ACTION_SUCCESS":
      return {
        ...state,
        distribution_actions: {
          type: action.distribution_action_type,
          status: "success",
          error: "",
        },
      };
      break;
    case "DISTRIBUTION_ACTION_FAIL":
      return {
        ...state,
        distribution_actions: {
          type: action.distribution_action_type,
          status: "fail",
          error: action.error_message,
        },
      };
      break;
    case "CLEAR_DISTRIBUTION_ACTION":
      return {
        ...state,
        distribution_actions: {
          type: "",
          status: "",
          error: "",
        },
      };
      break;
    case "FARMING_UNIT_ACTION_INIT":
      return {
        ...state,
        farming_unit_actions: {
          type: action.farming_unit_action_type,
          status: "init",
          error: "",
        },
      };
      break;
    case "FARMING_UNIT_ACTION_SUCCESS":
      return {
        ...state,
        farming_unit_actions: {
          type: action.farming_unit_action_type,
          status: "success",
          error: "",
        },
      };
      break;
    case "FARMING_UNIT_ACTION_FAIL":
      return {
        ...state,
        farming_unit_actions: {
          type: action.farming_unit_action_type,
          status: "fail",
          error: action.error_message,
        },
      };
      break;
    case "CLEAR_FARMING_UNIT_ACTION":
      return {
        ...state,
        farming_unit_actions: {
          type: "",
          status: "",
          error: "",
        },
      };
      break;

    case "FIELD_ACTION_INIT":
      return {
        ...state,
        field_actions: {
          type: action.field_action_type,
          status: "init",
          error: "",
        },
      };
      break;
    case "FIELD_ACTION_SUCCESS":
      return {
        ...state,
        field_actions: {
          type: action.field_action_type,
          status: "success",
          error: "",
        },
      };
      break;
    case "FIELD_ACTION_FAIL":
      return {
        ...state,
        field_actions: {
          type: action.field_action_type,
          status: "fail",
          error: action.error_message,
        },
      };
      break;
    case "CLEAR_FIELD_ACTION":
      return {
        ...state,
        field_actions: {
          type: "",
          status: "",
          error: "",
        },
      };
      break;
    case "CLEAR_DISTRIBUTION_DATA":
      return {
        ...state,
        distributions: null,
        selected_entities: {
          distribution: null,
          farming_unit: null,
          field: null,
          sensor: null,
        },
        all_distribution_load_status: {
          status: "",
        },
      };
      break;
    default:
      return state;
  }
};

// roles actions
var roles_data = {
  roles: {
    selected_distribution_roles: null,
    selected_farming_unit_roles: null,
  },
  role_actions: {
    type: "",
    status: "",
    error: "",
  },
};

export var rolesReducer = (state = roles_data, action) => {
  switch (action.type) {
    case "GET_SELECTED_DISTRIBUTION_ROLES_SUCESS":
      return {
        roles: {
          ...state.roles,
          selected_distribution_roles: action.selected_distribution_roles,
        },
        role_actions: {
          type: action.type,
          status: "success",
          error: "",
        },
      };
      break;
    case "GET_SELECTED_FARMING_UNIT_ROLES_SUCCESS":
      return {
        roles: {
          ...state.roles,
          selected_farming_unit_roles: action.selected_farming_unit_roles,
        },
        role_actions: {
          type: action.type,
          status: "success",
          error: "",
        },
      };
      break;
    case "ROLES_ACTION_FAIL":
      return {
        ...state,
        role_actions: {
          type: action.type,
          status: "fail",
          error: action.error_message,
        },
      };
      break;
    case "ROLES_ACTION_INIT":
      return {
        ...state,
        role_actions: {
          type: action.type,
          status: "init",
          error: "",
        },
      };
      break;
    case "ROLE_ACTION_SUCCESS":
      return {
        ...state,
        role_actions: {
          type: action.action_type,
          status: "success",
          error: "",
        },
      };
      break;
    case "CLEAR_ROLES_ACTION":
      return {
        roles: {
          selected_distribution_roles: null,
          selected_farming_unit_roles: null,
        },
        role_actions: {
          type: "",
          status: "",
          error: "",
        },
      };
      break;
    default:
      return state;
  }
};

const transmitter_test_search_results_data = {
  farming_unit_value: null,
  field_value: null,
  distribution_value: null,
  search_type: DEFAULT_SEARCH_TYPE,
  sensor_value: "",
  transmitter_data: null,
  history_log_data: null,
};
export const transmitterTestSearchResultsReducer = (
  state = transmitter_test_search_results_data,
  action,
) => {
  switch (action.type) {
    case "SET_DISTRIBUTION_VALUE":
      return {
        ...state,
        distribution_value: action.distribution_value,
      };
    case "SET_FARMING_UNIT_VALUE":
      return {
        ...state,
        farming_unit_value: action.farming_unit_value,
      };
    case "SET_FIELD_VALUE":
      return {
        ...state,
        field_value: action.field_value,
      };
    case "SET_SENSOR_VALUE":
      return {
        ...state,
        sensor_value: action.sensor_value,
      };
    case "RESET_SEARCH_RESULTS":
      return {
        farming_unit_value: null,
        field_value: null,
        distribution_value: null,
        search_type: DEFAULT_SEARCH_TYPE,
        sensor_value: "",
        transmitter_data: null,
      };
    case "SET_SENSOR_VALUE":
      return {
        ...state,
        sensor_value: action.sensor_value,
      };
    case "SET_TRANSMITTER_SEARCH_DATA":
      return {
        ...state,
        transmitter_data: action.transmitter_data,
      };
    case "SET_SEARCH_RESULTS":
      return {
        ...state,
        farming_unit: action.selected_farming_unit,
        distribution: action.selected_distribution,
        field_value: action.field_value,
      };
    case "SET_TRANSMITTER_SEARCH_TYPE":
      return {
        ...state,
        search_type: action.search_type,
      };
    case "SET_HISTORY_LOG_DATA":
      return {
        ...state,
        history_log_data: action.history_log_data,
      };

    default:
      return state;
  }
};
