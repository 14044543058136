var nitrogenManager = require("@managers/nitrogenManager");
var GeneralClasses = require("@model/GeneralClasses");
var { error_message } = require("@managers/helpers");

// Middleware
export var load_fertilizer_data = (force = false) => {
  return (dispatch, getState) => {
    var state = getState();

    // Load only once
    if (!state.fertilizer_data.fertilizer_data || force) {
      dispatch(get_fertilizer_data_init());

      nitrogenManager.fertilizer_data().then(
        function (result) {
          dispatch(get_fertilizer_data_success(result));
        },
        function (err) {
          console.log(err);
          dispatch(get_fertilizer_data_fail(err));
        },
      );
    }
  };
};

export var get_fertilizer_data_init = () => {
  return {
    type: "GET_FERTILIZER_DATA_INIT",
  };
};

export var get_fertilizer_data_success = (fertilizer_data) => {
  return {
    type: "GET_FERTILIZER_DATA_SUCCESS",
    fertilizer_data: fertilizer_data,
  };
};

export var get_fertilizer_data_fail = (err) => {
  return {
    type: "GET_FERTILIZER_DATA_FAIL",
    error_message: error_message(err),
  };
};

export var clear_fertilizer_data_actions = () => {
  return {
    type: "CLEAR_FERTILIZER_DATA_ACTIONS",
  };
};
