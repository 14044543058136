const React = require('react');
const { connect } = require('react-redux');
const { withTranslation } = require('react-i18next');

const InsightsTableContent = ({
  insightsTableMap = new Array(),
  openFieldsToggle,
  setOpenFieldsToggle,
}) => {
  let farmingUnitRows = [];
  if (Object.keys(insightsTableMap).length > 0) {
    Object.values(insightsTableMap).forEach((outerRowObj, outerRowIndex) => {
      if (Object.keys(outerRowObj.fields_details).length > 0) {
        let ID = Object.keys(insightsTableMap)[outerRowIndex];
        farmingUnitRows.push(
          <tr
            style={{ height: '12px' }}
            key={Object.keys(insightsTableMap)[outerRowIndex] + '0'}
          />,
        );
        farmingUnitRows.push(
          <InsightsOuterRow
            index={outerRowIndex}
            key={ID}
            data={outerRowObj.outerRowInformation}
            specificOpenFieldsToggleState={openFieldsToggle[outerRowIndex]}
            openFieldsToggle={openFieldsToggle}
            setOpenFieldsToggle={setOpenFieldsToggle}
            numOfRows={Object.keys(insightsTableMap).length}
          />,
        );

        let fieldsObjList = Object.values(outerRowObj.innerRowsInformation);

        Object.keys(outerRowObj.innerRowsInformation).forEach(
          (key, fieldIndex) => {
            if (openFieldsToggle[outerRowIndex]) {
              farmingUnitRows.push(
                <InsightsFieldRow
                  fieldIndex={fieldIndex}
                  key={key + fieldsObjList[fieldIndex][0]}
                  fieldObject={fieldsObjList[fieldIndex]}
                  openFieldToggle={openFieldsToggle}
                  numOfFields={fieldsObjList.length}
                />,
              );
              if (fieldIndex === fieldsObjList.length - 1) {
                farmingUnitRows.push(
                  <InsightsClosingFieldRow
                    fieldObject={fieldsObjList[fieldIndex]}
                    key={fieldsObjList[fieldIndex][0] + fieldIndex}
                  />,
                );
              }
            }
          },
        );
      }
    });
    farmingUnitRows.push(<tr style={{ height: '12px' }} key={'1'} />);
  }
  return <tbody>{farmingUnitRows}</tbody>;
};

const InsightsOuterRow = ({
  index,
  key,
  data,
  openFieldsToggle,
  setOpenFieldsToggle,
  specificOpenFieldsToggleState,
  numOfRows,
}) => {
  let imgSrc, marginTop, marginBottom;
  if (!specificOpenFieldsToggleState) {
    imgSrc = require('images/icons/close_arrow.svg');
  } else {
    imgSrc = require('images/icons/open_arrow.svg');
  }
  if (index === 0) {
    marginTop = '12px';
  } else {
    marginTop = '6px';
  }
  if (index === numOfRows - 1) {
    marginBottom = '12px';
  } else {
    marginBottom = '6px';
  }
  return (
    <tr key={key} className='text-grey45 text-size-14 font-weight-bold'>
      <td style={{ backgroundColor: '#DCE0E8' }}></td>
      <td
        style={{
          backgroundColor: '#EEF1F6',
          borderTopLeftRadius: '8px',
          borderBottomLeftRadius: specificOpenFieldsToggleState ? '0px' : '8px',
          marginTop: marginTop,
          marginBottom: specificOpenFieldsToggleState ? '0px' : marginBottom,
          marginLeft: '8px',
        }}
      >
        <span
          style={{ width: '10px' }}
          onClick={() => {
            handlePressedOnOpenFields(
              index,
              setOpenFieldsToggle,
              openFieldsToggle,
            );
          }}
        >
          <img src={imgSrc} />
        </span>
      </td>
      <td
        style={{
          backgroundColor: '#EEF1F6',
          marginTop: marginTop,
          marginBottom: specificOpenFieldsToggleState ? '0px' : marginBottom,
          fontWeight: '400',
          paddingLeft: '0px',
        }}
      >
        <span>{data[0]}</span>
      </td>
      {data.map((value, index) => {
        if (index !== 0 && index !== data.length - 1) {
          return (
            <td
              style={{
                backgroundColor: '#EEF1F6',
                fontWeight: 700,
                marginTop: marginTop,
                marginBottom: specificOpenFieldsToggleState
                  ? '0px'
                  : marginBottom,
              }}
            >
              <span>{value}</span>
            </td>
          );
        }
      })}
      <td
        style={{
          backgroundColor: '#EEF1F6',
          fontWeight: 700,
          marginTop: marginTop,
          marginBottom: specificOpenFieldsToggleState ? '0px' : marginBottom,
          borderTopRightRadius: '8px',
          borderBottomRightRadius: specificOpenFieldsToggleState
            ? '0px'
            : '8px',
          marginRight: '8px',
        }}
      >
        <span>{data[data.length - 1]}</span>
      </td>
      <td></td>
    </tr>
  );
};

const InsightsFieldRow = ({ fieldIndex, key, fieldObject, numOfFields }) => {
  let borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderTop;
  let isUpperRow = false;
  if (fieldIndex === 0 && numOfFields > 1) {
    isUpperRow = true;
    borderTopLeftRadius = '8px';
    borderTopRightRadius = '8px';
    borderTop = '1px solid #DCE0E8';
  } else if (fieldIndex === 0 && numOfFields === 1) {
    isUpperRow = true;
    borderTopLeftRadius = '8px';
    borderTopRightRadius = '8px';
    borderBottomLeftRadius = '8px';
    borderBottomRightRadius = '8px';
    borderTop = '1px solid #DCE0E8';
  } else if (fieldIndex > 0 && fieldIndex !== numOfFields - 1) {
    borderTopLeftRadius = '0px';
    borderTopRightRadius = '0px';
    borderBottomLeftRadius = '0px';
    borderBottomRightRadius = '0px';
  } else if (fieldIndex === numOfFields - 1) {
    borderBottomLeftRadius = '8px';
    borderBottomRightRadius = '8px';
  }
  return (
    <div style={{ display: 'contents', marginRight: '8px' }} key={key}>
      <tr
        style={{ lineHeight: '15px' }}
        className='font-weight-normal text-size-14 text-grey45'
      >
        <td style={{ backgroundColor: '#DCE0E8' }}></td>
        <td
          style={{ backgroundColor: 'rgb(238, 241, 246)', marginLeft: '8px' }}
        >
          <span> </span>
        </td>
        <div style={{ backgroundColor: '#EEF1F6', display: 'grid' }}>
          <td
            style={{
              backgroundColor: 'white',
              borderTopLeftRadius: borderTopLeftRadius,
              borderTop: isUpperRow ? '1px solid #DCE0E8' : 'none',
              borderBottomLeftRadius: borderBottomLeftRadius,
              borderBottom: '1px solid #DCE0E8',
              borderLeft: '1px solid #DCE0E8',
            }}
          >
            <span>{fieldObject[0]}</span>
          </td>
        </div>
        {fieldObject.map((value, index) => {
          if (index !== 0 && index !== fieldObject.length - 1) {
            return (
              <td
                style={{
                  backgroundColor: 'white',
                  borderTop: isUpperRow ? '1px solid #DCE0E8' : 'none',
                  borderBottom: '1px solid #DCE0E8',
                }}
              >
                <span>{value}</span>
              </td>
            );
          }
        })}
        <div style={{ backgroundColor: '#EEF1F6', display: 'grid' }}>
          <td
            style={{
              backgroundColor: 'white',
              borderTopRightRadius: borderTopRightRadius,
              marginRight: '10px',
              borderTop: borderTop,
              borderBottom: '1px solid #DCE0E8',
              borderRight: '1px solid #DCE0E8',
              borderBottomRightRadius: borderBottomRightRadius,
            }}
          >
            <span>{fieldObject[fieldObject.length - 1]}</span>
          </td>
        </div>
        <td></td>
      </tr>
    </div>
  );
};

const InsightsClosingFieldRow = ({ key, fieldObject }) => {
  return (
    <tr key={key} style={{ lineHeight: '10px' }}>
      <td style={{ backgroundColor: '#DCE0E8' }}></td>
      <td
        style={{
          backgroundColor: '#EEF1F6',
          marginBottom: '12px',
          borderBottomLeftRadius: '8px',
          marginLeft: '8px',
        }}
      >
        <span></span>
      </td>
      {fieldObject.map((value, index) => {
        if (index !== fieldObject.length - 1) {
          return (
            <td style={{ backgroundColor: '#EEF1F6', marginBottom: '12px' }}>
              <span></span>
            </td>
          );
        }
      })}
      <td
        style={{
          backgroundColor: '#EEF1F6',
          marginBottom: '12px',
          borderBottomRightRadius: '8px',
          marginRight: '8px',
        }}
      >
        <span></span>
      </td>
    </tr>
  );
};

const handlePressedOnOpenFields = (
  farmingUnitIndex,
  setOpenFieldsToggle,
  openFieldsToggle,
) => {
  let fieldsStatus = [...openFieldsToggle];
  let newOpenFieldStatus = openFieldsToggle[farmingUnitIndex];
  newOpenFieldStatus = !newOpenFieldStatus;
  fieldsStatus[farmingUnitIndex] = newOpenFieldStatus;
  setOpenFieldsToggle(fieldsStatus);
};

module.exports = connect()(withTranslation()(InsightsTableContent));
