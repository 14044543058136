var React = require('react');
var MainCSS = require('mainCSS');
var { Link, withRouter } = require('react-router-dom');
var { connect } = require('react-redux');
var authenticateActions = require('authenticateActions');
var userActions = require('userActions');
var FarmingUnitsDropdown = require('FarmingUnitsDropdown');
var DistributionsDropdown = require('DistributionsDropdown');
var {
  DashboardBannerForMobile,
  DashboardBannerForDesktop,
} = require('Banners');
const { withTranslation } = require('react-i18next');
var farmingUnitManager = require('farmingUnitManager');
var helpers = require('helpers');
var rolesManager = require('@managers/rolesManager');
var NotificationsCenterHeaderContainer = require('./NotificationsCenter/NotificationsCenterHeaderContainer');

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.onLogoutButtonClick = this.onLogoutButtonClick.bind(this);
  }

  onLogoutButtonClick() {
    this.props.logout();
  }

  render() {
    const { t } = this.props;
    const pathname = this.props.history.location.pathname;
    const monitoring_active = pathname === '/';
    const planning_active = pathname.startsWith('/Planning');
    const insights_active = pathname.startsWith('/Insights');
    const inMainScreen =
      monitoring_active || planning_active || insights_active;
    const inMainDashboard = monitoring_active;

    if (this.props.shouldDisplayNavigation == false) {
      return <div></div>;
    }

    var full_name =
      this.props.user_details &&
      this.props.user_details.first_name &&
      this.props.user_details.last_name &&
      this.props.user_details.first_name +
        ' ' +
        this.props.user_details.last_name;
    var shouldDisplayPlanner = farmingUnitManager.shouldDisplayPlanner(
      this.props.selectedFarmingUnit,
    );
    let isAdmin = rolesManager.getIsAdmin(
      this.props.allDistributions,
      this.props?.user_details?.is_root,
    );
    let isCropExpert = rolesManager.isCropExpert(
      this.props?.user_details?.is_root,
      this.props?.user_details?.email,
    );
    let hasInsightsAccess = rolesManager.getIsAdminForInsights(
      this.props?.user_details?.email,
    );
    let hasNotificationsCenterAccess = helpers.getNotificationsCenterAccess(
      this.props?.user_details?.email,
    );
    let isAbleToSeeDistributionDropdown =
      rolesManager.isAbleToSeeDistributionDropdown(
        this.props.allDistributions,
        this.props?.user_details?.is_root,
      );
    let isAbleToSeeFarmingUnitDropdown =
      rolesManager.isAbleToSeeFarmingUnitDropdown(
        this.props.allDistributions,
        this.props?.user_details?.is_root,
      );
    return (
      <div
        className='container-fluid position-fixed'
        style={{ zIndex: 1000, top: 0 }}
      >
        <div
          className='row bg-white align-items-center text-md-left text-center d-flex justify-content-between'
          style={{ height: this.props.headerHeight.toString() + 'px' }}
        >
          <div
            className='text-left align-items-center d-flex'
            style={{ width: '30%' }}
          >
            <Link to='/'>
              <img
                src={require('images/general/ndrip-logo.png?lastmod=12345678')}
                className='logo-image-height ml-0 ml-md-3 mb-0 mt-0'
                alt='N-Drip Logo'
              />
            </Link>
            {/*<DashboardBannerForDesktop/>*/}
            <div
              className='bg-grey91 ml-3 mr-4 align-self-center'
              style={{ width: 1, height: 32 }}
            />
            {inMainScreen ? (
              <div
                className='d-flex align-items-center'
                style={{ marginBottom: '3px' }}
              >
                {isAbleToSeeDistributionDropdown && (
                  <div style={{ marginRight: '24px' }}>
                    <DistributionsDropdown />
                  </div>
                )}
                {!insights_active && isAbleToSeeFarmingUnitDropdown ? (
                  <FarmingUnitsDropdown />
                ) : (
                  <span />
                )}
              </div>
            ) : (
              <div></div>
            )}
          </div>
          {inMainScreen ? (
            <div className='d-none d-xl-block align-self-stretch'>
              <div
                className='pt-3 d-flex '
                style={{
                  height: '100%',
                  paddingBottom: '5px',
                  paddingTop: '15px',
                }}
              >
                <div className='d-flex justify-content-between flex-column pl-2'>
                  <div
                    id='link_div'
                    className='d-flex align-items-center justify-content-between'
                  >
                    <Link
                      to='/'
                      className={
                        'title text-size-14 font-weight-bold ' +
                        (monitoring_active ? 'title_active' : '')
                      }
                      style={{ textDecoration: 'none', letterSpacing: 1.5 }}
                    >
                      {t('monitoring').toUpperCase()}
                    </Link>
                  </div>
                  <div
                    className={
                      'text-left ' + (monitoring_active ? 'underline_div' : '')
                    }
                    style={{ width: '100%', height: 4 }}
                  />
                </div>
                {shouldDisplayPlanner ? (
                  <div className='ml-3 d-flex justify-content-between flex-column  pl-2'>
                    <div
                      id='link_div'
                      className='d-flex align-items-center justify-content-between'
                    >
                      <Link
                        to={'Planning#'}
                        className={
                          'title text-size-14 font-weight-bold ' +
                          (planning_active ? 'title_active' : '')
                        }
                        style={{ textDecoration: 'none', letterSpacing: 1.5 }}
                      >
                        {t('planning').toUpperCase()}
                      </Link>
                    </div>
                    <div
                      className={
                        'text-left ' + (planning_active ? 'underline_div' : '')
                      }
                      style={{ width: '77px', height: 4 }}
                    />
                  </div>
                ) : null}
                {hasInsightsAccess && (
                  <div className='ml-3 d-flex justify-content-between flex-column  pl-2'>
                    <div
                      id='link_div'
                      className='d-flex align-items-center justify-content-between'
                    >
                      <Link
                        to='/Insights#'
                        className={
                          'title text-size-14 font-weight-bold ' +
                          (insights_active ? 'title_active' : '')
                        }
                        style={{ textDecoration: 'none', letterSpacing: 1.5 }}
                      >
                        {t('insights').toUpperCase()}
                      </Link>
                    </div>
                    <div
                      className={
                        'text-left ' + (insights_active ? 'underline_div' : '')
                      }
                      style={{ width: '71px', height: 4 }}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div></div>
          )}

          <div
            className='d-flex '
            style={{ width: '30%', marginBottom: '5px' }}
          >
            {hasNotificationsCenterAccess && (
              <NotificationsCenterHeaderContainer
                openFromMainDashboard={inMainDashboard}
              />
            )}
            <div
              className='dropdown mr-3'
              style={{ marginLeft: hasNotificationsCenterAccess ? 0 : 'auto' }}
            >
              <div
                className='d-none d-sm-block'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                <img src={require('images/icons/header/down_arrow.svg')} />
                <img src={require('images/icons/header/avatar_frame.svg')} />
              </div>
              <div className='dropdown-menu font-weight-normal text-size-16 mr-3'>
                <Link to='/' className='dropdown-item text-grey45'>
                  {t('home')}
                </Link>
                <Link to='Account#' className='dropdown-item text-grey45'>
                  {t('account')}
                </Link>
                {isAdmin ? (
                  <Link
                    to='DistributionUnitsManagement#'
                    className='dropdown-item text-grey45'
                  >
                    {t('admin')}
                  </Link>
                ) : (
                  ''
                )}
                {isAdmin ? (
                  <Link to='Admin#' className='dropdown-item text-grey45'>
                    {t('transmitter_admin_page')}
                  </Link>
                ) : (
                  ''
                )}
                {isCropExpert && isAdmin ? (
                  <Link
                    to='CropManagement#'
                    className='dropdown-item text-grey45'
                  >
                    {t('crop_management')}
                  </Link>
                ) : (
                  ''
                )}
                {isAdmin ? (
                  <Link
                    to='RemoteConfigurations#'
                    className='dropdown-item text-grey45'
                  >
                    {'Remote Configurations (Beta)'}
                  </Link>
                ) : (
                  ''
                )}
                <div className='dropdown-divider'></div>
                <a
                  className='dropdown-item text-grey45'
                  href='#'
                  onClick={() => {
                    this.onLogoutButtonClick();
                  }}
                >
                  {t('logout')}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className='row bg-pale-grey-two'
          style={{ height: this.props.seperatorHeight.toString() + 'px' }}
        ></div>
        {this.props.displayMobileBanner && (
          <DashboardBannerForMobile closeBanner={this.props.closeBanner} />
        )}
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    user_details: state.authentication_data.user_details,
    selectedFarmingUnit: state.distribution_data.selected_entities.farming_unit,
    allDistributions: state.distribution_data.distributions,
  };
};

const mapDispatchToProps = {
  logout: authenticateActions.logout,
};

module.exports = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Header)),
);
