var axios = require("axios");
var { error_message } = require("@managers/helpers");

const CONFIGURATIONS_PATH = "remote_configurations";
const USER_CONFIGURATIONS_PATH = "remote_user_configurations";

export const FeatureFlagDictionary = Object.freeze({
  FERT_TANK_HEIGHT: "Fert tank height",
  FERT_TANK_VOLUME: "fert_tank_volume",
  WT_PROTOCOL: "wt_protocol",
  EDIT_FARMING_UNIT: "edit_farming_unit",
  PLANNER_EXPORT: "planner_export",
  FARMING_UNIT_SENSORS: "farming_unit_sensors",
  // add other types as needed
});

export var create_new_configuration = (configuration) => {
  return new Promise(function (resolve, reject) {
    configuration.distributions = convertArrayToObject(
      configuration.distributions,
    );
    configuration.farming_units = convertArrayToObject(
      configuration.farming_units,
    );
    configuration.environments = convertArrayToObject(
      configuration.environments,
    );
    configuration.users_email = convertArrayToObject(configuration.users_email);
    configuration.extra_data = stringToJsonObject(configuration.extra_data);

    axios
      .post(CONFIGURATIONS_PATH, {
        configuration: configuration,
      })
      .then(function (res) {
        resolve(res.data);
      })
      .catch(function (e) {
        console.log(error_message(e));
        reject(e);
      });
  });
};

export var update_configuration = (configuration) => {
  return new Promise(function (resolve, reject) {
    configuration.distributions = convertArrayToObject(
      configuration.distributions,
    );
    configuration.farming_units = convertArrayToObject(
      configuration.farming_units,
    );
    configuration.environments = convertArrayToObject(
      configuration.environments,
    );
    configuration.users_email = convertArrayToObject(configuration.users_email);
    configuration.extra_data = stringToJsonObject(configuration.extra_data);

    axios
      .put(CONFIGURATIONS_PATH, {
        configuration: configuration,
      })
      .then(function (res) {
        resolve(res.data);
      })
      .catch(function (e) {
        console.log(error_message(e));
        reject(e);
      });
  });
};

export var delete_configuration = (configuration_id) => {
  return new Promise(function (resolve, reject) {
    axios
      .delete(CONFIGURATIONS_PATH + "/" + configuration_id, {})
      .then(function (res) {
        resolve("");
      })
      .catch(function (e) {
        console.log(error_message(e));
        reject(e);
      });
  });
};

export var get_configurations = () => {
  return new Promise(function (resolve, reject) {
    axios
      .get(CONFIGURATIONS_PATH, {})
      .then(function (res) {
        if (res.data.hasOwnProperty("configurations")) {
          res.data.configurations.forEach((configuration) => {
            configuration.distributions = convertObjectToArray(
              configuration.distributions,
            );
            configuration.farming_units = convertObjectToArray(
              configuration.farming_units,
            );
            configuration.environments = convertObjectToArray(
              configuration.environments,
            );
            configuration.users_email = convertObjectToArray(
              configuration.users_email,
            );
            configuration.extra_data = JSON.stringify(configuration.extra_data);
          });
        }
        resolve(res);
      })
      .catch(function (e) {
        console.log(error_message(e));
        reject(e);
      });
  });
};
export var load_user_configurations = () => {
  return new Promise(function (resolve, reject) {
    axios
      .get(USER_CONFIGURATIONS_PATH)
      .then(function (res) {
        resolve(res);
      })
      .catch(function (e) {
        console.log(error_message(e));
        reject(e);
      });
  });
};

// Function to convert array of strings to object
export function convertArrayToObject(arr) {
  if (arr.length === 0 || (arr.length === 1 && arr[0] === "")) return {};

  return arr.reduce((acc, str) => {
    let [key, value] = str.split(":").map((s) => s.trim());

    if (!key || typeof value === "undefined") {
      return acc;
    }

    acc[key] = value.toLowerCase() === "true";
    return acc;
  }, {});
}

// Function to convert object to array of strings
export function convertObjectToArray(obj) {
  if (Object.keys(obj).length === 0) return [];
  return Object.entries(obj).map(([key, value]) => {
    return `${key} :${value ? "True" : "False"}`;
  });
}

export function stringToJsonObject(inputString) {
  try {
    // Attempt to parse the input string as JSON directly
    return JSON.parse(inputString);
  } catch (error) {
    console.log(
      "Failed to parse input string as JSON. Ensure it's properly formatted.",
      error,
    );
    return null;
  }
}

// TODO: YAAKKKKK we need to find better way to do this shit without sending all the data and have direct access to redux
export var get_configuration_config = (
  configurationData,
  configName,
  currentFarmingUnitName,
  currentDistributionName,
) => {
  if (configurationData.configuration_data?.hasOwnProperty(configName)) {
    if (
      configurationData.configuration_data[
        configName
      ].farming_units.hasOwnProperty(currentFarmingUnitName)
    ) {
      return configurationData.configuration_data[configName].farming_units[
        currentFarmingUnitName
      ];
    }
    if (
      configurationData.configuration_data[
        configName
      ].distributions.hasOwnProperty(currentDistributionName)
    ) {
      return configurationData.configuration_data[configName].distributions[
        currentDistributionName
      ];
    }
    return configurationData.configuration_data[configName].default_value;
  }
  return false;
};
