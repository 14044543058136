var React = require('react');
var { connect } = require('react-redux');
const { useTranslation, withTranslation } = require('react-i18next');
const { SelectMenu } = require('SelectMenu');
var userActions = require('userActions');
var rolesActions = require('rolesActions');
const { useEffect, useState } = require('react');
const ToggleSwitch = require('../dashboard/ToggleSwitch');
const NotificationsChannels = require('./NotificationsChannels');
const NotificationsSelectorPerField = require('./NotificationsSelectorPerField');
var $ = require('jquery');
var notificationsManager = require('notificationsManager');
var helpers = require('helpers');

const NotificationsConfiguration = (props) => {
  const { t } = useTranslation();
  const [selectedDistribution, setSelectedDistribution] = useState(undefined);
  const [selectedFarmingUnit, setSelectedFarmingUnit] = useState(undefined);
  const [distributionsMenu, setDistributionsMenu] = useState([]);
  const [farmingUnitsMenu, setFarmingUnitsMenu] = useState([]);
  const [allowNotifications, setAllowNotifications] = useState(
    props?.userData?.notifications?.status === 'enabled' ? false : true,
  );
  const [fieldsMenu, setFieldsMenu] = useState([]);
  const [userData, setUserData] = useState(props?.userData);
  const [notificationsConfigurations, setNotificationsConfigurations] =
    useState({ distributions: [], farmingUnits: [], fields: [] });
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    // re-initialize the tooltip when the allow notifications toggle changes
    $(function () {
      $('[data-toggle="tooltip"]').tooltip({ offset: '0,6' });
    });
  }, [allowNotifications]);

  useEffect(() => {
    if (
      (!allowNotifications &&
        props?.userData?.notifications?.status === 'disabled') ||
      (allowNotifications &&
        props?.userData?.notifications?.status === 'enabled')
    ) {
      setAllowNotifications(
        props?.userData?.notifications?.status === 'enabled' ? false : true,
      );
      setUserData(props?.userData);
    }
  }, [props.userData]);

  useEffect(() => {
    let initialSortedDistributionsMenu = undefined;
    let initialSortedFarmingUnitsMenu = undefined;
    let initialSortedFieldsMenu = undefined;

    notificationsManager
      .getNotificationsConfigurations()
      .then((res) => {
        initialSortedDistributionsMenu = res.distributions.map((dist) => {
          return { title: dist.name, value: dist.name, id: dist.id };
        });
        initialSortedFarmingUnitsMenu = res.farmingUnits.map((farmingUnit) => {
          return {
            title: farmingUnit.name,
            value: farmingUnit.name,
            id: farmingUnit.id,
          };
        });
        initialSortedFieldsMenu = res.fields.map((field) => {
          return {
            title: field.name,
            id: field.id,
            value: field.name,
            notification_type: field.notification_type,
          };
        });
        setNotificationsConfigurations({
          distributions: res.distributions,
          farmingUnits: res.farmingUnits,
          fields: res.fields,
        });
        setDistributionsMenu(initialSortedDistributionsMenu);
        setSelectedDistribution(res.distributions[0]);
        setFarmingUnitsMenu(initialSortedFarmingUnitsMenu);
        setSelectedFarmingUnit(res.farmingUnits[0]);
        setFieldsMenu(initialSortedFieldsMenu);
        setLoadingData(false);
      })
      .catch(function (e) {
        console.log(e.message);
      });

    $(function () {
      $('[data-toggle="tooltip"]').tooltip({ offset: '0,6' });
    });
  }, []);

  const onChangeEntity = (
    chosenDistributionName,
    chosenFarmingUnitName = '',
  ) => {
    let distributions = notificationsConfigurations?.distributions;
    let distributionObj = distributions.filter((dist) => {
      if (dist.name === chosenDistributionName) return chosenDistributionName;
    })[0];
    let farmingUnitObj = {};
    let farmingUnitsArray = Object.entries(distributionObj.farming_units);
    farmingUnitsArray.sort(([aKey, aValue], [bKey, bValue]) =>
      aValue.name.localeCompare(bValue.name),
    );
    let sortedFarmingUnitsObj = Object.fromEntries(farmingUnitsArray);
    let sortedFarmingUnits = [];
    let sortedFarmingUnitsIds = [];
    for (let i = 0; i < farmingUnitsArray.length; i++) {
      sortedFarmingUnits.push(sortedFarmingUnitsObj[farmingUnitsArray[i][0]]);
      sortedFarmingUnitsIds.push(farmingUnitsArray[i][0]);
    }

    if (chosenFarmingUnitName === '') {
      let sortedFarmingUnitsMenu = sortedFarmingUnits
        .map((farmingUnit, index) => {
          return {
            title: farmingUnit.name,
            value: farmingUnit.name,
            id: sortedFarmingUnitsIds[index],
          };
        })
        .sort((a, b) => a.value.localeCompare(b.value));
      farmingUnitObj =
        sortedFarmingUnitsMenu.length > 0 ? sortedFarmingUnits[0] : {};
      if (sortedFarmingUnitsMenu.length > 0) {
        farmingUnitObj['id'] = sortedFarmingUnitsIds[0];
      }
      setFarmingUnitsMenu(sortedFarmingUnitsMenu);
    } else {
      farmingUnitObj = sortedFarmingUnits.filter((farming_unit) => {
        if (farming_unit.name === chosenFarmingUnitName) return farming_unit;
      })[0];
      farmingUnitObj['id'] =
        sortedFarmingUnitsIds[
          sortedFarmingUnits.findIndex((farmingUnit) => {
            return farmingUnit.name === farmingUnitObj.name;
          })
        ];
    }
    let sortedFields = [];
    let sortedFieldsIds = [];
    let sortedFieldsMenu = [];

    if (
      farmingUnitObj.hasOwnProperty('fields') &&
      Object.values(farmingUnitObj?.fields).length > 0
    ) {
      sortedFields = Object.values(farmingUnitObj?.fields);
      sortedFieldsIds = Object.keys(farmingUnitObj?.fields);
      sortedFieldsMenu = sortedFields
        .map((field, index) => {
          return {
            title: field.name,
            value: field.name,
            id: sortedFieldsIds[index],
            notification_type: field.notification_type,
          };
        })
        .sort((a, b) => a.value.localeCompare(b.value));
    }
    setSelectedDistribution(distributionObj);
    setSelectedFarmingUnit(farmingUnitObj);
    setFieldsMenu(sortedFieldsMenu);
  };

  const onToggleClick = () => {
    let newNotificationsStatus = !allowNotifications;
    let newNotificationsStatusString =
      newNotificationsStatus === true ? 'disabled' : 'enabled';
    setAllowNotifications(!allowNotifications);
    let copyOfUserData = {};
    Object.assign(copyOfUserData, userData);

    if (!copyOfUserData.hasOwnProperty('notifications')) {
      copyOfUserData['notifications'] = {
        status: newNotificationsStatusString,
      };
    } else {
      copyOfUserData['notifications'] = {
        ...copyOfUserData['notifications'],
        status: newNotificationsStatusString,
      };
    }
    setUserData(copyOfUserData);
    props.setUserData({ status: copyOfUserData['notifications']['status'] });
    mixpanel.track('Allow Notifications Toggle', {
      Mode: newNotificationsStatusString,
    });
  };

  const onNotificationsChannelChange = (changedData) => {
    let copyOfUserData = {};
    Object.assign(copyOfUserData, userData);
    copyOfUserData['notifications']['channels'] = changedData;
    setUserData(copyOfUserData);
    let copyOfSettingsData = copyOfUserData['notifications'];
    let userDataCopy = Object.assign({}, copyOfUserData);
    delete userDataCopy['notifications'];
    props.setUserData({ settings: { notifications: copyOfSettingsData } });
  };

  const onSelectNotificationType = (changedData, selectedFieldsIds) => {
    props.set_field_configuration(
      selectedDistribution.id,
      selectedFarmingUnit.id,
      selectedFieldsIds,
      changedData,
    );
    let copyOfNotificationsConfigurations = {};
    let copyOfFieldsMenu = [...fieldsMenu];
    Object.assign(
      copyOfNotificationsConfigurations,
      notificationsConfigurations,
    );

    for (const field of selectedFieldsIds) {
      let distributionIndex =
        copyOfNotificationsConfigurations.distributions.findIndex(
          (distribution) => {
            return distribution.id === selectedDistribution.id;
          },
        );
      let fieldIndexInFieldsMenu = copyOfFieldsMenu.findIndex(
        (searchedField) => {
          return searchedField.id === field;
        },
      );
      copyOfNotificationsConfigurations.distributions[
        distributionIndex
      ].farming_units[selectedFarmingUnit.id].fields[field][
        'notification_type'
      ] = changedData.notifications.notification_type;
      copyOfFieldsMenu[fieldIndexInFieldsMenu].notification_type =
        changedData.notifications.notification_type;
    }
    setNotificationsConfigurations(copyOfNotificationsConfigurations);
    setFieldsMenu(copyOfFieldsMenu);
  };

  const setUserPermissionsObject = () => {
    let userFieldsPermissions = {};
    fieldsMenu.forEach((field) => {
      userFieldsPermissions[field.id] = {
        permissions: { notification_type: field.notification_type },
        title: field.title,
      };
    });
    return userFieldsPermissions;
  };

  let toggleTitle = !allowNotifications ? t('yes') : t('no');
  let userDataNotificationsChannels = userData?.notifications?.channels;
  let userFieldsPermissions = setUserPermissionsObject();
  // allow alerts permissions change only for specific users
  let allowAlertsPermissions = false;
  if (
    helpers.getNotificationsCenterAccess(props?.userDetails?.email) ||
    props?.userDetails?.email === 'soli@yapro.co.il'
  ) {
    allowAlertsPermissions = true;
  }

  const loadingSelectorComponent = (
    <SelectMenu
      options={[{ title: t('loading'), value: t('loading') }]}
      curValue={t('loading')}
      sortBox={''}
      onChange={() => {}}
      existingFieldDisableType={true}
    />
  );

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          className='text-grey45'
          style={{ flexDirection: 'column', marginTop: 16, width: '23%' }}
        >
          <span style={{ fontSize: 14, fontWeight: '600' }}>
            {t('allow_notifications')}
          </span>
          <div style={{ marginTop: '11px', display: 'flex' }}>
            <ToggleSwitch
              initialValue={allowNotifications}
              onToggleClick={onToggleClick}
              toggleTitle={toggleTitle}
              toggleSize={'medium'}
              isLabelLeft={true}
            />
          </div>
        </div>
        {allowNotifications === false && (
          <div>
            <NotificationsChannels
              notificationsChannelChange={onNotificationsChannelChange}
              notificationsChannelsData={userDataNotificationsChannels}
              allowAlertsPermissions={allowAlertsPermissions}
            />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {distributionsMenu.length > 1 ? (
                <div
                  className='text-grey45'
                  style={{
                    flexDirection: 'column',
                    marginTop: 24,
                    width: '23%',
                    marginLeft: 0,
                    marginRight: 8,
                  }}
                >
                  <span style={{ fontSize: 14, fontWeight: '600' }}>
                    {t('distribution')}
                  </span>
                  <div style={{ marginTop: 8 }}>
                    {loadingData ? (
                      loadingSelectorComponent
                    ) : (
                      <SelectMenu
                        options={distributionsMenu}
                        curValue={selectedDistribution?.name}
                        sortBox={''}
                        onChange={(selectedDistributionName) => {
                          onChangeEntity(selectedDistributionName);
                        }}
                        existingFieldDisableType={
                          distributionsMenu.length > 1 ? false : true
                        }
                      />
                    )}
                  </div>
                </div>
              ) : null}
              {farmingUnitsMenu.length > 1 || distributionsMenu.length > 1 ? (
                <div
                  className='text-grey45'
                  style={{
                    flexDirection: 'column',
                    marginTop: 24,
                    width: '23%',
                  }}
                >
                  <span style={{ fontSize: 14, fontWeight: '600' }}>
                    {t('farming_unit')}
                  </span>
                  <div style={{ marginTop: 8 }}>
                    {loadingData ? (
                      loadingSelectorComponent
                    ) : (
                      <SelectMenu
                        options={farmingUnitsMenu}
                        curValue={selectedFarmingUnit?.name}
                        onChange={(selectedFarmingUnitName) => {
                          onChangeEntity(
                            selectedDistribution?.name,
                            selectedFarmingUnitName,
                          );
                        }}
                        sortBox={''}
                        existingFieldDisableType={
                          farmingUnitsMenu.length > 1 ? false : true
                        }
                      />
                    )}
                  </div>
                </div>
              ) : null}
            </div>

            <NotificationsSelectorPerField
              userFieldsPermissions={userFieldsPermissions}
              handleRadioButtonClicked={onSelectNotificationType}
              allowAlertsPermissions={allowAlertsPermissions}
              loadingData={loadingData}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    userData: state.authentication_data.user_data,
    all_distributions: state.distribution_data.distributions,
    selected_distribution_roles:
      state.roles_data.roles.selected_distribution_roles,
    setUserFlow: state.authentication_data.set_user_attributes_flow,
    userDetails: state.authentication_data.user_details,
  };
};
const mapDispatchToProps = {
  getUserData: userActions.get_user_data,
  setUserData: userActions.set_user_data,
  get_distribution_roles: rolesActions.get_distribution_roles,
  set_field_configuration: userActions.set_field_configuration,
  setUserAttributes: userActions.set_user_attributes,
};

module.exports = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(NotificationsConfiguration));
