import moment from "moment";

var asyncStorage = null;

//Used only by Mobile-App
export var initStorage = (storage) => {
  asyncStorage = storage;
};

async function AsyncStorage_getItem(itemKey) {
  try {
    let item = await asyncStorage.getItem(itemKey);

    return item;
  } catch (e) {
    // Ignore
  }
}

export const getItem = (itemKey, defaultValue = undefined) => {
  if (typeof localStorage === "object") {
    return localStorage.getItem(itemKey);
  }
  if (asyncStorage && itemKey != "SELECTED_SENSOR_TRANSMITTER_IDS") {
    return AsyncStorage_getItem(itemKey);
  }

  return defaultValue;
};

async function AsyncStorage_removeItem(itemKey) {
  try {
    await asyncStorage.removeItem(itemKey);
    // return;
  } catch (e) {
    // Ignore
  }
}

export var removeItem = (itemKey) => {
  if (typeof localStorage === "object") {
    localStorage.removeItem(itemKey);
    return;
  }
  if (asyncStorage && itemKey != "SELECTED_SENSOR_TRANSMITTER_IDS") {
    AsyncStorage_removeItem(itemKey);
  }
};

async function AsyncStorage_setItem(itemKey, itemValue) {
  try {
    if (!itemValue) {
      itemValue = "";
    }
    await asyncStorage.setItem(itemKey, itemValue);
    // return;
  } catch (e) {
    // Ignore
  }
}

export var setItem = (itemKey, itemValue) => {
  if (typeof localStorage === "object") {
    try {
      localStorage.setItem(itemKey, itemValue);
    } catch (e) {
      console.log("Cache is full, Please empty data");
    }
    return;
  }
  if (asyncStorage && itemKey != "SELECTED_SENSOR_TRANSMITTER_IDS") {
    AsyncStorage_setItem(itemKey, itemValue);
  }
};

export var getAllStorageKeys = async () => {
  if (asyncStorage) {
    return await asyncStorage.getAllKeys();
  }
};

export var multiGetFromStorage = async (keys) => {
  if (asyncStorage) {
    return await asyncStorage.multiGet(keys);
  }
};

export const loadFailedRequestsFromStorage = async () => {
  try {
    const now = moment();
    const keys = await getAllStorageKeys();
    let filteredKeys = keys.filter((key) => {
      let requestTs = moment.unix(key.replace("failed_sensor_request_", ""));
      let keyIsRequest = key.includes("failed_sensor_request");
      let diff = now.diff(requestTs, "weeks");
      return keyIsRequest && diff < 3;
    });
    const result = await multiGetFromStorage(filteredKeys);
    const requests = result.map((req) => JSON.parse(req[1]));
    return { requests: requests, keys: filteredKeys };
  } catch (error) {
    console.error(error);
  }
};
