import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
var distributionActions = require('distributionActions');
var React = require('react');
const { useState } = require('react');
var notificationsManager = require('notificationsManager');
const { useTranslation } = require('react-i18next');
const moment = require('moment');
const $ = require('jquery');
var ContextMenu = require('ContextMenu');

export const WelcomeNotification = (props) => {
  const setWelcomeNotificationDisplay = () => {
    props.updateUserWelcomeNotification();
    mixpanel.track('Notification Welcome Message Dismissed');
  };

  const { t } = useTranslation();

  return (
    <div
      className='alert_div'
      style={{
        borderBottom: '1px solid #DCE0E8',
        display: 'flex',
        flexDirection: 'row',
        padding: '16px',
        gap: '8px',
        backgroundColor: '#F6F9FE',
      }}
    >
      <div style={{ height: '100%' }}>
        <img src={require('images/icons/celibration icon.svg')} />
      </div>
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <div
          className='text-grey45 font-weight-bold text-size-16'
          style={{
            lineHeight: '120%',
            marginRight: '16px',
            marginBottom: '2.5px',
          }}
        >
          {t('notifications_welcome_message_title')}
        </div>
        <div
          className='text-grey45 text-size-14'
          style={{
            marginRight: '4px',
            display: 'flex',
            alignItems: 'center',
            marginBottom: '16px',
          }}
        >
          {t('notifications_welcome_message_body')}
        </div>
        <div
          className='text-grey45 text-size-14 justify-content-center'
          style={{
            width: '138px',
            display: 'flex',
            alignItems: 'center',
            padding: ' 0px 8px',
            border: '1px solid #919DB4',
            borderRadius: '3px',
            cursor: 'pointer',
          }}
          onClick={setWelcomeNotificationDisplay}
        >
          {t('dont_show_again')}
        </div>
      </div>
    </div>
  );
};

export const NotificationItem = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const all_distributions = useSelector(
    (state) => state.distribution_data.distributions,
  );

  let notificationEntities = { distribution: {}, farmingUnit: {}, field: {} };
  if (all_distributions) {
    notificationEntities.distribution =
      all_distributions?.distributions[props.notificationData.distribution_id];
    notificationEntities.farmingUnit =
      notificationEntities.distribution?.farming_units[
        props.notificationData.farming_unit_id
      ];
    notificationEntities.field =
      notificationEntities.farmingUnit?.fields[props.notificationData.field_id];
  }

  const menuOptions = [
    {
      title: t('notifications_settings'),
      func: props.onCloseClicked,
      type: 'link',
      href: '/Account',
      textColor: '#4D5674',
    },
  ];

  let copyOfNotificationObject = {
    ...props.notificationData,
    notification_text: { ...props.notificationData.notification_text },
  };

  copyOfNotificationObject.notification_text.title = t(
    copyOfNotificationObject.notification_text.title,
  );
  copyOfNotificationObject.notification_text.body = t(
    copyOfNotificationObject.notification_text.body,
  );
  let notificationObjet = notificationsManager.replaceKeywords(
    copyOfNotificationObject,
  );
  const [readMore, setReadMore] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [settingsDisplay, setSettingsDisplay] = useState(false);
  const [redirectTo, setRedirectTo] = useState('');

  const setOpenRedMoreTab = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setReadMore(!readMore);
    mixpanel.track('Notification Read More Click', {
      'Notification Type': notificationObjet.notification_text.name,
    });
  };

  const onNotificationsSettingsPressed = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setShowMenu(!showMenu);
    mixpanel.track('Notification Settings Click', {
      'Notification Type': notificationObjet.notification_text.name,
    });
  };

  const onNotificationPressed = () => {
    mixpanel.track('Notification Single Alert Click', {
      'Notification Type': notificationObjet.notification_text.name,
    });
    dispatch(
      distributionActions.selected_entities(
        notificationEntities.distribution,
        notificationEntities.farmingUnit,
        notificationEntities.field,
      ),
    );
    props.onCloseClicked();
    setRedirectTo('/');
  };

  const RegularNotification = () => {
    return (
      <Link
        to={redirectTo}
        className='alert_div'
        style={{
          borderBottom: '1px solid #DCE0E8',
          display: 'flex',
          flexDirection: 'row',
          padding: '16px',
          gap: '8px',
          textDecoration: 'none',
        }}
        onMouseOver={() => {
          setSettingsDisplay(true);
        }}
        onMouseLeave={() => {
          setSettingsDisplay(false);
        }}
        onClick={onNotificationPressed}
      >
        <div style={{ height: '100%' }}>
          <img
            src={
              notificationObjet.notification_text.type === 'alert'
                ? require('images/icons/alert_icon.svg')
                : require('images/icons/warning_icon.svg')
            }
          />
        </div>
        <div
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              className='text-grey71 font-weight-bold text-size-12'
              style={{
                lineHeight: '110%',
                textTransform: 'uppercase',
                marginBottom: '6px',
                marginRight: '7px',
                width: '270px',
              }}
            >
              {notificationObjet.farming_unit_name +
                '-' +
                notificationObjet.field_name}
            </div>
            <div className='d-flex align-items-center justify-content-between position-relative'>
              {showMenu && (
                <ContextMenu
                  options={menuOptions}
                  onToggle={setShowMenu}
                  isDeleteMenu={true}
                  isLinkMenu={true}
                />
              )}
              {settingsDisplay ? (
                <button
                  onClick={onNotificationsSettingsPressed}
                  type='button'
                  className={`btn d-flex justify-content-center align-items-center sensorMenuBtn ${showMenu ? 'active' : ''}`}
                  style={{ margin: '0px' }}
                >
                  <img
                    src={require('images/icons/three-dots-horizontal.svg')}
                  />
                </button>
              ) : (
                <div style={{ width: '24px', height: '24px' }} />
              )}
            </div>
          </div>
          <div
            className='text-grey45 font-weight-bold text-size-16'
            style={{
              lineHeight: '120%',
              marginRight: '24px',
              marginBottom: '8px',
            }}
          >
            {notificationObjet.notification_text.title}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <div
              className='text-grey45 text-size-14'
              style={{
                width: '179px',
                marginRight: '4px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {moment.unix(notificationObjet.date).format('MMM DD, hh:mma')}
            </div>
            <div
              className='read-more-pill text-grey45 font-weight-bold text-size-14'
              style={{
                backgroundColor: settingsDisplay ? '#DCE0E8' : '#EEF1F6',
                borderRadius: '16px',
                padding: '2px 6px 2px 10px',
                marginRight: '2px',
                cursor: 'pointer',
                width: '101px',
              }}
              onClick={setOpenRedMoreTab}
            >
              {readMore ? t('show_less') : t('read_more')}
              <img
                src={
                  readMore
                    ? require('images/icons/Chevron_up_icon.svg')
                    : require('images/icons/Chevron_down_icon.svg')
                }
              />
            </div>
          </div>
          {readMore && (
            <div
              className='text-grey45 text-size-14'
              style={{
                lineHeight: '140%',
                marginTop: '16px',
                marginRight: '16px',
              }}
            >
              {notificationObjet.notification_text.body}
            </div>
          )}
        </div>
      </Link>
    );
  };
  return <RegularNotification />;
};
