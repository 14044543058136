var React = require('react');
var NavigationLine = require('NavigationLine');
var DistributionUnitsTable = require('DistributionUnitsTable');
var { connect } = require('react-redux');
var { Link } = require('react-router-dom');
var BaseComponent = require('BaseComponent');
var UpdateDistributionUnitModal = require('UpdateDistributionUnitModal');
var { withTranslation } = require('react-i18next');

class DistributionUnitsManagement extends BaseComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { t } = this.props;
    var distributionModalId = 'distribution_edit_modal';

    return (
      <div>
        {/* Modal Section */}
        <UpdateDistributionUnitModal modalId={distributionModalId} />

        {/* Rest of Page */}
        <div className='container pt-5'>
          <div className='row justify-content-center'>
            <div className='col-12'>
              <h1>{t('management_distribution_units_management')}</h1>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-12 align-self-center mt-3'>
              <NavigationLine history={this.props.history} />
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-12 align-self-center mt-3'>
              <DistributionUnitsTable
                updateDistributionModalId={distributionModalId}
                history={this.props.history}
              />
            </div>
          </div>
          <div className='row justify-content-center mt-3 mb-5'>
            <div className='col-12 text-center '>
              <button
                className='btn btn-dark-green btn-lg general-button'
                data-toggle='modal'
                data-target={'#' + distributionModalId}
                data-name=''
                data-region=''
                data-id=''
                disabled={this.props.user_details?.is_root == 0}
              >
                {t('create')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    user_details: state.authentication_data.user_details,
  };
};

module.exports = connect(mapStateToProps)(
  withTranslation()(DistributionUnitsManagement),
);

/*

      <div className='container'>
        <div className="row justify-content-center mt-5">
        <div className="col-12">
          <h1>Distribution Units Management</h1>
        </div>

        </div>
        <div className="row justify-content-center">
          <div className="col-12 align-self-center mt-3">
            <NavigationLine history={this.props.history}/>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 align-self-center mt-3">
            <DistributionUnitsTable/>
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-12 text-center ">
            <button className="btn btn-dark-green btn-lg general-button" onClick={() => { this.onSubmitClick() }}>Create</button>
          </div>
        </div>
      </div>*/
