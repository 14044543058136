var React = require('react');
var { connect } = require('react-redux');
var { Link } = require('react-router-dom');
var rolesActions = require('rolesActions');
var { upper } = require('helpers');
var $ = require('jquery');
var { withTranslation } = require('react-i18next');

class ManageFarmingUnitUsersModal extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmitClick = this.onSubmitClick.bind(this);
    this.onRoleChange = this.onRoleChange.bind(this);
    this.onUserChange = this.onUserChange.bind(this);
    this.editRole = this.editRole.bind(this);
    this.deleteRole = this.deleteRole.bind(this);

    this.state = {
      distribution_id: '',
      farming_unit_id: '',
      farming_unit_name: '',
      action: '',
      user_id: '',
      role: 'Admin',
    };
  }

  componentDidMount() {
    var that = this;

    $('#' + this.props.modalId).on('show.bs.modal', function (event) {
      document.getElementById('role-form').classList.remove('was-validated');

      var button = $(event.relatedTarget); // Button that triggered the modal
      var distribution_id = button[0].dataset.id; // Extract info from data-* attributes
      var farming_unit_id = button[0].dataset.farming_unit_id; // Extract info from data-* attributes

      var selected_distribution =
        that.props.distributions.distributions[distribution_id];
      var selected_farming_unit =
        selected_distribution?.farming_units[farming_unit_id];
      that.setState({
        distribution_id: selected_distribution.id,
        farming_unit_id: selected_farming_unit.id,
        farming_unit_name: selected_farming_unit.name,
      });

      that.props.get_farming_unit_roles(distribution_id, farming_unit_id);
    });

    $('#' + this.props.modalId).on('hidden.bs.modal', function (event) {
      that.props.clear_role_actions();
      that.setState({
        distribution_id: '',
        farming_unit_id: '',
        farming_unit_name: '',
        action: '',
        user_id: '',
        role: 'Admin',
      });
    });
  }

  onSubmitClick(e) {
    e.preventDefault();
    e.stopPropagation();

    var form = document.getElementById('role-form');

    // Local check (given by bootstrap)
    if (form.checkValidity() == true) {
      this.props.add_farming_unit_role(
        this.state.distribution_id,
        this.state.farming_unit_id,
        this.state.user_id.toLowerCase(),
        this.state.role,
      );
      this.setState({ action: '', user_id: '', role: 'Admin' });
      form.classList.remove('was-validated');
    } else {
      form.classList.add('was-validated');
    }
  }

  onRoleChange(event) {
    this.setState({ role: event.target.value });
  }

  onUserChange(event) {
    this.setState({ user_id: event.target.value });
  }

  editRole(user_id) {
    if (
      this.props.selected_farming_unit_roles &&
      this.props.selected_farming_unit_roles.roles[user_id]
    ) {
      var role = this.props.selected_farming_unit_roles.roles[user_id];
      this.setState({
        role: upper(
          role.distributions[this.state.distribution_id]?.farming_units[
            this.state.farming_unit_id
          ].role,
        ),
        user_id: user_id,
      });
    }
  }

  deleteRole(user_id) {
    this.props.delete_farming_unit_role(
      this.state.distribution_id,
      this.state.farming_unit_id,
      user_id,
    );
  }

  render() {
    const { t } = this.props;

    return (
      <div
        className='modal fade'
        id={this.props.modalId}
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div
          className='modal-dialog modal-lg modal-dialog-centered'
          role='document'
        >
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                {t('management_manage_distribution_users_title', {
                  distribution: this.state.farming_unit_name,
                })}{' '}
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <form
                className='needs-validation'
                noValidate
                onSubmit={this.onSubmitClick}
                id='role-form'
              >
                <table className='table'>
                  <thead>
                    <tr>
                      <th scope='col'>{t('user')}</th>
                      <th scope='col'>{t('role')}</th>
                      <th scope='col'>{t('actions')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.selected_farming_unit_roles &&
                      this.props.selected_farming_unit_roles.roles &&
                      Object.keys(
                        this.props.selected_farming_unit_roles.roles,
                      ).map((item) => {
                        var current_farming_unit_role =
                          this.props.selected_farming_unit_roles.roles[item];

                        return (
                          <tr key={item}>
                            <td className='align-middle'>{item}</td>
                            <td className='align-middle'>
                              {upper(
                                current_farming_unit_role.distributions[
                                  this.state.distribution_id
                                ]?.farming_units[this.state.farming_unit_id]
                                  .role,
                              )}
                            </td>
                            <td className='align-middle'>
                              <a
                                className='btn btn-link'
                                href='#'
                                onClick={() => this.editRole(item)}
                              >
                                <img
                                  src={require('images/icons/edit_icon.png')}
                                  className='img-fluid'
                                  alt='edit field'
                                />
                              </a>
                              <a
                                className='btn btn-link'
                                href='#'
                                onClick={() => this.deleteRole(item)}
                              >
                                <img
                                  src={require('images/icons/delete_icon.png')}
                                  className='img-fluid'
                                  alt='delete field'
                                />
                              </a>
                            </td>
                          </tr>
                        );
                      })}

                    <tr className='table-smoked'>
                      <td>
                        <div className='form-group '>
                          <input
                            type='email'
                            placeholder='User'
                            required
                            className='form-control'
                            value={this.state.user_id}
                            onChange={this.onUserChange}
                          />
                          <div className='invalid-feedback'>
                            {t('management_enter_a_valid_username')}
                          </div>
                        </div>
                      </td>
                      <td>
                        <select
                          className='form-control'
                          value={this.state.role}
                          onChange={this.onRoleChange}
                        >
                          <option>{t('admin')}</option>
                          <option>{t('moderator')}</option>
                          <option>{t('viewer')}</option>
                        </select>
                      </td>
                      <td>
                        {this.props.role_actions.status != 'init' && (
                          <button className='btn p-0' type='submit'>
                            <img
                              src={require('images/icons/add_icon_framed.png')}
                              className='img-fluid'
                              alt='edit field'
                            />
                          </button>
                        )}
                        {this.props.role_actions.status == 'init' && (
                          <div
                            className='spinner-grow spinner-grow-sm mt-2'
                            role='status'
                          >
                            <span className='sr-only'>{t('loading')}</span>
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
              <div className='form-row justify-content-center mt-4'>
                <div className='text-red text-center'>
                  {this.props.role_actions.status == 'fail' &&
                    this.props.role_actions.error}
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <div className='container text-center'>
                <button
                  className='btn btn-dark-green btn-lg general-button'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  {t('close')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    distribution_actions: state.distribution_data.distribution_actions,
    distributions: state.distribution_data.distributions,
    selected_farming_unit_roles:
      state.roles_data.roles.selected_farming_unit_roles,
    role_actions: state.roles_data.role_actions,
  };
};

const mapDispatchToProps = {
  get_farming_unit_roles: rolesActions.get_farming_unit_roles,
  add_farming_unit_role: rolesActions.add_farming_unit_role,
  clear_role_actions: rolesActions.clear_role_actions,
  delete_farming_unit_role: rolesActions.delete_farming_unit_role,
};

module.exports = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ManageFarmingUnitUsersModal));

//<button type="submit" className="btn btn-dark-green general-button">Add</button>
