import { typeToDataType } from './PlannerUtils';

const PlannerEntities = require('./PlannerEntities');
import { Shadow } from './PlannerCommonUI';

var React = require('react');
const { useState } = require('react');
const { DAYS_AMOUNT, colorMap, titlesMap } = require('./PlannerUtils');
const { useTranslation } = require('react-i18next');

const bgColorMap = {
  irr: 'bg-light-blue',
  fert: 'bg-light-green',
  wtr: 'bg-light-orang-brown',
};

export function ContentRow({
  isResponsive,
  content = [],
  usedLine,
  isSecondLine,
  style = {},
}) {
  let capacity = DAYS_AMOUNT;
  let cellAmount = capacity;
  let cellWidth = 100 / cellAmount + '%';

  if (isResponsive) {
    cellAmount = 40;
    cellWidth = 48;
  }

  function ContentCell({ cellContent, index }) {
    let leftBorder = 'left-border-planner';
    if (cellContent?.seasonBoundary) {
      leftBorder = 'season-boundary-planner';
    }

    if (cellContent instanceof PlannerEntities.DayCellObj) {
      return <DayCell content={cellContent} />;
    } else if (cellContent instanceof PlannerEntities.WeekCellObj) {
      return <WeekCell content={cellContent} />;
    } else if (cellContent instanceof PlannerEntities.ValueCellObj) {
      return (
        <ValueCell
          content={cellContent}
          usedLine={usedLine}
          leftBorder={leftBorder}
          shouldDrawTopShadow={isSecondLine}
        />
      );
    } else if (cellContent instanceof PlannerEntities.Phase) {
      if (index !== 0) leftBorder = '';
      return <PhaseCell content={cellContent} leftBorder={leftBorder} />;
    } else if (cellContent instanceof PlannerEntities.Note) {
      return <NoteCell content={cellContent} leftBorder={leftBorder} />;
    }
    return null;
  }

  return (
    <div
      className='row ml-0 mr-0 pl-0 pr-0 d-flex flex-nowrap'
      style={{ flex: 1, ...style }}
    >
      <Shadow
        style={{ position: 'absolute', width: 'calc(100% - 435px)' }}
        shouldHide={!isSecondLine}
        direction={'top'}
      />

      {[...Array(cellAmount)].map((_, i) => {
        return (
          <div
            className='d-flex justify-content-center'
            style={{ width: cellWidth, whiteSpace: 'nowrap' }}
            key={i}
          >
            <ContentCell cellContent={content[i]} index={i} />
          </div>
        );
      })}
    </div>
  );
}

function DayCell({ content }) {
  let title = 'planner_days_shorts_' + content?.name;
  return <DateCell content={content} title={title} />;
}

function WeekCell({ content }) {
  let title = '';
  let borderStyle = '';
  if (content?.name) {
    title = 'shorts_' + content?.name;
  }
  if (content.isNewMonth) {
    borderStyle = 'border-left border-grey91';
  }
  return <DateCell content={content} title={title} borderStyle={borderStyle} />;
}

function DateCell({ content, title, borderStyle = '' }) {
  const { t } = useTranslation();
  let nameColor = 'text-bermuda-gray';
  let dateColor = 'text-bermuda-gray';
  if (content.isSelected) {
    nameColor = 'text-blue-primary';
    dateColor = 'text-blue-primary';
  }
  if (content.isPast) {
    nameColor = 'text-archived-bermuda-grey';
    dateColor = 'text-archived-bermuda-grey';
  }

  let dotColor = content.isSelected ? 'bg-blue-primary ' : '';

  return (
    <div className='d-flex' style={{ paddingTop: 6, paddingBottom: 5 }}>
      <div
        className={'d-flex align-items-center ' + borderStyle}
        style={{ flexDirection: 'column', height: 51 }}
      >
        <div className={nameColor + ' text-style-caps-11-bold'}>{t(title)}</div>
        <div style={{ height: 3 }} />
        <div
          className={'d-flex justify-content-center align-items-center'}
          style={{
            width: 40,
            height: 40,
            borderRadius: 90,
            flexDirection: 'column',
            padding: 2,
          }}
        >
          <div style={{ width: 6, height: 6, borderRadius: 90 }} />
          <span
            className={dateColor + ' font-weight-semi-bold d-flex'}
            style={{ textAlign: 'center', fontSize: 20, height: 20 }}
          >
            {content?.date}
          </span>
          <div
            className={dotColor}
            style={{ width: 6, height: 6, borderRadius: 90 }}
          />
        </div>
      </div>
    </div>
  );
}

function PhaseCell({ content, leftBorder }) {
  const { t } = useTranslation();
  let leftTag,
    rightTag = '';
  let additionalStyle = {};
  let leftPhaseStyle,
    rightPhaseStyle = '';

  if (content.name) {
    rightPhaseStyle = 'pl-1 bg-white';
    leftBorder = 'left-border-planner';
    rightTag = t('planner_growth_phase_' + content.name, content.name);
  }
  if (content.seasonBoundary == 'start') {
    rightPhaseStyle = 'pl-1';
    leftBorder = 'season-boundary-planner';
    leftTag = t('planner_season_start');
    leftPhaseStyle = 'pl-2 pr-2 bg-grey96';
    additionalStyle = {
      position: 'relative',
      left: -82,
      borderRadius: '4px 0px 0px 4px',
      height: 16,
    };
  }
  if (content.seasonBoundary == 'end') {
    leftBorder = 'season-boundary-planner';
    rightPhaseStyle = 'pl-2 pr-2 bg-grey96';
    rightTag = t('planner_season_end');
    additionalStyle = { borderRadius: '0px 4px 4px 0px', height: 16 };
  }

  return (
    <div
      className={[leftBorder, 'd-flex w-100'].join(' ')}
      style={{ flex: 1, position: 'relative' }}
    >
      <div
        className={[
          'd-flex align-items-center justify-content-center d-flex align-self-center',
          leftPhaseStyle,
        ].join(' ')}
        style={additionalStyle}
      >
        <span className='text-style-titles-12-regular text-grey71'>
          {leftTag}
        </span>
      </div>
      <div
        className={[
          'd-flex align-items-center justify-content-center d-flex align-self-center',
          rightPhaseStyle,
        ].join(' ')}
        style={additionalStyle}
      >
        <span className='text-style-titles-12-regular text-grey71'>
          {rightTag}
        </span>
      </div>
    </div>
  );
}

function NoteCell({ content, leftBorder }) {
  let bgCellColor = '';
  if (content?.isPast) {
    bgCellColor = 'bg-grey99';
  }
  let propsForModal = {};
  if (content.text) {
    content.type = 'note';
    propsForModal = Object.assign(propsForModal, {
      'data-target': '#eventsListModal',
      'data-content': JSON.stringify(content),
      'data-type': content.type,
      'data-editable': true,
    });
  } else {
    propsForModal = Object.assign(propsForModal, {
      'data-action': 'create',
      'data-target': '#eventModalId',
    });
  }

  let tense = content?.isPast ? 'past' : 'future';
  let border = content.text ? 'border' : 'no-border';

  return (
    <div
      className={[
        'd-flex w-100',
        `note-cell--${tense}`,
        leftBorder,
        bgCellColor,
      ].join(' ')}
      style={{ flex: 1 }}
    >
      <div
        className={[
          'd-flex overflow-hidden align-items-center justify-content-center',
          `note-cell-content--${border}`,
        ].join(' ')}
        style={{ margin: 4, flex: 1, borderRadius: 4 }}
        type='button'
        data-toggle='modal'
        data-start={content.startDate / 1000}
        data-id={content.fieldId}
        data-type='note'
        {...propsForModal}
      >
        <span
          className='text-style-titles-12-regular text-grey71 overflow-hidden'
          style={{ textOverflow: 'ellipsis', padding: 4 }}
        >
          {content.text}
        </span>
      </div>
    </div>
  );
}

function ValueCell({ content, usedLine, leftBorder }) {
  const [isHover, setIsHover] = useState(false);

  let shouldDrawTopBorder = usedLine;
  let propsForModal = {};
  let topBorder,
    bgCellColor,
    bgValueColor = '';
  let textValueColor,
    borderColor = '';
  let opacityStyle = {};
  let cursorStyle = {};
  if (shouldDrawTopBorder) {
    topBorder = content?.isPast ? 'top-border-past' : 'top-border-future';
  }
  if (content?.isPast) {
    bgCellColor = 'bg-grey99';
  }
  if (content.total) {
    bgValueColor = bgColorMap[content.type];
    textValueColor = 'text-' + colorMap[content.type];
    propsForModal = Object.assign(propsForModal, {
      'data-target': '#eventsListModal',
      'data-content': JSON.stringify(content),
      'data-type': content.type,
      'data-editable': content.type !== 'wtr' ? !usedLine : false,
    });
    if (isHover) {
      borderColor = 'border border-' + colorMap[content.type];
    }
  } else if (content.type !== 'wtr') {
    // adding wtr events manually is disabled for now
    propsForModal = Object.assign(propsForModal, {
      'data-target': '#eventModalId',
      'data-action': 'create',
      'data-type': typeToDataType[content.type],
      'data-ftype': content.subType,
      'data-content': JSON.stringify(content),
    });
  }
  if (isHover) {
    bgCellColor = content?.isPast ? 'bg-grey96' : 'cell-hover-planner';

    if (!usedLine && !content.total) {
      bgValueColor = bgColorMap[content.type];
      opacityStyle = { opacity: 0.5 };
    }
  }

  if (!usedLine || content.total) {
    propsForModal = Object.assign(propsForModal, {
      type: 'button',
      'data-toggle': 'modal',
      'data-start': content.startDate / 1000,
      'data-id': content.fieldId,
    });
  }

  return (
    <div
      className={['d-flex w-100', topBorder, bgCellColor, leftBorder].join(' ')}
      style={{ flex: 1, ...cursorStyle }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div
        className={[
          borderColor,
          bgValueColor,
          'd-flex align-items-center justify-content-center',
        ].join(' ')}
        style={{ margin: 4, flex: 1, borderRadius: 4, ...opacityStyle }}
        {...propsForModal}
      >
        <span
          className={['text-style-body-14-semi-bold', textValueColor].join(' ')}
        >
          {content.total}
        </span>
      </div>
    </div>
  );
}
