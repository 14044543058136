import {
  get_selected_distribution_roles_success,
  roles_action_fail,
  roles_action_init,
} from "./rolesActions";
import { load_user_configurations } from "../../managers/remoteConfigurationsManager";
import { distribution_action_success } from "./distributionActions";

export var set_remote_config_success = (remote_configuration) => {
  return {
    type: "GET_REMOTE_CONFIGURATION_INIT",
    data: remote_configuration,
  };
};

export var get_remote_config = () => {
  return async (dispatch) => {
    load_user_configurations().then(
      function (result) {
        dispatch(set_remote_config_success(result.data));
      },
      function (err) {
        console.log(err);
      },
    );
  };
};
