var React = require('react');
const { connect } = require('react-redux');
const { useTranslation } = require('react-i18next');
const { useState, useEffect } = require('react');
var { unitDisplayName } = require('unitsManager');
var distributionActions = require('distributionActions');
var { sortByAlphabetic, sortByDate } = require('helpers');
var FieldSelectionRow = require('FieldSelectionRow');
var moment = require('moment');
var { copyTextToClipboard } = require('Utils');

const ArchiveFieldsSelector = (props) => {
  const [hoveredField, setHoveredField] = useState(undefined);
  const [archivedFields, setArchivedFields] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (props.selectedFarmingUnit) {
      let userLengthUnits =
        props.userData && 'length' in props.userData
          ? unitDisplayName(props.userData.length)
          : '';
      let userPressureUnits =
        props.userData && 'pressure' in props.userData
          ? unitDisplayName(props.userData.pressure)
          : '';
      let allFields = Object.values(props.selectedFarmingUnit.fields);
      let formattedFields = allFields
        .filter(
          (currentField) => currentField.settings.field_state === 'archive',
        )
        .map((currentField) => {
          return {
            name: currentField.name,
            isActive: currentField.id === props.selectedField?.id,
            id: currentField.id,
            userLengthUnits: userLengthUnits,
            userPressureUnits: userPressureUnits,
            sowDate: currentField.crop_data.sow_date,
            harvestDate: currentField.crop_data.harvest_date,
            is_archive: currentField.settings.field_state === 'archive',
          };
        });

      // sort by type

      if (props.sortBy === 'alphabetic') {
        sortByAlphabetic(formattedFields, 'name');
      } else if (props.sortBy === 'date') {
        sortByDate(formattedFields, 'sowDate');
      }

      setArchivedFields(formattedFields);
    }
  }, [
    props.selectedFarmingUnit,
    props.selectedField,
    props.sortBy,
    props.selectedField?.settings.field_state,
  ]);

  let is_admin = props.selectedFarmingUnit?.role === 'admin';

  const handleFieldSelection = (fieldId) => {
    var selectedField = props.selectedFarmingUnit.fields[fieldId];
    mixpanel.track('Field Change', {
      'Field ID': selectedField.id,
      'Field Name': selectedField.name,
    });
    props.setSelectedEntities(null, null, selectedField, null);
  };

  const handleMouseLeave = (fieldId) => {
    if (hoveredField === fieldId) {
      setHoveredField('');
    }
  };

  const handleMouseEnter = (fieldId) => {
    setHoveredField(fieldId);
  };

  const handleActiveField = () => {
    props.update_field(
      props.selectedDistribution.id,
      props.selectedFarmingUnit.id,
      {
        ...props.selectedField,
        settings: { ...props.selectedField.settings, field_state: 'active' },
      },
    );
    mixpanel.track('Field moved to be active', {
      'Field ID': props.selectedField.id,
      'Field Name': props.selectedField.name,
    });
  };

  const handleDuplicateField = (currentFieldID, currentFieldName) => {
    mixpanel.track('Field has been duplicated and activated', {
      'Field ID': currentFieldID,
      'Field Name': currentFieldName,
    });
    props.setShowActive(true);
  };

  let archiveFieldsToShow = archivedFields.map((currentField) => {
    let nameElement = '';
    let activityDates = '';
    let currentBackground = 'white';
    if (currentField.isActive) {
      currentBackground = '#F6F8FA';
    } else if (hoveredField === currentField?.id) {
      currentBackground = '#F6F8FA';
    }

    nameElement = (
      <div
        className='text-grey72 pr-0 line-clamp justify-content-center text-size-16'
        style={{
          maxHeight: '2.250em',
          width: '210px',
        }}
      >
        {currentField.name}
      </div>
    );
    let sow_date_array = currentField.sowDate.split('-');
    let harvest_date_array = currentField.harvestDate.split('-');

    activityDates = (
      <div
        className='d-flex text-grey72 align-items-end flex-column justify-content-center text-size-14'
        style={{
          maxHeight: '2.250em',
          height: '30px',
          paddingRight: '8px',
        }}
      >
        <div
          className='d-flex flex-row align-items-start'
          style={{ gap: '4px', height: '15px', width: '78px' }}
        >
          {/*moment().month gets or sets the month and accepts numbers from 0 to 11. 0 - > Jan*/}
          <div>
            {moment()
              .month(sow_date_array[1] - 1)
              .format('MMM')}
          </div>
          <div>{sow_date_array[2] + ','}</div>
          <div>{sow_date_array[0]}</div>
        </div>
        {currentField.harvestDate !== '' ? (
          <div
            className='d-flex flex-row align-items-start'
            style={{ gap: '4px', width: '78px' }}
          >
            <div>
              {moment()
                .month(harvest_date_array[1] - 1)
                .format('MMM')}
            </div>
            <div>{harvest_date_array[2] + ','}</div>
            <div>{harvest_date_array[0]}</div>
          </div>
        ) : (
          ''
        )}
      </div>
    );

    let settingsElement = is_admin ? (
      <div className='dropdown-menu text-grey71'>
        {is_admin && (
          <div
            className='dropdown-item text-grey71'
            onClick={() => {
              copyTextToClipboard(currentField.id);
            }}
          >
            {t('dashboard_copy_field_id')}
          </div>
        )}
        {is_admin && (
          <div
            className='dropdown-item text-grey71'
            data-toggle='modal'
            data-target={'#' + 'fieldModalId'}
            data-id={currentField.id}
            href='#'
          >
            {t('dashboard_edit_field')}
          </div>
        )}
        {is_admin && (
          <div
            className='dropdown-item text-grey71'
            href='#'
            onClick={() => {
              handleActiveField();
            }}
          >
            {t('dashboard_unarchived')}
          </div>
        )}
        {is_admin && (
          <div
            className='dropdown-item text-grey71'
            data-toggle='modal'
            data-target={'#' + 'fieldModalId'}
            data-id={currentField.id}
            data-data='activate'
            href='#'
            onClick={() => {
              handleDuplicateField(currentField.id, currentField.name);
            }}
          >
            {t('duplicate')}
          </div>
        )}
        {is_admin && (
          <div
            className='dropdown-item text-grey71'
            data-toggle='modal'
            data-target={'#' + 'deletionApprovalModalId'}
            data-values={[
              props?.selectedDistribution.id,
              props?.selectedFarmingUnit.id,
              currentField.id,
            ]}
            href='#'
          >
            {t('dashboard_delete_field')}
          </div>
        )}
      </div>
    ) : (
      ''
    );

    let rowContent = (
      <div
        className='d-flex align-items-center row'
        style={{
          paddingLeft: currentField.isActive ? '11px' : '16px',
          height: '64px',
          flexWrap: 'nowrap',
          marginRight: '15px',
          gap: '8px',
        }}
      >
        <div
          className='text-size-16 font-weight-bold flex-fill'
          style={{ marginLeft: '16px', marginRight: '14px' }}
        >
          {nameElement}
        </div>

        <div>{activityDates}</div>
      </div>
    );
    return (
      <FieldSelectionRow
        onMouseLeave={() => handleMouseLeave(currentField.id)}
        onMouseEnter={() => handleMouseEnter(currentField.id)}
        onClick={() => handleFieldSelection(currentField.id)}
        key={Math.random() + Math.random()}
        currentField={currentField}
        currentBackground={currentBackground}
        rowContent={rowContent}
        settingsElement={settingsElement}
        hoveredField={hoveredField}
      />
    );
  });

  return (
    <div
      className='d-flex flex-column  h-100'
      style={{
        boxSizing: 'border-box',
        borderRight: '1px solid #DCE0E8',
        boxShadow:
          '0px 1px 4px rgba(146, 158, 181, 0.1), 0px 4px 8px rgba(145, 157, 180, 0.18)',
      }}
    >
      <div>
        <div className='container-fluid p-0 bg-white'>
          <div
            className='row justify-content-between align-items-center m-0 pt-2 pb-2'
            style={{
              borderBottom: '1px solid #dee2e6',
              borderTop: '1px solid #dee2e6',
              flexWrap: 'nowrap',
            }}
          >
            <div
              className='text-grey71 text-size-12'
              style={{
                width: '123px',
                marginLeft: '16px',
                marginRight: '16px',
                lineHeight: '120%',
              }}
            >
              {t('field').toUpperCase()}
              <br />
              {t('name').toUpperCase()}
            </div>
            <div
              className='text-grey71 text-size-12'
              style={{
                width: '100px',
                lineHeight: '120%',
              }}
            >
              {t('activity_dates').toUpperCase()}
            </div>
          </div>
        </div>
      </div>
      <div
        className='bg-white flex-fill'
        style={{
          overflow: 'auto',
          borderBottom: '1px solid #dee2e6',
          overflowX: 'hidden',
        }}
      >
        {archiveFieldsToShow}
      </div>
    </div>
  );
};
const mapStateToProps = function (state) {
  return {
    selectedField: state.distribution_data.selected_entities.field,
    selectedFarmingUnit: state.distribution_data.selected_entities.farming_unit,
    selectedDistribution:
      state.distribution_data.selected_entities.distribution,
    userData: state.authentication_data.user_data,
    fieldState:
      state.distribution_data?.selected_entities?.field?.settings?.field_state,
  };
};

const mapDispatchToProps = {
  setSelectedEntities: distributionActions.selected_entities,
  getFarmingUnitHistory: distributionActions.get_farming_units_history,
  update_field: distributionActions.update_field,
};

module.exports = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ArchiveFieldsSelector);
