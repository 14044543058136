var React = require('react');
const {
  getCropTypePrefix,
  DefaultCoup,
  DefaultGDD,
  DefaultYield,
} = require('./CropManagement.util');
const { useState } = require('react');
const { useTranslation } = require('react-i18next');
var { upper } = require('@managers/helpers');
var cropDataManager = require('cropDataManager');
var CSS = require('../crop/CropManagement.scss');

const EditCropSection = ({ crops, onUpdate }) => {
  const [gddParameters, setGddParameters] = useState(DefaultGDD);
  const [fathersGddParameters, setFathersGddParameters] = useState(DefaultGDD);
  const [yieldParameters, setYieldParameters] = useState(DefaultYield);
  const [fathersYieldParameters, setFathersYieldParameters] =
    useState(DefaultYield);
  const [phenologicalPhases, setPhenologicalPhases] = useState([]);
  const [coupParameters, setCoupParameters] = useState(DefaultCoup);
  const [fathersCoupParameters, setFatherCoupParameters] =
    useState(DefaultCoup);
  const [cropType, setCropType] = useState('');
  const [cropVariety, setCropVariety] = useState('');
  const [cropFeature, setCropFeature] = useState('');
  const { t } = useTranslation();

  const getMainCropParameters = (type, crop_type = cropType) => {
    let firstVariety = Object.keys(crops[crop_type]).includes('general')
      ? 'general'
      : Object.keys(crops[cropType])[0];
    let firstFeature = Object.keys(crops[crop_type][firstVariety]).includes(
      'general',
    )
      ? 'general'
      : Object.keys(crops[cropType][firstVariety])[0];
    if (crops[crop_type][firstVariety][firstFeature][type]) {
      return crops[crop_type][firstVariety][firstFeature][type];
    } else return [];
  };

  const handlePhenologicalPhases = (phenologicalPhasesFromDB, crop_type) => {
    if (phenologicalPhasesFromDB.length) {
      setPhenologicalPhases(phenologicalPhasesFromDB);
    } else {
      // check if his "father" have phenologicalPhases and put them
      let mainCropPhases = getMainCropParameters(
        'phenological_phases',
        crop_type,
      );
      setPhenologicalPhases(mainCropPhases);
    }
  };

  const handleYieldParametersChange = (yieldParamsFromDB) => {
    if (yieldParamsFromDB) {
      setYieldParameters(yieldParamsFromDB);
    } else {
      setYieldParameters(DefaultYield);
    }
  };

  const handleFathersCoupParametersChange = (
    fathersCoupParams,
    extend = true,
  ) => {
    if (fathersCoupParams) {
      setFatherCoupParameters(
        extend
          ? { ...fathersCoupParameters, ...fathersCoupParams }
          : fathersCoupParams,
      );
    } else {
      setFatherCoupParameters(DefaultCoup);
    }
  };

  const handleFathersGddParametersChange = (
    fathersGddParams,
    extend = true,
  ) => {
    if (fathersGddParams) {
      setFathersGddParameters(
        extend
          ? { ...fathersGddParameters, ...fathersGddParams }
          : fathersGddParams,
      );
    } else {
      setFathersGddParameters(DefaultGDD);
    }
  };

  const handleFathersYieldParametersChange = (
    fathersYieldParams,
    extend = true,
  ) => {
    if (fathersYieldParams) {
      setFathersYieldParameters(
        extend
          ? { ...fathersYieldParameters, ...fathersYieldParams }
          : fathersYieldParams,
      );
    } else {
      setFathersYieldParameters(DefaultYield);
    }
  };

  const handleCropTypeChange = (crop) => {
    setCropType(crop);
    let firstVariety = Object.keys(crops[crop]).includes('general')
      ? 'general'
      : Object.keys(crops[crop])[0];
    let firstFeature = Object.keys(crops[crop][firstVariety]).includes(
      'general',
    )
      ? 'general'
      : Object.keys(crops[crop][firstVariety])[0];
    setCropVariety(firstVariety);
    setCropFeature(firstFeature);
    if (firstVariety === 'general' && firstFeature === 'general') {
      handleFathersCoupParametersChange(
        crops[crop][firstVariety][firstFeature]['coup_parameters'],
        false,
      );
      handleFathersGddParametersChange(
        crops[crop][firstVariety][firstFeature]['gdd'],
        false,
      );
      handleFathersYieldParametersChange(
        crops[crop][firstVariety][firstFeature]['yield'],
        false,
      );
    }

    handleCoupParametersChange(
      crops[crop][firstVariety][firstFeature]['coup_parameters'],
    );
    handleGDDParametersChange(crops[crop][firstVariety][firstFeature]['gdd']);
    handlePhenologicalPhases(
      crops[crop][firstVariety][firstFeature]['phenological_phases'],
      crop,
    );
    handleYieldParametersChange(
      crops[crop][firstVariety][firstFeature]['yield'],
    );
  };

  const handleCropVarietyChange = (variety) => {
    setCropVariety(variety);
    let firstFeature = Object.keys(crops[cropType][variety])[0];
    if ('general' in crops[cropType][variety]) {
      firstFeature = 'general';
    }

    setCropFeature(firstFeature);

    // check about sub variety
    handleFathersCoupParametersChange(
      crops[cropType]['general']['general']['coup_parameters'],
      false,
    );
    handleFathersGddParametersChange(
      crops[cropType]['general']['general']['gdd'],
      false,
    );
    handleFathersYieldParametersChange(
      crops[cropType]['general']['general']['yield'],
      false,
    );
    handleCoupParametersChange(
      crops[cropType][variety][firstFeature]['coup_parameters'],
    );
    handleGDDParametersChange(crops[cropType][variety][firstFeature]['gdd']);
    handlePhenologicalPhases(
      crops[cropType][variety][firstFeature]['phenological_phases'],
    );
    handleYieldParametersChange(
      crops[cropType][variety][firstFeature]['yield'],
    );
  };

  const handleCropFeatureChange = (feature) => {
    setCropFeature(feature);
    let coup_to_inherit = {};
    let gdd_to_inherit = {};
    let yield_to_inherit = {};
    if (feature === 'general') {
      coup_to_inherit = {
        ...crops[cropType]['general']['general']['coup_parameters'],
        ...crops[cropType][cropVariety]['general']['coup_parameters'],
      };
      gdd_to_inherit = {
        ...crops[cropType]['general']['general']['gdd'],
        ...crops[cropType][cropVariety]['general']['gdd'],
      };
      yield_to_inherit = {
        ...crops[cropType]['general']['general']['yield'],
        ...crops[cropType][cropVariety]['general']['yield'],
      };
      handleFathersCoupParametersChange(coup_to_inherit, false);
      handleFathersGddParametersChange(gdd_to_inherit, false);
      handleFathersYieldParametersChange(yield_to_inherit, false);
    } else {
      if (Object.keys(crops[cropType]['general']).includes(feature)) {
        coup_to_inherit = {
          ...crops[cropType]['general'][feature]['coup_parameters'],
        };
        gdd_to_inherit = { ...crops[cropType]['general'][feature]['gdd'] };
        yield_to_inherit = { ...crops[cropType]['general'][feature]['yield'] };
      }
      handleFathersCoupParametersChange({
        ...coup_to_inherit,
        ...crops[cropType][cropVariety]['general']['coup_parameters'],
      });
      handleFathersGddParametersChange({
        ...gdd_to_inherit,
        ...crops[cropType][cropVariety]['general']['gdd'],
      });
      handleFathersYieldParametersChange({
        ...yield_to_inherit,
        ...crops[cropType][cropVariety]['general']['yield'],
      });
    }

    handleCoupParametersChange(
      crops[cropType][cropVariety][feature]['coup_parameters'],
    );
    handleGDDParametersChange(crops[cropType][cropVariety][feature]['gdd']);
    handlePhenologicalPhases(
      crops[cropType][cropVariety][feature]['phenological_phases'],
    );
    handleYieldParametersChange(crops[cropType][cropVariety][feature]['yield']);
  };

  const handleUpdate = () => {
    // encode special chars : # = %23
    let crop_type = '';

    if (
      cropType &&
      cropVariety &&
      cropVariety !== 'general' &&
      cropFeature &&
      cropFeature !== 'general'
    ) {
      crop_type = getCropTypePrefix(crops, cropType, cropVariety, cropFeature);
      crop_type = crop_type.replace(/#/g, '%23') + '%24' + cropFeature;
    } else if (
      cropType &&
      cropVariety &&
      cropVariety !== 'general' &&
      cropFeature === 'general'
    ) {
      crop_type = getCropTypePrefix(crops, cropType, cropVariety, cropFeature);
      crop_type = crop_type.replace(/#/g, '%23');
    } else if (
      cropType &&
      (cropVariety === 'general' || cropVariety === '') &&
      cropFeature &&
      cropFeature !== 'general'
    ) {
      crop_type = cropType + '%24' + cropFeature;
    } else if (
      cropType &&
      (cropVariety === 'general' || cropVariety === '') &&
      (cropFeature === 'general' || cropFeature === '')
    ) {
      crop_type = cropType;
    }

    try {
      // filter empty values
      const filteredCoupParameters = Object.entries(coupParameters).reduce(
        (acc, [key, value]) => {
          if (value !== '') {
            acc[key] = parseFloat(value);
          }
          return acc;
        },
        {},
      );

      const filteredGddParameters = Object.entries(gddParameters).reduce(
        (acc, [key, value]) => {
          if (value !== '') {
            acc[key] = parseFloat(value);
          }
          return acc;
        },
        {},
      );

      const filteredYieldParameters = Object.entries(yieldParameters).reduce(
        (acc, [key, value]) => {
          if (value !== '') {
            acc[key] = parseFloat(value);
          }
          return acc;
        },
        {},
      );

      // check if there are changes in phases from his "father" main crop and update it , else stay as is
      let mainCropPhases = getMainCropParameters('phenological_phases');
      let phasesFromDB =
        crops[cropType][cropVariety][cropFeature]['phenological_phases'];
      let samePhases = false;
      if (
        JSON.stringify(mainCropPhases) === JSON.stringify(phenologicalPhases)
      ) {
        samePhases = true;
      }

      const updatedCropItem = {
        id: crop_type,
        body: {
          coup_parameters: filteredCoupParameters,
          phenological_phases: !samePhases ? phenologicalPhases : phasesFromDB,
          gdd: filteredGddParameters,
          yield: filteredYieldParameters,
        },
      };
      cropDataManager.updateCropItem(updatedCropItem).then((res) => {
        if (res['success']) {
          onUpdate();
          alert('Crop item updated successfully!');
        }
      });
      mixpanel.track('Crop DB Update', {
        'Crop ID': crop_type,
        'Crop Name': cropType,
      });
    } catch (error) {
      console.error('Error updating crop item:', error);
    }
  };

  const handleCoupParametersChange = (coupParamsFromDB) => {
    if (coupParamsFromDB) {
      const sortedKeys = Object.keys(coupParamsFromDB).sort();
      const sortedObject = {};
      sortedKeys.forEach((key) => {
        sortedObject[key] = coupParamsFromDB[key];
      });
      setCoupParameters(sortedObject);
    } else {
      setCoupParameters(DefaultCoup);
    }
  };

  const handleGDDParametersChange = (gddParamsFromDB) => {
    if (gddParamsFromDB) {
      setGddParameters(gddParamsFromDB);
    } else {
      setGddParameters(DefaultGDD);
    }
  };

  const handleAddPhase = () => {
    setPhenologicalPhases([
      ...phenologicalPhases,
      {
        name: '',
        duration_days: '',
        kc: '',
        stress: {
          factor: '',
          is_stress_active: false,
        },
        duration_gdd: '',
        is_dev: false,
      },
    ]);
  };

  const handlePhaseChange = (index, field, value) => {
    const newPhenologicalPhases = [...phenologicalPhases];
    if (
      field === 'is_stress_active' &&
      newPhenologicalPhases[index].hasOwnProperty('stress')
    ) {
      newPhenologicalPhases[index]['stress']['is_stress_active'] = value;
    } else if (
      field === 'factor' &&
      newPhenologicalPhases[index].hasOwnProperty('stress')
    ) {
      newPhenologicalPhases[index]['stress']['factor'] = value;
    } else {
      newPhenologicalPhases[index][field] = value;
    }
    setPhenologicalPhases(newPhenologicalPhases);
  };

  const handleDeletePhase = (index) => {
    setPhenologicalPhases((prevPhases) => {
      const newPhases = [...prevPhases];
      newPhases.splice(index, 1);
      return newPhases;
    });
  };

  let coupParamsJSX = Object.keys(DefaultCoup).map((key, index) => (
    <div className='params-div' key={key} style={{ marginRight: '20px' }}>
      <label htmlFor={key}>{key}:</label>
      <input
        className='form-control'
        type='number'
        id={key}
        name={key}
        value={key in coupParameters ? coupParameters[key] : ''}
        placeholder={
          key in fathersCoupParameters ? fathersCoupParameters[key] : ''
        }
        onChange={(e) =>
          setCoupParameters({ ...coupParameters, [key]: e.target.value })
        }
      />
    </div>
  ));
  return (
    <div>
      <h2 className='pl-3'>Edit Parameters Section</h2>
      <div className='p-3 w-25'>
        <div>
          <div>
            <label style={{ marginBottom: '4px' }}>{t('crop_type')}</label>
            <select
              className='custom-select'
              onChange={(e) => handleCropTypeChange(e.target.value)}
            >
              {crops &&
                Object.keys(crops)
                  .sort()
                  .map((item, index) => {
                    return (
                      <option key={item} value={item}>
                        {t(upper(item))}
                      </option>
                    );
                  })}
            </select>
          </div>
          <label style={{ marginBottom: '4px' }}>{t('kc_variety')}</label>
          <select
            className='custom-select'
            value={cropVariety}
            onChange={(event) => handleCropVarietyChange(event.target.value)}
          >
            {crops &&
              cropType &&
              Object.keys(crops[cropType])
                .sort()
                .map((item, index) => {
                  return (
                    <option key={item} value={item}>
                      {t(upper(item))}
                    </option>
                  );
                })}
          </select>

          <label style={{ marginBottom: '4px' }}>{t('kc_feature')}</label>
          <br />
          <select
            className='custom-select'
            value={cropFeature}
            onChange={(event) => handleCropFeatureChange(event.target.value)}
          >
            {crops &&
              cropType &&
              cropVariety &&
              Object.keys(crops[cropType][cropVariety])
                .sort()
                .map((key, index) => (
                  <option key={key} value={key}>
                    {t(upper(key))}
                  </option>
                ))}
          </select>
        </div>
        <div className='d-flex justify-content-center pt-3'>
          <button className='btn btn-grey45 w-100' onClick={handleUpdate}>
            Update
          </button>
        </div>
      </div>
      <div className='p-3'>
        <div>
          <h2>Phenological Phases:</h2>
          <div
            style={{
              border: '1px solid #ccc',
              padding: '10px',
              margin: '10px',
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {phenologicalPhases.map((phase, index) => {
              return (
                <div
                  key={index}
                  style={{
                    border: '1px solid #ccc',
                    padding: '10px',
                    margin: '10px',
                  }}
                >
                  <div style={{ marginBottom: '10px' }}>Phase {index + 1}</div>
                  {[
                    'name',
                    ...Object.keys(phase)
                      .filter((key) => key !== 'name')
                      .flatMap((key) =>
                        key === 'stress' ? Object.keys(phase[key]) : key,
                      ),
                  ].map((key) => {
                    let labelName =
                      key === 'is_active'
                        ? 'is_stress_active'
                        : key === 'factor'
                          ? 'stress_factor'
                          : key;
                    return (
                      <div key={key}>
                        <label htmlFor={key} style={{ marginRight: '10px' }}>
                          {labelName}:
                        </label>
                        {key === 'is_dev' ? (
                          <select
                            className='form-control'
                            id='is_dev'
                            name='is_dev'
                            value={phase.is_dev}
                            onChange={(e) => {
                              handlePhaseChange(
                                index,
                                'is_dev',
                                e.target.value === 'true',
                              );
                            }}
                          >
                            <option value={true}>True</option>
                            <option value={false}>False</option>
                          </select>
                        ) : key === 'is_stress_active' ? (
                          <select
                            className='form-control'
                            id='is_active'
                            name='is_active'
                            value={phase.stress.is_stress_active}
                            onChange={(e) => {
                              handlePhaseChange(
                                index,
                                'is_stress_active',
                                e.target.value === 'true',
                              );
                            }}
                          >
                            <option value={true}>True</option>
                            <option value={false}>False</option>
                          </select>
                        ) : key === 'factor' ? (
                          <input
                            className='form-control'
                            type='text'
                            id={key}
                            name={key}
                            value={phase.stress.factor}
                            onChange={(e) => {
                              handlePhaseChange(index, key, e.target.value);
                            }}
                          />
                        ) : (
                          <input
                            className='form-control'
                            type='text'
                            id={key}
                            name={key}
                            value={phase[key]}
                            onChange={(e) => {
                              handlePhaseChange(index, key, e.target.value);
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                  <div className='d-flex justify-content-center mt-2'>
                    <button
                      className='btn btn-danger d-flex justify-content-center'
                      onClick={() => handleDeletePhase(index)}
                    >
                      Delete Phase
                    </button>
                  </div>
                </div>
              );
            })}
            <div className='d-flex align-items-center'>
              <button className='btn btn-grey45' onClick={handleAddPhase}>
                Add Phase
              </button>
            </div>
          </div>
        </div>

        <div className='d-flex flex-row'>
          <div>
            <h2>GDD Parameters:</h2>
            <div
              style={{
                border: '1px solid #ccc',
                padding: '10px',
                margin: '10px',
              }}
            >
              {Object.keys(DefaultGDD).map((key) => (
                <div className='params-div' key={key}>
                  <label htmlFor={key} style={{ marginRight: '10px' }}>
                    {key}:
                  </label>
                  <input
                    className='form-control'
                    type='number'
                    id={key}
                    name={key}
                    value={key in gddParameters ? gddParameters[key] : ''}
                    placeholder={
                      key in fathersGddParameters
                        ? fathersGddParameters[key]
                        : ''
                    }
                    onChange={(e) =>
                      setGddParameters({
                        ...gddParameters,
                        [key]: e.target.value,
                      })
                    }
                  />
                </div>
              ))}
            </div>
          </div>

          <div className={'pl-5'}>
            <h2>Yield Parameters:</h2>
            <div
              style={{
                border: '1px solid #ccc',
                padding: '10px',
                margin: '10px',
              }}
            >
              {Object.keys(DefaultYield).map((key) => (
                <div className='params-div' key={key}>
                  <label htmlFor={key} style={{ marginRight: '10px' }}>
                    {key}:
                  </label>
                  <input
                    className='form-control'
                    type='number'
                    id={key}
                    name={key}
                    value={key in yieldParameters ? yieldParameters[key] : ''}
                    placeholder={
                      key in fathersYieldParameters
                        ? fathersYieldParameters[key]
                        : ''
                    }
                    onChange={(e) =>
                      setYieldParameters({
                        ...yieldParameters,
                        [key]: e.target.value,
                      })
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div>
          <h2>Coup Parameters:</h2>
          <div
            style={{
              border: '1px solid #ccc',
              padding: '10px',
              margin: '10px',
              display: 'flex',
              flexDirection: 'column',
              columnCount: 4,
            }}
          >
            <div className='form-container'>{coupParamsJSX}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

module.exports = EditCropSection;
