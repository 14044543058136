var rolesManager = require("@managers/rolesManager");
var GeneralClasses = require("@model/GeneralClasses");
var { error_message } = require("@managers/helpers");

// Middleware
export var get_distribution_roles = (distribution_id) => {
  return (dispatch, getState) => {
    dispatch(roles_action_init());

    rolesManager.distribution_roles(distribution_id).then(
      function (result) {
        dispatch(get_selected_distribution_roles_success(result));
      },
      function (err) {
        console.log(err);
        dispatch(roles_action_fail(error_message(err)));
      },
    );
  };
};

export var get_farming_unit_roles = (distribution_id, farming_unit_id) => {
  return (dispatch, getState) => {
    dispatch(roles_action_init());

    rolesManager.farming_unit_roles(distribution_id, farming_unit_id).then(
      function (result) {
        dispatch(get_selected_farming_unit_roles_success(result));
      },
      function (err) {
        console.log(err);
        dispatch(roles_action_fail(error_message(err)));
      },
    );
  };
};

export var add_distribution_role = (distribution_id, user_id, role) => {
  return (dispatch, getState) => {
    var state = getState();

    dispatch(roles_action_init());

    rolesManager.add_distribution_role(distribution_id, user_id, role).then(
      function (result) {
        dispatch(role_action_success("add_distribution_role"));
        dispatch(get_distribution_roles(distribution_id));
      },
      function (err) {
        console.log(err);
        dispatch(roles_action_fail(error_message(err)));
      },
    );
  };
};

export var add_farming_unit_role = (
  distribution_id,
  farming_unit_id,
  user_id,
  role,
) => {
  return (dispatch, getState) => {
    var state = getState();

    dispatch(roles_action_init());

    rolesManager
      .add_farming_unit_role(distribution_id, farming_unit_id, user_id, role)
      .then(
        function (result) {
          dispatch(role_action_success("add_farming_unit_role"));
          dispatch(get_farming_unit_roles(distribution_id, farming_unit_id));
        },
        function (err) {
          console.log(err);
          dispatch(roles_action_fail(error_message(err)));
        },
      );
  };
};

export var delete_distribution_role = (distribution_id, user_id) => {
  return (dispatch, getState) => {
    var state = getState();

    dispatch(roles_action_init());

    rolesManager.delete_distribution_role(distribution_id, user_id).then(
      function (result) {
        dispatch(role_action_success("delete_distribution_role"));
        dispatch(get_distribution_roles(distribution_id));
      },
      function (err) {
        console.log(err);
        dispatch(roles_action_fail(error_message(err)));
      },
    );
  };
};

export var delete_farming_unit_role = (
  distribution_id,
  farming_unit_id,
  user_id,
) => {
  return (dispatch, getState) => {
    var state = getState();

    dispatch(roles_action_init());

    rolesManager
      .delete_farming_unit_role(distribution_id, farming_unit_id, user_id)
      .then(
        function (result) {
          dispatch(role_action_success("delete_farming_unit_role"));
          dispatch(get_farming_unit_roles(distribution_id, farming_unit_id));
        },
        function (err) {
          console.log(err);
          dispatch(roles_action_fail(error_message(err)));
        },
      );
  };
};

export var roles_action_init = () => {
  return {
    type: "ROLES_ACTION_INIT",
  };
};

export var roles_action_fail = (error_message) => {
  return {
    type: "ROLES_ACTION_FAIL",
    error_message: error_message,
  };
};

export var get_selected_distribution_roles_success = (
  selected_distribution_roles,
) => {
  return {
    type: "GET_SELECTED_DISTRIBUTION_ROLES_SUCESS",
    selected_distribution_roles: selected_distribution_roles,
  };
};

export var get_selected_farming_unit_roles_success = (
  selected_farming_unit_roles,
) => {
  return {
    type: "GET_SELECTED_FARMING_UNIT_ROLES_SUCCESS",
    selected_farming_unit_roles: selected_farming_unit_roles,
  };
};

export var role_action_success = (action_type) => {
  return {
    type: "ROLE_ACTION_SUCCESS",
    action_type: action_type,
  };
};

export var clear_role_actions = () => {
  return {
    type: "CLEAR_ROLES_ACTION",
  };
};
