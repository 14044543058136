const { useTranslation, withTranslation } = require('react-i18next');
const moment = require('moment/moment');
const React = require('react');
const { connect } = require('react-redux');

const ExtraSensorContent = React.memo(
  ({ chosenFertType, chosenFertID, chosenStartDate }) => {
    const { t } = useTranslation();
    var start_date_object = chosenStartDate
      ? moment.unix(chosenStartDate).format('DD MMM YYYY')
      : undefined;

    return (
      <div
        className='d-flex flex-grow-1 flex-row w-100 border-top'
        style={{ padding: '8px 0', gap: '4px' }}
      >
        <div className='text-grey45' style={{ width: '248px', gap: '4px' }}>
          <label
            style={{ lineHeight: 1 }}
            className='font-weight-semi-bold mb-0 text-size-14 lh-1 text-grey45'
          >
            {t('Fert_sensor_fertlization_type')}
          </label>
          <div style={{ lineHeight: 1 }}>{chosenFertType}</div>
          <div style={{ lineHeight: 1 }}></div>
        </div>

        <div className='text-grey45' style={{ width: '152px', gap: '4px' }}>
          <label
            style={{ lineHeight: 1 }}
            className='font-weight-semi-bold mb-0 text-size-14 lh-1 text-grey45'
          >
            {t('fertilizer_meter_id')}
          </label>
          <div style={{ lineHeight: 1 }}>{chosenFertID}</div>
        </div>
        <div className='text-grey45' style={{ width: '152px', gap: '4px' }}>
          <label
            style={{ lineHeight: 1 }}
            className='font-weight-semi-bold mb-0 text-size-14 lh-1 text-grey45'
          >
            {t('start_date')}
          </label>
          <div style={{ lineHeight: 1 }}>{start_date_object}</div>
        </div>
      </div>
    );
  },
);

module.exports = connect()(withTranslation()(ExtraSensorContent));
