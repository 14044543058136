var React = require('react');
var { connect } = require('react-redux');
var {
  getCurrentSeasonStartDate,
  loadFieldRecords,
  deleteFieldRecord,
} = require('fieldManager');
var moment = require('moment');
var $ = require('jquery');
var distributionActions = require('distributionActions');
var SensorGraph = require('SensorGraph');
var GeneralClasses = require('GeneralClasses');
var { withTranslation } = require('react-i18next');
var { unitDisplayName } = require('unitsManager');
var weatherManager = require('weatherManager');
var GraphToggle = require('GraphToggle');
var { generateDates, dateToUnixTimestamp } = require('@managers/helpers');

class NitrogenGraphContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleIrrigationInputDisplay =
      this.handleIrrigationInputDisplay.bind(this);
    this.handleNitrogenInputDisplay =
      this.handleNitrogenInputDisplay.bind(this);
    this.closeInputFrame = this.closeInputFrame.bind(this);

    this.reloadGraph = this.reloadGraph.bind(this);
    this.loadNitrogenData = this.loadNitrogenData.bind(this);

    this.updateGraphDisplay = this.updateGraphDisplay.bind(this);
    this.precipitationDeleted = this.precipitationDeleted.bind(this);

    this.sensor_samples = null;

    this.state = {
      days_back: 7,
      requested_sensor_ids: [],
      timezone: '',
      units: 'Kg/Ha',
      isIrrigationdrawerOpen: false,
      isNitrogenDrawerOpen: false,
      shouldDisplayInSoil: true,
      shouldDisplayInPlant: true,
      shouldDisplayLeached: true,
      nitrogenData: { n_in_soil: [], n_in_plant: [], n_leached: [] },
    };
  }

  componentDidMount() {
    if (this.props.selectedField) {
      this.loadNitrogenData();
    }
  }

  componentWillUnmount() {}

  componentDidUpdate(nextProps, nextState) {
    // update graph for every new field
    if (
      nextProps.loadHistoryStatus.status == 'success' ||
      (this.props.selectedField && !nextProps.selectedField) ||
      (this.props.selectedField &&
        nextProps.selectedField &&
        JSON.stringify(this.props.selectedField) !=
          JSON.stringify(nextProps.selectedField))
    ) {
      this.loadNitrogenData();
    }
  }

  loadNitrogenData() {
    let that = this;
    let one_month_in_unx = 60 * 60 * 24 * 30;
    let unix_from_sow =
      dateToUnixTimestamp(getCurrentSeasonStartDate(this.props.selectedField)) -
      one_month_in_unx;
    loadFieldRecords(
      this.props.selectedField.id,
      unix_from_sow,
      ['n_in_soil', 'n_leached', 'n_in_plant'],
      ['', '', ''],
    ).then(
      function (res) {
        let nitrogenData = {};
        for (let currentDataSetIndex in res) {
          let currentDataSet = res[currentDataSetIndex];
          let Items = Object.keys(currentDataSet.values);

          nitrogenData[currentDataSetIndex] = Items.map((sampleKey, index) => {
            let currentSample = currentDataSet.values[sampleKey];

            return {
              value: parseFloat(currentSample),
              date: moment
                .unix(sampleKey)
                .tz(that.props.selectedField.geo_data.time_zone),
              index: index,
            };
          });
        }
        let earliest_nitrogen_date =
          nitrogenData && 'n_in_plant' in nitrogenData
            ? nitrogenData['n_in_plant'][0].date
            : getCurrentSeasonStartDate(that.props.selectedField);
        let latest_nitrogen_data =
          nitrogenData && 'n_in_plant' in nitrogenData
            ? nitrogenData['n_in_plant'][nitrogenData['n_in_plant'].length - 1]
                .date
            : moment().unix();
        nitrogenData['Invisible Ticks Line'] = generateDates(
          earliest_nitrogen_date,
          latest_nitrogen_data,
          60 * 60 * 4,
        ).map((dateSample, index) => {
          return {
            // NaN value to graph to avoid from tooltip jumps
            value: NaN,
            date: dateSample,
            index: index, // Calculate the index
          };
        });

        that.setState({ nitrogenData: nitrogenData }, that.reloadGraph);
      },
      function (error) {
        console.log(error);
      },
    );
  }

  reloadGraph() {
    let dataToDisplay = {};

    if (this.state.shouldDisplayInSoil) {
      dataToDisplay['in soil'] = new GeneralClasses.Sensor(
        {
          name: 'In Soil',
          displayName: 'In Soil',
          data: this.state.nitrogenData['n_in_soil'],
          color: '#3F83F7',
          coordinates: { latitude: '23.2222', longitude: '23.2222' },
          settings: { is_active: true },
        },
        'In Soil',
      );
    }

    if (this.state.shouldDisplayInPlant) {
      dataToDisplay['in plant'] = new GeneralClasses.Sensor(
        {
          name: 'In Plant',
          displayName: 'In Plant',
          data: this.state.nitrogenData['n_in_plant'],
          color: '#00C1A7',
          coordinates: { latitude: '23.2222', longitude: '23.2222' },
          settings: { is_active: true },
        },
        'In Plant',
      );
    }

    if (this.state.shouldDisplayLeached) {
      dataToDisplay['leached'] = new GeneralClasses.Sensor(
        {
          id: 'sss',
          name: 'Leached',
          displayName: 'Leached',
          data: this.state.nitrogenData['n_leached'],
          color: '#EF9331',
          coordinates: { latitude: '23.2222', longitude: '23.2222' },
          settings: { is_active: true },
        },
        'Leached',
      );
    }

    dataToDisplay['Invisible Ticks Line'] = new GeneralClasses.Sensor(
      {
        id: 'Invisible Ticks Line',
        data: this.state.nitrogenData['Invisible Ticks Line'],
        name: 'Invisible Ticks Line',
        color: '#FFFFFF',
        coordinates: { latitude: '23.2222', longitude: '23.2222' },
        settings: { is_active: false },
      },
      'Invisible Ticks Line',
    );

    // Add
    dataToDisplay['wfr'] = this.props.selectedField.historical_data['wfr'];
    dataToDisplay['fert'] = this.props.selectedField.historical_data['fert'];

    let temperatureStatistics = {};
    let etcStatistics = {};

    if (this.props.selectedField.historical_data.temperature_h) {
      temperatureStatistics = weatherManager.weatherDataStatistics(
        this.props.selectedField.historical_data.temperature_h.values,
        this.props.selectedField.geo_data.time_zone,
      );
      etcStatistics = weatherManager.weatherDataStatistics(
        this.props.selectedField.historical_data.etc?.values,
        this.props.selectedField.geo_data.time_zone,
      );
    }

    // dataToDisplay['wfr'] = this.props.selectedField.historical_data['wfr'];
    this.sensor_graph.loadData(
      dataToDisplay,
      this.props.selectedField.historical_data,
      0,
      this.props.selectedField.geo_data.time_zone,
      temperatureStatistics,
      etcStatistics,
    );
  }

  updateGraphDisplay(statetoChange, newValue) {
    this.setState({ [statetoChange]: newValue }, this.reloadGraph);
  }

  precipitationDeleted(precipitationDate, source) {
    var that = this;
    let deletePrecipitation;
    if (source == 'manual') {
      deletePrecipitation = deleteFieldRecord;
      source = this.props.selectedField.id;
    } else {
      deletePrecipitation = weatherManager.deleteWeatherRecord;
    }

    deletePrecipitation(source, precipitationDate, 'precipitation_h').then(
      function (res) {
        that.props.getFieldHistory(
          that.props.selectedDistribution.id,
          that.props.selectedFarmingUnit.id,
          that.props.selectedField.id,
        );
        mixpanel.track('Precipitation Deleted', {
          Source: source,
          Date: precipitationDate,
        });
      },
      function (error) {
        console.log(error);
      },
    );
  }

  closeInputFrame(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      isIrrigationdrawerOpen: false,
      isNitrogenDrawerOpen: false,
    });
  }

  handleNitrogenInputDisplay(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      isIrrigationdrawerOpen: false,
      isNitrogenDrawerOpen: !this.state.isNitrogenDrawerOpen,
    });
  }

  handleIrrigationInputDisplay(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      isIrrigationdrawerOpen: !this.state.isIrrigationdrawerOpen,
      isNitrogenDrawerOpen: false,
    });
  }

  render() {
    const { t } = this.props;

    let sensorsInLoadingState = false;

    let graphTitle = this.props.onToggleClick
      ? t('Nitrogen graph')
      : t('fertilization_history');

    let userUnits =
      this.props.userData && 'weight' in this.props.userData
        ? unitDisplayName(
            this.props.userData.weight + 'N/' + this.props.userData.area,
          )
        : '';

    return (
      <div className='d-flex flex-column w-100 h-100'>
        <div
          className='d-flex justify-content-between'
          style={{ paddingLeft: '63px', paddingRight: '63px' }}
        >
          <div className='supporting_data d-flex d-inline align-items-center mr-2'>
            {this.props.onToggleClick ? (
              <GraphToggle
                initialValue='nitrogen'
                onToggleClick={(toggle) => this.props.onToggleClick(toggle)}
                icon={this.props.icon}
              />
            ) : (
              <div></div>
            )}
          </div>

          <div className='text-grey45 text-size-16 font-weight-bold ml-3 d-flex align-items-center'>
            {graphTitle}{' '}
            {sensorsInLoadingState && (
              <span
                className='spinner-border text-grey45  ml-2'
                role='status'
                style={{ width: '15px', height: '15px' }}
              ></span>
            )}
          </div>

          <div className='mt-sm-0 p-0 d-flex align-items-center justify-content-lg-end text-size-14 text-blue-grey font-weight-normal'>
            <div className='custom-checkbox position-relative mr-2 d-flex align-items-center'>
              <input
                type='checkbox'
                checked={this.state.shouldDisplayInSoil}
                onChange={() =>
                  this.updateGraphDisplay(
                    'shouldDisplayInSoil',
                    !this.state.shouldDisplayInSoil,
                  )
                }
              />
              <span className='check-blue '></span> &nbsp;{t('soil')}
            </div>

            <div className='custom-checkbox position-relative mx-2 d-flex align-items-center'>
              <input
                type='checkbox'
                className=''
                checked={this.state.shouldDisplayInPlant}
                onChange={() =>
                  this.updateGraphDisplay(
                    'shouldDisplayInPlant',
                    !this.state.shouldDisplayInPlant,
                  )
                }
              />
              <span className='check-green'></span> &nbsp;{t('plant')}
            </div>

            <div className='custom-checkbox position-relative ml-2 align-items-center d-flex align-items-center'>
              <input
                type='checkbox'
                className=''
                checked={this.state.shouldDisplayLeached}
                onChange={() =>
                  this.updateGraphDisplay(
                    'shouldDisplayLeached',
                    !this.state.shouldDisplayLeached,
                  )
                }
              />
              <span className='check-orange'></span> &nbsp;{t('leached')}
            </div>
          </div>
        </div>

        <div className=' flex-grow-1'>
          <div className='d-flex h-100'>
            <div className='flex-fill' style={{ width: '40%' }}>
              <SensorGraph
                onRef={(ref) => (this.sensor_graph = ref)}
                onGraphUpdate={this.onGraphUpdate}
                units={userUnits}
                isLoading={sensorsInLoadingState}
                selectedField={this.props?.selectedField}
                selectedFarmingUnit={this.props?.selectedFarmingUnit}
                selectedDistribution={this.props?.selectedDistribution}
                onPrecipitationDeletion={this.precipitationDeleted}
                shouldDrawTodayMark={false}
                shouldDrawBottom={true}
                shouldDrawInputs={true}
                shouldDrawResetButton={true}
                toolTipMode={'index'}
                distributionMode={'series'}
                shouldDisplayZoom={true}
                shouldSpaceData={false}
                shouldDiplayUTC={false}
                shouldScaleGraph={false}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    selectedField: state.distribution_data.selected_entities.field,
    selectedFarmingUnit: state.distribution_data.selected_entities.farming_unit,
    loadHistoryStatus: state.distribution_data.field_load_history,
    selectedDistribution:
      state.distribution_data.selected_entities.distribution,
    userData: state.authentication_data.user_data,
  };
};

const mapDispatchToProps = {
  setSelectedEntities: distributionActions.selected_entities,
  getFieldHistory: distributionActions.get_field_history,
};

module.exports = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(NitrogenGraphContainer));
