import moment from "moment";
import { initiate_distribution_action } from "./distributionActions";
var axios = require("axios");
var { error_message } = require("@managers/helpers");
var distributionActions = require("@actions/distributionActions");
var fertilizerDataActions = require("@actions/fertilizerDataActions");
var remoteConfigurationActions = require("@actions/remoteConfigurationActions");
var notificationsManager = require("@managers/notificationsManager");
var { getMixpanelInstance } = require("@managers/mixpanelManager");

const SCIROOT_USER_PATH = "user";
const SCIROOT_USERDATA_PATH = "userdata";
const SCIROOT_USERDATA_CONFIGURATION_PATH = "userdata_field_configuration";

const units_details = {
  metric_india: {
    area: "Acre",
    length: "mm",
    pressure: "CBar",
    temperature: "Celsius",
    volume: "Cubic Metre",
    weight: "Kg",
  },
  metric_israel: {
    area: "Dunam",
    length: "mm",
    pressure: "CBar",
    temperature: "Celsius",
    volume: "Cubic Metre",
    weight: "Kg",
  },
  metric_australia: {
    area: "Hectare",
    length: "mm",
    pressure: "CBar",
    temperature: "Celsius",
    volume: "Megaliter",
    weight: "Kg",
  },
  metric: {
    area: "Hectare",
    length: "mm",
    pressure: "CBar",
    temperature: "Celsius",
    volume: "Cubic Metre",
    weight: "Kg",
  },
  imperial: {
    area: "Acre",
    length: "Inch",
    pressure: "CBar",
    temperature: "Fahrenheit",
    volume: "Acre Foot",
    weight: "lb",
  },
};

export var get_user_data = (userData) => {
  return (dispatch, getState) => {
    dispatch(initiate_set_user_attributes());

    axios
      .get(SCIROOT_USERDATA_PATH, userData)
      .then(function (res) {
        var user_details = {
          ...res.data.units,
          ...units_details[res.data.units.unit_system],
          ...res.data.settings,
          distributions: { ...res.data.distributions },
          meta_data: { ...res.data.meta_data },
          created_at: res.data.created_at,
        };

        // Sending User Creation date to Mixpanel
        let mixpanelInstance = getMixpanelInstance();

        if (user_details.created_at) {
          let createdAtDate = moment.unix(parseInt(user_details.created_at));
          let formattedCreatedAtTime = createdAtDate.format(
            "YYYY-MM-DD HH:mm:ss",
          );

          mixpanelInstance.people.set({
            "User Creation Date": formattedCreatedAtTime,
          });
        }

        dispatch(get_user_data_success(user_details));
        dispatch(remoteConfigurationActions.get_remote_config());
      })
      .catch(function (err) {
        console.log(error_message(err));
        dispatch(set_user_attributes_fail(error_message(err)));
      });
  };
};

// It's a new version of set user attributes, and will probably remove the set_user_attributes in the future
export var set_user_data = (userData) => {
  return (dispatch, getState) => {
    dispatch(initiate_set_user_attributes());

    axios
      .put(SCIROOT_USERDATA_PATH, { dataDictionary: userData })
      .then(function (res) {
        var user_details = res.data;
        dispatch(get_user_data_success(user_details));
        dispatch(remoteConfigurationActions.get_remote_config());
        dispatch(distributionActions.load_distributions(true));
        dispatch(fertilizerDataActions.load_fertilizer_data(true));
      })
      .catch(function (err) {
        console.log(error_message(err));
        dispatch(set_user_attributes_fail(error_message(err)));
      });
  };
};

export var set_user_meta_data = (userData) => {
  return (dispatch, getState) => {
    dispatch(initiate_set_user_attributes());

    axios
      .put(SCIROOT_USERDATA_PATH, { dataDictionary: userData })
      .then(function (res) {
        var user_details = res.data;
      })
      .catch(function (err) {
        console.log(error_message(err));
        dispatch(set_user_attributes_fail(error_message(err)));
      });
  };
};

export var set_field_configuration = (
  distribution_id,
  farming_unit_id,
  fields_ids,
  configuration_object,
) => {
  return (dispatch, getState) => {
    dispatch(initiate_set_user_attributes());

    axios
      .put(SCIROOT_USERDATA_CONFIGURATION_PATH, {
        distribution_id: distribution_id,
        farming_unit_id: farming_unit_id,
        fields_ids: fields_ids,
        configuration_object: configuration_object,
      })
      .then(function (res) {})
      .catch(function (err) {
        console.log(error_message(err));
        dispatch(set_user_attributes_fail(error_message(err)));
      });
  };
};

// Middleware
export var set_user_attributes = (attributes) => {
  return (dispatch, getState) => {
    dispatch(initiate_set_user_attributes());

    axios
      .put(SCIROOT_USER_PATH, attributes)
      .then(function (res) {
        var user_details = res.data.user_details;

        dispatch(set_user_attributes_success(user_details));
      })
      .catch(function (err) {
        console.log(error_message(err));
        dispatch(set_user_attributes_fail(error_message(err)));
      });
  };
};

export var get_notifications_for_user = () => {
  return (dispatch, getState) => {
    dispatch(initiate_get_user_notifications());

    notificationsManager
      .getNotificationsForUser(moment().unix())
      .then((res) => {
        dispatch(get_user_notifications_success(res));
      })
      .catch(function (err) {
        console.log(error_message(err));
        dispatch(get_user_notifications_fail(err));
      });
  };
};

export var set_discharge_test = (field_id, discharge_test) => {
  return (dispatch, getState) => {
    dispatch(initiate_discharge_test(field_id, discharge_test));
  };
};

export var initiate_discharge_test = (field_id, discharge_test) => {
  return {
    type: "SET_DISCHARGE_TEST",
    field_id: field_id,
    discharge_test: discharge_test,
  };
};

export var initiate_get_user_notifications = () => {
  return {
    type: "GET_USER_NOTIFICATIONS_INIT",
  };
};

export var get_user_notifications_success = (userNotifications) => {
  return {
    type: "GET_USER_NOTIFICATIONS_SUCCESS",
    user_notifications: userNotifications,
  };
};

export var get_user_notifications_fail = (get_user_notifications_error) => {
  return {
    type: "GET_USER_ATTRIBUTES_FAIL",
    get_user_notifications_error,
  };
};

export var initiate_set_user_attributes = () => {
  return {
    type: "SET_USER_ATTRIBUTES_INIT",
  };
};

export var set_user_attributes_success = (user_details) => {
  return {
    type: "SET_USER_ATTRIBUTES_SUCCESS",
    user_details: user_details,
  };
};

export var set_user_attributes_fail = (set_user_attributes_error) => {
  return {
    type: "SET_USER_ATTRIBUTES_FAIL",
    set_user_attributes_error,
  };
};

export var get_user_data_success = (userData) => {
  return {
    type: "GET_USER_DATA_SUCCESS",
    userData: userData,
  };
};

export var set_user_settings = (has_previewed) => {
  return {
    type: "HIDDEN_ITEM_HAS_PREVIEWED",
    has_previewed,
  };
};
