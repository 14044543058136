var React = require('react');
var { Link } = require('react-router-dom');
var { withTranslation } = require('react-i18next');

class NavigationLine extends React.Component {
  constructor(props) {
    super(props);

    this.setupNavigationLine();
  }

  setupNavigationLine() {
    var path_name_state = '';

    var current_path = this.props.history.location.pathname;
    var path_parts = current_path.split('/');

    if (path_parts.length > 1) {
      if (path_parts[1] == '') {
        sub_path_name = 'My Fields';
      } else {
        sub_path_name = path_parts[1].match(/[A-Z][a-z]+|[0-9]+/g).join(' ');
      }
    }

    this.state = {
      home_path_name: <Link to='/'>{this.props.t('home')}</Link>,
      sub_path_name: sub_path_name,
    };
  }

  render() {
    return (
      <div>
        {this.state.home_path_name} -> {this.state.sub_path_name}
      </div>
    );
  }
}

module.exports = withTranslation()(NavigationLine);
