var React = require('react');
var { connect } = require('react-redux');
var { timeSinceNowString } = require('helpers');
var moment = require('moment');
var distributionActions = require('distributionActions');
var IrrigationMeter = require('IrrigationMeter');
var { withTranslation, useTranslation } = require('react-i18next');
var { calculateVolume, unitDisplayName, convert } = require('unitsManager');
var fieldManager = require('fieldManager');
const { useState, useEffect } = require('react');

const IrrigationMeterDetailsContainer = (props) => {
  const [gauge, setGauge] = useState(null);
  const [shouldDisplayVolume, setShouldDisplayVolume] = useState(false);
  const [noNeedToIrrigateMessage, setNoNeedToIrrigateMessage] =
    useState(undefined);
  const [irrigationAmountMessage, setIrrigationAmountMessage] = useState('');
  const [irrigationVolumeMessage, setIrrigationVolumeMessage] = useState('0');
  const [stressLevel, setStressLevel] = useState(0);
  const [recommendation_date, setRecommendationDate] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (props.selectedField) {
      updateGauge(props.selectedField);
    }
  }, [props.loadHistoryStatus.status, props.selectedField, stressLevel]);

  const onMouseover = (e) => {
    setShouldDisplayVolume(true);
  };

  const onMouseout = (e) => {
    setShouldDisplayVolume(false);
  };

  const updateGauge = (field) => {
    let recommendation_amount = -1;
    let stress_level = -1;
    let accumulatedETc = -1;
    let recommendation_date = moment();
    let recommendationAmountVolume = -1;
    let accumulatedETcVolume = -1;
    let area = undefined;
    let fieldAreaConverted = undefined;
    let messageByStressLevel = 'Normal';

    if (field) {
      let fieldItems = fieldManager.getFieldItems(field);
      stress_level = fieldItems.stressDataItem.value;
      recommendation_amount = fieldItems.irrigationDataItem.value;
      recommendation_date = fieldItems.irrigationDataItem.date;
      accumulatedETc = fieldItems.accumulatedETc.value;
      messageByStressLevel =
        stress_level === -1
          ? 'Normal'
          : stress_level === 1
            ? 'Normal'
            : stressLevel < 0.2
              ? 'High'
              : stressLevel < 0.4
                ? 'Medium'
                : 'Normal';
      area = fieldManager.getFieldArea(field.geo_data.areas);
      fieldAreaConverted = convert(area, 'dunam', props.userData.area, 'area');
      recommendationAmountVolume = calculateVolume(
        fieldAreaConverted,
        props.userData['area'],
        recommendation_amount,
        props.userData['length'],
        props.userData['volume'],
      );
      accumulatedETcVolume = calculateVolume(
        fieldAreaConverted,
        props.userData['area'],
        accumulatedETc,
        props.userData['length'],
        props.userData['volume'],
      );
    }

    gauge?.updateMeterValue(stress_level);

    var volumeMessageObject = fieldManager.get_stress_message(
      stress_level,
      recommendationAmountVolume,
      accumulatedETcVolume,
      recommendation_date,
      unitDisplayName(props.userData['volume']),
    );
    var messageObject = fieldManager.get_stress_message(
      stress_level,
      recommendation_amount,
      accumulatedETc,
      recommendation_date,
      props.userData['length'],
    );

    let irrigationMessage = undefined;
    let irrigationVolumeMessage = undefined;
    let noNeedToIrrigateMessage = undefined;

    if (messageObject['irrigationAmountMessage'] === 'good') {
      noNeedToIrrigateMessage = (
        <div
          className='font-weight-bold text-blue-secondary bg-grey96 font-weight-bold text-size-14 align-items-center d-flex'
          style={{
            border: '1px solid #eef1f6',
            lineHeight: '1',
            borderRadius: '6px',
            gap: '4px',
            inlineSize: 'max-content',
            height: '24px',
            padding: '3px 8px 3px 8px',
          }}
        >
          {'0 mm' + ' - ' + messageByStressLevel}{' '}
        </div>
      );
    } else if (messageObject['irrigationAmountMessage']) {
      irrigationMessage = messageObject['irrigationAmountMessage'];
      irrigationVolumeMessage = volumeMessageObject['irrigationAmountMessage'];
    }

    let timeAgo = timeSinceNowString(moment.unix(recommendation_date));

    if (irrigationMessage) {
      irrigationMessage = irrigationMessage + ' - ' + messageByStressLevel;
    }

    if (irrigationVolumeMessage) {
      irrigationVolumeMessage =
        irrigationVolumeMessage + ' - ' + messageByStressLevel;
    }

    setStressLevel(stress_level);
    setNoNeedToIrrigateMessage(noNeedToIrrigateMessage);
    setIrrigationAmountMessage(irrigationMessage);
    setIrrigationVolumeMessage(irrigationVolumeMessage);
    setRecommendationDate(timeAgo);
  };

  let recommendationElement = <div></div>;

  if (noNeedToIrrigateMessage) {
    recommendationElement = (
      <div>
        <div className=' text-center '>
          <div className=''>{noNeedToIrrigateMessage}</div>
        </div>
      </div>
    );
  } else if (irrigationAmountMessage) {
    recommendationElement = (
      <div className='align-self-center'>
        <div className='text-center'>
          {shouldDisplayVolume ? (
            <div>
              <div
                className='font-weight-bold text-blue-secondary bg-grey96 font-weight-bold text-size-14 align-items-center justify-content-center flex-row d-flex w-100'
                style={{
                  border: '1px solid #eef1f6',
                  lineHeight: '1',
                  borderRadius: '5px',
                  padding: '3px 8px 3px 8px',
                  gap: '4px',
                  whiteSpace: 'nowrap',
                }}
                onMouseEnter={onMouseover}
                onMouseLeave={onMouseout}
              >
                {irrigationVolumeMessage}{' '}
              </div>
            </div>
          ) : (
            <div
              className='text-blue-secondary bg-grey96 font-weight-bold align-items-center justify-content-center text-size-14 w-100'
              onMouseEnter={onMouseover}
              onMouseLeave={onMouseout}
              style={{
                border: '1px solid #eef1f6',
                lineHeight: '1',
                borderRadius: '5px',
                padding: '3px 8px 3px 8px',
                gap: '4px',
              }}
            >
              {irrigationAmountMessage}
            </div>
          )}
        </div>
      </div>
    );
  } else {
    recommendationElement = (
      <div
        className='text-size-14 text-center text-grey71 font-weight-bold'
        style={{
          lineHeight: '17px',
        }}
      >
        {t('no_recommendation')}
      </div>
    );
  }

  return (
    <div
      className='d-flex  justify-content-center align-items-center bg-white gaugeContainer'
      style={{
        maxWidth: '574px',
        borderRadius: '15px',
        width: '380px',
        minHeight: '52px',
        gap: '24px',
        padding: '8px 24px 8px 24px',
        marginLeft: '8px',
      }}
    >
      <div
        className='d-flex flex-column justify-content-center gaugeTitlesContainer'
        style={{ height: '34px', width: '203px', gap: '4px' }}
      >
        <div
          className='text-grey45 text-size-14 text-truncate'
          style={{ height: '18px', lineHeight: '17px', width: '120px' }}
        >
          {t('soil_water_deficit')}
        </div>
        {recommendation_date && stressLevel !== -1 && (
          <div
            className='text-grey71 text-size-12 text-truncate lastUpdatedGaugeText'
            style={{ height: '15px', lineHeight: '90%', width: '105px' }}
          >
            {t('updated') +
              ' ' +
              t(recommendation_date[1]).format(recommendation_date[0])}
          </div>
        )}
      </div>
      <div
        className='d-flex align-items-center flex-row'
        style={{ width: '222px', height: '42px', gap: '10px' }}
      >
        <div
          className='d-flex align-items-center justify-content-center text-center'
          style={{ height: '24px', width: '138px', gap: '4px' }}
        >
          {recommendationElement}
        </div>
        <div
          className='d-flex flex-column align-items-center justify-content-center'
          style={{ height: '40px' }}
        >
          <IrrigationMeter
            onRef={(ref) => setGauge(ref)}
            shouldDisplayInFieldDetails={true}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    selectedField: state.distribution_data.selected_entities.field,
    userData: state.authentication_data.user_data,
    loadHistoryStatus: state.distribution_data.farming_unit_load_history,
  };
};

const mapDispatchToProps = {
  setSelectedEntities: distributionActions.selected_entities,
};

module.exports = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(IrrigationMeterDetailsContainer));
