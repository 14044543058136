var React = require('react');
var NavigationLine = require('NavigationLine');
var FarmingUnitsTable = require('FarmingUnitsTable');
var { connect } = require('react-redux');
var BaseComponent = require('BaseComponent');
var DistributionsDropdown = require('DistributionsDropdown');
var UpdateFarmingUnitModal = require('UpdateFarmingUnitModal');
var { withTranslation } = require('react-i18next');
var {
  get_configuration_config,
  FeatureFlagDictionary,
} = require('remoteConfigurationsManager');

class FarmingUnitsManagement extends BaseComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { t } = this.props;

    var farmingUnitsModalId = 'farmingUnitsCreateModalId';

    var current_distribution_id = '';
    if (this.props.selected_distribution) {
      current_distribution_id = this.props.selected_distribution.id;
    }

    let featureFlag = get_configuration_config(
      this.props.configuration_data,
      FeatureFlagDictionary.EDIT_FARMING_UNIT,
      this.props.selected_farming_unit?.name,
      this.props.selected_distribution?.name,
    );

    return (
      <div>
        {/* Modal Section */}
        <UpdateFarmingUnitModal modalId={farmingUnitsModalId} />

        {/* Rest of Page */}
        <div className='container pt-5'>
          <div className='row justify-content-center'>
            <div className='col-12'>
              <h1>{t('management_farming_units_management')}</h1>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-10 col-md-9 col-sm-8 align-self-center'>
              <NavigationLine history={this.props.history} />
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4'>
              <DistributionsDropdown />
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-12 align-self-center mt-3'>
              <FarmingUnitsTable modalId={farmingUnitsModalId} />
            </div>
          </div>
          <div className='row justify-content-center mt-3 mb-5'>
            <div className='col-12 text-center '>
              {!featureFlag && (
                <button
                  className='btn btn-dark-green btn-lg general-button'
                  data-toggle='modal'
                  data-target={'#' + farmingUnitsModalId}
                  data-name=''
                  data-id=''
                  data-distributionid={current_distribution_id}
                  disabled={
                    this.props.selected_distribution &&
                    this.props.selected_distribution.role.toLowerCase() ==
                      'viewer'
                  }
                >
                  {t('create')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    user_details: state.authentication_data.user_details,
    selected_distribution:
      state.distribution_data.selected_entities.distribution,
    selected_farming_unit:
      state.distribution_data.selected_entities.farming_unit,
    configuration_data: state.configuration_data,
  };
};

module.exports = connect(mapStateToProps)(
  withTranslation()(FarmingUnitsManagement),
);
