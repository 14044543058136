var React = require('react');
var { connect } = require('react-redux');
var $ = require('jquery');
var { withTranslation } = require('react-i18next');

class ApprovalModal extends React.Component {
  constructor(props) {
    super(props);

    this.onApproveButtonClick = this.onApproveButtonClick.bind(this);
  }

  componentDidMount() {
    var that = this;

    $('#' + this.props.modalId).on('show.bs.modal', function (event) {
      var button = $(event.relatedTarget); // Button that triggered the modal
      var values = button[0].dataset.values.split(',');

      that.setState({ values: values });
    });

    $('#' + this.props.modalId).on('hide.bs.modal', function (event) {
      if (that.props.onDismiss) {
        that.props.onDismiss();
      }
    });
  }

  onApproveButtonClick(e) {
    e.preventDefault();
    e.stopPropagation();

    this.props.onApprove(this.state.values);
    $('#' + this.props.modalId).modal('hide');
  }

  render() {
    const { t } = this.props;

    return (
      <div
        className='modal fade'
        id={this.props.modalId}
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                {t('confirmation')}
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body text-center'>{this.props.message}</div>
            <div className='modal-footer'>
              <div className='container text-center'>
                <button
                  type='button'
                  className='btn btn-dark-green btn-md general-button mr-3'
                  onClick={this.onApproveButtonClick}
                >
                  {t('yes')}
                </button>
                <button
                  type='button'
                  className='btn btn-danger btn-md general-button ml-3'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  {t('no')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

module.exports = connect()(withTranslation()(ApprovalModal));
