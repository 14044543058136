var axios = require("axios");
var dialPrefixes = require("dialPrefixes");

export var get_dial_code_prefixes = () => {
  var all_dial_prefixes = [];
  for (var index in dialPrefixes) {
    var current_country = dialPrefixes[index];

    var name = current_country["name"]["common"];
    var dial_prefixes = current_country["callingCode"];

    for (var current_prefix_index in dial_prefixes) {
      var key = dial_prefixes[current_prefix_index];

      all_dial_prefixes.push({
        name: name + " (+" + dial_prefixes[current_prefix_index] + ")",
        prefix: key,
        country: name,
      });
    }
  }

  return all_dial_prefixes;
};

export var generate_phone_number = (prefix, phone_number) => {
  if (!phone_number || !prefix) {
    return "";
  }

  var phone_number_digits = phone_number.replace(/[-()+*#]/g, "");

  var final_phone_number = "+" + prefix;
  var cutting_index = 0;

  if (phone_number.charAt(0) == "0") {
    cutting_index = 1;
  }

  final_phone_number =
    final_phone_number +
    phone_number_digits.substring(cutting_index, phone_number_digits.length);

  return final_phone_number;
};
