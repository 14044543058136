var React = require('react');
var NavigationLine = require('NavigationLine');
var DistributionUnitsTable = require('DistributionUnitsTable');
var { connect } = require('react-redux');
var { Link } = require('react-router-dom');
var BaseComponent = require('BaseComponent');
var distributionActions = require('distributionActions');
var { withTranslation } = require('react-i18next');
var $ = require('jquery');

class UpdateDistributionUnitModal extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmitClick = this.onSubmitClick.bind(this);

    this.state = { distribution_id: '', title: '', action: '' };
  }

  componentDidMount() {
    var that = this;

    $('#' + this.props.modalId).on('show.bs.modal', function (event) {
      var form = document.getElementById('distribution-form');
      form.classList.remove('was-validated');

      var button = $(event.relatedTarget); // Button that triggered the modal
      var distribution_name = button[0].dataset.name; // Extract info from data-* attributes
      var distribution_region = button[0].dataset.region; // Extract info from data-* attributes
      var distribution_id = button[0].dataset.id; // Extract info from data-* attributes

      that.refs.distribution_name.value = distribution_name;

      that.setState({ distribution_id: distribution_id });

      if (distribution_id == '') {
        that.setState({ title: 'Create' });
        that.setState({ action: 'Create' });
      } else {
        that.setState({ title: 'Update' });
        that.setState({ action: 'Update' });
      }
    });

    $('#' + this.props.modalId).on('hidden.bs.modal', function (event) {
      that.props.clearDistributionActions();
    });
  }

  onSubmitClick(e) {
    e.preventDefault();
    e.stopPropagation();

    var form = document.getElementById('distribution-form');

    // Local check (given by bootstrap)
    if (form.checkValidity() == true) {
      if (this.state.action == 'Create') {
        this.props.createDistribution(this.refs.distribution_name.value);
      } else if (this.state.action == 'Update') {
        this.props.updateDistribution(
          this.state.distribution_id,
          this.refs.distribution_name.value,
        );
      }
    }
    form.classList.add('was-validated');
  }

  render() {
    const { t } = this.props;

    if (
      this.props.distribution_actions.status == 'success' &&
      ['UPDATE', 'CREATE'].includes(this.props.distribution_actions.type)
    ) {
      $('#' + this.props.modalId).modal('hide');
    }

    return (
      <div
        className='modal fade'
        id={this.props.modalId}
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <form
          className='needs-validation'
          noValidate
          onSubmit={this.onSubmitClick}
          id='distribution-form'
        >
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {this.state.title} {t('distribution_unit')}
                </h5>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <div className='form-group ml-2 mr-2 mt-3 text-light-blue'>
                  <label htmlFor='distributionName'>
                    {t('management_distribution_name')}
                  </label>
                  <input
                    type='text'
                    ref='distribution_name'
                    className='form-control'
                    id='distribution_name_input'
                    aria-describedby='emailHelp'
                    placeholder={t('management_distribution_name')}
                    required
                  />
                  <div className='invalid-feedback'>
                    {t('management_enter_a_valid_distribution_name')}
                  </div>
                </div>
                <div className='text-red text-center'>
                  {this.props.distribution_actions.status == 'fail' &&
                    this.props.distribution_actions.error}
                </div>
              </div>
              <div className='modal-footer'>
                <div className='container text-center'>
                  <button
                    type='submit'
                    className='btn btn-dark-green btn-lg general-button'
                  >
                    {this.state.title}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    distribution_actions: state.distribution_data.distribution_actions,
  };
};

const mapDispatchToProps = {
  createDistribution: distributionActions.create_distribution,
  updateDistribution: distributionActions.update_distribution,
  clearDistributionActions: distributionActions.clear_distribution_action,
};

module.exports = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(UpdateDistributionUnitModal));
