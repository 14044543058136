import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ModalContainer, ModalContent, SuccessMessage } from './EventModal';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import $ from 'jquery';
var { get_field_history } = require('distributionActions');
var { convert } = require('unitsManager');
var { getDensity } = require('nitrogenManager');
var { upper, getEarlyAdoptersAccess } = require('helpers');
var sensorsManager = require('sensorsManager');
var fieldManager = require('fieldManager');

export const ReportEventModal = (props) => {
  const [eventType, setEventType] = useState('irrigation');
  const [shouldDisplaySuccessMessage, setShouldDisplaySuccessMessage] =
    useState(false);
  const successMessageStyle = {
    width: '400px',
    position: 'absolute',
    bottom: '86px',
    right: '22px',
  };

  const cancelButtonRef = useRef(null);

  const handleOutsideClick = () => {
    if (cancelButtonRef.current) {
      return cancelButtonRef.current.click();
    }
  };

  return (
    <ModalContainer
      style={shouldDisplaySuccessMessage ? successMessageStyle : undefined}
      id={'reportEventModal'}
      handleClose={handleOutsideClick}
    >
      {shouldDisplaySuccessMessage ? (
        <SuccessMessage t={props.t} eventType={eventType} status={'success'} />
      ) : (
        <ReportEventModalContent
          t={props.t}
          eventType={eventType}
          setEventType={setEventType}
          setShouldDisplaySuccessMessage={setShouldDisplaySuccessMessage}
          ref={cancelButtonRef}
        />
      )}
    </ModalContainer>
  );
};

const ReportEventModalContent = forwardRef(
  ({ t, eventType, setEventType, setShouldDisplaySuccessMessage }, ref) => {
    const [measurement, setMeasurement] = useState('duration');
    const [reportedData, setReportedData] = useState({});
    const [enableAddingWTR, setEnableAddingWTR] = useState(0);
    const [duringInput, setDuringInput] = useState(false);

    const selectedDistribution = useSelector(
      (state) => state.distribution_data.selected_entities.distribution,
    );
    const selectedFarmingUnit = useSelector(
      (state) => state.distribution_data.selected_entities.farming_unit,
    );
    const selectedField = useSelector(
      (state) => state.distribution_data.selected_entities.field,
    );
    const userData = useSelector(
      (state) => state.authentication_data.user_data,
    );
    const userDetails = useSelector(
      (state) => state.authentication_data.user_details,
    );
    const dispatch = useDispatch();
    let hasAccessToAddDischarge =
      getEarlyAdoptersAccess(userDetails?.email) &&
      selectedField?.irrigation_system?.irrigation_type === 'ndrip';

    useEffect(() => {
      clearStates();
    }, [eventType, clearStates]);

    useEffect(() => {
      setEventType('irrigation');
    }, [selectedField]);

    const clearStates = useCallback(() => {
      setReportedData({
        startTime: moment(),
        duration: 0,
        value: 0,
      });
      setDuringInput(false);
    }, []);

    function finishReport() {
      clearStates();
      setShouldDisplaySuccessMessage(true);

      setTimeout(function () {
        $('#reportEventModal').modal('hide');
        setTimeout(function () {
          setShouldDisplaySuccessMessage(false);
        }, 500);
      }, 2000);

      dispatch(
        get_field_history(
          selectedDistribution.id,
          selectedFarmingUnit.id,
          selectedField.id,
        ),
      );
    }

    function reportIrrigation() {
      setDuringInput(true);
      if (enableAddingWTR) {
        reportWaterTreatment();
      }
      let startDate = moment(reportedData.startTime).format('X');
      let endDate = moment(reportedData.startTime)
        .add(reportedData.duration, 'hours')
        .format('X');

      let fieldArea = fieldManager.getSelectedFieldArea(
        selectedField,
        userData.area,
      );
      let valueLengthInMM = convert(
        reportedData.value,
        userData.length,
        'mm',
        'length',
      );
      let finalValueConverted = valueLengthInMM * fieldArea;

      sensorsManager
        .addSensorRecord(
          selectedField.id,
          'manual',
          startDate,
          'wfr',
          finalValueConverted,
          { end_date: endDate, reporter: userDetails?.email },
        )
        .then(
          function (res) {
            mixpanel.track('Irrigation Reported');
            finishReport();
          },
          function (error) {
            this.setState({ duringInput: false });
            console.log(error);
          },
        );
    }

    function reportRain() {
      setDuringInput(true);

      let startDate = moment(reportedData.startTime).format('X');
      let numberOfRainEvents = Math.ceil(reportedData.duration || 1);
      let amountPerEvent = reportedData.value / numberOfRainEvents;
      let promiseArray = [];

      for (let i = 0; i < numberOfRainEvents; i++) {
        promiseArray.push(
          fieldManager.addFieldRecord(
            selectedField.id,
            startDate.toString(),
            'precipitation_h',
            amountPerEvent.toString(),
            { source: 'manual', reporter: userDetails.email },
          ),
        );
        startDate = parseInt(startDate) + 60 * 60; // add 1 hour
      }

      Promise.all(promiseArray).then(
        function (res) {
          mixpanel.track('Rain Reported');
          finishReport();
        },
        function (error) {
          setDuringInput(false);
          console.log(error);
        },
      );
    }

    function reportFertilization() {
      let startDate = moment(reportedData.startTime).format('X');
      let value =
        parseFloat(reportedData?.fertAmount) *
        getDensity(reportedData?.fertType);

      let extraData = {};
      extraData['fertType'] = reportedData.fertType;
      extraData['source'] = 'manual';
      extraData['reporter'] = userDetails?.email;

      fieldManager
        .addFieldRecord(selectedField.id, startDate, 'fert', value, extraData)
        .then(
          function (res) {
            mixpanel.track('Fertilization Reported', {
              Type: reportedData.fertType,
            });
            finishReport();
          },
          function (error) {
            setDuringInput(false);
            console.log(error);
          },
        );
    }

    function reportDischarge() {
      setDuringInput(true);
      let startDate = moment(reportedData.startTime).format('X');
      sensorsManager
        .addSensorRecord(
          selectedField.id,
          'manual',
          startDate,
          'discharge_test',
          reportedData.value,
          { reporter: userDetails.email },
        )
        .then(
          function (res) {
            finishReport();
            mixpanel.track('Discharge Test Reported Successfully', {
              testResult: reportedData.value,
              testsAdded: reportedData.activeTests,
            });
          },
          function (error) {
            mixpanel.track('Discharge Test Failed', {
              testResult: reportedData.value,
              errorType: error,
            });
            console.log(error);
          },
        );
    }

    function reportWaterTreatment() {
      let startDate = moment(reportedData.startTime).format('X');
      let value = parseFloat(reportedData?.wtrAmount);

      let extraData = {};
      extraData['type'] = reportedData.wtrType;
      extraData['source'] = 'manual';
      extraData['reporter'] = userDetails?.email;

      fieldManager
        .addFieldRecord(selectedField.id, startDate, 'wtr', value, extraData)
        .then(
          function (res) {
            mixpanel.track('Water Treatment Reported', {
              Type: reportedData.wtrType,
            });
            finishReport();
          },
          function (error) {
            setDuringInput(false);
            console.log(error);
          },
        );
    }

    const onSaveClick = () => {
      if (!enableSave()) {
        return;
      }
      switch (eventType) {
        case 'irrigation':
          reportIrrigation();
          break;
        case 'rain':
          reportRain();
          break;
        case 'fertilization':
          reportFertilization();
          break;
        case 'discharge':
          reportDischarge();
        case 'water treatment':
          reportWaterTreatment();
          break;
      }
    };

    const enableSave = () => {
      if (eventType === 'irrigation') {
        let irr_reported =
          reportedData.duration > 0 &&
          reportedData.value > 0 &&
          !!reportedData.startTime;
        let wtr_reported = !!reportedData.wtrType && reportedData.wtrAmount > 0;
        return irr_reported && (!enableAddingWTR || wtr_reported);
      }
      if (eventType === 'rain') {
        return reportedData.value > 0 && !!reportedData.startTime;
      }
      if (eventType === 'fertilization') {
        return (
          reportedData.fertAmount > 0 &&
          !!reportedData.startTime &&
          !!reportedData.fertType
        );
      }
      if (eventType === 'discharge') {
        return reportedData.value > 0 && !!reportedData.startTime;
      }
      if (eventType === 'water treatment') {
        return (
          !!reportedData.wtrType &&
          reportedData.wtrAmount > 0 &&
          !!reportedData.startTime
        );
      }
      return false;
    };

    const EventChip = ({ type, title }) => {
      let mode = eventType == type ? 'selected' : 'notselected';
      return (
        <div
          className={`chip chip--${mode}`}
          style={hasAccessToAddDischarge ? {} : { width: 188 }}
          onClick={() => setEventType(type)}
        >
          {upper(title)}
        </div>
      );
    };
    let eventTypesChips = (
      <div className='row' style={{ marginLeft: 0 }}>
        <EventChip type={'water treatment'} title={t('water treatment')} />
        <EventChip type={'irrigation'} title={t('irrigation')} />
        <EventChip type={'rain'} title={t('rain')} />
        <EventChip type={'fertilization'} title={t('fertilization')} />
        {hasAccessToAddDischarge && (
          <EventChip type={'discharge'} title={t('discharge_test')} />
        )}
      </div>
    );

    return (
      <ModalContent
        selectedField={selectedField}
        userData={userData}
        reportedData={reportedData}
        setReportedData={setReportedData}
        measurement={measurement}
        setMeasurement={setMeasurement}
        title={t('report_event')}
        actionButtonText={t('save')}
        eventType={eventType}
        eventTypesChips={eventTypesChips}
        onActionButtonClick={onSaveClick}
        enableAct={enableSave}
        displayAddWTRCheckbox={true}
        enableAddingWTR={enableAddingWTR}
        setEnableAddingWTR={setEnableAddingWTR}
        handleCloseWindow={clearStates}
        ref={ref}
      />
    );
  },
);
