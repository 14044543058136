import { MAX_VALUE_16BITS } from "jszip/lib/utils";
import moment from "moment";

var axios = require("axios");
var GeneralClasses = require("@model/GeneralClasses");

const SCIROOT_DISTRIBUTION_PATH = "distribution";

export var is_admin = (all_distributions, distribution_id, farming_unit_id) => {
  if (all_distributions[distribution_id].role.toLowerCase() == "admin") {
    return true;
  } else if (
    farming_unit_id &&
    all_distributions[distribution_id]["farming_units"][farming_unit_id] &&
    all_distributions[distribution_id]["farming_units"][farming_unit_id].role ==
      "admin"
  ) {
    return true;
  } else {
    return false;
  }
};

export var getFirstInstallationDate = (distributions) => {
  let lastDate = moment().unix();
  for (const distributionKey in distributions) {
    const distribution = distributions[distributionKey];

    for (const farmingUnitKey in distribution.farming_units) {
      const farmingUnit = distribution.farming_units[farmingUnitKey];

      for (const fieldKey in farmingUnit.fields) {
        const field = farmingUnit.fields[fieldKey];

        if (parseInt(field.contract_data.installation_date) < lastDate) {
          lastDate = parseInt(field.contract_data.installation_date);
        }
      }
    }
  }

  return moment.unix(lastDate);
};

export var getFieldCrops = (distributions) => {
  let allCrops = [];
  for (const distributionKey in distributions) {
    const distribution = distributions[distributionKey];

    for (const farmingUnitKey in distribution.farming_units) {
      const farmingUnit = distribution.farming_units[farmingUnitKey];

      for (const fieldKey in farmingUnit.fields) {
        const field = farmingUnit.fields[fieldKey];

        allCrops.push(field.crop_data.crop_type.toLowerCase());
      }
    }
  }

  let uniqueValues = [...new Set(allCrops)];
  return uniqueValues;
};

export var distributions = () => {
  return new Promise(function (resolve, reject) {
    axios
      .get(SCIROOT_DISTRIBUTION_PATH, {
        params: {},
      })
      .then(function (res) {
        var current_distributions = new GeneralClasses.Distributions(res.data);
        resolve(current_distributions);
      })
      .catch(function (e) {
        console.log(e.message);
        reject(e);
      });
  });
};

export var create_distribution = (distribution_name) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(SCIROOT_DISTRIBUTION_PATH, {
        distribution_name: distribution_name,
      })
      .then(function (res) {
        resolve({
          distributionId: res.data["distribution_id"],
          distributionName: res.data["distribution_name"],
        });
      })
      .catch(function (e) {
        console.log(e.message);
        reject(e);
      });
  });
};

export var update_distribution = (distribution_id, distribution_name) => {
  return new Promise(function (resolve, reject) {
    axios
      .put(SCIROOT_DISTRIBUTION_PATH + "/" + distribution_id, {
        distribution_name: distribution_name,
      })
      .then(function (res) {
        resolve("");
      })
      .catch(function (e) {
        console.log(e.message);
        reject(e);
      });
  });
};

export var delete_distribution = (distribution_id) => {
  return new Promise(function (resolve, reject) {
    axios
      .delete(SCIROOT_DISTRIBUTION_PATH + "/" + distribution_id, {})
      .then(function (res) {
        resolve("");
      })
      .catch(function (e) {
        console.log(e.message);
        reject(e);
      });
  });
};

export const getSearchDataByFieldId = (fieldId, distributions) => {
  if (!fieldId || !distributions) {
    return null;
  }
  let selectedField;

  for (const distributionKey in distributions.distributions) {
    const distribution = distributions.distributions[distributionKey];

    for (const farmingUnitKey in distribution.farming_units) {
      const farmingUnit = distribution.farming_units[farmingUnitKey];

      for (const fieldKey in farmingUnit.fields) {
        const field = farmingUnit.fields[fieldKey];

        if (fieldKey == fieldId) {
          return { field, farmingUnit, distribution };
        }
      }
    }
  }

  return selectedField;
};
