var React = require('react');
const NotificationIconContainer = require('./NotificationIconContainer');
const { connect } = require('react-redux');
const { withTranslation, useTranslation } = require('react-i18next');
const NotificationsCenterContainer = require('./NotifiactionsCenterContainer');
const { useState } = require('react');
const $ = require('jquery');
const { useEffect } = require('react');
var notificationsManager = require('notificationsManager');
const moment = require('moment');

const NotificationsCenterHeaderContainer = (props) => {
  const [notificationsData, setNotificationsData] = useState({});
  const { t } = useTranslation();

  const displayModal = () => {
    $('#NotificationsCenterModal').modal('show');
  };

  const hideModal = () => {
    $('#NotificationsCenterModal').addClass('hide');
    setTimeout(() => {
      $('#NotificationsCenterModal').modal('hide');
    }, 700); // Adjust this delay to match the transition duration
  };

  // Listen for modal hidden event
  $('#NotificationsCenterModal').on('hidden.bs.modal', function () {
    $(this).removeClass('hide');
  });

  useEffect(() => {
    notificationsManager
      .getNotificationsForUser(moment().unix())
      .then((res) => {
        setNotificationsData(res);
      })
      .catch(function (e) {
        console.log(e.message);
        reject(e);
      });
  }, []);

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', marginLeft: 'auto' }}
    >
      <div
        className='modal modal-tall modal-slide'
        tabIndex='-1'
        role='dialog'
        id='NotificationsCenterModal'
        data-backdrop='true'
        aria-labelledby='NotificationsCenterModalLabel'
        aria-hidden='true'
      >
        <div
          className='modal-dialog modal-dialog-slide'
          role='document'
          style={{
            position: 'fixed',
            margin: 'auto',
            width: '360px',
            height: '96%',
            marginTop: '48px',
            right: '-1px',
          }}
        >
          <div
            className='modal-content'
            style={{
              height: '100%',
              borderRadius: '0px',
              borderTop: '1px solid #DCE0E8',
              borderLeft: '1px solid #DCE0E8',
              boxShadow:
                '0px 4px 8px 0px rgba(145, 157, 180, 0.18), 0px 1px 4px 0px rgba(146, 158, 181, 0.10)',
            }}
          >
            <div
              className='modal-header'
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '17px 24px 23px 16px',
                borderBottom: '0px',
              }}
            >
              <h5 className='modal-title font-weight-bold text-grey45 text-size-24'>
                {t('notifications')}
              </h5>
              <button
                type='button'
                className='close'
                onClick={hideModal}
                style={{ opacity: 1, outline: 'none' }}
              >
                <img src={require('images/icons/close_button.svg')} />
              </button>
            </div>
            <div className='modal-body p-0' style={{ overflowY: 'auto' }}>
              <NotificationsCenterContainer
                onCloseClicked={hideModal}
                notificationsData={notificationsData}
                openFromMainDashboard={props.openFromMainDashboard}
              />
            </div>
          </div>
        </div>
      </div>
      <NotificationIconContainer
        onIconClicked={displayModal}
        notificationsData={notificationsData}
      />
    </div>
  );
};

module.exports = connect()(
  withTranslation()(NotificationsCenterHeaderContainer),
);
