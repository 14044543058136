var React = require('react');
var { connect } = require('react-redux');
var BaseComponent = require('BaseComponent');
var DashboardContent = require('DashboardContent');
var UpdateFieldModal = require('UpdateFieldModal');
var $ = require('jquery');
var farmingUnitManager = require('farmingUnitManager');
var FieldsContainer = require('FieldsContainer');
var fieldManager = require('fieldManager');
var distributionActions = require('distributionActions');
var ApprovalModal = require('ApprovalModal');
var AlertModal = require('AlertModal');
var moment = require('moment');
const { useState } = require('react');
const { withTranslation } = require('react-i18next');

class Dashboard extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      archiveMode: false,
    };

    this.setArchiveMode = this.setArchiveMode.bind(this);
    this.onPolygonClick = this.onPolygonClick.bind(this);
    this.onDeleteApprovalClick = this.onDeleteApprovalClick.bind(this);
    this.refreshDistributions = this.refreshDistributions.bind(this);

    this.onCreateNewField = this.onCreateNewField.bind(this);

    this.refreshView = this.refreshView.bind(this);
    this.onArchive = this.onArchive.bind(this);

    // update data every 3 hours (the number is in milliseconds)
    setInterval(this.refreshDistributions, 10800000);
  }

  componentDidMount() {}

  refreshDistributions() {
    this.props.dispatch(distributionActions.load_distributions(true));
  }

  componentDidUpdate(nextProps, nextState) {
    if (
      (nextProps.selected_field &&
        this.props.selected_field &&
        JSON.stringify(nextProps.selected_field) !=
          JSON.stringify(this.props.selected_field)) ||
      (!nextProps.selected_field && this.props.selected_field) ||
      nextProps.loadHistoryStatus.status == 'success'
    ) {
      this.refreshView();
      mixpanel.track('Dashboard Load');
    }
  }

  refreshView() {
    var current_selected_field = this.props.selected_field;
    if (current_selected_field) {
      this.updateMap(current_selected_field);
      this.updateGauge(current_selected_field);
    } else {
      // clear views
      this.updateMap(undefined);
      this.updateGauge(undefined);
    }
  }

  updateMap(field) {
    if (this.props.selected_farming_unit) {
      var selected_field_index = field ? field.id : '';

      if (this.custom_map) {
        let fieldsState = this.props.archiveMode ? 'archive' : 'active';

        var all_areas = farmingUnitManager.all_farming_unit_areas(
          this.props.selected_farming_unit,
          selected_field_index,
          fieldsState,
        );
        var all_markers = farmingUnitManager.all_farming_unit_markers(
          this.props.selected_farming_unit,
          selected_field_index,
          fieldsState,
        );

        this.custom_map.invalidate(all_areas, all_markers);
      }
    }
  }

  updateGauge(field) {
    var recommendation_amount = -1;
    var stress_level = 0;
    var recommendation_date_text = '01/01/1970';

    if (field) {
      let fieldDataItems = fieldManager.getFieldItems(field);

      recommendation_amount = fieldDataItems.irrigationDataItem.value;
      var recommendation_date = moment.unix(
        fieldDataItems.irrigationDataItem.date,
      );
      recommendation_date_text = recommendation_date.format('DD/MM/YYYY');
      stress_level = fieldDataItems.stressDataItem.value;
    }

    var message = fieldManager.get_stress_message(
      stress_level,
      recommendation_amount,
      recommendation_date,
      this.props.userData['length'],
    );
  }

  setArchiveMode() {
    this.setState({ archiveMode: !this.state.archiveMode });
  }

  onPolygonClick(polygon_id) {
    var current_selected_field =
      this.props.selected_farming_unit.fields[polygon_id];

    this.props.dispatch(
      distributionActions.selected_entities(
        null,
        null,
        current_selected_field,
        null,
      ),
    );
  }

  onCreateNewField() {
    if (
      this.props.selected_farming_unit != null &&
      this.props.selected_farming_unit.role.toLowerCase() == 'admin'
    ) {
      $('#fieldModalId').modal('show');
    }
  }

  onDeleteApprovalClick(objects) {
    this.props.dispatch(
      distributionActions.delete_field(objects[0], objects[1], objects[2]),
    );
  }

  onArchive(objects) {
    this.props.dispatch(
      distributionActions.update_field(objects[0], objects[1], {
        ...this.props.selected_field,
        settings: {
          ...this.props.selected_field.settings,
          field_state: 'archive',
        },
      }),
    );
    mixpanel.track('Field moved to archive', {
      'Field ID': this.props.selected_field.id,
      'Field Name': this.props.selected_field.name,
    });
  }

  render() {
    const { t } = this.props;

    return (
      <div className='main-screen'>
        <UpdateFieldModal modalId={'fieldModalId'} />
        <ApprovalModal
          modalId={'deletionApprovalModalId'}
          message={t('edit_field_delete_field_message')}
          onApprove={this.onDeleteApprovalClick}
        />
        <ApprovalModal
          modalId={'approvalModalId'}
          message='Are you sure you want to archive this field?'
          onApprove={this.onArchive}
        />
        <AlertModal
          modalId={'deleteAlertModalId'}
          message={this.props.field_actions.error}
          onDismiss={this.props.clear_field_action}
        />

        <div className='d-flex '>
          <div className='d-none d-xl-block' style={{ width: '355px' }}>
            <div
              style={{
                width: '355px',
                height: '100%',
                position: 'fixed',
                boxShadow:
                  '0px 4px 8px 0px rgba(145, 157, 180, 0.18), 0px 1px 4px 0px rgba(146, 158, 181, 0.10)',
              }}
            >
              <FieldsContainer
                onCreateNewField={this.onCreateNewField}
                archiveMode={this.state.archiveMode}
                setArchiveMode={this.setArchiveMode}
              />
            </div>

            <div className='d-none d-xl-block' style={{ width: '355px' }}></div>
          </div>

          <div
            className='flex-fill pl-xl-4 pr-xl-4 pb-xl-4 pt-xl-3 p-2'
            style={{ backgroundColor: '#EEF1F6' }}
          >
            <DashboardContent archiveMode={this.state.archiveMode} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    selected_farming_unit:
      state.distribution_data.selected_entities.farming_unit,
    selected_distribution:
      state.distribution_data.selected_entities.distribution,
    selected_field: state.distribution_data.selected_entities.field,
    field_actions: state.distribution_data.field_actions,
    loadHistoryStatus: state.distribution_data.field_load_history,
    userData: state.authentication_data.user_data,
  };
};

module.exports = connect(mapStateToProps)(withTranslation()(Dashboard));

/*

*/
