var React = require('react');
const { connect } = require('react-redux');
const { withTranslation } = require('react-i18next');
const moment = require('moment');
var userActions = require('userActions');
const { useEffect, useState } = require('react');
var notificationsManager = require('notificationsManager');

const NotificationIconContainer = (props) => {
  const [shouldDisplayRedNotificationDot, setShouldDisplayRedNotificationDot] =
    useState(false);

  useEffect(() => {
    if (
      Object.keys(props.notificationsData).length > 0 &&
      Object.keys(props.userData).length > 0
    ) {
      let NotificationsDataRes = notificationsManager.notificationsDataMap(
        props.notificationsData,
      );
      let latestNotificationDate =
        NotificationsDataRes && NotificationsDataRes.allNotifications
          ? Math.max(
              ...NotificationsDataRes.allNotifications.map((e) => e.date),
            )
          : 0;

      if (
        (props.userData.hasOwnProperty('meta_data') &&
          Object.keys(props.userData?.meta_data).length === 0) ||
        latestNotificationDate === 0 ||
        latestNotificationDate >
          props.userData?.meta_data?.notifications?.notifications_last_visit ||
        props.userData?.meta_data?.notifications?.notifications_last_visit === 0
      ) {
        setShouldDisplayRedNotificationDot(true);
      } else {
        setShouldDisplayRedNotificationDot(false);
      }
    }
  }, [props.notificationsData]);

  const updateUserLastVisit = () => {
    let copyOfUserData = {};
    Object.assign(copyOfUserData, props.userData);

    // for users that see the Notifications Center for the first time
    if (
      !copyOfUserData.hasOwnProperty('meta_data') ||
      Object.keys(copyOfUserData['meta_data']).length === 0
    ) {
      copyOfUserData['meta_data'] = {
        notifications: {
          notifications_last_visit: moment().unix(),
        },
      };

      // edit users date of last time visiting on Notifications Center
    } else {
      copyOfUserData['meta_data'] = {
        notifications: {
          ...copyOfUserData['meta_data']['notifications'],
          notifications_last_visit: moment().unix(),
        },
      };
    }
    props.setUserMetaData({
      meta_data_notifications_last_visit:
        copyOfUserData['meta_data']['notifications'][
          'notifications_last_visit'
        ],
    });
    setShouldDisplayRedNotificationDot(false);
    mixpanel.track('Notification Center Panel Upload');
  };

  const OnNotificationIconClicked = () => {
    props.onIconClicked();
    updateUserLastVisit();
  };

  return (
    <div
      className='notificationsBtn'
      data-toggle='modal'
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginTop: '2px',
        marginRight: '8px',
      }}
      onClick={() => {
        OnNotificationIconClicked();
      }}
    >
      <img
        src={
          shouldDisplayRedNotificationDot
            ? require('images/icons/notifications_icon_red.svg')
            : require('images/icons/notification_icon.svg')
        }
      />
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    userData: state.authentication_data.user_data,
  };
};

const mapDispatchToProps = {
  setUserMetaData: userActions.set_user_meta_data,
};

module.exports = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(NotificationIconContainer));
