var React = require('react');
var RegisterForm = require('RegisterForm');
var MainCSS = require('mainCSS');
var loginCSS = require('loginCSS');
var { connect } = require('react-redux');
var { Redirect, Link } = require('react-router-dom');
var TermsAndConditions = require('TermsAndConditions');

class Register extends React.Component {
  constructor(props) {
    super(props);

    // get the username out of the url params
    var item = this.props.location.search.split('=');
    var user_name = '';
    if (item.length == 2 && item[0] == '?user') {
      user_name = item[1];
    }

    this.state = { user_name: user_name };
  }

  render() {
    // go to root page if login already happened
    if (this.props.id_token) {
      return <Redirect to='/' />;
    } else if (this.props.register_flow.register_status == 'success') {
      return <Redirect to='/confirm' />;
    }

    return (
      <div className='container'>
        <TermsAndConditions modalId={'termsAndConditionsId'} />

        <div className='row justify-content-center mainLoginRow shadow-lg mb-sm-5 rounded mt-sm-5'>
          <div className='d-none d-lg-block col-12 col-lg-6 m-0 p-0 '>
            <img
              src={require('images/login/login-bg.png')}
              className='img-fluid w-100 h-100 '
              alt=''
              style={{ opacity: '0.9' }}
            />
          </div>
          <div className='col-12 col-lg-6 bg-white'>
            <div className='row mt-5'>
              <div className='col text-center'>
                <Link to='/'>
                  <img
                    src={require('images/general/ndrip-logo.png')}
                    className='img-fluid'
                    alt='N-Drip Logo'
                  />
                </Link>
              </div>
            </div>

            <RegisterForm
              history={this.props.history}
              user_name={this.state.user_name}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    id_token: state.authentication_data.tokens.id_token,
    register_flow: state.authentication_data.register_flow,
  };
};

module.exports = connect(mapStateToProps)(Register);
