var React = require('react');
const { useState } = require('react');
var PlusIcon = require('images/icons/plus_icon.svg');

const ValvesInput = ({ valves, onAdd }) => {
  const [curValves, setCurValves] = useState(valves || []);
  const [tagInEditIndex, setTagInEdit] = useState(null);

  const handleChange = (e, i) => {
    const valves = [...curValves];
    if (e.target.value.length) {
      valves[i] = e.target.value;
    } else {
      valves.splice(i, 1);
    }
    setCurValves(valves);
    setTagInEdit(null);
    onAdd(valves);
  };

  const addValveHandler = (e) => {
    const valves = [...curValves];
    valves.push('');
    setTagInEdit(valves.length - 1);
    setCurValves(valves);
  };

  const ValveTagElements = curValves.map((valveNum, i) => {
    const style = {
      width: `52px`,
      height: '26px',
      fontSize: '14px',
      lineHeight: 1,
      padding: '4px',
      background: '#919DB4', // TODO object for this color
      margin: '0 4px 4px 0',
      borderRadius: '500px',
      border: 'none',
      color: 'white',
      outline: 'none',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };
    return tagInEditIndex === i ? (
      <input
        autoFocus={true}
        key={valveNum || Math.random()}
        defaultValue={curValves[i]}
        onBlur={(e) => handleChange(e, i)}
        className='text-size-14 form-control'
        style={style}
        maxLength={7}
      />
    ) : (
      <div
        key={valveNum || Math.random()}
        style={style}
        onClick={(e) => setTagInEdit(i)}
      >
        {curValves[i]}
      </div>
    );
  });

  return (
    <div className='d-flex flex-wrap'>
      {ValveTagElements}
      {tagInEditIndex === null && (
        <div
          className='d-flex btn align-items-center justify-content-center p-0'
          style={{
            backgroundColor: '#DCE0E8',
            borderRadius: '40px',
            height: '24px',
            width: '24px',
          }}
          onClick={addValveHandler}
        >
          <img src={PlusIcon} />
        </div>
      )}
    </div>
  );
};

module.exports = ValvesInput;
