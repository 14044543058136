var React = require("react");
var axios = require("axios");
var { error_message, upper } = require("@managers/helpers");

const SCIROOT_FERTILIZER_DATA_PATH = "fertilizer";
let FERTILIZER_DATA = {};

export const getDensity = (fertType) => {
  return fertType in FERTILIZER_DATA &&
    "matter_state" in FERTILIZER_DATA[fertType] &&
    FERTILIZER_DATA[fertType]["matter_state"] === "liquid"
    ? FERTILIZER_DATA[fertType]["density"]
    : 1;
};

const nitrogen_percentage_in_fertilizer = (fertType) => {
  if (
    FERTILIZER_DATA &&
    fertType &&
    FERTILIZER_DATA[fertType] &&
    ["no3", "nh4"].filter((key) => key in FERTILIZER_DATA)
  ) {
    let nitroInFert =
      FERTILIZER_DATA[fertType]["no3"] + FERTILIZER_DATA[fertType]["nh4"];
    return nitroInFert * getDensity(fertType);
  }
  return 100;
};

export const fertilizerList = () => Object.keys(FERTILIZER_DATA).sort();

export const getMatterState = (fertType) => {
  if (FERTILIZER_DATA && FERTILIZER_DATA[fertType])
    return FERTILIZER_DATA[fertType]["matter_state"];
};

export const nitrogenInFert = (fertType, amount) => {
  return parseFloat(
    ((amount * nitrogen_percentage_in_fertilizer(fertType)) / 100).toFixed(3),
  );
};

export const fertilizerNameToDisplay = (fertType) => {
  switch (fertType) {
    case "no3":
      return "Custom N - NO₃⁻";
    case "nh4":
      return "Custom N - NH₄⁺";
    case "uan 32":
      return "UAN 32";
    default:
      return upper(fertType);
  }
};

export const fertilizer_data = () => {
  return new Promise(function (resolve, reject) {
    axios
      .get(SCIROOT_FERTILIZER_DATA_PATH, {})
      .then(function (res) {
        FERTILIZER_DATA = res.data;
        resolve(res.data);
      })
      .catch(function (err) {
        console.log(error_message(err));
        reject(err);
      });
  });
};
