import {
  getFieldCrops,
  getFirstInstallationDate,
} from "../../managers/distributionManager";

var distributionActions = require("@actions/distributionActions");
var distributionManager = require("@managers/distributionManager");
var farmingUnitManager = require("@managers/farmingUnitManager");
var fieldManager = require("@managers/fieldManager");
var sensorsManager = require("@managers/sensorsManager");
var GeneralClasses = require("@model/GeneralClasses");
var { error_message } = require("@managers/helpers");
var { getItem, setItem } = require("@managers/localStorageManager.jsx");
var { getMixpanelInstance } = require("@managers/mixpanelManager");

// Middleware
export var load_distributions = (
  force_load = false,
  shouldUseCache = false,
  selectedDistributionId = null,
  selectedFarmingUnitId = null,
  selectedFieldId = null,
) => {
  return async (dispatch, getState) => {
    let state = getState();

    // Load only once
    if (!state.distribution_data.distributions || force_load) {
      // used to declare that we already have distributions loaded ( Mobile use )
      dispatch(set_first_all_distributions_load_status("init"));

      const distributions_data = await getItem("DISTRIBUTIONS_DATA");

      if (distributions_data && shouldUseCache) {
        dispatch(set_all_distributions(JSON.parse(distributions_data)));
        dispatch(distributionActions.selected_entities(null, null, null, null));
      }

      distributionManager.distributions().then(
        async function (result) {
          if (result) {
            // Save result to local storage without any modification
            await setItem("DISTRIBUTIONS_DATA", JSON.stringify(result));

            // Refresh state, might have changed while distribution loaded
            state = getState();

            var selectedDistribution = null;
            var selectedFarmingUnit = null;
            var selectedField = null;

            // handle selected entities
            for (var distributionIndex in result["distributions"]) {
              if (
                distributionIndex ==
                state.distribution_data.selected_entities.distribution?.id
              ) {
                selectedDistribution =
                  result["distributions"][distributionIndex];
              }

              for (var farmingUnitIndex in result["distributions"][
                distributionIndex
              ]["farming_units"]) {
                if (
                  farmingUnitIndex ==
                  state.distribution_data.selected_entities.farming_unit?.id
                ) {
                  selectedFarmingUnit =
                    result["distributions"][distributionIndex]["farming_units"][
                      farmingUnitIndex
                    ];

                  // Since distribution load can overwrite data in selected entity, we preserve the data
                  // from state before heading to selected entities
                  if (
                    state.distribution_data.selected_entities.farming_unit
                      .historical_data
                  ) {
                    selectedFarmingUnit.historical_data =
                      state.distribution_data.selected_entities.farming_unit.historical_data;
                  }
                }

                for (var fieldIndex in result["distributions"][
                  distributionIndex
                ]["farming_units"][farmingUnitIndex]["fields"]) {
                  if (
                    state.distribution_data.selected_entities.farming_unit
                      ?.fields[fieldIndex] !== undefined
                  ) {
                    result["distributions"][distributionIndex]["farming_units"][
                      farmingUnitIndex
                    ]["fields"][fieldIndex].historical_data =
                      state.distribution_data.selected_entities.farming_unit.fields[
                        fieldIndex
                      ].historical_data;
                  }

                  if (
                    fieldIndex ===
                      state.distribution_data.selected_entities.field?.id &&
                    state.distribution_data.selected_entities.field.settings
                      .field_state ===
                      result["distributions"][distributionIndex][
                        "farming_units"
                      ][farmingUnitIndex]["fields"][fieldIndex].settings
                        .field_state
                  ) {
                    selectedField =
                      result["distributions"][distributionIndex][
                        "farming_units"
                      ][farmingUnitIndex]["fields"][fieldIndex];
                  }
                }
              }
            }
            dispatch(set_all_distributions(result));

            // Setting selected entities
            dispatch(
              distributionActions.selected_entities(
                selectedDistribution,
                selectedFarmingUnit,
                selectedField,
                null,
              ),
            );

            postDistributionLoadAnalytics(result);
          }
        },
        function (err) {
          console.log(err);
        },
      );
    }
  };
};

export var selected_entities = (distribution, farming_unit, field, sensors) => {
  return async (dispatch, getState) => {
    var state = getState();
    let is_archive_mode = state.distribution_data.field_state === "archive";

    // Distribution
    var selected_distribution = distribution;

    if (!selected_distribution) {
      var selected_distribution_id = undefined;

      if (state.distribution_data.selected_entities.distribution) {
        selected_distribution_id =
          state.distribution_data.selected_entities.distribution.id;
      } else if ((await getItem("SELECTED_DISTRIBUTION_ID", null)) != null) {
        selected_distribution_id = await getItem(
          "SELECTED_DISTRIBUTION_ID",
          null,
        );
      }
      if (state.distribution_data?.distributions?.distributions === undefined) {
        selected_distribution = null;
      } else if (
        selected_distribution_id in
        state.distribution_data?.distributions?.distributions
      ) {
        selected_distribution =
          state.distribution_data.distributions.distributions[
            selected_distribution_id
          ];
      } else {
        for (var first_distribution_id in state.distribution_data.distributions
          .distributions)
          break; // Take the first distribution
        selected_distribution =
          state.distribution_data.distributions.distributions[
            first_distribution_id
          ];
      }
    }

    // Farming Unit
    var selected_farming_unit = farming_unit;

    if (!selected_farming_unit && selected_distribution) {
      var selected_farming_unit_id = undefined;

      if (state.distribution_data.selected_entities.farming_unit) {
        selected_farming_unit_id =
          state.distribution_data.selected_entities.farming_unit.id;
      } else if ((await getItem("SELECTED_FARMING_UNIT_ID", null)) != null) {
        selected_farming_unit_id = await getItem(
          "SELECTED_FARMING_UNIT_ID",
          null,
        );
      }

      if (selected_farming_unit_id in selected_distribution.farming_units) {
        selected_farming_unit =
          selected_distribution.farming_units[selected_farming_unit_id];
      } else {
        for (var first_farming_unit_id in selected_distribution.farming_units)
          break; // Take the first distribution
        selected_farming_unit =
          selected_distribution.farming_units[first_farming_unit_id];
      }
    }

    // Field
    var selected_field = field;

    if (!selected_field && selected_farming_unit) {
      var selected_field_id = undefined;

      if (state.distribution_data.selected_entities.field) {
        selected_field_id = state.distribution_data.selected_entities.field.id;
      } else if ((await getItem("SELECTED_FIELD_ID", null)) != null) {
        selected_field_id = await getItem("SELECTED_FIELD_ID", null);
      }

      // filter just active fields from farmingUnit fields ( field_state is active )
      let filtered_ids = Object.fromEntries(
        Object.entries(selected_farming_unit.fields).filter(
          ([k, field]) => field.settings.field_state === "active",
        ),
      );

      if (is_archive_mode) {
        // when we toggle to archive mode , filter to archive fields only !
        filtered_ids = Object.fromEntries(
          Object.entries(selected_farming_unit.fields).filter(
            ([k, field]) => field.settings.field_state === "archive",
          ),
        );
      }

      if (selected_field_id in filtered_ids) {
        selected_field = selected_farming_unit.fields[selected_field_id];
      } else {
        for (var first_field_id in filtered_ids) break; // Take the first distribution
        selected_field = selected_farming_unit.fields[first_field_id];
      }
    }

    let shouldLoadSelectedFarmingUnitData = false;
    let shouldLoadSelectedFieldData = false;
    let farmingUnitLoadingObject =
      getState().distribution_data.farming_unit_load_history;

    if (
      selected_farming_unit &&
      !farmingUnitLoadingObject.farming_unit_ids.includes(
        selected_farming_unit.id,
      )
    ) {
      // and no history for this field
      // load field history
      shouldLoadSelectedFarmingUnitData = true;
    }

    if (
      shouldLoadSelectedFarmingUnitData &&
      selected_distribution &&
      selected_farming_unit
    ) {
      dispatch(
        get_farming_units_history(
          selected_distribution.id,
          selected_farming_unit.id,
          null,
        ),
      );
      shouldLoadSelectedFieldData = true;
    }

    if (
      selected_field &&
      ((selected_field && !state.distribution_data.selected_entities.field) ||
        JSON.stringify(state.distribution_data.selected_entities.field) !==
          JSON.stringify(selected_field))
    ) {
      shouldLoadSelectedFieldData = true;

      Object.values(selected_field.sensors).map((currentSensor) => {
        selected_field.sensors[currentSensor["id"]].state =
          GeneralClasses.SENSOR_STATES.INIT;
      });
    }

    if (
      shouldLoadSelectedFieldData &&
      selected_distribution &&
      selected_farming_unit &&
      selected_field
    ) {
      dispatch(
        get_field_history(
          selected_distribution.id,
          selected_farming_unit.id,
          selected_field.id,
        ),
      );
    }

    // Sensor
    var selected_sensors = sensors;

    if (!selected_sensors && selected_field) {
      var selected_sensor_transmitter_ids = undefined;

      if ((await getItem("SELECTED_SENSOR_TRANSMITTER_IDS", null)) != null) {
        selected_sensor_transmitter_ids = await getItem(
          "SELECTED_SENSOR_TRANSMITTER_IDS",
          null,
        ).split(",");

        let field_transmitter_ids = sensorsManager.get_transmitter_ids(
          selected_field.sensors,
        );

        // find the stored sensors objects in the current field instance
        let checkSensorsArray = selected_sensor_transmitter_ids.map(
          function (sensor_id) {
            if (field_transmitter_ids.includes(sensor_id)) {
              let selectedSensor = Object.values(selected_field.sensors).filter(
                (a) => a.transmitter_id == sensor_id,
              );
              return selectedSensor;
            } else {
              return undefined;
            }
          },
        );

        if (checkSensorsArray.includes(undefined)) {
          selected_sensors = {};
        } else {
          selected_sensors = selected_sensor_transmitter_ids.reduce(function (
            map,
            obj,
          ) {
            let sensor = Object.values(selected_field.sensors).filter(
              (a) => a.transmitter_id == obj,
            )[0];
            map[sensor.id] = sensor;
            return map;
          }, {});
        }
      } else {
        selected_sensors = {};
      }
    }

    selected_distribution &&
      (await setItem("SELECTED_DISTRIBUTION_ID", selected_distribution.id));
    selected_farming_unit &&
      (await setItem("SELECTED_FARMING_UNIT_ID", selected_farming_unit.id));
    selected_field && (await setItem("SELECTED_FIELD_ID", selected_field.id));

    let field_transmitter_ids =
      sensorsManager.get_transmitter_ids(selected_sensors);
    selected_sensors
      ? await setItem("SELECTED_SENSOR_TRANSMITTER_IDS", field_transmitter_ids)
      : await setItem("SELECTED_SENSORS_ID", null);

    var mixpanelInstance = getMixpanelInstance();
    mixpanelInstance.register({
      Distribution: selected_distribution ? selected_distribution.name : null,
      "Distribution Id": selected_distribution
        ? selected_distribution.id
        : null,
      "Farming Unit": selected_farming_unit ? selected_farming_unit.name : null,
      "Farming Unit Id": selected_farming_unit
        ? selected_farming_unit.id
        : null,
      Field: selected_field ? selected_field.name : null,
      "Field Id": selected_field ? selected_field.id : null,
      "Early Season": fieldManager.isFieldEarlyDays(selected_field),
      "Crop Type": selected_field ? selected_field.crop_data.crop_type : null,
      "Soil Type": selected_field ? selected_field.soil_type : null,
      // 'Sensor Ids': selected_sensors ? selected_sensors.map(a => a.id) : null,
    });

    mixpanelInstance.people.set({
      "Last Distribution": selected_distribution
        ? selected_distribution.name
        : null,
      "Last Farming Unit": selected_farming_unit
        ? selected_farming_unit.name
        : null,
      "Last Field": selected_field ? selected_field.name : null,
    });

    dispatch(
      set_selected_entities(
        selected_distribution,
        selected_farming_unit,
        selected_field,
        selected_sensors,
      ),
    );
  };
};

export var postDistributionLoadAnalytics = (distributions) => {
  let mixpanelInstance = getMixpanelInstance();
  let lastInstallationDate = getFirstInstallationDate(
    distributions.distributions,
  );
  let formattedInstallationTime = lastInstallationDate.format(
    "YYYY-MM-DD HH:mm:ss",
  );

  mixpanelInstance.people.set({
    "First Installation Date": formattedInstallationTime,
  });

  let allUserCrops = getFieldCrops(distributions.distributions);
  mixpanelInstance.people.union({
    Crops: allUserCrops,
  });
};

export var selected_entities_by_id = (distribution_id, farming_unit_id) => {
  return (dispatch, getState) => {
    var state = getState();

    var selected_distribution = null;
    var selected_farming_unit = null;

    var all_distributions = state.distribution_data.distributions.distributions;
    if (all_distributions && distribution_id in all_distributions) {
      selected_distribution = all_distributions[distribution_id];
    }

    if (
      selected_distribution &&
      farming_unit_id in selected_distribution.farming_units
    ) {
      selected_farming_unit =
        selected_distribution.farming_units[farming_unit_id];
    }

    dispatch(
      distributionActions.selected_entities(
        selected_distribution,
        selected_farming_unit,
        null,
      ),
    );
  };
};

export var delete_distribution = (distribution_id) => {
  return (dispatch, getState) => {
    dispatch(initiate_distribution_action("DELETE"));

    if (
      !distributionManager.is_admin(
        getState().distribution_data.distributions.distributions,
        distribution_id,
        null,
      )
    ) {
      dispatch(
        distribution_action_fail(
          "DELETE",
          new GeneralClasses.LocalError(
            "You don't have the right permissions to delete this distribution",
          ),
        ),
      );
    } else {
      // create distribution
      distributionManager.delete_distribution(distribution_id).then(
        function (result) {
          dispatch(distribution_action_success("DELETE"));
          dispatch(load_distributions(true));
        },
        function (err) {
          console.log(err);
          dispatch(distribution_action_fail("DELETE", err));
        },
      );
    }
  };
};

export var update_distribution = (distribution_id, distribution_name) => {
  return (dispatch, getState) => {
    dispatch(initiate_distribution_action("UPDATE"));

    if (
      !distributionManager.is_admin(
        getState().distribution_data.distributions.distributions,
        distribution_id,
        null,
      )
    ) {
      dispatch(
        distribution_action_fail(
          "UPDATE",
          new GeneralClasses.LocalError(
            "You don't have the right permissions to update this distribution",
          ),
        ),
      );
    } else {
      // create distribution
      distributionManager
        .update_distribution(distribution_id, distribution_name)
        .then(
          function (result) {
            dispatch(distribution_action_success("UPDATE"));
            dispatch(load_distributions(true));
          },
          function (err) {
            console.log(err);
            dispatch(distribution_action_fail("UPDATE", err));
          },
        );
    }
  };
};

export var create_distribution = (distribution_name) => {
  return (dispatch, getState) => {
    dispatch(initiate_distribution_action("CREATE"));

    if (!getState().authentication_data.user_details.is_root) {
      dispatch(
        distribution_action_fail(
          "CREATE",
          new GeneralClasses.LocalError(
            "You don't have the right permissions to create distributions",
          ),
        ),
      );
    } else {
      // create distribution
      distributionManager.create_distribution(distribution_name).then(
        function (result) {
          dispatch(distribution_action_success("CREATE"));
          dispatch(load_distributions(true));
        },
        function (err) {
          console.log(err);
          dispatch(distribution_action_fail("CREATE", err));
        },
      );
    }
  };
};

export var set_field_state = (field_state) => {
  return (dispatch, getState) => {
    dispatch(set_field_state_action(field_state));
    dispatch(selected_entities(null, null, null, null));
  };
};

export var initiate_distribution_action = (distribution_action_type) => {
  return {
    type: "DISTRIBUTION_ACTION_INIT",
    distribution_action_type: distribution_action_type,
  };
};

export var distribution_action_success = (distribution_action_type) => {
  return {
    type: "DISTRIBUTION_ACTION_SUCCESS",
    distribution_action_type: distribution_action_type,
  };
};

export var distribution_action_fail = (distribution_action_type, err) => {
  return {
    type: "DISTRIBUTION_ACTION_FAIL",
    distribution_action_type: distribution_action_type,
    error_message: error_message(err),
  };
};

export var clear_distribution_action = () => {
  return {
    type: "CLEAR_DISTRIBUTION_ACTION",
  };
};

export var clear_distribution_data = () => {
  return {
    type: "CLEAR_DISTRIBUTION_DATA",
  };
};

// ======

export var delete_farming_unit = (distribution_id, farming_unit_id) => {
  return (dispatch, getState) => {
    dispatch(initiate_farming_unit_action("DELETE"));

    if (
      !distributionManager.is_admin(
        getState().distribution_data.distributions.distributions,
        distribution_id,
        farming_unit_id,
      )
    ) {
      dispatch(
        farming_unit_action_fail(
          "DELETE",
          new GeneralClasses.LocalError(
            "You don't have the right permissions to delete this farming unit",
          ),
        ),
      );
    } else {
      // delete farming unit
      farmingUnitManager
        .delete_farming_unit(distribution_id, farming_unit_id)
        .then(
          function (result) {
            dispatch(farming_unit_action_success("DELETE"));
            dispatch(load_distributions(true));
          },
          function (err) {
            console.log(err);
            dispatch(farming_unit_action_fail("DELETE", err));
          },
        );
    }
  };
};

export var update_farming_unit = (distribution_id, farming_unit) => {
  return (dispatch, getState) => {
    dispatch(initiate_farming_unit_action("UPDATE"));

    if (
      !distributionManager.is_admin(
        getState().distribution_data.distributions.distributions,
        distribution_id,
        farming_unit.id,
      )
    ) {
      dispatch(
        farming_unit_action_fail(
          "UPDATE",
          new GeneralClasses.LocalError(
            "You don't have the right permissions to update this farming unit",
          ),
        ),
      );
    } else {
      // update farming unit
      farmingUnitManager
        .update_farming_unit(distribution_id, farming_unit)
        .then(
          function (result) {
            dispatch(farming_unit_action_success("UPDATE"));
            dispatch(load_distributions(true));
          },
          function (err) {
            console.log(err);
            dispatch(farming_unit_action_fail("UPDATE", err));
          },
        );
    }
  };
};

export var create_farming_unit = (distribution_id, farming_unit) => {
  return (dispatch, getState) => {
    dispatch(initiate_farming_unit_action("CREATE"));

    if (
      !distributionManager.is_admin(
        getState().distribution_data.distributions.distributions,
        distribution_id,
        null,
      )
    ) {
      dispatch(
        farming_unit_action_fail(
          "CREATE",
          new GeneralClasses.LocalError(
            "You don't have the right permissions to create a farming unit under this distribution",
          ),
        ),
      );
    } else {
      // create farming unit
      farmingUnitManager
        .create_farming_unit(distribution_id, farming_unit)
        .then(
          function (result) {
            dispatch(farming_unit_action_success("CREATE"));
            dispatch(load_distributions(true));
          },
          function (err) {
            console.log(err);
            dispatch(farming_unit_action_fail("CREATE", err));
          },
        );
    }
  };
};

export var get_farming_units_history = (
  distribution_id,
  farming_unit_id,
  field_id,
) => {
  return (dispatch, getState) => {
    dispatch(initiate_farming_unit_history_load_action(farming_unit_id));

    farmingUnitManager
      .loadFarmingUnitHistory(distribution_id, farming_unit_id, field_id)
      .then(
        function (result) {
          let currentFarmingUnit =
            getState().distribution_data.distributions.distributions[
              distribution_id
            ].farming_units[farming_unit_id];

          for (let currentFieldId in currentFarmingUnit.fields) {
            currentFarmingUnit.fields[currentFieldId].historical_data = {
              ...currentFarmingUnit.fields[currentFieldId].historical_data,
              ...result["historicalData"][currentFieldId],
            };
          }
          currentFarmingUnit.historical_data = {
            ...result["historicalData"][farming_unit_id],
          };
          dispatch(success_farming_unit_history_load_action(farming_unit_id));
          dispatch(clear_status_farming_unit_history_load_action());
        },
        function (err) {
          console.log(err);
        },
      );
  };
};

export var get_field_history = (distribution_id, farming_unit_id, field_id) => {
  return (dispatch, getState) => {
    dispatch(initiate_field_history_load_action(field_id));

    fieldManager
      .loadFieldHistory(distribution_id, farming_unit_id, field_id)
      .then(
        function (result) {
          let currentField =
            getState().distribution_data.distributions.distributions[
              distribution_id
            ].farming_units[farming_unit_id].fields[field_id];
          currentField.historical_data = result["historicalData"][field_id];

          dispatch(success_field_history_load_action(field_id));
          dispatch(clear_status_field_history_load_action());
        },
        function (err) {
          console.log(err);
        },
      );
  };
};

// ======

export var initiate_farming_unit_action = (farming_unit_action_type) => {
  return {
    type: "FARMING_UNIT_ACTION_INIT",
    farming_unit_action_type: farming_unit_action_type,
  };
};

export var farming_unit_action_success = (farming_unit_action_type) => {
  return {
    type: "FARMING_UNIT_ACTION_SUCCESS",
    farming_unit_action_type: farming_unit_action_type,
  };
};

export var farming_unit_action_fail = (farming_unit_action_type, err) => {
  return {
    type: "FARMING_UNIT_ACTION_FAIL",
    farming_unit_action_type: farming_unit_action_type,
    error_message: error_message(err),
  };
};

export var clear_farming_unit_action = () => {
  return {
    type: "CLEAR_FARMING_UNIT_ACTION",
  };
};

export var initiate_farming_unit_history_load_action = (farming_unit_id) => {
  return {
    type: "LOAD_FARMING_UNIT_HISTORY_ACTION_INIT",
    farming_unit_id: farming_unit_id,
  };
};

export var initiate_field_history_load_action = (field_id) => {
  return {
    type: "LOAD_FIELD_HISTORY_ACTION_INIT",
    field_id: field_id,
  };
};

export var clear_farming_unit_history_load_action = () => {
  return {
    type: "LOAD_FARMING_UNIT_HISTORY_ACTION_CLEAR",
    farming_unit_id: "",
  };
};

export var clear_status_farming_unit_history_load_action = () => {
  return {
    type: "LOAD_FARMING_UNIT_HISTORY_ACTION_CLEAR_STATUS",
  };
};

export var clear_status_field_history_load_action = () => {
  return {
    type: "LOAD_FIELD_HISTORY_ACTION_CLEAR_STATUS",
  };
};

export var success_farming_unit_history_load_action = (farming_unit_id) => {
  return {
    type: "LOAD_FARMING_UNIT_HISTORY_ACTION_SUCCESS",
    farming_unit_id: farming_unit_id,
  };
};
export var success_field_history_load_action = (field_id) => {
  return {
    type: "LOAD_FIELD_HISTORY_ACTION_SUCCESS",
    field_id: field_id,
  };
};

export var delete_field = (distribution_id, farming_unit_id, field_id) => {
  return (dispatch, getState) => {
    dispatch(initiate_field_action("DELETE"));

    if (
      !distributionManager.is_admin(
        getState().distribution_data.distributions.distributions,
        distribution_id,
        farming_unit_id,
      )
    ) {
      dispatch(
        field_action_fail(
          "DELETE",
          new GeneralClasses.LocalError(
            "You don't have the right permissions to delete this field",
          ),
        ),
      );
    } else {
      // delete farming unit
      fieldManager
        .delete_field(distribution_id, farming_unit_id, field_id)
        .then(
          function (result) {
            dispatch(field_action_success("DELETE"));
            dispatch(load_distributions(true));

            var mixpanelInstance = getMixpanelInstance();

            mixpanelInstance.track("Field Delete");
          },
          function (err) {
            console.log(err);
            dispatch(field_action_fail("DELETE", err));
          },
        );
    }
  };
};

export var update_field = (distribution_id, farming_unit_id, field_object) => {
  return (dispatch, getState) => {
    dispatch(initiate_field_action("UPDATE"));

    if (
      !distributionManager.is_admin(
        getState().distribution_data.distributions.distributions,
        distribution_id,
        farming_unit_id,
      ) &&
      !farmingUnitManager.is_moderator(
        getState().distribution_data.selected_entities.farming_unit,
      )
    ) {
      dispatch(
        field_action_fail(
          "UPDATE",
          new GeneralClasses.LocalError(
            "You don't have the right permissions to update this field",
          ),
        ),
      );
    } else {
      // update field
      fieldManager
        .update_field(distribution_id, farming_unit_id, field_object)
        .then(
          function (result) {
            dispatch(field_action_success("UPDATE"));
            dispatch(load_distributions(true));

            var mixpanelInstance = getMixpanelInstance();
            mixpanelInstance.track("Field Update");
          },
          function (err) {
            console.log(err);
            dispatch(field_action_fail("UPDATE", err));
          },
        );
    }
  };
};

export var create_field = (distribution_id, farming_unit_id, field_object) => {
  return (dispatch, getState) => {
    var state = getState();

    dispatch(initiate_field_action("CREATE"));

    if (
      !distributionManager.is_admin(
        state.distribution_data.distributions.distributions,
        distribution_id,
        farming_unit_id,
      )
    ) {
      dispatch(
        field_action_fail(
          "CREATE",
          new GeneralClasses.LocalError(
            "You don't have the right permissions to create a field under this farming unit",
          ),
        ),
      );
    } else {
      // create field
      fieldManager
        .create_field(distribution_id, farming_unit_id, field_object)
        .then(
          function (result) {
            dispatch(field_action_success("CREATE"));
            dispatch(
              load_distributions(
                true,
                false,
                null,
                null,
                result["fieldId"],
                null,
              ),
            );

            mixpanel.track("Field Create", {
              Field: result["fieldName"],
              "Field Id": result["fieldId"],
            });
          },
          function (err) {
            console.log(err);
            dispatch(field_action_fail("CREATE", err));
          },
        );
    }
  };
};

export var initiate_field_action = (field_action_type) => {
  return {
    type: "FIELD_ACTION_INIT",
    field_action_type: field_action_type,
  };
};

export var field_action_success = (field_action_type) => {
  return {
    type: "FIELD_ACTION_SUCCESS",
    field_action_type: field_action_type,
  };
};

export var field_action_fail = (field_action_type, err) => {
  return {
    type: "FIELD_ACTION_FAIL",
    field_action_type: field_action_type,
    error_message: error_message(err),
  };
};

export var clear_field_action = () => {
  return {
    type: "CLEAR_FIELD_ACTION",
  };
};

export var set_selected_entities = (
  selected_distribution,
  selected_farming_unit,
  selected_field,
  selected_sensors,
) => {
  return {
    type: "SET_SELECTED_ENTITIES",
    selected_distribution: selected_distribution,
    selected_farming_unit: selected_farming_unit,
    selected_field: selected_field,
    selected_sensors: selected_sensors,
  };
};

export var set_all_distributions = (distributions) => {
  return {
    type: "SET_ALL_DISTRIBUTIONS",
    distributions: distributions,
  };
};

export const set_transmitter_coordinates = (transmitter_coordinates) => {
  return {
    type: "SET_TRANSMITTER_COORDINATES",
    transmitter_coordinates,
  };
};

export var set_field_state_action = (field_state) => {
  return {
    type: "SET_FIELD_STATE",
    field_state: field_state,
  };
};

export var set_first_all_distributions_load_status = (status) => {
  return {
    type: "SET_FIRST_ALL_DISTRIBUTIONS_LOAD_STATUS",
    status: status,
  };
};

export const set_selected_farming_unit = (selected_farming_unit) => {
  return {
    type: "SET_SELECTED_FARMING_UNIT",
    selected_farming_unit,
  };
};

export const set_selected_distribution = (selected_distribution) => {
  return {
    type: "SET_SELECTED_DISTRIBUTION",
    selected_distribution,
  };
};
