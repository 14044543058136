var React = require('react');

const SwitchToArchive = require('images/icons/fields/switch_to_archive_fields.svg');
const SwitchToActive = require('images/icons/fields/switch_to_active_fields.svg');
const { useTranslation } = require('react-i18next');

const toggleSizeDictionary = {
  big: { width: '62px', height: '36px' },
  medium: { width: '42px', height: '24px' },
  small: { width: '28px', height: '16px' },
};

const ToggleSwitch = ({
  initialValue,
  onToggleClick,
  toggleTitle,
  toggleSize = 'small',
  isLabelLeft = false,
  applyMarginBottom = true,
}) => {
  const { t } = useTranslation();
  const titleDiv = (
    <div
      className='text-size-14 align-items-end text-grey45'
      style={{ height: '18px', lineHeight: '1.8', paddingLeft: '12px' }}
    >
      {t(toggleTitle)}
    </div>
  );

  return (
    <div
      className='d-flex justify-content-end align-items-center'
      style={{ gap: '8px' }}
    >
      {!isLabelLeft && titleDiv}
      <div style={{ width: '28px', height: '16px' }}>
        <button
          className='p-0'
          style={{
            border: '0px',
            outline: 'none',
            backgroundColor: 'transparent',
          }}
          onClick={onToggleClick}
        >
          <img
            style={toggleSizeDictionary[toggleSize]}
            src={initialValue ? SwitchToArchive : SwitchToActive}
            className={applyMarginBottom ? 'mb-1' : ''}
          />
        </button>
      </div>
      {isLabelLeft && titleDiv}
    </div>
  );
};

module.exports = ToggleSwitch;
