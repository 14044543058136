import React, { useState, useEffect } from 'react';
import {
  DeleteDialogModalContent,
  ModalContainer,
  SuccessMessage,
} from './EventModal';
import $ from 'jquery';

export const DeleteModal = (props) => {
  const [shouldDisplayActionModal, setShouldDisplayActionModal] =
    useState(false);
  const [actionModalStatus, setActionModalStatus] = useState('success');
  const [item, setItem] = useState({
    type: '',
    source: '',
    startDate: '',
    wfrValue: '',
    wfrEndDate: '',
    sensorId: '',
    sourceID: '',
  });
  let tempItem = {
    type: '',
    source: '',
    startDate: '',
    wfrValue: '',
    wfrEndDate: '',
    sensorId: '',
    sourceID: '',
  };
  let values = [];

  useEffect(() => {
    $('#deleteModal').on('show.bs.modal', function (event) {
      if (event.relatedTarget) {
        let button = $(event.relatedTarget); // Button that triggered the modal
        values = button[0].dataset.values.split(',');
        tempItem.type = values[0];
        tempItem.source = values[1];
        tempItem.startDate = values[2];
        tempItem.wfrValue = values[3];
        tempItem.wfrEndDate = values[4];
        tempItem.sensorId = values[5];
        tempItem.sourceID = values[6];
        setItem(tempItem);
      }
    });
  }, []);

  let containerDefaultStyle = { width: 304, top: 160 };
  const successStyle = {
    width: '400px',
    position: 'absolute',
    bottom: '86px',
    right: '22px',
  };
  return (
    <ModalContainer
      style={shouldDisplayActionModal ? successStyle : containerDefaultStyle}
      id={'deleteModal'}
    >
      {shouldDisplayActionModal ? (
        <SuccessMessage
          status={actionModalStatus}
          t={props.t}
          eventType={'delete'}
        />
      ) : (
        <DeleteDialogModalContent
          item={item}
          setActionModalStatus={setActionModalStatus}
          setDisplayActionModal={setShouldDisplayActionModal}
          t={props.t}
        />
      )}
    </ModalContainer>
  );
};
