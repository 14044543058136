var React = require('react');
var { connect } = require('react-redux');
var sensorsManager = require('sensorsManager');
var weatherManager = require('weatherManager');
var farmingUnitManager = require('farmingUnitManager');
var fieldManager = require('fieldManager');
var distributionActions = require('distributionActions');
var SensorGraph = require('SensorGraph');
var GeneralClasses = require('GeneralClasses');
var GraphToggle = require('GraphToggle');
var { withTranslation } = require('react-i18next');
var moment = require('moment-timezone'); //moment-timezone
var rolesManager = require('@managers/rolesManager');
var { daysSinceNowFromString } = require('helpers');

class WaterPotentialGraphContainer extends React.Component {
  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    this.loadDataForManySensors = this.loadDataForManySensors.bind(this);

    this.addSensorData = this.addSensorData.bind(this);
    this.updateSelectionRedux = this.updateSelectionRedux.bind(this);
    this.handleIrrigationInputDisplay =
      this.handleIrrigationInputDisplay.bind(this);
    this.handleNitrogenInputDisplay =
      this.handleNitrogenInputDisplay.bind(this);
    this.closeInputFrame = this.closeInputFrame.bind(this);
    this.selectAllSensors = this.selectAllSensors.bind(this);

    this.clearData = this.clearData.bind(this);

    this.precipitationDeleted = this.precipitationDeleted.bind(this);

    this.onSensorSelection = this.onSensorSelection.bind(this);
    this.getSelectedWPSensors = this.getSelectedWPSensors.bind(this);

    this.sensor_samples = null;
    this.loading_timer = null;
    this.state = {
      dummy: '',
      days_back: 7,
      requested_sensor_ids: [],
      timezone: '',
      units: '',
      isIrrigationdrawerOpen: false,
      isNitrogenDrawerOpen: false,
      weatherData: undefined,
      firstTimeInit: true,
    };
  }

  componentDidMount() {
    if (this.props.selectedField) {
      if (this.props.field_state === 'archive') {
        let twoYearsInDays = 356 * 2;
        let daysSinceNowFromHarvest = daysSinceNowFromString(
          this.props.selectedField.crop_data.harvest_date,
        );
        this.setState({ days_back: daysSinceNowFromHarvest + twoYearsInDays });
      }
      this.initGraph(this.props.selectedField);
    }
  }

  componentWillUnmount() {}

  componentDidUpdate(nextProps, nextState) {
    // update graph for every new field
    if (
      nextProps.loadHistoryStatus.status == 'success' ||
      this.props.temperature_h != nextProps.temperature_h ||
      (this.props.selectedField &&
        nextProps.selectedField &&
        JSON.stringify(this.props.selectedField) !=
          JSON.stringify(nextProps.selectedField)) ||
      (this.props.selectedField && this.state.firstTimeInit)
    ) {
      this.setState({ firstTimeInit: false });
      this.initGraph(this.props.selectedField);
    }
  }

  initGraph(field) {
    this.clearData();

    if (field && field.sensors && Object.keys(field.sensors).length > 0) {
      if (
        this.props.selectedSensors &&
        Object.keys(this.props.selectedSensors).length > 0
      ) {
        this.loadDataForManySensors(
          undefined,
          Object.keys(this.props.selectedSensors),
          field.geo_data.time_zone,
          this.state.days_back,
        );
      } else {
        // on initial page load, we load all sensors
        this.selectAllSensors();
      }
    }
  }

  precipitationDeleted(precipitationDate, source) {
    var that = this;
    let deletePrecipitation;
    if (source === 'manual') {
      deletePrecipitation = fieldManager.deleteFieldRecord;
      source = this.props.selectedField.id;
    } else {
      deletePrecipitation = weatherManager.deleteWeatherRecord;
    }
    deletePrecipitation(source, precipitationDate, 'precipitation_h').then(
      function (res) {
        that.props.getFieldHistory(
          that.props.selectedDistribution.id,
          that.props.selectedFarmingUnit.id,
          that.props.selectedField.id,
        );
        mixpanel.track('Precipitation Deleted', {
          Source: source,
          Date: precipitationDate,
        });
      },
      function (error) {
        console.log(error);
      },
    );
  }

  addSensorData(newSensorData) {
    if (
      this.props.selectedSensors &&
      this.props.selectedField &&
      'etc' in this.props.selectedField.historical_data &&
      this.props.temperature_h &&
      Object.keys(this.props.selectedField.historical_data.etc.values).length >
        0
    ) {
      let currentSelectedSensors = this.props.selectedSensors;
      let soilData =
        this.props.soil_data?.soil_data[
          this.props.selectedField.soil_type.toLowerCase()
        ];

      currentSelectedSensors[newSensorData.id] = newSensorData;
      newSensorData.displayName =
        sensorsManager.sensorShortDisplayName(newSensorData);
      let temperatureStatistics = weatherManager.weatherDataStatistics(
        this.props.temperature_h.values,
        this.props.selectedField.geo_data.time_zone,
      );
      let etcStatistics = weatherManager.weatherDataStatistics(
        this.props.selectedField.historical_data.etc.values,
        this.props.selectedField.geo_data.time_zone,
      );

      this.sensor_graph?.loadData(
        currentSelectedSensors,
        this.props.selectedField.historical_data,
        parseInt(soilData?.suggestedMinWPValue),
        this.props.selectedField.geo_data.time_zone,
        temperatureStatistics,
        etcStatistics,
      );

      this.updateSelectionRedux(currentSelectedSensors);
    }
  }

  removeSensorData(sensorId) {
    if (
      this.props.soil_data &&
      this.props.selectedField &&
      this.props.selectedField.sensors &&
      sensorId in this.props.selectedField.sensors &&
      this.props.temperature_h &&
      this.props.selectedField.historical_data.etc
    ) {
      let currentSelectedSensors = this.props.selectedSensors;
      let soilData =
        this.props.soil_data.soil_data[
          this.props.selectedField.soil_type.toLowerCase()
        ];

      this.props.selectedField.sensors[sensorId].state =
        GeneralClasses.SENSOR_STATES.INIT;
      delete currentSelectedSensors[sensorId];
      let temperatureStatistics = weatherManager.weatherDataStatistics(
        this.props.temperature_h.values,
        this.props.selectedField.geo_data.time_zone,
      );
      let etcStatistics = weatherManager.weatherDataStatistics(
        this.props.selectedField.historical_data.etc.values,
        this.props.selectedField.geo_data.time_zone,
      );

      this.sensor_graph.loadData(
        currentSelectedSensors,
        this.props.selectedField.historical_data,
        parseInt(soilData?.suggestedMinWPValue),
        this.props.selectedField.geo_data.time_zone,
        temperatureStatistics,
        etcStatistics,
      );
      this.updateSelectionRedux(currentSelectedSensors);
    }
  }

  onSensorSelection(sensor, event) {
    if (event.target.checked) {
      // update new sensor for loading state and selection
      let currentSelectedSensors = this.props.selectedSensors;

      let currentSensor = this.props.selectedField.sensors[sensor.id];

      currentSelectedSensors[currentSensor.id] = currentSensor;
      this.updateSelectionRedux(currentSelectedSensors);

      this.loadData(sensor.id, this.state.timezone, this.state.days_back);

      mixpanel.track('Sensor Selection Changed', {
        'Sensor Id': sensor.id,
        Selected: event.target.checked,
      });
    } else {
      this.removeSensorData(sensor.id);
      mixpanel.track('Sensor Selection Changed', {
        'Sensor Id': sensor.id,
        Selected: event.target.checked,
      });
    }
  }

  selectAllSensors() {
    let currentSelectedSensors = this.props.selectedSensors;

    let sensorsList = Object.keys(this.props.selectedField.sensors).filter(
      (currentSensor) =>
        this.props.selectedField.sensors[currentSensor] instanceof
        GeneralClasses.WPSensor,
    );

    let unselectedSensors = sensorsList.filter(
      (sensorId) =>
        !(sensorId in currentSelectedSensors) ||
        !currentSelectedSensors[sensorId],
    );

    unselectedSensors.map((sensorId) => {
      currentSelectedSensors[sensorId] =
        this.props.selectedField.sensors[sensorId];
    });

    this.updateSelectionRedux(currentSelectedSensors);
    this.loadDataForManySensors(
      null,
      unselectedSensors,
      this.state.timezone,
      this.state.days_back,
    );
  }

  onSelectAllSensorsSelection(event) {
    if (event.target.checked) {
      this.selectAllSensors();
    } else {
      Object.keys(this.props.selectedSensors).forEach((sensorId) =>
        this.removeSensorData(sensorId),
      );
    }
    mixpanel.track('Select All', { Selected: event.target.checked });
  }

  areAllSensorsSelected() {
    let currentSelectedSensors = this.props.selectedSensors;
    let allSensors =
      this.props.selectedField && this.props.selectedField.sensors
        ? this.props.selectedField.sensors
        : null;
    if (!allSensors || !currentSelectedSensors) return false;

    let WPSensorsList = Object.keys(allSensors).filter(
      (currentSensor) =>
        allSensors[currentSensor] instanceof GeneralClasses.WPSensor,
    );
    return WPSensorsList.length === Object.keys(currentSelectedSensors).length;
  }

  updateSelectionRedux(selectedSensors) {
    this.props.setSelectedEntities(null, null, null, selectedSensors);

    if (this && this.state) {
      this.setState({ dummy: Math.random() });
    }
  }

  loadDataForManySensors(event, sensors, timezone, days_back = 7) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    sensors.map((currentSensor) =>
      this.loadData(currentSensor, timezone, days_back),
    );

    mixpanel.track('Timeframe Selection Changed', { 'Days Back': days_back });
  }

  clearData() {
    this.sensor_graph.loadData(null, null, undefined, undefined);
  }

  loadData(sensor_id, timezone, days_back = 7) {
    if (sensor_id && timezone) {
      var that = this;

      if (sensor_id.trim() in this.props.selectedField.sensors) {
        this.props.selectedField.sensors[sensor_id.trim()].state =
          GeneralClasses.SENSOR_STATES.LOADING;

        this.setState({
          days_back: days_back,
          timezone: timezone,
        });

        sensorsManager
          .sensor_samples(this.props.selectedField.id, sensor_id, days_back)
          .then(
            function (res) {
              if (that.sensor_graph && that.props.selectedField) {
                if ('code' in res && res.code == 1001) {
                  // no new data arrived
                } else if (
                  res.sensor_data.length > 0 &&
                  res.serial_number in that.props.selectedField.sensors
                ) {
                  let currentSensorData = res.sensor_data.map(
                    (sample, index) => {
                      return {
                        value: parseFloat(sample.value),
                        date: moment.unix(sample.date).tz(timezone),
                        index: index,
                      };
                    },
                  );

                  mixpanel.track('Graph Load', {
                    'Sensor Id': res.serial_number,
                    'Days Back': days_back,
                  });

                  // get sensor color
                  let sensorIndex = Object.keys(
                    sensorsManager.getWPSensors(
                      that.props.selectedField.sensors,
                    ),
                  ).indexOf(res.serial_number);

                  let currentSensor =
                    that.props.selectedField.sensors[res.serial_number];
                  currentSensor.color = sensorsManager.sensorColorByIndex(
                    sensorIndex,
                    that.props.field_state,
                  );
                  currentSensor.data = currentSensorData;
                  currentSensor.state = GeneralClasses.SENSOR_STATES.COMPLETE;

                  that.setState({ units: res.units });

                  that.addSensorData(currentSensor);
                } else {
                  that.removeSensorData(sensor_id);
                }

                if (that.props.onGraphUpdate) {
                  that.props.onGraphUpdate();
                }
              }
            },
            function (error) {
              console.log(error);
            },
          );
      }
    }
  }

  getSelectedWPSensors() {
    if (this.props.selectedSensors) {
      let wpSensors = Object.entries(this.props.selectedSensors).filter(
        (a) => a[1] instanceof GeneralClasses.WPSensor,
      );
      let wpSensorsDict = wpSensors.reduce(function (prev, curr) {
        prev[curr[0]] = curr[1];
        return prev;
      }, {});
      return wpSensorsDict;
    } else {
      return {};
    }
  }

  getWPSensors() {
    if (this.props.selectedSensors) {
      let wpSensors = Object.entries(this.props.selectedField.sensors).filter(
        (a) => a[1] instanceof GeneralClasses.WPSensor,
      );
      let wpSensorsDict = wpSensors.reduce(function (prev, curr) {
        prev[curr[0]] = curr[1];
        return prev;
      }, {});
      return wpSensorsDict;
    } else {
      return {};
    }
  }

  closeInputFrame(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      isIrrigationdrawerOpen: false,
      isNitrogenDrawerOpen: false,
    });
  }

  handleNitrogenInputDisplay(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      isIrrigationdrawerOpen: false,
      isNitrogenDrawerOpen: !this.state.isNitrogenDrawerOpen,
    });
  }

  handleIrrigationInputDisplay(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      isIrrigationdrawerOpen: !this.state.isIrrigationdrawerOpen,
      isNitrogenDrawerOpen: false,
    });
  }

  render() {
    const { t } = this.props;

    let dateFilterNames = {
      1: t('last_day'),
      7: t('last_week'),
      14: t('last_two_weeks'),
      30: t('last_month'),
      182: t('last_six_months'),
      365: t('last_year'),
    };
    let isAdmin = rolesManager.getIsAdmin(this.props.allDistributions);
    let graphTitle = this.props.onToggleClick
      ? t('water_potential_graph')
      : t('water_pressure_history');

    let wpSelectedSensors = this.getSelectedWPSensors();
    let selectedSensorIds = Object.keys(wpSelectedSensors);

    // is graph loading
    let sensorsInLoadingState = true;
    let wpSensors = sensorsManager.getWPSensors(
      this.props.selectedField ? this.props.selectedField.sensors : [],
    );
    let wpSensorsIds = Object.keys(wpSensors);
    if (wpSensors) {
      sensorsInLoadingState =
        Object.keys(wpSensors).filter(
          (a) => wpSensors[a].state === GeneralClasses.SENSOR_STATES.LOADING,
        ).length > 0;
    }

    let titleColor =
      this.props.field_state === 'active' ? 'text-grey45' : 'text-grey71';
    return (
      <div className='w-100 h-100'>
        <div
          className='d-flex justify-content-between'
          style={{ paddingLeft: '63px', paddingRight: '63px' }}
        >
          <div className='bg-blued-flex mr-2'>
            {this.props.onToggleClick ? (
              <GraphToggle
                initialValue='drop'
                onToggleClick={(toggle) => this.props.onToggleClick(toggle)}
                icon={this.props.icon}
              />
            ) : (
              <div></div>
            )}
          </div>

          <div className='d-flex'>
            <div
              className={
                'd-flex align-items-center d-inline text-size-16 font-weight-bold text-truncate ' +
                titleColor
              }
            >
              {graphTitle}
            </div>

            <div className='d-flex d-inline align-items-center ml-2'>
              {sensorsInLoadingState && (
                <div
                  className='spinner-border text-grey45 '
                  role='status'
                  style={{ width: '15px', height: '15px' }}
                ></div>
              )}
            </div>
          </div>

          <div className='row'>
            {this.props.field_state === 'active' && (
              <div className='dropdown text-size-14 ml-3 mr-2 text-grey71 align-self-center align-items-center'>
                <div
                  className='dropdown-toggle'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                  style={{ cursor: 'pointer' }}
                >
                  {this.state &&
                    dateFilterNames[this.state.days_back.toString()]}
                </div>

                <div
                  className='dropdown-menu'
                  aria-labelledby='dropdownMenuButton'
                >
                  <a
                    className='dropdown-item text-grey71'
                    href='#'
                    key='1'
                    onClick={(e) => {
                      this.loadDataForManySensors(
                        e,
                        wpSensorsIds,
                        this.state.timezone,
                        1,
                      );
                    }}
                  >
                    {dateFilterNames['1']}
                  </a>
                  <a
                    className='dropdown-item text-grey71'
                    href='#'
                    key='2'
                    onClick={(e) => {
                      this.loadDataForManySensors(
                        e,
                        wpSensorsIds,
                        this.state.timezone,
                        7,
                      );
                    }}
                  >
                    {dateFilterNames['7']}
                  </a>
                  <a
                    className='dropdown-item text-grey71'
                    href='#'
                    key='3'
                    onClick={(e) => {
                      this.loadDataForManySensors(
                        e,
                        wpSensorsIds,
                        this.state.timezone,
                        14,
                      );
                    }}
                  >
                    {dateFilterNames['14']}
                  </a>
                  <a
                    className='dropdown-item text-grey71'
                    href='#'
                    key='4'
                    onClick={(e) => {
                      this.loadDataForManySensors(
                        e,
                        wpSensorsIds,
                        this.state.timezone,
                        30,
                      );
                    }}
                  >
                    {dateFilterNames['30']}
                  </a>
                  <a
                    className='dropdown-item text-grey71'
                    href='#'
                    key='5'
                    onClick={(e) => {
                      this.loadDataForManySensors(
                        e,
                        wpSensorsIds,
                        this.state.timezone,
                        182,
                      );
                    }}
                  >
                    {dateFilterNames['182']}
                  </a>
                  {isAdmin && (
                    <a
                      className='dropdown-item text-grey71'
                      href='#'
                      key='6'
                      onClick={(e) => {
                        this.loadDataForManySensors(
                          e,
                          wpSensorsIds,
                          this.state.timezone,
                          365,
                        );
                      }}
                    >
                      {dateFilterNames['365']}
                    </a>
                  )}
                </div>
              </div>
            )}

            <div
              className={
                'dropdown text-size-14 text-grey71 mr-2 ml-lg-2 mr-lg-3 align-self-center ' +
                (farmingUnitManager.shouldDisplayAllSensors(
                  this.props.selectedFarmingUnit,
                )
                  ? ''
                  : 'd-none')
              }
            >
              <div
                className='dropdown-toggle'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
                style={{ cursor: 'pointer' }}
              >
                {t('select_sensors')}
              </div>
              <div
                className='dropdown-menu pt-3'
                aria-labelledby='dropdownMenuButton'
                style={{ width: '80px' }}
              >
                {/*select all checkbox*/}
                <div
                  className='d-flex mb-3 align-items-center'
                  key={'select_all'}
                >
                  <input
                    className='ml-2'
                    type='checkbox'
                    checked={this.areAllSensorsSelected()}
                    onChange={(element) => {
                      this.onSelectAllSensorsSelection(element);
                    }}
                  />
                  <span
                    className='text-size-14 text-grey45 text-truncate pl-2 pr-2 mr-2'
                    style={{ borderRadius: '3px', lineHeight: '20px' }}
                    title={'Select all'}
                  >
                    {t('select_all')}
                  </span>
                </div>

                {/*sensors' checkboxes*/}
                {Object.keys(wpSensors).map((item, index) => {
                  let name = sensorsManager.sensorDisplayName(wpSensors[item]);
                  let color = sensorsManager.sensorColorByIndex(
                    index,
                    this.props.field_state,
                  );
                  let isSelected = false;
                  let spinnerClasses = 'd-none';
                  let isLoading =
                    wpSensors[item].state ==
                    GeneralClasses.SENSOR_STATES.LOADING;
                  spinnerClasses = isLoading ? 'mr-2' : 'd-none';
                  if (
                    (this.props.selectedSensors &&
                      item in this.props.selectedSensors) ||
                    isLoading
                  ) {
                    isSelected = true;
                  }

                  return (
                    <div className='d-flex mb-3 align-items-center' key={index}>
                      <input
                        className='ml-2'
                        type='checkbox'
                        checked={isSelected}
                        onChange={(element) => {
                          this.onSensorSelection(wpSensors[item], element);
                        }}
                      />
                      <span
                        className=' ml-2 text-size-14 text-white text-truncate pl-2 pr-2 mr-2'
                        style={{
                          borderRadius: '3px',
                          lineHeight: '20px',
                          backgroundColor: color,
                        }}
                        title={name}
                      >
                        {name}
                      </span>
                      <div className={spinnerClasses}>
                        <div
                          className='spinner-border text-secondary'
                          role='status'
                          style={{ width: '16px', height: '16px' }}
                        ></div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className='pb-4  h-100'>
          <div className='d-flex h-100'>
            <div className='flex-fill' style={{ width: '40%' }}>
              <SensorGraph
                onRef={(ref) => (this.sensor_graph = ref)}
                onGraphUpdate={this.onGraphUpdate}
                units={this.state.units}
                isLoading={sensorsInLoadingState}
                selectedField={this.props?.selectedField}
                selectedFarmingUnit={this.props?.selectedFarmingUnit}
                selectedDistribution={this.props?.selectedDistribution}
                onPrecipitationDeletion={this.precipitationDeleted}
                shouldDrawInputs={true}
                shouldDrawBottom={true}
                shouldDrawTodayMark={false}
                shouldDrawResetButton={true}
                toolTipMode={'nearest'}
                distributionMode={'series'}
                shouldDisplayZoom={true}
                shouldSpaceData={true}
                shouldDiplayUTC={false}
                shouldScaleGraph={false}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  const field = state.distribution_data.selected_entities.field;
  return {
    selectedSensors: state.distribution_data.selected_entities.sensors,
    selectedField: field,
    temperature_h:
      field && field.historical_data && field.historical_data.temperature_h,
    plan_irr: field && field.historical_data && field.historical_data.plan_irr,
    selectedFarmingUnit: state.distribution_data.selected_entities.farming_unit,
    soil_data: state.soil_data.soil_data,
    loadHistoryStatus: state.distribution_data.field_load_history,
    selectedDistribution:
      state.distribution_data.selected_entities.distribution,
    allDistributions: state.distribution_data.distributions,
    field_state: state.distribution_data.field_state,
  };
};

const mapDispatchToProps = {
  setSelectedEntities: distributionActions.selected_entities,
  getFieldHistory: distributionActions.get_field_history,
};

module.exports = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(WaterPotentialGraphContainer));

/*
<a href="#" onClick={(evt) => this.handleIrrigationInputDisplay(evt)}>
  <img src={ require('images/icons/graph/irrigation.svg') } /></a>

  <u>{t('report_irrigation')}</u>
*/

// <button className="d-none d-xl-block btn btn-grey71 text-grey96  general-button dropdown-toggle " style={{'border':'0px solid #919db4', height:'32px'}} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//   {this.state && dateFilterNames[this.state.days_back.toString()]}
//
// </button>
// <button className="d-none d-xl-block btn text-grey71 general-button dropdown-toggle" style={{'border':'0px solid #919db4', height:'32px'}} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//   Select Sensors
//
// </button>

/*
<div className="m-0 p-0 align-self-end flex-shrink mr-3" >
  <a className="text-grey71 text-size-14" href="#" onClick={(evt) => this.handleNitrateInputDisplay(evt)}>
    <div className="m-0 p-0" title={t('report_irrigation')}>Nitrate</div>

  </a>
</div>


<div style={{'width':'230px'}} className="bg-green">
    <a className="text-grey71 text-size-14" href="#" onClick={(evt) => this.handleNitrateInputDisplay(evt)}>
      <div className="m-0 p-0" title={t('report_irrigation')}>Report Nitrate</div>

    </a>
</div>

*/
